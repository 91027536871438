import * as H from 'history'
import * as React from 'react'
import {RouteComponentProps} from 'react-router-dom'
import APILoggedOutError from '~/api/client/APIError/APILoggedOutError'
import APIError from '~/api/client/APIError/base'
import api from '~/api/endpoints'
import DotDotDot from '~/components/DotDotDot'
import Spacer from '~/components/Spacer'
import {DSTextDiv} from '~/design-system/DSText'
import FEMLayout from '~/layouts/FEMLayout'
import {logoutRedux} from '~/redux/reducers/root'
import {Redirect} from '~/routing/ReactRouterLinks'
import {staticURLs} from '~/routing/sitemap'
import {safely, unreachableCase} from '~/utils'
import {stringFromPayload} from '~/utils/components'

type TRedirectLocation = 'login' | 'home'

export type TLogoutPagePayload = {
  redirectTo?: TRedirectLocation // defaults to 'home'
}

interface IProps extends RouteComponentProps<any> {}

type TLogoutStep = 'loading' | 'success' | 'retrying'
type TState = {
  redirectTo: TRedirectLocation
  step: TLogoutStep
}

const RETRY_INTERVAL_MS = 10 * 1000 // retry every 10 seconds

const redirectLocation: Record<TRedirectLocation, H.LocationDescriptor> = {
  login: staticURLs.auth.signin,
  home: staticURLs.home,
}

const redirectLocationFromString = (
  loc: string | undefined
): TRedirectLocation | undefined => {
  switch (loc) {
    case 'login':
      return loc
    default:
      return undefined
  }
}

export default class SignOutPage extends React.Component<IProps, TState> {
  retryInterval: number | undefined = undefined

  state: TState = {
    step: 'loading',
    redirectTo:
      redirectLocationFromString(
        stringFromPayload<TLogoutPagePayload>(this.props)('redirectTo')
      ) ?? 'home',
  }

  runLogout = () => {
    const handleLogoutSuccess = () => {
      // clear all redux state
      logoutRedux()
      // clear user's data
      // Analytics.reset()

      // NB: this is not a Route.SignedIn so we can do this redirect ourself
      this.setState({step: 'success'})
    }

    api.auth.signOut
      .post({}, {})
      .then(() => {
        handleLogoutSuccess()
      })
      .catch((e: APIError) => {
        if (e instanceof APILoggedOutError) {
          // since this is a Route.Simple, and cases like another tab logged out, etc., this is possible
          handleLogoutSuccess()
        } else {
          // some error on logout occurred. in this case, we haven't logged out with the server, so retry soon
          // this will occur i.e. if the user's computer loses internet/sleeps and the logout timer is triggered
          console.warn('Error while trying to sign out, retrying in 10 seconds. ', e)

          this.retryInterval = window.setInterval(() => {
            if (this.state.step === 'retrying') {
              safely(this.retryInterval, window.clearInterval)
              this.runLogout()
            }
          }, RETRY_INTERVAL_MS)

          this.setState({
            step: 'retrying',
          })
        }
      })
  }

  componentDidMount() {
    this.runLogout()
  }

  componentWillUnmount() {
    safely(this.retryInterval, window.clearInterval)
  }

  renderLoggingOut = (step: 'retrying' | 'loading') => {
    const text =
      step === 'retrying' ? `Logging out failed, retrying automatically soon.` : null

    return (
      <FEMLayout>
        <Spacer height="40px" />
        <DSTextDiv size="h2">
          <DotDotDot>Logging out</DotDotDot>
        </DSTextDiv>
        {!!text && (
          <>
            <Spacer height="12px" />
            <DSTextDiv size="body-17">{text}</DSTextDiv>
          </>
        )}
      </FEMLayout>
    )
  }

  render() {
    switch (this.state.step) {
      case 'success':
        return <Redirect to={redirectLocation[this.state.redirectTo]} />
      case 'loading':
      case 'retrying':
        return this.renderLoggingOut(this.state.step)

      default:
        return unreachableCase(this.state.step)
    }
  }
}
