import {Range} from '~/utils/types'

export const MAX_NUMBER_OF_PLAYERS: 8 = 8
export const MIN_NUMBER_OF_PLAYERS: 2 = 2
export type TValidNumberOfPlayers =
  | Range<typeof MIN_NUMBER_OF_PLAYERS, typeof MAX_NUMBER_OF_PLAYERS>
  | typeof MAX_NUMBER_OF_PLAYERS

export const MAX_CARDS_IN_HAND = 7

export type TRuleset = {
  // oneEachUnordered: can have a house, a hotel, or both
  // oneEachOrdered: can have a house, or a house and hotel.
  // oneTotal: can only have either a house or a hotel, but not both
  // unrestricted: can have N houses, N hotels, or M houses and N hotels
  residenceStacking:
    | 'oneEachUnordered'
    | 'oneEachOrdered'
    | 'oneTotal'
    | 'unrestricted'
  residencesOnUtilitiesAndRailroads: boolean

  // other possible / fun rules we could support:
  // discardTo: 'bottomOfDeck' | 'usedPile'
  // zeroCardsAway: 'allowed' | 'instantWin'
}

export const defaultRuleset: TRuleset = {
  residenceStacking: 'oneEachUnordered',
  residencesOnUtilitiesAndRailroads: false,
}
