import {TMajorActionSlyDealData} from '~/api/generated/types/common'
import {SlyDealCard} from '~/models/game/Cards/ActionCard'
import {propertyCardFactory} from '~/models/game/factories'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MajorAction, {
  TMajorActionUserFacingDescriptionData,
} from '~/models/game/MajorAction'
import PropertyCard from '~/models/game/Cards/PropertyCard'
import {unambiguousNeighborhoodDescriptionData} from '~/models/game/MajorActions/utils'
import {compact} from '~/utils'
import {sentenceCase, xVerbY} from '~/utils/language'

export default class MajorActionSlyDeal extends MajorAction {
  card: SlyDealCard
  minorActorPlayerId: string // target player
  requestedCard: PropertyCard<any>

  constructor(data: TMajorActionSlyDealData) {
    super(data)
    this.card = new SlyDealCard(data.card)
    this.minorActorPlayerId = data.minorActorPlayerId
    this.requestedCard = propertyCardFactory(data.requestedCard)
  }

  public userFacingDescription({
    tense,
    skin,
    gamePosition,
  }: TMajorActionUserFacingDescriptionData): string {
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined
    const majorActor = gamePosition.majorActorPlayer
    const minorActor = gamePosition.players.find(
      o => o.id === this.minorActorPlayerId
    )
    if (!minorActor) {
      throw new Error(`Minor actor not found in MajorActionSlyDeal description`)
    }

    const portfolioNeighborhoodOfRequestedCard = minorActor.portfolio.neighborhoods.find(
      pn => pn.properties.find(p => p.id === this.requestedCard.id)
    )

    if (!portfolioNeighborhoodOfRequestedCard) {
      throw new Error(
        `Sly Deal's requested card not found in minor actor's portfolio`
      )
    }

    const {sameColorCount, sameColorIndex} = unambiguousNeighborhoodDescriptionData(
      minorActor.portfolio,
      portfolioNeighborhoodOfRequestedCard
    )

    const dropFirstNoun = tense === 'imperativePrompt'
    const {conjugatedVerb, xNoun, yNounPossessive} = xVerbY(
      tense,
      majorActor,
      dropFirstNoun ? 'Play' : 'play',
      minorActor,
      currentPlayer?.id
    )

    return compact([
      dropFirstNoun
        ? undefined
        : xNoun === majorActor.username
        ? xNoun
        : sentenceCase(xNoun),
      conjugatedVerb,
      this.card.displayName(skin),
      'against',
      yNounPossessive,
      this.requestedCard.displayName(skin),
      sameColorCount > 1
        ? `from ${portfolioNeighborhoodOfRequestedCard.displayColor(
            skin
          )} neighborhood #${sameColorIndex + 1}`
        : undefined,
    ]).join(' ')
  }
}
