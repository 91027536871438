import {subSeconds} from 'date-fns'
import {OmitStrict} from 'type-zoo'
import {PostApi} from '~/api/client'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TAdminSignUpsResponse} from '~/api/generated/types/AdminSignUps'
import {TAdminSignUpRequestData} from '~/api/generated/types/common'
import {TSignUpParams} from '~/api/generated/types/Signup'

type TAdminSignUpsParams = {}
export type TAdminSignUpsResult = OmitStrict<
  TAdminSignUpsResponse,
  'signupRequests'
> & {
  signupRequests: Array<
    OmitStrict<TAdminSignUpRequestData, 'createdAt'> & {
      createdAt: Date
    }
  >
}

export default class AdminSignUps extends PostApi<
  TAdminSignUpsParams,
  TAdminSignUpsResponse,
  {},
  TAdminSignUpsResult
> {
  protected getMockResponse(params: TSignUpParams): TAdminSignUpsResponse {
    return {
      signupRequests: AdminSignupsMock,
    }
  }

  protected getPath() {
    return serverEndpoints.adminAdminSignUps()
  }

  protected transformResponse(r: TAdminSignUpsResponse): TAdminSignUpsResult {
    return transformAdminSignUpsResponse(r)
  }
}

export const transformAdminSignUpsResponse = (
  r: TAdminSignUpsResponse
): TAdminSignUpsResult => {
  return {
    ...r,
    signupRequests: r.signupRequests.map(sr => ({
      ...sr,
      createdAt: new Date(sr.createdAt),
    })),
  }
}

export const AdminSignupsMock: TAdminSignUpRequestData[] = [
  {
    id: '456',
    email: 'monroe456@example.com',
    createdAt: new Date().toISOString(),
    username: 'monroe456',
  },
  {
    id: '123',
    email: 'monroe123@example.com',
    createdAt: subSeconds(new Date(), 90).toISOString(),
    username: 'monroe123',
  },
]
