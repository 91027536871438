import classnames from 'classnames'
import * as React from 'react'
import {OmitStrict} from 'type-zoo'
import styles from './styles.module.css'

type TTooltipPosition = 'above' | 'right' | 'custom'
type TTooltipStyle = 'dark' | 'light'

/** `tipText` will show in a tooltip bubble centered over `children` when hovered. */
interface IProps
  extends OmitStrict<React.HTMLAttributes<HTMLDivElement>, 'className'> {
  tipText: JSX.Element
  position?: TTooltipPosition // default: 'above'. If 'custom', will not have speech bubble arrow
  classNames?: {
    tooltipBubble?: string
    wrapper?: string
  }
  disableTooltip?: boolean
  tooltipStyle?: TTooltipStyle // defaults to 'dark'
  noArrow?: boolean // remove speech bubble arrow
}

/**
 * Wrap a component that should have a tooltip shown for it when hovered in a TooltipWrapper.
 * The hover detection on the children will be done for you.
 */
export default class TooltipWrapper extends React.Component<IProps> {
  render() {
    const {
      tipText,
      classNames = {},
      children,
      position,
      disableTooltip,
      noArrow,
      tooltipStyle = 'dark',
      ...divProps
    } = this.props
    const realPosition = position || 'above'

    return (
      <div
        className={classnames(
          styles['tooltip-wrapper'],
          !disableTooltip && styles['tooltip-wrapper-enabled'],
          classNames.wrapper,
          disableTooltip && styles['disable-tooltip'],
          styles[`tooltip-style-${tooltipStyle}`]
        )}
        {...divProps}
      >
        <div
          className={classnames(
            styles['tooltip-bubble'],
            styles[`tooltip-bubble-${realPosition}`],
            !noArrow && styles[`tooltip-bubble-${realPosition}-arrow`],
            classNames.tooltipBubble
          )}
        >
          {tipText}
        </div>
        {children}
      </div>
    )
  }
}
