import * as React from 'react'
import api from '~/api/endpoints'
import {TWaitingRoomResponse} from '~/api/generated/types/GetGameWaitingRoom'
import DashboardLayout from '~/layouts/DashboardLayout'
import WaitingRoomUI from '~/pages/WaitingRoom/stateless'
import {navigateTo} from '~/routing/navigate'
import {ISignedInRouteComponentProps} from '~/routing/Route/SignedIn'
import {dynamicURLs} from '~/routing/sitemap'

export interface IWaitingRoomPagePathPieces {
  gameId: string
}

interface IProps extends ISignedInRouteComponentProps<IWaitingRoomPagePathPieces> {}

interface IState {
  currentData: TWaitingRoomResponse | undefined
  gameId: string

  // as soon as you join a game, refresh the waiting room data immediately
  justJoined: boolean
}

export default class WaitingRoomPage extends React.Component<IProps, IState> {
  state: IState = {
    currentData: undefined,
    gameId: this.props.match.params.gameId,
    justJoined: false,
  }

  receiveNewData = (currentData: TWaitingRoomResponse) => {
    if (currentData.status === 'started') {
      this.gameStartedHandler()
    } else {
      this.setState({currentData})
    }
  }

  gameStartedHandler = () => {
    navigateTo(dynamicURLs.game.lobbyV2({gameId: this.state.gameId}))
  }

  render() {
    const {gameId, justJoined} = this.state
    return (
      <api.preGame.getWaitingRoomData.PromiseManager
        params={{}}
        pathPieces={{gameId}}
        onSuccess={this.receiveNewData}
        then={() => (
          <DashboardLayout {...this.props}>
            <api.preGame.getWaitingRoomData.PromisePoller
              params={{}}
              pathPieces={{gameId}}
              refreshTrigger={justJoined}
              onSuccess={this.receiveNewData}
              frequencyMs={[2000, 1000, 3000, 5000, -1]}
              pollImmediatelyOnMount={false}
            >
              <WaitingRoomUI
                data={this.state.currentData!}
                user={this.props.user}
                startGame={() => api.preGame.startGame.post({}, {gameId})}
                gameStarted={this.gameStartedHandler}
                joinGame={() => api.preGame.joinGame.post({}, {gameId})}
                joinGameSuccess={() => this.setState({justJoined: true})}
                kickPlayer={playerId =>
                  api.preGame.kickPlayer
                    .post({}, {gameId, playerId})
                    .then(this.receiveNewData)
                }
              />
            </api.preGame.getWaitingRoomData.PromisePoller>
          </DashboardLayout>
        )}
      />
    )
  }
}
