import {css} from 'emotion'
import * as React from 'react'
import {TSubmitMajorActionResponse} from '~/api/generated/types/SubmitMajorAction'
import DotDotDot from '~/components/DotDotDot'
import DSButton from '~/design-system/Button'
import {MAX_CARDS_IN_HAND} from '~/game/ruleset'
import SpecialPropertyCard from '~/models/game/Cards/SpecialPropertyCard'
import MajorActionDiscard from '~/models/game/MajorActions/MajorActionDiscard'
import CurrentPlayerHandUI, {
  ICurrentPlayerHandUIProps,
} from '~/pages/GameLobby/CurrentPlayerHand/stateless'

interface IProps extends ICurrentPlayerHandUIProps {}

interface IState {
  autoEndingTurnUI:
    | 'notTriedYet'
    | 'inProgress'
    | 'failed'
    | 'done'
    | 'notApplicable'
}

export default class CurrentPlayerHand extends React.Component<IProps, IState> {
  state: IState = {
    autoEndingTurnUI: 'notTriedYet',
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
    const {playerGamePosition} = this.props

    // skip the requirement to click the 'end turn' button if there are no free actions left to take
    // and their turn is over
    if (
      this.state.autoEndingTurnUI === 'notTriedYet' &&
      playerGamePosition.isMyMajorTurn() &&
      !playerGamePosition.inProgressMajorAction &&
      (playerGamePosition.play === 'discardPlay' ||
        playerGamePosition.currentPlayerHand.cardCount() === 0) &&
      playerGamePosition.currentPlayerHand.cardCount() <= MAX_CARDS_IN_HAND
    ) {
      const portfolio = playerGamePosition.currentPlayer.portfolio
      if (
        portfolio.allProperties().find(p => {
          return (
            p instanceof SpecialPropertyCard &&
            p.possibleDestinationNeighborhoods(portfolio).length > 0
          )
        })
      ) {
        // they can rotate a card, so we can't auto-end
        this.setState({autoEndingTurnUI: 'notApplicable'})
        return
      }

      if (portfolio.completeSets().find(cs => cs.residences.length > 0)) {
        // they can move a residence to cash, so we can't auto-end
        this.setState({autoEndingTurnUI: 'notApplicable'})
        return
      }

      // it's the end of their turn, and there are no inProgressMajorActions
      // they have no cards to play / can't play any more cards,
      // they don't need to / can't discard
      // there are no properties to change neighborhoods with
      // there are no residences to move to cash
      // this is all public information, so ending their turn right away is fine/nice
      this.setState({autoEndingTurnUI: 'inProgress'})
    } else if (
      this.state.autoEndingTurnUI !== 'notTriedYet' &&
      (playerGamePosition.play !== 'discardPlay' ||
        !playerGamePosition.isMyMajorTurn())
    ) {
      this.setState({autoEndingTurnUI: 'notTriedYet'})
    }
  }

  render() {
    return (
      <CurrentPlayerHandUI
        {...this.props}
        buttonAreaOverride={
          this.state.autoEndingTurnUI === 'inProgress' && (
            <ButtonAreaOverride
              {...this.props}
              autoDiscardFailed={() => this.setState({autoEndingTurnUI: 'failed'})}
              chooseMajorActionSubmitSuccess={r => {
                this.setState({autoEndingTurnUI: 'done'}, () =>
                  this.props.chooseMajorActionSubmitSuccess(r)
                )
              }}
            />
          )
        }
      />
    )
  }
}

interface IButtonAreaOverrideProps
  extends Pick<
    ICurrentPlayerHandUIProps,
    'chooseMajorAction' | 'chooseMajorActionSubmitSuccess'
  > {
  autoDiscardFailed: () => void
}

interface IButtonAreaOverrideState {
  loading: boolean
  promiseResult?: TSubmitMajorActionResponse
}

class ButtonAreaOverride extends React.Component<
  IButtonAreaOverrideProps,
  IButtonAreaOverrideState
> {
  state: IButtonAreaOverrideState = {
    loading: true,
  }

  componentDidMount() {
    this.props
      .chooseMajorAction(
        new MajorActionDiscard({
          type: 'discard',
          cards: [],
        })
      )
      .then(r => this.setState({loading: false, promiseResult: r}))
      .catch(e => {
        console.warn('error auto-discarding:', e)
        this.setState({loading: false}, this.props.autoDiscardFailed)
      })
  }

  componentDidUpdate(
    prevProps: Readonly<IButtonAreaOverrideProps>,
    prevState: Readonly<IButtonAreaOverrideState>
  ) {
    if (prevState.loading && !this.state.loading && this.state.promiseResult) {
      this.props.chooseMajorActionSubmitSuccess(this.state.promiseResult)
    }
  }

  render() {
    return (
      <DSButton
        size="17px"
        buttonStyle="tertiary-destructive"
        disabled
        className={css`
          width: 165px;
          text-align: left;
        `}
      >
        <DotDotDot>Auto-ending Turn</DotDotDot>
      </DSButton>
    )
  }
}
