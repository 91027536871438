import {TCardsInHand} from '~/api/generated/types/common'
import Card from '~/models/game/Card'
import {cardFactory} from '~/models/game/factories'
import Hand from '~/models/game/Hand'

export default class ExposedHand extends Hand {
  public cards: Card<any>[]

  constructor(handData: TCardsInHand & {type: 'exposed'}) {
    super()
    this.cards = handData.cards.map(c => cardFactory(c))
  }

  public cardCount(): number {
    return this.cards.length
  }
}
