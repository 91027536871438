import classnames from 'classnames'
import {css} from 'emotion'
import * as RA from 'ramda-adjunct'
import React from 'react'
import {OmitStrict} from 'type-zoo'
import {TSubmitMajorActionResponse} from '~/api/generated/types/SubmitMajorAction'
import {TSubmitMinorActionResponse} from '~/api/generated/types/SubmitMinorAction'
import {
  ISingleButtonFormProps,
  SingleButtonForm,
} from '~/components/SingleButtonForm'
import DSButton, {IDSButtonProps} from '~/design-system/Button'
import {DSTextDiv} from '~/design-system/DSText'
import {DSLinkText} from '~/design-system/Link'
import {MAX_CARDS_IN_HAND, TRuleset} from '~/game/ruleset'
import {TSkin} from '~/game/skins'
import {movesLeftForPlay, playHumanOrdinal} from '~/game/types'
import {
  getObviousPayment,
  groupedCardDisplayNames,
  paymentPropriety,
  TProperPaymentResponse,
} from '~/game/utils'
import dealTunaSrc from '~/img/art/dealtuna.png'
import Card from '~/models/game/Card'
import ActionCard, {
  DealBreakerCard,
  DebtCollectorCard,
  ForcedDealCard,
  JustSayNoCard,
  SlyDealCard,
} from '~/models/game/Cards/ActionCard'
import CashCard from '~/models/game/Cards/CashCard'
import DualPropertyCard from '~/models/game/Cards/DualPropertyCard'
import RentCard, {
  DirectionalRentCard,
  UniversalRentCard,
} from '~/models/game/Cards/RentCard'
import ResidenceCard from '~/models/game/Cards/ResidenceCard'
import SpecialPropertyCard from '~/models/game/Cards/SpecialPropertyCard'
import GameData from '~/models/game/GameData'
import MajorAction from '~/models/game/MajorAction'
import MajorActionBirthday from '~/models/game/MajorActions/MajorActionBirthday'
import MajorActionChangeNeighborhood from '~/models/game/MajorActions/MajorActionChangeNeighborhood'
import MajorActionDealBreaker from '~/models/game/MajorActions/MajorActionDealBreaker'
import MajorActionDebtCollector from '~/models/game/MajorActions/MajorActionDebtCollector'
import MajorActionDirectionalRent from '~/models/game/MajorActions/MajorActionDirectionalRent'
import MajorActionDiscard from '~/models/game/MajorActions/MajorActionDiscard'
import MajorActionDoubleTheRent from '~/models/game/MajorActions/MajorActionDoubleTheRent'
import MajorActionForcedDeal from '~/models/game/MajorActions/MajorActionForcedDeal'
import MajorActionPassGo from '~/models/game/MajorActions/MajorActionPassGo'
import MajorActionPlayAsCash from '~/models/game/MajorActions/MajorActionPlayAsCash'
import MajorActionPlayToNeighborhood from '~/models/game/MajorActions/MajorActionPlayToNeighborhood'
import MajorActionResidenceToCash from '~/models/game/MajorActions/MajorActionResidenceToCash'
import MajorActionSlyDeal from '~/models/game/MajorActions/MajorActionSlyDeal'
import MajorActionUniversalRent from '~/models/game/MajorActions/MajorActionUniversalRent'
import MinorAction from '~/models/game/MinorAction'
import MinorActionPromptForcedDeal from '~/models/game/MinorActionPrompts/MinorActionPromptForcedDeal'
import MinorActionPromptJustSayNo from '~/models/game/MinorActionPrompts/MinorActionPromptJustSayNo'
import MinorActionAcceptForcedDeal from '~/models/game/MinorActions/MinorActionAcceptForcedDeal'
import MinorActionJustSayNo from '~/models/game/MinorActions/MinorActionJustSayNo'
import MinorActionPaySomething from '~/models/game/MinorActions/MinorActionPaySomething'
import {AnyMajorAction} from '~/models/game/types'
import {valueOfCards} from '~/models/game/utils'
import {
  CardBackImage,
  cardHeightFromWidth,
} from '~/pages/GameLobby/GameCard/stateless'
import {TClickableCardStateV2} from '~/pages/GameLobbyV2/stateless'
import {V2LobbyCardImage} from '~/pages/GameLobbyV2/utils'
import {compact, safelyOr, unreachableCase} from '~/utils'
import {pluralize, show$} from '~/utils/language'
import * as M from '~/utils/Map'
import * as S from '~/utils/Set'
import {isInstance} from '~/utils/types'
import styles from './styles.module.css'

interface IProps {
  gameData: GameData

  clickableCardState: TClickableCardStateV2
  setClickableCardState: (
    setFunc: (prevState: TClickableCardStateV2) => TClickableCardStateV2
  ) => void

  chooseMajorAction: (
    majorAction: MajorAction
  ) => Promise<TSubmitMajorActionResponse>
  chooseMajorActionSubmitSuccess: (response: TSubmitMajorActionResponse) => void
  chooseMinorAction: (
    minorAction: MinorAction<any>
  ) => Promise<TSubmitMinorActionResponse>
  chooseMinorActionSubmitSuccess: (response: TSubmitMinorActionResponse) => void

  skin: TSkin
  rules: TRuleset
}

interface IState {
  autoEndingTurnUI:
    | 'notTriedYet'
    | 'inProgress'
    | 'failed'
    | 'done'
    | 'notApplicable'
}

export default class GameBoardCenter extends React.Component<IProps, IState> {
  state: IState = {
    autoEndingTurnUI: 'notTriedYet',
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
    const {playerGamePosition} = this.props.gameData
    if (!playerGamePosition) {
      return
    }

    // skip the requirement to click the 'end turn' button if there are no free actions left to take
    // and their turn is over
    if (
      this.state.autoEndingTurnUI === 'notTriedYet' &&
      playerGamePosition.isMyMajorTurn() &&
      !playerGamePosition.inProgressMajorAction &&
      (playerGamePosition.play === 'discardPlay' ||
        playerGamePosition.currentPlayerHand.cardCount() === 0) &&
      playerGamePosition.currentPlayerHand.cardCount() <= MAX_CARDS_IN_HAND
    ) {
      const portfolio = playerGamePosition.currentPlayer.portfolio
      if (
        portfolio.allProperties().find(p => {
          return (
            p instanceof SpecialPropertyCard &&
            p.possibleDestinationNeighborhoods(portfolio).length > 0
          )
        })
      ) {
        // they can rotate a card, so we can't auto-end
        this.setState({autoEndingTurnUI: 'notApplicable'})
        return
      }

      if (portfolio.completeSets().find(cs => cs.residences.length > 0)) {
        // they can move a residence to cash, so we can't auto-end
        this.setState({autoEndingTurnUI: 'notApplicable'})
        return
      }

      // it's the end of their turn, and there are no inProgressMajorActions
      // they have no cards to play / can't play any more cards,
      // they don't need to / can't discard
      // there are no properties to change neighborhoods with
      // there are no residences to move to cash
      // this is all public information, so ending their turn right away is fine/nice
      this.setState({autoEndingTurnUI: 'inProgress'})
    } else if (
      this.state.autoEndingTurnUI !== 'notTriedYet' &&
      (playerGamePosition.play !== 'discardPlay' ||
        !playerGamePosition.isMyMajorTurn())
    ) {
      this.setState({autoEndingTurnUI: 'notTriedYet'})
    }
  }

  render() {
    const {gameData, skin} = this.props
    const {currentGamePosition, playerGamePosition} = gameData

    const [mainTurnText, secondaryTurnText] = ((): [string, string] => {
      if (currentGamePosition.inProgressMajorAction) {
        return [
          // TODO this is a bit long for the middle of the table in most cases
          currentGamePosition.inProgressMajorAction.majorAction.userFacingDescription(
            {
              gamePosition: currentGamePosition,
              skin,
              tense: 'past',
            }
          ),
          safelyOr(
            currentGamePosition.inProgressMajorAction.pendingMinorActions[0],
            pma =>
              `Waiting for ${
                playerGamePosition?.currentPlayer?.id === pma.minorActor.id
                  ? 'you'
                  : pma.minorActor.username
              }`,
            ` `
          ),
        ]
      }

      if (currentGamePosition.isMyMajorTurn()) {
        if (currentGamePosition.play === 'discardPlay') {
          return [`You have no moves left`, `End your turn when you're ready`]
        } else {
          return [
            `Make your ${playHumanOrdinal[currentGamePosition.play]} move`,
            `Waiting...`,
          ]
        }
      } else {
        const movesLeft = movesLeftForPlay[currentGamePosition.play]
        return [
          `It’s ${currentGamePosition.majorActorPlayer.username}’s turn`,
          // could remove this since we have the arc markers now
          `${movesLeft} ${pluralize(movesLeft, 'move')} left`,
        ]
      }
    })()

    return (
      <div className={styles.gameBoardCenterWrapper}>
        <div className={styles.textInCenterWrapper}>
          <DSTextDiv size="body-17-demi" color="white">
            {mainTurnText}
          </DSTextDiv>
          <DSTextDiv size="label" color="white">
            {secondaryTurnText}
          </DSTextDiv>
        </div>
        <CenterOfTableCards
          skin={skin}
          cardsInDeck={currentGamePosition.cardsInDeck}
          lastPlayedCard={currentGamePosition.recentlyPlayedCards[0]}
          classNames={{wrapper: styles.gameBoardCenterCards}}
        />
        {this.renderOverlayForClickableCardState()}
        {gameData.winner && (
          <DSTextDiv size="h3" className={classnames(styles.winnerBanner)}>
            <img src={dealTunaSrc} />
            <p>{gameData.winner.username} won the game!</p>
          </DSTextDiv>
        )}
      </div>
    )
  }

  DiscardNowButton = ({
    disabled,
    buttonText,
    majorActionDiscard,
  }: {
    majorActionDiscard: MajorActionDiscard
    disabled: boolean
    buttonText: string
  }) => (
    <SingleButtonForm
      key={this.state.autoEndingTurnUI === 'inProgress' ? 1 : 2}
      buttonStyle="tableAction"
      size="15px"
      disabled={disabled}
      onSubmit={() => {
        return this.props.chooseMajorAction(majorActionDiscard)
      }}
      submitSuccess={response => {
        this.setState(
          prevState => {
            if (prevState.autoEndingTurnUI === 'inProgress') {
              return {autoEndingTurnUI: 'done'}
            }
            return null
          },
          () => this.props.chooseMajorActionSubmitSuccess(response)
        )
      }}
      submitFailure={() => {
        this.setState(prevState => {
          if (prevState.autoEndingTurnUI === 'inProgress') {
            return {autoEndingTurnUI: 'failed'}
          }
          return null
        })
      }}
      submitOnMount={this.state.autoEndingTurnUI === 'inProgress'}
    >
      {buttonText}
    </SingleButtonForm>
  )

  renderOverlayForClickableCardState() {
    const {clickableCardState, gameData, rules, skin} = this.props
    const {currentGamePosition, playerGamePosition} = gameData

    if (!playerGamePosition) {
      return null
    }
    const {currentPlayerHand} = playerGamePosition

    switch (clickableCardState.mode) {
      case 'awaitingYourMajorAction':
        const {
          selectedCardInHandId,
          selectedCardInMyPortfolioId,
          consideringMajorAction,
        } = clickableCardState
        const selectedCardInHand = currentPlayerHand.cards.find(
          c => c.id === selectedCardInHandId
        )
        const selectedCardInMyPortfolio = playerGamePosition.currentPlayer.portfolio
          .allCards()
          .find(c => c.id === selectedCardInMyPortfolioId)

        const needToDiscardCount = Math.max(
          0,
          currentPlayerHand.cardCount() - MAX_CARDS_IN_HAND
        )
        const endTurnEarlyButtonText =
          needToDiscardCount <= 0
            ? `Or, end turn early`
            : `Or, discard ${needToDiscardCount} & end turn early...`

        switch (consideringMajorAction?.type) {
          case undefined:
            const selectedCardSomewhere =
              selectedCardInHand ?? selectedCardInMyPortfolio
            if (!selectedCardSomewhere) {
              // discard / end turn is always an option
              return (
                <ListOfActionButtons>
                  {needToDiscardCount <= 0 ? (
                    <this.DiscardNowButton
                      majorActionDiscard={
                        new MajorActionDiscard({
                          type: 'discard',
                          cards: [],
                        })
                      }
                      disabled={false}
                      buttonText={endTurnEarlyButtonText}
                    />
                  ) : (
                    <DSButton
                      buttonStyle="tableAction"
                      size="15px"
                      onClick={() =>
                        this.props.setClickableCardState(prevClickableCardState => ({
                          ...prevClickableCardState,
                          consideringMajorAction: {
                            type: 'discard',
                            selectedCardsToDiscardIds: new Set(),
                          },
                        }))
                      }
                    >
                      {endTurnEarlyButtonText}
                    </DSButton>
                  )}
                </ListOfActionButtons>
              )
            }

            return (
              <AwaitingActionOverlay
                {...this.props}
                clickableCardState={clickableCardState}
                selectedCard={selectedCardSomewhere}
                selectedCardLocation={selectedCardInHand ? 'hand' : 'table'}
              />
            )
          case 'slyDeal':
            return (
              <GenericOverlay>
                <DSTextDiv size="body-17-demi" color="white">
                  What do you want to Sly Deal?
                </DSTextDiv>
                <DSTextDiv size="label" color="white" style={{marginBottom: '10px'}}>
                  Select an opponent’s property
                </DSTextDiv>
                <CenterOfTableCards
                  skin={skin}
                  cardsInDeck={currentGamePosition.cardsInDeck}
                  lastPlayedCard={consideringMajorAction.card}
                  classNames={{
                    deck: styles.dimDeck,
                  }}
                />
                <div className={styles.backButtonAndConfirm}>
                  <DSLinkText
                    underline="onHover"
                    size="label-demi"
                    chevron="left"
                    color="white"
                    onClick={() => {
                      this.props.setClickableCardState(prevClickableCardState => ({
                        ...prevClickableCardState,
                        consideringMajorAction: undefined,
                      }))
                    }}
                  >
                    Back
                  </DSLinkText>
                  <SingleButtonForm
                    buttonStyle="tableAction"
                    size="15px"
                    disabled={!consideringMajorAction.selectedCardToRequestId}
                    onSubmit={() =>
                      this.props.chooseMajorAction(
                        RA.ensureArray(
                          consideringMajorAction.card.availableMovesFromHand({
                            playerGamePosition:
                              clickableCardState.playerGamePosition,
                            portfolio:
                              clickableCardState.playerGamePosition.currentPlayer
                                .portfolio,
                            rules,
                          })
                        ).find(
                          ma =>
                            ma instanceof MajorActionSlyDeal &&
                            ma.requestedCard.id ===
                              consideringMajorAction.selectedCardToRequestId!
                        )!
                      )
                    }
                    submitSuccess={this.props.chooseMajorActionSubmitSuccess}
                  >
                    Play
                  </SingleButtonForm>
                </div>
              </GenericOverlay>
            )
          case 'forcedDeal':
            return (
              <GenericOverlay>
                <DSTextDiv size="body-17-demi" color="white">
                  What do you want to Forced Deal?
                </DSTextDiv>
                <DSTextDiv size="label" color="white" style={{marginBottom: '10px'}}>
                  Select the two properties
                </DSTextDiv>
                <CenterOfTableCards
                  skin={skin}
                  cardsInDeck={currentGamePosition.cardsInDeck}
                  lastPlayedCard={consideringMajorAction.card}
                  classNames={{
                    deck: styles.dimDeck,
                  }}
                />
                <div className={styles.backButtonAndConfirm}>
                  <DSLinkText
                    underline="onHover"
                    size="label-demi"
                    chevron="left"
                    color="white"
                    onClick={() => {
                      this.props.setClickableCardState(prevClickableCardState => ({
                        ...prevClickableCardState,
                        consideringMajorAction: undefined,
                      }))
                    }}
                  >
                    Back
                  </DSLinkText>
                  <SingleButtonForm
                    buttonStyle="tableAction"
                    size="15px"
                    disabled={
                      !consideringMajorAction.selectedCardToRequestId ||
                      !consideringMajorAction.selectedCardToOfferId
                    }
                    onSubmit={() =>
                      this.props.chooseMajorAction(
                        RA.ensureArray(
                          consideringMajorAction.card.availableMovesFromHand({
                            playerGamePosition:
                              clickableCardState.playerGamePosition,
                            portfolio:
                              clickableCardState.playerGamePosition.currentPlayer
                                .portfolio,
                            rules,
                          })
                        ).find(
                          ma =>
                            ma instanceof MajorActionForcedDeal &&
                            ma.requestedCard.id ===
                              consideringMajorAction.selectedCardToRequestId! &&
                            ma.offeredCard.id ===
                              consideringMajorAction.selectedCardToOfferId!
                        )!
                      )
                    }
                    submitSuccess={this.props.chooseMajorActionSubmitSuccess}
                  >
                    Play
                  </SingleButtonForm>
                </div>
              </GenericOverlay>
            )
          case 'needsNeighborhood':
            if (consideringMajorAction.card instanceof SpecialPropertyCard) {
              const specialPropertyCard = consideringMajorAction.card

              const cardLocation = currentPlayerHand.cards.find(
                c => c.id === selectedCardInHandId
              )
                ? 'hand'
                : 'table'

              const actions =
                cardLocation === 'hand'
                  ? specialPropertyCard.availableMovesFromHand({
                      portfolio: playerGamePosition.currentPlayer.portfolio,
                      playerGamePosition,
                      rules,
                    })
                  : specialPropertyCard.availableMovesFromTable({
                      portfolio: playerGamePosition.currentPlayer.portfolio,
                      playerGamePosition,
                      rules,
                    })

              const chosenPlay = actions.find(
                ptn =>
                  ptn.portfolioNeighborhoodId ===
                  consideringMajorAction.selectedPortfolioNeighborhoodId
              )

              return (
                <GenericOverlay>
                  <DSTextDiv size="body-17-demi" color="white">
                    Place a property
                  </DSTextDiv>
                  <DSTextDiv
                    size="label"
                    color="white"
                    style={{marginBottom: '10px'}}
                  >
                    Choose where to place it.
                  </DSTextDiv>
                  <CenterOfTableCards
                    skin={skin}
                    cardsInDeck={currentGamePosition.cardsInDeck}
                    lastPlayedCard={currentGamePosition.recentlyPlayedCards[0]}
                    classNames={{
                      deck: styles.dimDeck,
                      lastPlayedCard: styles.dimDeck,
                    }}
                  />
                  <div className={styles.backButtonAndConfirm}>
                    <DSLinkText
                      underline="onHover"
                      size="label-demi"
                      chevron="left"
                      color="white"
                      onClick={() => {
                        this.props.setClickableCardState(prevClickableCardState => ({
                          ...prevClickableCardState,
                          consideringMajorAction: undefined,
                        }))
                      }}
                    >
                      Back
                    </DSLinkText>
                    <SingleButtonForm
                      buttonStyle="tableAction"
                      size="15px"
                      disabled={!chosenPlay}
                      onSubmit={() => this.props.chooseMajorAction(chosenPlay!)}
                      submitSuccess={this.props.chooseMajorActionSubmitSuccess}
                    >
                      {cardLocation === 'hand' ? `Play` : `Move`}
                    </SingleButtonForm>
                  </div>
                </GenericOverlay>
              )
            } else if (consideringMajorAction.card instanceof UniversalRentCard) {
              const chosenPlay = consideringMajorAction.card
                .availableMovesFromHand({
                  portfolio: playerGamePosition.currentPlayer.portfolio,
                  playerGamePosition,
                  rules,
                })
                .find(
                  action =>
                    isInstance(MajorActionUniversalRent)(action) &&
                    action.portfolioNeighborhoodId ===
                      consideringMajorAction.selectedPortfolioNeighborhoodId
                )

              return (
                <GenericOverlay>
                  <DSTextDiv size="body-17-demi" color="white">
                    Universal Rent
                  </DSTextDiv>
                  <DSTextDiv
                    size="label"
                    color="white"
                    style={{marginBottom: '10px'}}
                  >
                    Select a neighborhood to rent from.
                  </DSTextDiv>
                  <CenterOfTableCards
                    skin={skin}
                    cardsInDeck={currentGamePosition.cardsInDeck}
                    lastPlayedCard={consideringMajorAction.card}
                    classNames={{
                      deck: styles.dimDeck,
                    }}
                  />
                  <div className={styles.backButtonAndConfirm}>
                    <DSLinkText
                      underline="onHover"
                      size="label-demi"
                      chevron="left"
                      color="white"
                      onClick={() => {
                        this.props.setClickableCardState(prevClickableCardState => ({
                          ...prevClickableCardState,
                          consideringMajorAction: undefined,
                        }))
                      }}
                    >
                      Back
                    </DSLinkText>
                    <SingleButtonForm
                      buttonStyle="tableAction"
                      size="15px"
                      disabled={!chosenPlay}
                      onSubmit={() => this.props.chooseMajorAction(chosenPlay!)}
                      submitSuccess={this.props.chooseMajorActionSubmitSuccess}
                    >
                      Play
                    </SingleButtonForm>
                  </div>
                </GenericOverlay>
              )
            } else if (consideringMajorAction.card instanceof DealBreakerCard) {
              const chosenPlay = consideringMajorAction.card
                .availableMovesFromHand({
                  portfolio: playerGamePosition.currentPlayer.portfolio,
                  playerGamePosition,
                  rules,
                })
                .find(
                  action =>
                    isInstance(MajorActionDealBreaker)(action) &&
                    action.portfolioNeighborhoodId ===
                      consideringMajorAction.selectedPortfolioNeighborhoodId
                )

              return (
                <div className={styles.backButtonAndConfirm}>
                  <DSLinkText
                    underline="onHover"
                    size="label-demi"
                    chevron="left"
                    color="white"
                    onClick={() => {
                      this.props.setClickableCardState(prevClickableCardState => ({
                        ...prevClickableCardState,
                        consideringMajorAction: undefined,
                      }))
                    }}
                  >
                    Back
                  </DSLinkText>
                  <SingleButtonForm
                    buttonStyle="tableAction"
                    size="15px"
                    disabled={!chosenPlay}
                    onSubmit={() => this.props.chooseMajorAction(chosenPlay!)}
                    submitSuccess={this.props.chooseMajorActionSubmitSuccess}
                  >
                    Play
                  </SingleButtonForm>
                </div>
              )
            } else {
              return unreachableCase(consideringMajorAction.card)
            }
          case 'discard': {
            const majorActionDiscard = new MajorActionDiscard({
              type: 'discard',
              cards: currentPlayerHand.cards.filter(c =>
                consideringMajorAction.selectedCardsToDiscardIds.has(c.id)
              ),
            })
            console.log(
              'rendering discard center',
              this.state.autoEndingTurnUI,
              selectedCardInHand,
              clickableCardState
            )

            const tableMoves = RA.ensureArray(
              selectedCardInMyPortfolio?.availableMovesFromTable({
                portfolio: playerGamePosition.currentPlayer.portfolio,
                rules,
                playerGamePosition,
              }) ?? []
            )

            const currentlyDiscardingCount = majorActionDiscard.cards.length
            const buttonText =
              needToDiscardCount <= 0
                ? `End turn`
                : `Discard ${needToDiscardCount} ${pluralize(
                    needToDiscardCount,
                    'card'
                  )}`

            if (selectedCardInMyPortfolio && tableMoves.length) {
              return (
                <AwaitingActionOverlay
                  {...this.props}
                  clickableCardState={clickableCardState}
                  selectedCard={selectedCardInMyPortfolio}
                  selectedCardLocation="table"
                />
              )
            }

            // can't discard more or less than you need to
            const incorrectNumberOfDiscards =
              needToDiscardCount !== currentlyDiscardingCount

            return (
              <div className={styles.backButtonAndConfirm}>
                <DSButton
                  buttonStyle="tableAction"
                  size="15px"
                  disabled={currentGamePosition.play === 'discardPlay'}
                  onClick={() => {
                    this.props.setClickableCardState(prevClickableCardState => ({
                      ...prevClickableCardState,
                      consideringMajorAction: undefined,
                    }))
                  }}
                >
                  Back
                </DSButton>
                <this.DiscardNowButton
                  disabled={incorrectNumberOfDiscards}
                  majorActionDiscard={majorActionDiscard}
                  buttonText={buttonText}
                />
              </div>
            )
          }
          case 'debtCollector': {
            return (
              <GenericOverlay>
                <DSTextDiv size="body-17-demi" color="white">
                  Debt Collector
                </DSTextDiv>
                <DSTextDiv size="label" color="white" style={{marginBottom: '10px'}}>
                  Choose who to debt collect
                </DSTextDiv>
                <CenterOfTableCards
                  skin={skin}
                  cardsInDeck={currentGamePosition.cardsInDeck}
                  lastPlayedCard={consideringMajorAction.card}
                  classNames={{
                    deck: styles.dimDeck,
                  }}
                />
                <div className={styles.backButtonAndConfirm}>
                  <DSLinkText
                    underline="onHover"
                    size="label-demi"
                    chevron="left"
                    color="white"
                    onClick={() => {
                      this.props.setClickableCardState(prevClickableCardState => ({
                        ...prevClickableCardState,
                        consideringMajorAction: undefined,
                      }))
                    }}
                  >
                    Back
                  </DSLinkText>
                  <SingleButtonForm
                    buttonStyle="tableAction"
                    size="15px"
                    disabled={!consideringMajorAction.selectedPlayerId}
                    onSubmit={() =>
                      this.props.chooseMajorAction(
                        RA.ensureArray(
                          consideringMajorAction.card.availableMovesFromHand({
                            playerGamePosition:
                              clickableCardState.playerGamePosition,
                            portfolio:
                              clickableCardState.playerGamePosition.currentPlayer
                                .portfolio,
                            rules,
                          })
                        ).find(
                          ma =>
                            ma instanceof MajorActionDebtCollector &&
                            ma.minorActorPlayerId ===
                              consideringMajorAction.selectedPlayerId!
                        )!
                      )
                    }
                    submitSuccess={this.props.chooseMajorActionSubmitSuccess}
                  >
                    Play
                  </SingleButtonForm>
                </div>
              </GenericOverlay>
            )
          }
          case 'directionalRent': {
            const mRentAmount = playerGamePosition.currentPlayer.portfolio.neighborhoods
              .find(
                pn =>
                  pn.id === consideringMajorAction.selectedPortfolioNeighborhoodId
              )
              ?.rent()
            const mOpponent = playerGamePosition.opponents.find(
              o => o.id === consideringMajorAction.selectedPlayerId
            )

            return (
              <GenericOverlay>
                <DSTextDiv size="body-17-demi" color="white">
                  Directional Rent
                </DSTextDiv>
                <DSTextDiv size="label" color="white" style={{marginBottom: '10px'}}>
                  {((): string => {
                    if (mRentAmount && mOpponent) {
                      return `Rent ${mOpponent.username} ${show$(mRentAmount)}`
                    } else if (mRentAmount) {
                      return `Choose who to rent ${show$(mRentAmount)}`
                    } else if (mOpponent) {
                      return `Choose how to rent ${mOpponent.username}`
                    } else {
                      return `Select a neighborhood and an opponent to rent`
                    }
                  })()}
                </DSTextDiv>
                <CenterOfTableCards
                  skin={skin}
                  cardsInDeck={currentGamePosition.cardsInDeck}
                  lastPlayedCard={consideringMajorAction.card}
                  classNames={{
                    deck: styles.dimDeck,
                  }}
                />
                <div className={styles.backButtonAndConfirm}>
                  <DSLinkText
                    underline="onHover"
                    size="label-demi"
                    chevron="left"
                    color="white"
                    onClick={() => {
                      this.props.setClickableCardState(prevClickableCardState => ({
                        ...prevClickableCardState,
                        consideringMajorAction: undefined,
                      }))
                    }}
                  >
                    Back
                  </DSLinkText>
                  <SingleButtonForm
                    buttonStyle="tableAction"
                    size="15px"
                    disabled={
                      !consideringMajorAction.selectedPlayerId ||
                      !consideringMajorAction.selectedPortfolioNeighborhoodId
                    }
                    onSubmit={() =>
                      this.props.chooseMajorAction(
                        RA.ensureArray(
                          consideringMajorAction.card.availableMovesFromHand({
                            playerGamePosition:
                              clickableCardState.playerGamePosition,
                            portfolio:
                              clickableCardState.playerGamePosition.currentPlayer
                                .portfolio,
                            rules,
                          })
                        ).find(
                          ma =>
                            ma instanceof MajorActionDirectionalRent &&
                            ma.minorActorPlayerId ===
                              consideringMajorAction.selectedPlayerId! &&
                            ma.portfolioNeighborhood.id ===
                              consideringMajorAction.selectedPortfolioNeighborhoodId!
                        )!
                      )
                    }
                    submitSuccess={this.props.chooseMajorActionSubmitSuccess}
                  >
                    Play
                  </SingleButtonForm>
                </div>
              </GenericOverlay>
            )
          }
          default:
            return unreachableCase(consideringMajorAction)
        }
      case 'acceptOrJustSayNo': {
        const validResponses = clickableCardState.minorActionPrompt.validResponses({
          inProgressMajorAction: clickableCardState.inProgressMajorAction,
          gamePosition: currentGamePosition,
          portfolio: playerGamePosition.currentPlayer.portfolio,
          hand: playerGamePosition.currentPlayerHand,
          rules,
        })
        return (
          <ListOfActionButtons>
            {validResponses.map((ma, i) => (
              <SingleButtonForm
                key={i}
                buttonStyle="tableAction"
                size="15px"
                onSubmit={() => this.props.chooseMinorAction(ma)}
                submitSuccess={this.props.chooseMinorActionSubmitSuccess}
              >
                {ma.userFacingDescription({
                  gamePosition: currentGamePosition,
                  inProgressMajorAction: clickableCardState.inProgressMajorAction,
                  skin,
                  majorActor: clickableCardState.inProgressMajorAction.majorActor,
                  minorActor: playerGamePosition.currentPlayer,
                  tense: 'imperativePrompt',
                })}
              </SingleButtonForm>
            ))}
          </ListOfActionButtons>
        )
      }
      case 'chooseNeighborhood': {
        if (clickableCardState.for instanceof MinorActionPromptForcedDeal) {
          // TODO this cast is probably ok?
          const inProgressMajorAction = playerGamePosition.inProgressMajorAction!
          const validResponses = clickableCardState.for.validResponses({
            inProgressMajorAction,
            gamePosition: playerGamePosition,
            portfolio: playerGamePosition.currentPlayer.portfolio,
            hand: playerGamePosition.currentPlayerHand,
            rules,
          })
          console.log(
            'minor forced deal prompt: ',
            clickableCardState.for,
            validResponses
          )

          return (
            <ListOfActionButtons>
              {validResponses.map((ma, i) => (
                <SingleButtonForm
                  key={i}
                  buttonStyle="tableAction"
                  size="15px"
                  onSubmit={() => {
                    if (ma instanceof MinorActionAcceptForcedDeal) {
                      // eslint-disable-next-line max-len
                      ma.datasource.placedInPortfolioNeighborhoodId = clickableCardState.selectedPortfolioNeighborhoodId!
                    }
                    return this.props.chooseMinorAction(ma)
                  }}
                  submitSuccess={this.props.chooseMinorActionSubmitSuccess}
                  disabled={
                    clickableCardState.selectedPortfolioNeighborhoodId ===
                      undefined && !isInstance(MinorActionJustSayNo)(ma)
                  }
                >
                  {ma.userFacingDescription({
                    gamePosition: playerGamePosition,
                    inProgressMajorAction,
                    skin,
                    majorActor: inProgressMajorAction.majorActor,
                    minorActor: playerGamePosition.currentPlayer,
                    tense: 'imperativePrompt',
                  })}
                </SingleButtonForm>
              ))}
            </ListOfActionButtons>
          )
        } else if (clickableCardState.for instanceof MinorActionPromptJustSayNo) {
          return null
        } else {
          return unreachableCase(clickableCardState.for)
        }
      }
      case 'paySomething': {
        const {inProgressMajorAction, selectedCardIds} = clickableCardState
        const validResponses = clickableCardState.minorActionPrompt.validResponses({
          inProgressMajorAction,
          gamePosition: playerGamePosition,
          portfolio: playerGamePosition.currentPlayer.portfolio,
          hand: playerGamePosition.currentPlayerHand,
          rules,
        })

        return (
          <ListOfActionButtons>
            {validResponses.map((minorAction, i) => {
              if (minorAction instanceof MinorActionPaySomething) {
                const properPaymentData = paymentPropriety(
                  minorAction,
                  playerGamePosition,
                  selectedCardIds
                )
                const obviousPayment = getObviousPayment(
                  minorAction,
                  playerGamePosition
                )

                const selectedAmountToPay = ((): string => {
                  switch (properPaymentData.type) {
                    case 'proper':
                      return show$(valueOfCards(properPaymentData.paidWith))
                    case 'invalidCardSelected':
                      return '(error)'
                    case 'moreMoneyOwed':
                    case 'tooManyCards':
                      return show$(properPaymentData.currentPayment)
                    default:
                      return unreachableCase(properPaymentData)
                  }
                })()
                return (
                  <React.Fragment key={i}>
                    {obviousPayment && (
                      <SingleButtonForm
                        buttonStyle="tableAction"
                        size="15px"
                        onSubmit={() => {
                          minorAction.paidWithCards = obviousPayment.paidWith
                          return this.props.chooseMinorAction(minorAction)
                        }}
                        submitSuccess={this.props.chooseMinorActionSubmitSuccess}
                        disabled={
                          selectedCardIds.size > 0 ||
                          !playerGamePosition.isMyMinorTurn()
                        }
                      >
                        Autopay (
                        {groupedCardDisplayNames(obviousPayment.paidWith, skin).join(
                          ', '
                        )}
                        )
                      </SingleButtonForm>
                    )}

                    <SingleButtonForm
                      buttonStyle="tableAction"
                      size="15px"
                      disabled={
                        properPaymentData.type !== 'proper' ||
                        !playerGamePosition.isMyMinorTurn()
                      }
                      onSubmit={() => {
                        minorAction.paidWithCards = (properPaymentData as TProperPaymentResponse).paidWith
                        return this.props.chooseMinorAction(minorAction)
                      }}
                      submitSuccess={this.props.chooseMinorActionSubmitSuccess}
                    >
                      {selectedCardIds.size === 0 ||
                      properPaymentData.type === 'invalidCardSelected'
                        ? `Select cards to pay with`
                        : `${
                            obviousPayment ? 'Manually p' : 'P'
                          }ay ${selectedAmountToPay}`}
                    </SingleButtonForm>
                  </React.Fragment>
                )
              }

              // I believe JSN would be the only other choice here, but I'm leaving this somewhat generic just in case
              let otherActionButtonText = minorAction.userFacingDescription({
                gamePosition: playerGamePosition,
                inProgressMajorAction,
                skin,
                majorActor: inProgressMajorAction.majorActor,
                minorActor: playerGamePosition.currentPlayer,
                tense: 'imperativePrompt',
              })
              let disabled = false
              if (minorAction instanceof MinorActionJustSayNo) {
                const selectedCard = playerGamePosition.currentPlayerHand.cards.find(
                  c => c.id === S.first(selectedCardIds)
                )
                if (
                  !(
                    selectedCardIds.size === 1 &&
                    isInstance(JustSayNoCard)(selectedCard)
                  )
                ) {
                  disabled = true
                  otherActionButtonText = 'Click your Just Say No card to use it'
                }
              }

              return (
                <SingleButtonForm
                  key={i}
                  buttonStyle="tableAction"
                  size="15px"
                  onSubmit={() => this.props.chooseMinorAction(minorAction)}
                  submitSuccess={this.props.chooseMinorActionSubmitSuccess}
                  disabled={!playerGamePosition.isMyMinorTurn() || disabled}
                >
                  {otherActionButtonText}
                </SingleButtonForm>
              )
            })}
          </ListOfActionButtons>
        )
      }
      case 'none':
        return null
      default:
        return unreachableCase(clickableCardState)
    }
  }
}

interface IGenericOverlayProps extends React.HTMLProps<HTMLDivElement> {}
const GenericOverlay = (props: IGenericOverlayProps) => (
  <div {...props} className={classnames(styles.overlay, props.className)} />
)

interface IListOfActionButtonsProps extends React.HTMLProps<HTMLDivElement> {}
const ListOfActionButtons = (props: IListOfActionButtonsProps) => (
  <div {...props} className={classnames(styles.actionButtons, props.className)} />
)

const AwaitingActionOverlay = (
  props: IProps & {
    selectedCard: Card<any>
    selectedCardLocation: 'hand' | 'table'
    clickableCardState: TClickableCardStateV2 & {mode: 'awaitingYourMajorAction'}
  }
) => {
  const {clickableCardState, rules, selectedCard, selectedCardLocation} = props

  const availableMoves =
    selectedCardLocation === 'hand'
      ? RA.ensureArray(
          props.selectedCard.availableMovesFromHand({
            playerGamePosition: clickableCardState.playerGamePosition,
            portfolio: clickableCardState.playerGamePosition.currentPlayer.portfolio,
            rules,
          })
        )
      : RA.ensureArray(
          props.selectedCard.availableMovesFromTable({
            playerGamePosition: clickableCardState.playerGamePosition,
            portfolio: clickableCardState.playerGamePosition.currentPlayer.portfolio,
            rules,
          })
        )

  if (!availableMoves.length) {
    return null
  }

  const shortText = (action: AnyMajorAction): string => {
    if (
      action instanceof MajorActionBirthday ||
      action instanceof MajorActionDoubleTheRent
    ) {
      return `Play card`
    } else if (
      action instanceof MajorActionUniversalRent ||
      action instanceof MajorActionDealBreaker ||
      action instanceof MajorActionDebtCollector ||
      action instanceof MajorActionDirectionalRent ||
      action instanceof MajorActionForcedDeal ||
      action instanceof MajorActionSlyDeal
    ) {
      return `Play card...`
    } else if (action instanceof MajorActionChangeNeighborhood) {
      return `Move property...`
    } else if (action instanceof MajorActionDiscard) {
      // TODO probably can't happen here?
      return `Discard`
    } else if (action instanceof MajorActionPassGo) {
      return `Pass Go`
    } else if (action instanceof MajorActionPlayAsCash) {
      return `Play as cash`
    } else if (action instanceof MajorActionResidenceToCash) {
      return `Convert to cash`
    } else if (action instanceof MajorActionPlayToNeighborhood) {
      return `Place on table`
    } else {
      return unreachableCase(action)
    }
  }

  const groupedActions = M.groupBy(ma => ma.type, availableMoves)

  return (
    <GenericOverlay>
      <DSTextDiv size="body-17-demi" color="white">
        {props.selectedCard.shortDescription(props.skin)}
      </DSTextDiv>
      {M.mapEntries((actions, key) => {
        const text = shortText(actions[0])

        // TODO replace this with a `switchOn...` method or add unreachableCase?
        if (
          selectedCard instanceof SlyDealCard &&
          actions[0] instanceof MajorActionSlyDeal
        ) {
          return (
            <RegularOverlayButton
              {...{key, children: text}}
              onClick={() =>
                props.setClickableCardState(prevClickableCardState => ({
                  ...prevClickableCardState,
                  consideringMajorAction: {
                    type: 'slyDeal',
                    card: selectedCard,
                    requestableCardIds: new Set(
                      compact(
                        availableMoves.map(ma =>
                          ma instanceof MajorActionSlyDeal
                            ? ma.requestedCard.id
                            : undefined
                        )
                      )
                    ),
                    selectedCardToRequestId: undefined,
                  },
                }))
              }
            />
          )
        } else if (
          selectedCard instanceof ForcedDealCard &&
          actions[0] instanceof MajorActionForcedDeal
        ) {
          const forcedDeals = availableMoves.filter(
            isInstance(MajorActionForcedDeal)
          )

          return (
            <RegularOverlayButton
              {...{key, children: text}}
              onClick={() =>
                props.setClickableCardState(prevClickableCardState => ({
                  ...prevClickableCardState,
                  consideringMajorAction: {
                    type: 'forcedDeal',
                    card: selectedCard,
                    requestableCardIds: new Set(
                      forcedDeals.map(fd => fd.requestedCard.id)
                    ),
                    offerableCardIds: new Set(
                      forcedDeals.map(fd => fd.offeredCard.id)
                    ),
                    selectedCardToRequestId: undefined,
                    selectedCardToOfferId: undefined,
                  },
                }))
              }
            />
          )
        } else if (
          selectedCard instanceof DebtCollectorCard &&
          actions[0] instanceof MajorActionDebtCollector
        ) {
          const debtCollectors = availableMoves.filter(
            isInstance(MajorActionDebtCollector)
          )
          const clickablePlayerIds = new Set(
            debtCollectors.map(dc => dc.minorActorPlayerId)
          )
          return (
            <RegularOverlayButton
              {...{key, children: text}}
              onClick={() =>
                props.setClickableCardState(prevClickableCardState => ({
                  ...prevClickableCardState,
                  consideringMajorAction: {
                    type: 'debtCollector',
                    card: selectedCard,
                    clickablePlayerIds,
                    selectedPlayerId: S.soleMemberIfSingleton(clickablePlayerIds),
                  },
                }))
              }
            />
          )
        } else if (
          selectedCard instanceof DirectionalRentCard &&
          actions[0] instanceof MajorActionDirectionalRent
        ) {
          const directionalRents = availableMoves.filter(
            isInstance(MajorActionDirectionalRent)
          )
          const clickablePlayerIds = new Set(
            directionalRents.map(dr => dr.minorActorPlayerId)
          )
          const clickablePortfolioNeighborhoodIds = new Set(
            directionalRents.map(dr => dr.portfolioNeighborhood.id)
          )
          return (
            <RegularOverlayButton
              {...{key, children: text}}
              onClick={() =>
                props.setClickableCardState(prevClickableCardState => ({
                  ...prevClickableCardState,
                  consideringMajorAction: {
                    type: 'directionalRent',
                    card: selectedCard,
                    clickablePlayerIds,
                    selectedPlayerId: S.soleMemberIfSingleton(clickablePlayerIds),
                    clickablePortfolioNeighborhoodIds,
                    selectedPortfolioNeighborhoodId: S.soleMemberIfSingleton(
                      clickablePortfolioNeighborhoodIds
                    ),
                  },
                }))
              }
            />
          )
        } else if (
          selectedCard instanceof UniversalRentCard &&
          actions.length > 1 &&
          actions[0] instanceof MajorActionUniversalRent
        ) {
          const universalRents = availableMoves.filter(
            isInstance(MajorActionUniversalRent)
          )
          const clickablePortfolioNeighborhoodIds = new Set(
            universalRents.map(ur => ur.portfolioNeighborhood.id)
          )
          return (
            <RegularOverlayButton
              {...{key, children: text}}
              onClick={() =>
                props.setClickableCardState(prevClickableCardState => ({
                  ...prevClickableCardState,
                  consideringMajorAction: {
                    type: 'needsNeighborhood',
                    card: selectedCard,
                    clickablePortfolioNeighborhoodIds,
                    selectedPortfolioNeighborhoodId: S.soleMemberIfSingleton(
                      clickablePortfolioNeighborhoodIds
                    ),
                  },
                }))
              }
            />
          )
        } else if (
          selectedCard instanceof DealBreakerCard &&
          actions[0] instanceof MajorActionDealBreaker
        ) {
          const dealBreakers = availableMoves.filter(
            isInstance(MajorActionDealBreaker)
          )
          const clickablePortfolioNeighborhoodIds = new Set(
            dealBreakers.map(db => db.portfolioNeighborhoodId)
          )
          return (
            <RegularOverlayButton
              {...{key, children: text}}
              onClick={() =>
                props.setClickableCardState(prevClickableCardState => ({
                  ...prevClickableCardState,
                  consideringMajorAction: {
                    type: 'needsNeighborhood',
                    card: selectedCard,
                    clickablePortfolioNeighborhoodIds,
                    selectedPortfolioNeighborhoodId: S.soleMemberIfSingleton(
                      clickablePortfolioNeighborhoodIds
                    ),
                  },
                }))
              }
            />
          )
        }

        if (actions.length === 1) {
          let editedText = text.replace('...', '')

          if (
            actions[0] instanceof MajorActionChangeNeighborhood &&
            actions[0].card instanceof DualPropertyCard
          ) {
            editedText = editedText.replace('Move property', 'Flip property')
          }

          return (
            <PromiseOverlayButton
              {...{key, children: editedText}}
              onSubmit={() => {
                if (
                  actions[0] instanceof MajorActionPlayAsCash &&
                  !(
                    actions[0].card instanceof CashCard ||
                    actions[0].card instanceof ResidenceCard
                  )
                ) {
                  // TODO Real modal instead of this
                  if (!confirm('Are you sure you want to play this card as cash?')) {
                    return Promise.reject()
                  }
                }
                return props.chooseMajorAction(actions[0])
              }}
              submitSuccess={props.chooseMajorActionSubmitSuccess}
            />
          )
        } else {
          if (selectedCard instanceof SpecialPropertyCard) {
            const isChangeOrPlayToNeighborhood = (
              a: AnyMajorAction
            ): a is MajorActionPlayToNeighborhood | MajorActionChangeNeighborhood =>
              isInstance(MajorActionPlayToNeighborhood)(a) ||
              isInstance(MajorActionChangeNeighborhood)(a)

            return (
              <RegularOverlayButton
                {...{key, children: text}}
                onClick={() =>
                  props.setClickableCardState(prevClickableCardState => ({
                    ...prevClickableCardState,
                    consideringMajorAction: {
                      type: 'needsNeighborhood',
                      card: selectedCard,
                      // TODO it'd be nice to not have to define these since maybeMutateClickableCardState should do it
                      clickablePortfolioNeighborhoodIds: S.create(
                        actions
                          .filter(isChangeOrPlayToNeighborhood)
                          .map(ptn => ptn.portfolioNeighborhoodId)
                      ),
                      selectedPortfolioNeighborhoodId: undefined,
                    },
                  }))
                }
              />
            )
          }

          console.log('dont know how to display actions for selected card:', {
            selectedCard,
            actions,
          })
        }
      }, groupedActions)}
    </GenericOverlay>
  )
}

const CenterOfTableCards = (props: {
  skin: TSkin
  lastPlayedCard: ActionCard<any> | RentCard<any> | undefined
  cardsInDeck: number
  classNames?: {
    wrapper?: string
    deck?: string
    lastPlayedCard?: string
  }
}) => {
  const {lastPlayedCard, cardsInDeck, skin, classNames} = props

  const cardWidth = 64 // sizeData[gameLobbySize].board
  const cardHeight = cardHeightFromWidth(cardWidth)

  const centerCardXPaddingPx = 0
  const centerCardYPaddingPx = 0
  const centerCardSpacerPx = 8

  const cardInCenterWidth = cardWidth + centerCardXPaddingPx * 2

  return (
    <div
      className={classnames(
        styles.cardsInCenter,
        classNames?.wrapper,
        css`
          width: ${cardWidth * 2 + centerCardSpacerPx + centerCardXPaddingPx * 4}px;
          height: ${cardHeight + centerCardYPaddingPx * 2}px;
        `
      )}
    >
      <div
        className={classnames(
          styles.cardInCenterWrap,
          css`
            width: ${cardInCenterWidth}px;
          `,
          classNames?.deck
        )}
      >
        <div style={{position: 'relative', width: cardWidth, height: cardHeight}}>
          {cardsInDeck > 0 && (
            <CardBackImage
              className={css`
                width: ${cardWidth}px;
              `}
              skin={skin}
              roundedCorners
            />
          )}
          <DSTextDiv size="tiny" color="white" className={styles.cardCounter}>
            {cardsInDeck}
          </DSTextDiv>
        </div>
      </div>
      <div
        className={classnames(
          styles.cardInCenterWrap,
          css`
            margin-left: ${centerCardSpacerPx}px;
            width: ${cardInCenterWidth}px;
          `,
          classNames?.lastPlayedCard
        )}
      >
        {lastPlayedCard && (
          <V2LobbyCardImage
            className={css`
              width: ${cardWidth}px;
            `}
            model={lastPlayedCard}
            invert={false}
            skin={skin}
            roundedCorners
          />
        )}
      </div>
    </div>
  )
}

const RegularOverlayButton = (
  ps: OmitStrict<IDSButtonProps, 'className' | 'size'> &
    Required<Pick<IDSButtonProps, 'onClick'>>
) => (
  <DSButton
    buttonStyle="tableAction"
    size="17px"
    className={styles.overlayButton}
    {...ps}
  />
)

const PromiseOverlayButton = (
  ps: OmitStrict<
    ISingleButtonFormProps,
    'hideGlobalErrors' | 'buttonStyle' | 'classNames'
  >
) => (
  <SingleButtonForm<TSubmitMajorActionResponse>
    hideGlobalErrors
    buttonStyle="tableAction"
    size="17px"
    classNames={{wrapper: styles.overlayButton}}
    {...ps}
  />
)
