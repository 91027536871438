import {
  BirthdayCard,
  DealBreakerCard,
  DebtCollectorCard,
  DoubleTheRentCard,
  ForcedDealCard,
  JustSayNoCard,
  PassGoCard,
  SlyDealCard,
} from '~/models/game/Cards/ActionCard'
import CashCard from '~/models/game/Cards/CashCard'
import DualPropertyCard from '~/models/game/Cards/DualPropertyCard'
import PropertyCard from '~/models/game/Cards/PropertyCard'
import PropertyWildCard from '~/models/game/Cards/PropertyWildCard'
import {DirectionalRentCard, UniversalRentCard} from '~/models/game/Cards/RentCard'
import ResidenceCard from '~/models/game/Cards/ResidenceCard'
import StandardPropertyCard from '~/models/game/Cards/StandardPropertyCard'
import MajorActionBirthday from '~/models/game/MajorActions/MajorActionBirthday'
import MajorActionChangeNeighborhood from '~/models/game/MajorActions/MajorActionChangeNeighborhood'
import MajorActionDealBreaker from '~/models/game/MajorActions/MajorActionDealBreaker'
import MajorActionDebtCollector from '~/models/game/MajorActions/MajorActionDebtCollector'
import MajorActionDirectionalRent from '~/models/game/MajorActions/MajorActionDirectionalRent'
import MajorActionDiscard from '~/models/game/MajorActions/MajorActionDiscard'
import MajorActionDoubleTheRent from '~/models/game/MajorActions/MajorActionDoubleTheRent'
import MajorActionForcedDeal from '~/models/game/MajorActions/MajorActionForcedDeal'
import MajorActionPassGo from '~/models/game/MajorActions/MajorActionPassGo'
import MajorActionPlayAsCash from '~/models/game/MajorActions/MajorActionPlayAsCash'
import MajorActionPlayToNeighborhood from '~/models/game/MajorActions/MajorActionPlayToNeighborhood'
import MajorActionResidenceToCash from '~/models/game/MajorActions/MajorActionResidenceToCash'
import MajorActionSlyDeal from '~/models/game/MajorActions/MajorActionSlyDeal'
import MajorActionUniversalRent from '~/models/game/MajorActions/MajorActionUniversalRent'
import MinorActionPromptDealBreaker from '~/models/game/MinorActionPrompts/MinorActionPromptDealBreaker'
import MinorActionPromptForcedDeal from '~/models/game/MinorActionPrompts/MinorActionPromptForcedDeal'
import MinorActionPromptJustSayNo from '~/models/game/MinorActionPrompts/MinorActionPromptJustSayNo'
import MinorActionPromptPayOther from '~/models/game/MinorActionPrompts/MinorActionPromptPayOther'
import MinorActionPromptPayRent from '~/models/game/MinorActionPrompts/MinorActionPromptPayRent'
import MinorActionPromptSlyDeal from '~/models/game/MinorActionPrompts/MinorActionPromptSlyDeal'
import {unreachableCase} from '~/utils'

export type AnyActionCard =
  | BirthdayCard
  | DealBreakerCard
  | DebtCollectorCard
  | DoubleTheRentCard
  | ForcedDealCard
  | JustSayNoCard
  | PassGoCard
  | SlyDealCard

export type AnyRentCard = UniversalRentCard | DirectionalRentCard

export type AnyLiquidatedCard =
  | CashCard
  | AnyRentCard
  | AnyActionCard
  | ResidenceCard
export type AnyPropertyCard =
  | StandardPropertyCard
  | DualPropertyCard
  | PropertyWildCard
// all cards w/ a monetary value (everything but a PWC)
export type AnyValuableCard =
  | AnyLiquidatedCard
  | StandardPropertyCard
  | DualPropertyCard

export type AnyPlayableCard = AnyActionCard | AnyRentCard

export type AnyCard = AnyValuableCard | PropertyWildCard

export type MajorActionAllowsMinorActions =
  | MajorActionBirthday
  | MajorActionDealBreaker
  | MajorActionDebtCollector
  | MajorActionDoubleTheRent
  | MajorActionForcedDeal
  | MajorActionDirectionalRent
  | MajorActionUniversalRent
  | MajorActionSlyDeal

export type AnyMajorAction =
  | MajorActionAllowsMinorActions
  | MajorActionBirthday
  | MajorActionChangeNeighborhood
  | MajorActionDiscard
  | MajorActionPassGo
  | MajorActionPlayAsCash
  | MajorActionPlayToNeighborhood
  | MajorActionResidenceToCash

export type AnyMinorActionPrompt =
  | MinorActionPromptDealBreaker
  | MinorActionPromptForcedDeal
  | MinorActionPromptJustSayNo
  | MinorActionPromptPayOther
  | MinorActionPromptPayRent
  | MinorActionPromptSlyDeal

export const switchOnPropertyCard = <T>(cases: {
  whenStandard: (spc: StandardPropertyCard) => T
  whenDual: (dpc: DualPropertyCard) => T
  whenWild: (wpc: PropertyWildCard) => T
}) => (card: PropertyCard<any>): T => {
  if (card instanceof StandardPropertyCard) {
    return cases.whenStandard(card)
  } else if (card instanceof DualPropertyCard) {
    return cases.whenDual(card)
  } else if (card instanceof PropertyWildCard) {
    return cases.whenWild(card)
  }
  // would be neat if there was a type-safe way to detect that all subclasses have been added here :(
  throw new Error(
    `PropertyCard is not of a recognized instance in switchOnPropertyCard: ${card.type}`
  )
}

export const switchOnMajorAction = <T>(cases: {
  whenBirthday: (action: MajorActionBirthday) => T
  whenChangeNeighborhood: (action: MajorActionChangeNeighborhood) => T
  whenDealBreaker: (action: MajorActionDealBreaker) => T
  whenDebtCollector: (action: MajorActionDebtCollector) => T
  whenDirectionalRent: (action: MajorActionDirectionalRent) => T
  whenDiscard: (action: MajorActionDiscard) => T
  whenDoubleTheRent: (action: MajorActionDoubleTheRent) => T
  whenForcedDeal: (action: MajorActionForcedDeal) => T
  whenPassGo: (action: MajorActionPassGo) => T
  whenPlayAsCash: (action: MajorActionPlayAsCash) => T
  whenPlayToNeighborhood: (action: MajorActionPlayToNeighborhood) => T
  whenResidenceToCash: (action: MajorActionResidenceToCash) => T
  whenSlyDeal: (action: MajorActionSlyDeal) => T
  whenUniversalRent: (action: MajorActionUniversalRent) => T
}) => (action: AnyMajorAction): T => {
  if (action instanceof MajorActionBirthday) {
    return cases.whenBirthday(action)
  } else if (action instanceof MajorActionChangeNeighborhood) {
    return cases.whenChangeNeighborhood(action)
  } else if (action instanceof MajorActionDealBreaker) {
    return cases.whenDealBreaker(action)
  } else if (action instanceof MajorActionDebtCollector) {
    return cases.whenDebtCollector(action)
  } else if (action instanceof MajorActionDirectionalRent) {
    return cases.whenDirectionalRent(action)
  } else if (action instanceof MajorActionDiscard) {
    return cases.whenDiscard(action)
  } else if (action instanceof MajorActionDoubleTheRent) {
    return cases.whenDoubleTheRent(action)
  } else if (action instanceof MajorActionForcedDeal) {
    return cases.whenForcedDeal(action)
  } else if (action instanceof MajorActionPassGo) {
    return cases.whenPassGo(action)
  } else if (action instanceof MajorActionPlayAsCash) {
    return cases.whenPlayAsCash(action)
  } else if (action instanceof MajorActionPlayToNeighborhood) {
    return cases.whenPlayToNeighborhood(action)
  } else if (action instanceof MajorActionResidenceToCash) {
    return cases.whenResidenceToCash(action)
  } else if (action instanceof MajorActionSlyDeal) {
    return cases.whenSlyDeal(action)
  } else if (action instanceof MajorActionUniversalRent) {
    return cases.whenUniversalRent(action)
  } else {
    return unreachableCase(action)
  }
}

export const switchOnMajorActionAllowsMinorAction = <T>(cases: {
  whenBirthday: (action: MajorActionBirthday) => T
  whenDealBreaker: (action: MajorActionDealBreaker) => T
  whenDebtCollector: (action: MajorActionDebtCollector) => T
  whenDirectionalRent: (action: MajorActionDirectionalRent) => T
  whenDoubleTheRent: (action: MajorActionDoubleTheRent) => T
  whenForcedDeal: (action: MajorActionForcedDeal) => T
  whenSlyDeal: (action: MajorActionSlyDeal) => T
  whenUniversalRent: (action: MajorActionUniversalRent) => T
}) => (action: MajorActionAllowsMinorActions): T => {
  if (action instanceof MajorActionBirthday) {
    return cases.whenBirthday(action)
  } else if (action instanceof MajorActionDealBreaker) {
    return cases.whenDealBreaker(action)
  } else if (action instanceof MajorActionDebtCollector) {
    return cases.whenDebtCollector(action)
  } else if (action instanceof MajorActionDirectionalRent) {
    return cases.whenDirectionalRent(action)
  } else if (action instanceof MajorActionDoubleTheRent) {
    return cases.whenDoubleTheRent(action)
  } else if (action instanceof MajorActionForcedDeal) {
    return cases.whenForcedDeal(action)
  } else if (action instanceof MajorActionSlyDeal) {
    return cases.whenSlyDeal(action)
  } else if (action instanceof MajorActionUniversalRent) {
    return cases.whenUniversalRent(action)
  } else {
    return unreachableCase(action)
  }
}

export const switchOnMinorActionPrompt = <T>(cases: {
  whenDealBreaker: (prompt: MinorActionPromptDealBreaker) => T
  whenForcedDeal: (prompt: MinorActionPromptForcedDeal) => T
  whenJustSayNo: (prompt: MinorActionPromptJustSayNo) => T
  whenPayOther: (prompt: MinorActionPromptPayOther) => T
  whenPayRent: (prompt: MinorActionPromptPayRent) => T
  whenSlyDeal: (prompt: MinorActionPromptSlyDeal) => T
}) => (prompt: AnyMinorActionPrompt): T => {
  if (prompt instanceof MinorActionPromptDealBreaker) {
    return cases.whenDealBreaker(prompt)
  } else if (prompt instanceof MinorActionPromptForcedDeal) {
    return cases.whenForcedDeal(prompt)
  } else if (prompt instanceof MinorActionPromptJustSayNo) {
    return cases.whenJustSayNo(prompt)
  } else if (prompt instanceof MinorActionPromptPayOther) {
    return cases.whenPayOther(prompt)
  } else if (prompt instanceof MinorActionPromptPayRent) {
    return cases.whenPayRent(prompt)
  } else if (prompt instanceof MinorActionPromptSlyDeal) {
    return cases.whenSlyDeal(prompt)
  } else {
    return unreachableCase(prompt)
  }
}
