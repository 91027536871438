import {reverse} from 'ramda'
import * as React from 'react'
import classnames from 'classnames'
import DSText, {DSTextDiv} from '~/design-system/DSText'
import {TRuleset} from '~/game/ruleset'
import {TSkin} from '~/game/skins'
import GamePosition from '~/models/game/GamePosition'
import InProgressMajorAction from '~/models/game/InProgressMajorAction'
import MinorAction from '~/models/game/MinorAction'
import MinorActionPrompt from '~/models/game/MinorActionPrompt'
import styles from './styles.module.css'

interface IProps {
  skin: TSkin
  rules: TRuleset
  currentGamePosition: GamePosition
  inProgressMajorAction: InProgressMajorAction
}

interface IState {}

export default class InProgressMajorActionUI extends React.Component<
  IProps,
  IState
> {
  state: IState = {}

  renderFutureMinorAction(minorActionPrompt: MinorActionPrompt<any>) {
    const {currentGamePosition, inProgressMajorAction, skin} = this.props
    const {minorActorPlayer: activeMinorActor} = currentGamePosition
    const minorActor = minorActionPrompt.minorActor

    const tense =
      activeMinorActor!.id === minorActor.id ? 'presentContinuous' : 'future'

    const desc = minorActionPrompt
      .userFacingDescription({
        gamePosition: currentGamePosition,
        inProgressMajorAction,
        skin,
        tense,
      })
      .replace(/(is|are) paying/, `$1 deciding how to pay`)
    return (
      <DSText
        size="body-15"
        tag="li"
        key={minorActionPrompt.minorActorPlayerId}
        className={styles.actionChoice}
        underline={tense === 'presentContinuous'}
      >
        {desc}
      </DSText>
    )
  }

  renderPastMinorAction(minorAction: MinorAction<any>) {
    const {currentGamePosition, inProgressMajorAction, skin} = this.props

    const desc = minorAction.userFacingDescription({
      gamePosition: currentGamePosition,
      inProgressMajorAction,
      majorActor: currentGamePosition.majorActorPlayer,
      minorActor: currentGamePosition.players.find(
        p => p.id === minorAction.minorActorPlayerId
      )!,
      skin,
      tense: 'past',
    })
    return (
      <DSText size="body-15" tag="li" key={desc} className={styles.actionChoice}>
        {desc}
      </DSText>
    )
  }

  render() {
    const {currentGamePosition, inProgressMajorAction, skin} = this.props

    // NB: server returns `previousMinorActions` in newest-first order,
    // we `reverse` them to oldest-first for display here.
    return (
      <div className={classnames(styles.inProgressMajorAction)}>
        <DSTextDiv size="body-16" bold>
          {inProgressMajorAction.majorAction.userFacingDescription({
            tense: 'past',
            skin,
            gamePosition: currentGamePosition,
          })}
          <br />
          <br />
        </DSTextDiv>
        <ul>
          {reverse(inProgressMajorAction.previousMinorActions).map(minorAction =>
            this.renderPastMinorAction(minorAction)
          )}
          {inProgressMajorAction.pendingMinorActions.map(minorAction =>
            this.renderFutureMinorAction(minorAction)
          )}
        </ul>
      </div>
    )
  }
}
