import * as R from 'ramda'
import * as React from 'react'
import {connect} from 'react-redux'
import api from '~/api/endpoints'
import {TGameData, TGameSpectatorData} from '~/api/generated/types/common'
import {TGetGameDataResponse} from '~/api/generated/types/GetGameData'
import {defaultRuleset} from '~/game/ruleset'
import GameLobbyV2Layout from '~/layouts/GameLobbyV2Layout'
import GameData from '~/models/game/GameData'
import GameLobbyV2UI from '~/pages/GameLobbyV2/stateless'
import NotFound from '~/pages/NotFound'
import {addPastGamePositionsForGame} from '~/redux/reducers/pastGamePositions'
import {ReduxState} from '~/redux/reducers/root'
import {setSpectatorData, spectatorsDataForPlayer} from '~/redux/reducers/spectator'
import {ISignedInRouteComponentProps} from '~/routing/Route/SignedIn'
import {setStatePromise} from '~/utils'
import {values} from '~/utils/Map'
import {createSafe} from '~/utils/NEList'
import {TReactSetStateStateParameter} from '~/utils/types'
import * as S from '~/utils/Set'
import {sendNotification, TAttackDirection} from '~/utils/UserNotifications'

export interface IGameLobbyPagePathPieces {
  gameId: string
}

interface IReduxProps
  extends Pick<
    ReduxState,
    'settingsState' | 'spectatorState' | 'pastGamePositionsState'
  > {}
interface IProps
  extends ISignedInRouteComponentProps<IGameLobbyPagePathPieces>,
    IReduxProps,
    TDispatch {}

interface IState {
  currentGameData: GameData | undefined
  soundsEnabled: boolean
  gameDataRequestsPending: number
}

class GameLobbyV2Page extends React.Component<IProps, IState> {
  state: IState = {
    currentGameData: undefined,
    soundsEnabled: false,
    gameDataRequestsPending: 0,
  }

  receiveNewGameDataResponse = (initialLoad: boolean) => (
    gameDataResponse: TGetGameDataResponse
  ) => {
    // NB: anything done here should try to be efficient and only set state when
    // necessary, since otherwise it causes the whole lobby to rerender, which
    // could cause performance impacts later
    this.receiveNewGameData(gameDataResponse.gameData)
    this.receiveNewSpectatorData(initialLoad)(gameDataResponse.spectatorRequests)
  }

  receiveNewGameData = (gameData: TGameData) => {
    const oldGameData = this.state.currentGameData
    const newMD5 = GameData.hash(gameData)

    if (newMD5 === oldGameData?.hash) {
      // avoid re-rendering the whole lobby if we don't need to on this poll
      return
    }

    const gameDataModel = new GameData(
      gameData,
      this.props.user.id,
      this.props.settingsState.betaFeatures.gameLog,
      newMD5
    )

    // store pastGamePositions in redux since the backend doesn't send the old ones?
    this.props.addPastGamePositionsForGame({
      gameId: gameDataModel.gameId,
      pastGamePositions: gameDataModel.pastGamePositions ?? [],
    })

    this.setState({currentGameData: gameDataModel}, () => {
      // NB: this callback is all just for user notifications
      const newGameData = this.state.currentGameData
      if (!oldGameData || !newGameData) {
        return
      }
      const newGamePos = newGameData.playerGamePosition
      const oldGamePos = oldGameData.playerGamePosition

      if (oldGamePos?.isMyMajorTurn() !== newGamePos?.isMyMajorTurn()) {
        sendNotification(
          {
            type: 'turnChange',
            isMyTurn: newGamePos?.isMyMajorTurn() ?? false,
          },
          this.props.settingsState.notifications
        )
      } else if (
        oldGamePos?.inProgressMajorAction === undefined &&
        newGamePos?.inProgressMajorAction
      ) {
        // new attack
        const attackDirection: TAttackDirection =
          newGamePos.inProgressMajorAction.majorActor.id === this.props.user.id
            ? 'fromMe'
            : newGamePos.inProgressMajorAction.pendingMinorActions.find(
                pma => pma.minorActorPlayerId === this.props.user.id
              )
            ? 'againstMe'
            : 'uninvolved'
        sendNotification(
          {
            type: 'attack',
            direction: attackDirection,
          },
          this.props.settingsState.notifications
        )
      }
    })
  }

  receiveNewSpectatorData = (initialLoad: boolean) => (
    spectatorData: TGameSpectatorData[]
  ) => {
    if (R.equals(this.props.spectatorState.spectatorData, spectatorData)) {
      // avoid re-rendering the whole lobby if we don't need to on this poll
      return
    }

    // update Redux
    this.props.setSpectatorData(spectatorData)

    if (initialLoad) {
      // no notification on first page load
      return
    }

    // previously denied requests are treated the same as nonexistent requests
    const oldSpectatorData = spectatorsDataForPlayer(
      this.props.user.id,
      this.props.spectatorState.spectatorData
    ).filter(sd => sd.status !== 'denied')
    const newSpectatorData = spectatorsDataForPlayer(
      this.props.user.id,
      spectatorData
    ).filter(sd => sd.status !== 'denied')

    const oldSpectatorsIds = new Set(
      spectatorsDataForPlayer(this.props.user.id, oldSpectatorData).map(
        sd => sd.spectatorPlayer.playerId
      )
    )
    const newSpectatorsIds = new Set(
      spectatorsDataForPlayer(this.props.user.id, newSpectatorData).map(
        sd => sd.spectatorPlayer.playerId
      )
    )

    if (!S.subset(newSpectatorsIds, oldSpectatorsIds)) {
      // new spectator
      sendNotification({type: 'spectator'}, this.props.settingsState.notifications)
    }
  }

  static requestsPendingStateChange = (
    delta: -1 | 1
  ): TReactSetStateStateParameter<IState, any, any> => prevState => ({
    gameDataRequestsPending: Math.max(0, prevState.gameDataRequestsPending + delta),
  })

  render() {
    const rules = defaultRuleset
    const {settingsState} = this.props
    const {gameId} = this.props.match.params

    return (
      <GameLobbyV2Layout {...this.props}>
        <api.preGame.getGameData.PromiseManager
          params={{}}
          pathPieces={{gameId}}
          onSuccess={this.receiveNewGameDataResponse(true)}
          then={() => {
            const {currentGameData} = this.state
            if (!currentGameData) {
              return <NotFound />
            }

            return (
              <>
                <api.preGame.getGameData.PromisePoller
                  params={{}}
                  pathPieces={{gameId}}
                  onSuccess={this.receiveNewGameDataResponse(false)}
                  frequencyMs={[3500, 1000, 3000, 5000, -1]}
                  pollImmediatelyOnMount={false}
                  pause={this.state.gameDataRequestsPending > 0}
                  restartKey={this.state.currentGameData?.currentGamePosition.time}
                />
                <GameLobbyV2UI
                  gameData={currentGameData}
                  settingsState={settingsState}
                  rules={rules}
                  chooseMajorAction={majorAction =>
                    setStatePromise(
                      this,
                      GameLobbyV2Page.requestsPendingStateChange(1)
                    ).then(() =>
                      api.inGame.submitMajorAction
                        .post(
                          {
                            time: currentGameData.currentGamePosition.time,
                            action: majorAction.datasource,
                          },
                          {gameId}
                        )
                        .finally(() =>
                          this.setState(
                            GameLobbyV2Page.requestsPendingStateChange(-1)
                          )
                        )
                    )
                  }
                  chooseMajorActionSubmitSuccess={r =>
                    this.receiveNewGameData(r.gameData)
                  }
                  chooseMinorAction={minorAction =>
                    setStatePromise(
                      this,
                      GameLobbyV2Page.requestsPendingStateChange(1)
                    ).then(() =>
                      api.inGame.submitMinorAction
                        .post(
                          {
                            time: currentGameData.currentGamePosition.time,
                            action: minorAction.datasource,
                          },
                          {gameId}
                        )
                        .finally(() =>
                          this.setState(
                            GameLobbyV2Page.requestsPendingStateChange(-1)
                          )
                        )
                    )
                  }
                  chooseMinorActionSubmitSuccess={r =>
                    this.receiveNewGameData(r.gameData)
                  }
                  allPastGamePositions={createSafe(
                    R.sortWith(
                      [R.ascend(a => a.date), R.ascend(a => a.gamePosition.time)],
                      values(
                        this.props.pastGamePositionsState.games.get(
                          this.state.currentGameData?.gameId ?? ''
                        )?.pastGamePositionsMap ?? new Map()
                      )
                    )
                  )}
                />
              </>
            )
          }}
        />
      </GameLobbyV2Layout>
    )
  }
}

const mapStateToProps = (state: ReduxState): IReduxProps => ({
  settingsState: state.settingsState,
  spectatorState: state.spectatorState,
  pastGamePositionsState: state.pastGamePositionsState,
})

const mapDispatchToProps = {setSpectatorData, addPastGamePositionsForGame}
type TDispatch = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(GameLobbyV2Page)
