import * as React from 'react'
import {OmitStrict} from 'type-zoo'
import {TPeripheralConstraintData} from '~/components/ProvideForm/base'
import ProvideForm from '~/components/ProvideForm/normal'
import SubmitOnMount from '~/components/ProvideForm/SubmitOnMount'
import {TConstraint} from '~/components/ProvideForm/utils'
import DSButton, {IDSButtonProps} from '~/design-system/Button'

export interface ISingleButtonFormProps<PromiseResultType = any>
  extends OmitStrict<IDSButtonProps, 'onClick' | 'className'> {
  hideGlobalErrors?: boolean
  onSubmit: () => Promise<PromiseResultType>
  submitSuccess?: (result: PromiseResultType) => void
  submitFailure?: (error: unknown) => void
  classNames?: {
    button?: string
    wrapper?: string
  }
  peripheralConstraints?: TConstraint<TPeripheralConstraintData<{}>>[]
  submitOnMount?: boolean
}

export class SingleButtonForm<PromiseResultType = any> extends React.Component<
  ISingleButtonFormProps<PromiseResultType>
> {
  render() {
    const {
      appearDisabled,
      disabled,
      hideGlobalErrors,
      onSubmit,
      peripheralConstraints,
      submitSuccess,
      submitFailure,
      classNames,
      submitOnMount,
      ...buttonProps
    } = this.props

    return (
      <ProvideForm<{}, PromiseResultType>
        hideGlobalErrors={hideGlobalErrors}
        onSubmit={onSubmit}
        submitSuccess={(_, r) => submitSuccess?.(r)}
        submitFailure={(_, e) => submitFailure?.(e)}
        getPreviouslySubmittedValuesFromInitialValues
        peripheralConstraints={peripheralConstraints}
        wrapperClassName={classNames?.wrapper}
        toFormFields={(generateFormFieldProps, formProps) => {
          const isButtonDisabled =
            disabled || formProps.isFormSubmitting || formProps.anyErrors

          return (
            <>
              <DSButton
                loading={formProps.isFormSubmitting}
                disabled={isButtonDisabled}
                appearDisabled={appearDisabled || isButtonDisabled}
                onClick={() => formProps.onSubmit()}
                className={classNames?.button}
                {...buttonProps}
              />
              <SubmitOnMount
                formProps={formProps}
                shouldSubmitOnMount={submitOnMount ?? false}
              />
            </>
          )
        }}
      />
    )
  }
}
