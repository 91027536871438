import {PostApi} from '~/api/client'
import GetGameData from '~/api/endpoints/game/GetGameData'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TGetGameDataResponse} from '~/api/generated/types/RevokeSpectatorPermission'

type Request = {}
type TRevokeSpectatorPermissionPathPieces = {
  gameId: string
  spectatorPlayerId: string
}
type Response = TGetGameDataResponse

/**
 * A player in a game can deny a spectator the right to see their hand,
 * either outright or after previously approving it.
 */
export default class RevokeSpectatorPermission extends PostApi<
  Request,
  Response,
  TRevokeSpectatorPermissionPathPieces
> {
  protected getMockResponse(
    params: Request,
    pathPieces: TRevokeSpectatorPermissionPathPieces
  ): Response {
    return GetGameData.mockResponseWithMockConfig({pathPieces})
  }

  protected getPath(pathPieces: TRevokeSpectatorPermissionPathPieces) {
    return serverEndpoints.revokeSpectatorPermission({
      playerId: pathPieces.spectatorPlayerId,
      gameId: pathPieces.gameId,
    })
  }

  protected transformResponse(r: Response) {
    return r
  }
}
