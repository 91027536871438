import {TPlayerData} from '~/api/generated/types/common'
import {DataBackedModel} from '~/models/base'
import Hand from '~/models/game/Hand'
import Portfolio from '~/models/game/Portfolio'

// represents all players at the table
//
// subclasses can specify whether this is an Opponent ("not you") or a CurrentPlayer ("you").
export default abstract class Player extends DataBackedModel<TPlayerData> {
  id: string
  username: string
  email: string
  portfolio: Portfolio

  hand: Hand

  constructor(data: TPlayerData, portfolio: Portfolio, hand: Hand) {
    super(data)
    this.id = data.playerId
    this.username = data.username
    this.email = data.email
    this.portfolio = portfolio
    this.hand = hand
  }
}
