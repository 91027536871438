import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {TGameSpectatorData} from '~/api/generated/types/common'

export type SpectatorState = {
  spectatorData: TGameSpectatorData[]
}

const initialState: SpectatorState = {
  spectatorData: [],
}

const spectatorSlice = createSlice({
  name: 'spectator',
  initialState,
  reducers: {
    setSpectatorData(
      state: SpectatorState,
      action: PayloadAction<TGameSpectatorData[]>
    ) {
      return {spectatorData: action.payload}
    },
  },
})

const {setSpectatorData} = spectatorSlice.actions
export {setSpectatorData}
export default spectatorSlice.reducer

export const spectatorsDataForPlayer = (
  playerId: string,
  spectatorData: TGameSpectatorData[]
): TGameSpectatorData[] =>
  spectatorData.filter(sd => sd.spectatedPlayer.playerId === playerId)
