import * as RA from 'ramda-adjunct'
import React from 'react'
import {safelyOr} from '~/utils'

interface IExternalClickDetectionObject {
  needsDetection: boolean // could probably be optional, just lets us avoid the `.contains` search if we don't need it.

  // refs for each "internal" node (we won't call the externalClickDetected callback if a click
  // target is contained within any of these ref'd elements)
  targetRefs: React.RefObject<HTMLElement> | React.RefObject<HTMLElement>[]

  externalClickDetected: () => void
}

interface IProps {
  objects: IExternalClickDetectionObject[]
}

/** Detects & reports clicks outside of each of the given target DOM nodes. Useful for closing menus. */
export class ExternalClickDetectors extends React.Component<IProps> {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleDocumentClick)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentClick)
  }

  handleDocumentClick = (e: Event) => {
    this.props.objects.forEach(o => {
      if (o.needsDetection) {
        // do any of the targetRefs contain the click?
        const containingRef = RA.ensureArray(o.targetRefs).find(tr =>
          safelyOr(tr.current, ref => ref.contains(e.target as Node), false)
        )
        if (!containingRef) {
          o.externalClickDetected()
        }
      }
    })
  }

  render() {
    return null
  }
}

/** Detects & reports clicks outside of a given target DOM node. Useful for closing menus. */
export default class ExternalClickDetector extends React.Component<
  IExternalClickDetectionObject
> {
  render() {
    return <ExternalClickDetectors objects={[this.props]} />
  }
}
