import {OmitStrict} from 'type-zoo'
import {TMinorActionPromptDataDebtCollectorBirthday} from '~/api/generated/types/common'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MinorAction from '~/models/game/MinorAction'
import {
  TMinorActionPromptUserFacingDescriptionData,
  TMinorActionPromptValidResponseData,
} from '~/models/game/MinorActionPrompt'
import MinorActionPromptPaySomething from '~/models/game/MinorActionPrompts/MinorActionPromptPaySomething'
import MinorActionPayOther from '~/models/game/MinorActions/MinorActionPayOther'
import {sentenceCase, show$, xVerbY} from '~/utils/language'

type PublicMembersFromApi = OmitStrict<
  TMinorActionPromptDataDebtCollectorBirthday,
  'type'
>

// Paying off a Debt Collector or Birthday
export default class MinorActionPromptPayOther
  extends MinorActionPromptPaySomething<TMinorActionPromptDataDebtCollectorBirthday>
  implements PublicMembersFromApi {
  protected _validResponses(
    data: TMinorActionPromptValidResponseData
  ): MinorAction<any> | MinorAction<any>[] {
    return this.withJustSayNo(
      data,
      new MinorActionPayOther({
        ...this.datasource,
        paidWith: [], // this will be filled in later once the user chooses how to pay
      })
    )
  }

  public userFacingDescription(
    data: TMinorActionPromptUserFacingDescriptionData
  ): string {
    const {gamePosition, tense} = data
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined
    const {majorActor, minorActor} = this

    const {conjugatedVerb, xNoun, yNoun} = xVerbY(
      tense,
      minorActor,
      'pay',
      majorActor,
      currentPlayer?.id
    )

    return [
      xNoun === minorActor.username ? xNoun : sentenceCase(xNoun),
      conjugatedVerb,
      yNoun,
      show$(Math.min(minorActor.portfolio.totalValue(), this.millionsOwed)),
    ].join(' ')
  }
}
