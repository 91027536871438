import * as R from 'ramda'
import * as RA from 'ramda-adjunct'
import * as React from 'react'
import {
  dollarsPrettifier,
  dollarsToCents,
  isPresent,
  ltrim,
  onlyNumbers,
  PHONE_NUMBER_COUNTRY_CODE_PREFIX_REGEX,
  safelyOr,
  unreachableCase,
} from '~/utils'
import Day from '~/utils/Day'
import * as S from '~/utils/Set'
import {IFormFieldProps} from './types'

// autocomplete="off" doesn't work on Chrome. so we use a string that, in theory, will make it think
// it doesn't know how to handle this input for autocomplete/autofill instead.
// context: https://stackoverflow.com/a/47822599/2544629 and https://stackoverflow.com/a/15917221/2544629
export const DISABLE_AUTOCOMPLETE_STRING = 'garbage'

export type TConstraint<T> = IBasicConstraint<T> | AdvancedConstraint<T>

/* The `ProvideForm` component uses this type to describe what kinds of constraints it can accept. */
export type TFormConstraint = IBasicConstraint<string> | AdvancedConstraint<string>

/**
 * A simple way to construct a constraint for a form field.
 * `constraint` defines a check that returns `true` if the field's value is acceptable.
 * If `constraint` returns `false`, `message` will be called to generate an error message for the user.
 * Both functions are passed the current value of the form field.
 */
export interface IBasicConstraint<T = undefined> {
  constraint: (s: T) => boolean
  message: (s: T) => string
}

export interface IBasicStringConstraint extends IBasicConstraint<string> {}

export type TAdvancedConstraintResponse =
  | TAdvancedConstraintSuccessResponse
  | TAdvancedConstraintFailureResponse

export type TAdvancedConstraintSuccessResponse = {type: 'pass'}
export type TAdvancedConstraintFailureResponse = {type: 'fail'; message: string}

/**
 * The "Basic" constraints are good for most use cases, and are really easy to construct in-line.
 * However, if you have a more complicated constraint that needs to check a few different things,
 * it can be more convenient / type-safe / run-time-efficient to run a single function that can
 * report N failures with different failure messages rather than just 1 test and 1 message.
 *
 * The "Advanced" constraints here do just that. You build the class with your `test` function,
 * which returns a success or a failure based on the current value of the field.
 *
 * All `TBasicConstraint<T>`s can be written as / converted to `AdvancedConstraint<T>`s trivially
 * (see `basicToAdvanced`), and if we end up using them a lot, we could just combine the two concepts.
 * For now, it seems nice to keep most constraints simple/easy to write.
 */
export class AdvancedConstraint<T = undefined> {
  static pass: TAdvancedConstraintSuccessResponse = {type: 'pass'}
  static fail = (message: string): TAdvancedConstraintFailureResponse => {
    return {type: 'fail', message}
  }

  public testFunction: (value: T) => TAdvancedConstraintResponse
  private _preProcessor: ((value: T) => T) | undefined = undefined

  constructor(test: (value: T) => TAdvancedConstraintResponse) {
    this.testFunction = test
  }

  /*
    Set this optional function to e.g. strip a dollar sign or % sign from the submitted text before running the test
    This is nice for being able to re-use existing constraints easily in new situations.
  */
  public setPreProcessor = (preProcessor: ((value: T) => T) | undefined): this => {
    this._preProcessor = preProcessor
    return this
  }

  public test = (value: T): TAdvancedConstraintResponse => {
    const processed: T = safelyOr(this._preProcessor, ppv => ppv(value), value)
    return this.testFunction(processed)
  }
}

export class AdvancedStringConstraint extends AdvancedConstraint<string> {}

export const basicToAdvanced = <T>(
  basic: IBasicConstraint<T>
): AdvancedConstraint<T> => {
  return new AdvancedConstraint<T>(value => {
    if (basic.constraint(value)) {
      return {type: 'pass'}
    } else {
      return {type: 'fail', message: basic.message(value)}
    }
  })
}

export const areConstraintsMet = <T>(
  constraints: IBasicConstraint<T> | IBasicConstraint<T>[],
  value: T
): boolean => {
  return RA.ensureArray(constraints).every(c => c.constraint(value))
}

export const notEmptyWithMessage = (message: string): IBasicStringConstraint => ({
  constraint: isPresent,
  message: _ => message,
})

export const notEmpty: IBasicStringConstraint = notEmptyWithMessage(
  'Please complete this field'
)

export const lengthConstraint = (
  message: string,
  mustBeLength: number
): IBasicStringConstraint => ({
  constraint: (s: string) => s.length === mustBeLength,
  message: _ => message,
})

export const maxLengthConstraint = (
  message: string,
  maxLength: number
): IBasicStringConstraint => ({
  constraint: (s: string) => s.length <= maxLength,
  message: _ => message,
})

export const exactConstraint = (
  message: string,
  mustMatch: string
): IBasicStringConstraint => ({
  constraint: (s: string) => s === mustMatch,
  message: _ => message,
})

export const endsWithConstraint = (
  message: string,
  endsWith: string
): IBasicStringConstraint => ({
  constraint: (s: string) => s.endsWith(endsWith),
  message: _ => message,
})

export const integerConstraint: IBasicStringConstraint = {
  constraint: R.test(/^\d+$/),
  message: _ => 'Must be a number',
}

export const positiveIntegerConstraint: AdvancedStringConstraint = new AdvancedStringConstraint(
  s => {
    const n = Number(s)
    if (!R.test(/^\d+$/, s) || isNaN(n) || n <= 0) {
      return AdvancedStringConstraint.fail('Must be a positive number')
    }

    return AdvancedStringConstraint.pass
  }
)

// [min, max)
// (between min and max, including min but excluding max)
export const integerRangeConstraint = (
  min: number,
  max: number
): IBasicStringConstraint => ({
  constraint: s =>
    R.test(/^\d+$/, s) && !isNaN(Number(s)) && RA.inRange(min, max, Number(s)),
  message: _ => `Must be >= ${min} and < ${max}`,
})

// doesn't allow commas
export const floatRangeConstraint = (
  min: number,
  max: number,
  edgeBehavior: '[]' | '()' | '[)' | '(]',
  customEdgeErrorMessage?: (data: {
    min: number
    max: number
    value: number
  }) => string
): AdvancedStringConstraint =>
  new AdvancedStringConstraint(s => {
    const num = Number(s)
    if (isNaN(num)) {
      return {type: 'fail', message: 'Must be a number'}
    }

    const pass: TAdvancedConstraintSuccessResponse = {type: 'pass'}
    const customMessage = customEdgeErrorMessage?.({min, max, value: num})

    switch (edgeBehavior) {
      case '()':
        return num > min && num < max
          ? pass
          : {type: 'fail', message: customMessage ?? `Must be > ${min} and < ${max}`}
      case '[]':
        return num >= min && num <= max
          ? pass
          : {
              type: 'fail',
              message: customMessage ?? `Must be >= ${min} and <= ${max}`,
            }
      case '(]':
        return num > min && num <= max
          ? pass
          : {
              type: 'fail',
              message: customMessage ?? `Must be > ${min} and <= ${max}`,
            }
      case '[)':
        return num >= min && num < max
          ? pass
          : {
              type: 'fail',
              message: customMessage ?? `Must be >= ${min} and < ${max}`,
            }
      default:
        return unreachableCase(edgeBehavior)
    }
  })

// for checkboxes
export const isCheckedConstraint = (message: string): IBasicStringConstraint => ({
  constraint: (s: string) => {
    return TrueOrFalse.toBool(s as TTrueOrFalse)
  },
  message: _ => message,
})

export const isDomesticPhoneNumber: IBasicStringConstraint = {
  constraint: R.test(
    /^(?:\([0-9]{3}\)|[0-9]{3})[.\s\-–]{0,3}[0-9]{3}[\-–\s.]{0,3}[0-9]{4}$/
  ),
  message: _ => 'Please submit a 10-digit US phone number',
}

export const isMemberConstraint = (
  ofCollection: S.SetCreationParameter<any>,
  message?: (s: string) => string
): IBasicStringConstraint => ({
  constraint: s => S.create(ofCollection).has(s),
  message: s => message?.(s) ?? 'Please choose a valid option',
})

export const isInternationalPhoneNumber: AdvancedStringConstraint = new AdvancedStringConstraint(
  s => {
    if (!R.test(/^\+\d/, s)) {
      return AdvancedConstraint.fail(
        'Phone number must start with a + and country code.'
      )
    }

    if (!R.test(PHONE_NUMBER_COUNTRY_CODE_PREFIX_REGEX, s)) {
      return AdvancedConstraint.fail('The country code you entered is not valid.')
    }

    // we've had bugs in the past with our input adding extra `+`s, so check that there is exactly one,
    // and that it is at the beginning of the string
    if (!R.test(/^\+[^+]+$/, s)) {
      return AdvancedConstraint.fail('The phone number you entered is invalid.')
    }

    const digitsOnly = onlyNumbers(s)

    // https://stackoverflow.com/q/14894899/2544629 suggests it's 7 or 8 minimum
    if (digitsOnly.length < 7) {
      return AdvancedConstraint.fail('Phone number is too short.')
    }

    // The ITU E.164 standard says all international phone numbers are 15 digits or less
    if (digitsOnly.length > 15) {
      return AdvancedConstraint.fail('Phone number is too long.')
    }

    return AdvancedConstraint.pass
  }
)

export const isSSN = (allowDots: boolean): IBasicStringConstraint => ({
  constraint: (s: string) => {
    const clean = s.replace(/[^\d•]/g, '') // remove dashes
    return (allowDots && R.test(/^[•]{9}$/)(clean)) || R.test(/^[\d]{9}$/)(clean)
  },
  message: _ => 'Please submit a 9-digit SSN',
})

/* eslint-disable max-len */
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export const emailConstraints: IBasicStringConstraint[] = [
  notEmpty,
  {
    constraint: R.contains('@'),
    message: _ => 'Please submit a valid email',
  },
  {
    constraint: R.test(EMAIL_REGEX),
    message: _ => 'Please submit a valid email',
  },
]

export const zipCodeConstraints: IBasicStringConstraint[] = [
  {
    constraint: R.test(/^\d{5}$/),
    message: _ => 'Must be a 5-digit number',
  },
]

export const isValidDate: IBasicStringConstraint[] = [
  {
    constraint: (s: string) => !!Day.maybeFromServer(s),
    message: _ => 'Please enter a valid date',
  },
  {
    constraint: (s: string) => Day.fromServer(s).isAfter(new Day(1900, 1, 1)),
    message: _ => 'Please check the year',
  },
  {
    constraint: (s: string) => Day.fromServer(s).isBeforeOrEqual(Day.today()),
    message: _ => 'Date cannot be in the future',
  },
]

// Taken from https://stackoverflow.com/a/34529037/3806046
/* eslint-disable max-len */
const IP_ADDRESS_REGEX = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/

export const commaSeparatedIPAddressListConstraint: IBasicStringConstraint = {
  constraint: (s: string) =>
    s
      .split(',')
      .map(ip => R.test(IP_ADDRESS_REGEX)(ip.trim()))
      .every(R.identity),
  message: _ => 'Enter a comma-separated list of IPv4/6 addresses.',
}

/* Banking Constraints */

const ROUTING_NUMBER_REGEX = /^\d{9}$/
export const routingNumberConstraints: IBasicStringConstraint[] = [
  {
    constraint: R.test(ROUTING_NUMBER_REGEX),
    message: _ => 'Routing # should be a 9-digit number',
  },
]

export const duplicateAcctRoutingConstraint = (
  acctOrRoutingNumber: string | undefined
): IBasicStringConstraint => ({
  constraint: s => s !== acctOrRoutingNumber,
  message: _ => 'Account and Routing # should not match',
})

// tests for correct comma structure (but also allows numbers w/ no commas) for currency strings
// as well as decimal-part structure. see the tests for valid/invalid inputs
// the beginning lookahead (?=.*\d.*) ensures the optionality in the rest of the regex doesn't allow an empty string
const DOLLAR_VALUE_REGEX = /^(?=.*\d.*)\$?\d*((,\d{3}){1,})?(\.\d{0,2})?$/
export const dollarStringConstraint: IBasicStringConstraint = {
  constraint: s => {
    return R.test(DOLLAR_VALUE_REGEX)(s) && dollarStringToFloat(s) > 0
  },
  message: _ => 'Must be a real amount',
}

// [min, max)
// (between min and max, including min but excluding max)
export const dollarStringRangeConstraint = (
  min: number,
  max: number
): IBasicStringConstraint => ({
  constraint: s => {
    return (
      R.test(DOLLAR_VALUE_REGEX)(s) && RA.inRange(min, max, dollarStringToFloat(s))
    )
  },
  message: _ =>
    `Must be >= ${dollarsPrettifier(min)} and < ${dollarsPrettifier(max)}`,
})

export const dollarStringInclusiveRangeConstraint = (
  min: number,
  max: number
): IBasicStringConstraint => ({
  constraint: s => {
    return (
      R.test(DOLLAR_VALUE_REGEX)(s) &&
      dollarStringToFloat(s) >= min &&
      dollarStringToFloat(s) <= max
    )
  },
  message: _ =>
    `Must be >= ${dollarsPrettifier(min)} and <= ${dollarsPrettifier(max)}`,
})

export const dollarStringGreaterOrEqualConstraint = (
  min: number
): IBasicStringConstraint => ({
  constraint: s => {
    return R.test(DOLLAR_VALUE_REGEX)(s) && dollarStringToFloat(s) >= min
  },
  message: _ => `Must be >= ${dollarsPrettifier(min)}`,
})

/** Remove all characters other than digits and decimal points from a string */
export const cleanDollarString = (str: string): string => {
  return str.replace(/[^\d\.]/g, '')
}

export const dollarStringToFloat = (str: string): number => {
  return parseFloat(cleanDollarString(str))
}

export const dollarStringToCents = (str: string): number => {
  return dollarsToCents(dollarStringToFloat(str))
}

const EIN_REGEX = /(0[1-6]|1[0-6]|2[0-7]|3\d|4[0-8]|5\d|6[0-8]|7[1-7]|8[0-8]|9[0-589])[-–]?\d{7}/i
const obviouslyFakeEINs = ['123456789', '001234567', '123123123']
export const federalEinConstraints: IBasicStringConstraint[] = [
  {
    // we specifically want to catch fake EINs and warn users that we really need a real one
    constraint: s =>
      !obviouslyFakeEINs.includes(onlyNumbers(s)) && !/(\d)\1[-–]?\1{7}/.test(s),
    message: _ => 'You must have a real EIN to open an account.',
  },
  {
    constraint: R.test(EIN_REGEX),
    message: _ => 'Please enter a valid 9-digit EIN',
  },
]

/* Other Constraints */

// since the form doesn't support non-strings yet, these are helpful for storing booleans (checkboxes) in a form
// TODO add boolean to ProvideForm
export class BooleanString<T extends string> {
  choiceSet: Set<T> = new Set<T>([this.truthyVal, this.falseyVal])
  false: T = this.falseyVal
  true: T = this.truthyVal

  constructor(private truthyVal: T, private falseyVal: T) {}

  toBool = (s: T): boolean => s === this.truthyVal
  toString = (b: boolean): T => (b ? this.truthyVal : this.falseyVal)
  toggle = (s: T): T => (this.isChecked(s) ? this.falseyVal : this.truthyVal)

  /// Specializations for checkbox/radio button/etc. contexts.

  // alias for `toBool` for readability
  isChecked = (s: T): boolean => this.toBool(s)
  // use this version if your boolean starts undefined (i.e. the user has to choose), so there are 3 states.
  isSetAndChecked = (s: T | undefined): boolean => !!s && this.isChecked(s)
  // use this version if your boolean starts undefined (i.e. the user has to choose), so there are 3 states.
  isSetAndUnchecked = (s: T | undefined): boolean => !!s && !this.isChecked(s)
}

export type TTrueOrFalse = 'true' | 'false'
export const TrueOrFalse = new BooleanString<TTrueOrFalse>('true', 'false')
export type TOnOrOff = 'on' | 'off'
export const OnOrOff = new BooleanString<TOnOrOff>('on', 'off')

export const yesOrNoHuman: Record<TTrueOrFalse, string> = {
  true: 'Yes',
  false: 'No',
}

/** Only enforce the given constraint(s) if the field is not empty */
export const ignoreConstraintsIfBlank = (
  constraints: TFormConstraint[]
): TFormConstraint[] => {
  return ignoreConstraintsIf(s => s.length === 0, constraints)
}

export const ignoreConstraintsIf = (
  condition: (s: string) => boolean,
  constraints: TFormConstraint | TFormConstraint[]
): TFormConstraint[] => {
  return RA.ensureArray(constraints).map(c => {
    if (c instanceof AdvancedConstraint) {
      return new AdvancedConstraint(value => {
        if (condition(value)) {
          return {type: 'pass'}
        } else {
          return c.test(value)
        }
      })
    } else {
      return {
        constraint: (s: string) => condition(s) || c.constraint(s),
        message: c.message,
      }
    }
  })
}

export const internationalPhoneNumberOnChangeHandler = (
  formFieldProps: IFormFieldProps
) => (e: React.ChangeEvent<HTMLInputElement>) => {
  // automatically add the `+` prefix if the new value is not blank
  let value = e.target.value
  if (isPresent(value) && !value.includes('+')) {
    formFieldProps.onChange(`+${ltrim(value)}`)
  }
}
