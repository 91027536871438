import {OmitStrict} from 'type-zoo'
import {TMajorActionDiscardData} from '~/api/generated/types/common'
import Card from '~/models/game/Card'
import {cardFactory} from '~/models/game/factories'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MajorAction, {
  TMajorActionUserFacingDescriptionData,
} from '~/models/game/MajorAction'
import {compact, mutateIf} from '~/utils'
import {inTense, pluralize, sentenceCase} from '~/utils/language'

type PublicMembersFromApi = OmitStrict<TMajorActionDiscardData, 'cards' | 'type'>

export default class MajorActionDiscard
  extends MajorAction
  implements PublicMembersFromApi {
  cards: Card<any>[]

  constructor(data: TMajorActionDiscardData) {
    super(data)
    this.cards = data.cards.map(cardFactory)
  }

  public userFacingDescription({
    tense,
    skin,
    gamePosition,
  }: TMajorActionUserFacingDescriptionData): string {
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined
    const majorActor = gamePosition.majorActorPlayer
    const dropFirstNoun = tense === 'imperativePrompt'

    // NB: the game log uses this, and we fake the cards in the card array since the backend
    // sends an empty array, so don't try to print data about the discarded cards (except the count).
    return compact([
      dropFirstNoun
        ? undefined
        : currentPlayer?.id === majorActor.id
        ? 'You'
        : majorActor.username,
      mutateIf(inTense(tense)('discard'), dropFirstNoun, sentenceCase),
      this.cards.length,
      pluralize(this.cards.length, 'card'),
    ]).join(' ')
  }
}
