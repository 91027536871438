import {TPlayerData} from '~/api/generated/types/common'
import ConcealedHand from '~/models/game/Hands/ConcealedHand'
import Player from '~/models/game/Player'
import Portfolio from '~/models/game/Portfolio'

/* a player whose hand we can't see */
export default class ConcealedPlayer extends Player {
  public hand: ConcealedHand
  constructor(playerData: TPlayerData, portfolio: Portfolio, hand: ConcealedHand) {
    super(playerData, portfolio, hand)
    this.hand = hand
  }
}
