import {TMinorActionDataAcceptSlyDeal} from '~/api/generated/types/common'
import Card from '~/models/game/Card'
import {propertyCardFactory} from '~/models/game/factories'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MinorAction, {
  TMinorActionUserFacingDescriptionData,
} from '~/models/game/MinorAction'
import PropertyCard from '~/models/game/Cards/PropertyCard'
import {compact, mutateIf} from '~/utils'
import {sentenceCase, xVerbY} from '~/utils/language'

export default class MinorActionAcceptSlyDeal extends MinorAction<
  TMinorActionDataAcceptSlyDeal
> {
  requestedCard: PropertyCard<any>

  constructor(data: TMinorActionDataAcceptSlyDeal) {
    super(data)
    this.requestedCard = propertyCardFactory(data.requestedCard)
  }

  userFacingDescription({
    tense,
    majorActor,
    minorActor,
    skin,
    inProgressMajorAction,
    gamePosition,
  }: TMinorActionUserFacingDescriptionData): string {
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined

    const dropFirstNoun = tense === 'imperativePrompt'

    const {conjugatedVerb, xNoun, yNounPossessive} = xVerbY(
      tense,
      minorActor,
      dropFirstNoun ? 'Accept' : 'accept',
      majorActor,
      currentPlayer?.id
    )

    return compact([
      dropFirstNoun
        ? undefined
        : xNoun === minorActor.username
        ? xNoun
        : sentenceCase(xNoun),
      mutateIf(conjugatedVerb, dropFirstNoun, sentenceCase),
      yNounPossessive,
      Card.displayNameForCard('slyDeal', skin),
    ]).join(' ')
  }
}
