import classnames from 'classnames'
import {css} from 'emotion'
import * as React from 'react'
import {TPlay} from '~/api/generated/types/common'
import {TSubmitMajorActionResponse} from '~/api/generated/types/SubmitMajorAction'
import {SingleButtonForm} from '~/components/SingleButtonForm'
import {movesLeftForPlay} from '~/game/types'
import {ForcedDealCard, SlyDealCard} from '~/models/game/Cards/ActionCard'
import CashCard from '~/models/game/Cards/CashCard'
import PropertyCard from '~/models/game/Cards/PropertyCard'
import ResidenceCard from '~/models/game/Cards/ResidenceCard'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MajorActionForcedDeal from '~/models/game/MajorActions/MajorActionForcedDeal'
import MajorActionPlayAsCash from '~/models/game/MajorActions/MajorActionPlayAsCash'
import MajorActionSlyDeal from '~/models/game/MajorActions/MajorActionSlyDeal'
import Opponent from '~/models/game/Players/Opponent'
import {TClickableCardState} from '~/pages/GameLobby/stateless'
import {compact, partitionIs, safely} from '~/utils'
import DSButton from '~/design-system/Button'
import Card from '~/models/game/Card'
import MajorActionDiscard from '~/models/game/MajorActions/MajorActionDiscard'
import DSText, {DSTextDiv} from '~/design-system/DSText'
import {MAX_CARDS_IN_HAND, TRuleset} from '~/game/ruleset'
import MajorAction from '~/models/game/MajorAction'
import {px} from '~/components/utils'
import {TSkin} from '~/game/skins'
import CardImage, {cardHeightFromWidth} from '~/pages/GameLobby/GameCard/stateless'
import GameData from '~/models/game/GameData'
import {sizeData, TGameLobbySize} from '~/pages/GameLobby/types'
import {pluralize} from '~/utils/language'
import * as S from '~/utils/Set'
import styles from './styles.module.css'

export interface ICurrentPlayerHandUIProps {
  gameData: GameData
  playerGamePosition: PlayerGamePosition
  gameLobbySize: TGameLobbySize
  skin: TSkin
  rules: TRuleset
  classNames?: {
    wrapper?: string
  }

  chooseMajorAction: (
    majorAction: MajorAction
  ) => Promise<TSubmitMajorActionResponse>
  chooseMajorActionSubmitSuccess: (response: TSubmitMajorActionResponse) => void

  buttonAreaOverride?: React.ReactNode

  // pushed up to GameLobby because it needs to read it
  selectedCardId: string | undefined
  selectCard: (cardId: string | undefined) => void

  clickableCardState: TClickableCardState
}

interface IState {
  submittingMajorAction: boolean
  discarding: Set<string> | false // Set<cardId> if discarding
  confirmingEndTurn: boolean

  propPlay: TPlay
}

export default class CurrentPlayerHandUI extends React.Component<
  ICurrentPlayerHandUIProps,
  IState
> {
  state: IState = {
    submittingMajorAction: false,
    discarding: false,
    confirmingEndTurn: false,
    propPlay: this.props.gameData.currentGamePosition.play,
  }

  static getDerivedStateFromProps(
    nextProps: ICurrentPlayerHandUIProps,
    prevState: IState
  ): Partial<IState> | null {
    if (nextProps.gameData.currentGamePosition.play !== prevState.propPlay) {
      return {
        propPlay: nextProps.gameData.currentGamePosition.play,
        submittingMajorAction: false,
        confirmingEndTurn: false,
        discarding: false,
      }
    }
    return null
  }

  cardClicked = (card: Card<any>) => {
    let newlySelectedCardId: string | undefined
    this.setState(
      prevState => {
        if (prevState.discarding) {
          newlySelectedCardId = undefined
          return {
            discarding: S.toggleMembership(card.id, prevState.discarding),
          }
        } else {
          newlySelectedCardId =
            this.props.selectedCardId === card.id ? undefined : card.id
          return {
            discarding: false,
          }
        }
      },
      () => this.props.selectCard(newlySelectedCardId)
    )
  }

  renderEndOfTurnButtons() {
    const {gameData, buttonAreaOverride, playerGamePosition} = this.props
    const {currentGamePosition} = gameData
    const {cards} = playerGamePosition.currentPlayerHand

    const canDiscard =
      playerGamePosition.isMyMajorTurn() &&
      !currentGamePosition.inProgressMajorAction

    if (buttonAreaOverride) {
      return <div className={styles.turnButtons}>{buttonAreaOverride}</div>
    }

    return (
      <div className={classnames(styles.turnButtons, !canDiscard && styles.hidden)}>
        <DSButton
          size="17px"
          buttonStyle="tertiary-destructive"
          onClick={() =>
            this.setState({discarding: new Set()}, () =>
              this.props.selectCard(undefined)
            )
          }
          disabled={
            this.state.confirmingEndTurn ||
            cards.length <= MAX_CARDS_IN_HAND ||
            !!this.state.discarding
          }
          className={classnames(styles.turnButton, styles.hideWhenDisabled)}
        >
          Discard {cards.length - MAX_CARDS_IN_HAND} & End Turn
        </DSButton>
        <DSButton
          size="17px"
          buttonStyle="tertiary-destructive"
          onClick={() => this.setState({confirmingEndTurn: true})}
          disabled={this.state.confirmingEndTurn || cards.length > MAX_CARDS_IN_HAND}
          className={classnames(styles.turnButton, styles.hideWhenDisabled)}
        >
          End Turn
        </DSButton>
        <SingleButtonForm
          size="17px"
          buttonStyle="tertiary-destructive"
          onSubmit={() =>
            this.props.chooseMajorAction(
              new MajorActionDiscard({
                type: 'discard',
                cards: [],
              })
            )
          }
          submitSuccess={r => {
            this.props.chooseMajorActionSubmitSuccess(r)
            this.setState({discarding: false, confirmingEndTurn: false})
          }}
          disabled={!this.state.confirmingEndTurn}
          classNames={{
            wrapper: classnames(
              styles.turnButton,
              !this.state.confirmingEndTurn && styles.hidden
            ),
          }}
        >
          Yes, End Turn
        </SingleButtonForm>
        <DSButton
          size="17px"
          buttonStyle="secondary"
          onClick={() =>
            this.setState({confirmingEndTurn: false, discarding: false})
          }
          disabled={
            (!this.state.confirmingEndTurn && !this.state.discarding) ||
            currentGamePosition.play === 'discardPlay'
          }
          className={classnames(styles.turnButton, styles.hideWhenDisabled)}
        >
          Go back
        </DSButton>
        <SingleButtonForm
          size="17px"
          buttonStyle="tertiary-destructive"
          onSubmit={() =>
            this.props.chooseMajorAction(
              new MajorActionDiscard({
                type: 'discard',
                cards: compact(
                  S.map(
                    id => cards.find(c => c.id === id),
                    this.state.discarding as Set<string>
                  )
                ),
              })
            )
          }
          submitSuccess={r => {
            this.props.chooseMajorActionSubmitSuccess(r)
            this.setState({discarding: false, confirmingEndTurn: false})
          }}
          disabled={
            !this.state.discarding ||
            cards.length - this.state.discarding.size !== MAX_CARDS_IN_HAND
          }
          classNames={{
            wrapper: classnames(
              styles.turnButton,
              !this.state.discarding && styles.hidden
            ),
          }}
        >
          Discard {cards.length - MAX_CARDS_IN_HAND} Selected Cards
        </SingleButtonForm>
      </div>
    )
  }

  renderSelectedCardOptions = (selectedCard: Card<any>) => {
    const {
      gameData,
      skin,
      rules,
      playerGamePosition,
      clickableCardState,
    } = this.props
    const {currentGamePosition} = gameData

    const canDiscard =
      playerGamePosition.isMyMajorTurn() &&
      !currentGamePosition.inProgressMajorAction
    const canTakeMajorAction =
      canDiscard && currentGamePosition.play !== 'discardPlay'

    const actionListUI = (actions: MajorAction[]) => {
      return (
        <ul className={styles.actionChoicesList}>
          {actions.map((action, i) => {
            const desc = action.userFacingDescription({
              gamePosition: currentGamePosition,
              skin,
              tense: 'imperativePrompt',
            })
            // TODO better loading state and error handling / UI for this promise...
            //  aka use the form
            return (
              <DSText
                size="body-15"
                tag="li"
                key={
                  desc +
                  i /* if you have e.g. 2 PWCs or 2 red/yellow wilds, desc isn't unique atm */
                }
                className={classnames(
                  styles.actionChoice,
                  canTakeMajorAction && styles.clickable
                )}
                onClick={() => {
                  if (!canTakeMajorAction) {
                    return
                  }

                  if (
                    action instanceof MajorActionPlayAsCash &&
                    !(
                      selectedCard instanceof CashCard ||
                      selectedCard instanceof ResidenceCard
                    )
                  ) {
                    if (
                      !confirm('Are you sure you want to play this card as cash?')
                    ) {
                      return
                    }
                  }

                  this.setState({submittingMajorAction: true})
                  this.props
                    .chooseMajorAction(action)
                    .then(r => {
                      this.setState({submittingMajorAction: false}, () =>
                        this.props.chooseMajorActionSubmitSuccess(r)
                      )
                    })
                    .catch(e => {
                      console.error('error submitting major action: ', e)
                      this.setState({submittingMajorAction: false})
                    })
                }}
              >
                {desc}
              </DSText>
            )
          })}
        </ul>
      )
    }

    const availableMoves = playerGamePosition.currentPlayer.availableMovesFromHandForCardId(
      selectedCard.id,
      rules,
      playerGamePosition
    )

    if (selectedCard instanceof SlyDealCard) {
      const [slyDeals, otherActions] = partitionIs(
        (m: MajorAction): m is MajorActionSlyDeal => m instanceof MajorActionSlyDeal,
        availableMoves
      )
      const slyDealData = (():
        | {
            cardToStealOwner: Opponent
            cardToSteal: PropertyCard<any>
            slyDeal: MajorActionSlyDeal
          }
        | undefined => {
        if (clickableCardState.mode === 'playSlyDeal') {
          const findCard = (o: Opponent) =>
            o.portfolio
              .allProperties()
              .find(p => p.id === clickableCardState.selectedCardToRequestId)
          const cardToStealOwner = playerGamePosition.opponents.find(findCard)
          const cardToSteal = safely(cardToStealOwner, findCard)
          const slyDeal = safely(cardToSteal, cts =>
            slyDeals.find(sd => sd.requestedCard.id === cts.id)
          )
          if (!!cardToStealOwner && !!cardToSteal && !!slyDeal) {
            return {cardToStealOwner, cardToSteal, slyDeal}
          }
        }
        return undefined
      })()

      return (
        <>
          {slyDealData ? (
            <SingleButtonForm
              buttonStyle="primary"
              size="15px"
              disabled={!canTakeMajorAction}
              onSubmit={() => {
                this.setState({submittingMajorAction: true})
                return this.props.chooseMajorAction(slyDealData.slyDeal)
              }}
              submitSuccess={r => {
                this.setState({submittingMajorAction: false}, () =>
                  this.props.chooseMajorActionSubmitSuccess(r)
                )
              }}
              submitFailure={e => {
                console.error('error submitting major action: ', e)
                this.setState({submittingMajorAction: false})
              }}
            >
              Steal {slyDealData.cardToStealOwner.username}'s{' '}
              {slyDealData.cardToSteal.displayName(skin)}
            </SingleButtonForm>
          ) : (
            <DSText size="body-15">
              Choose a card to take with your {selectedCard.displayName(skin)}. Or:
            </DSText>
          )}
          {actionListUI(otherActions)}
        </>
      )
    } else if (selectedCard instanceof ForcedDealCard) {
      const [forcedDeals, otherActions] = partitionIs(
        (m: MajorAction): m is MajorActionForcedDeal =>
          m instanceof MajorActionForcedDeal,
        availableMoves
      )
      const forcedDealData = (():
        | {
            cardToStealOwner: Opponent
            cardToSteal: PropertyCard<any>
            forcedDeal: MajorActionForcedDeal
            cardToGive: PropertyCard<any>
          }
        | undefined => {
        if (clickableCardState.mode === 'playForcedDeal') {
          const findCardToSteal = (o: Opponent) =>
            o.portfolio
              .allProperties()
              .find(p => p.id === clickableCardState.selectedCardToRequestId)

          const cardToStealOwner = playerGamePosition.opponents.find(findCardToSteal)
          const cardToSteal = safely(cardToStealOwner, findCardToSteal)
          const cardToGive = playerGamePosition.currentPlayer.portfolio
            .allProperties()
            .find(p => p.id === clickableCardState.selectedCardToOfferId)

          const forcedDeal = forcedDeals.find(
            fd =>
              fd.requestedCard.id === cardToSteal?.id &&
              fd.offeredCard.id === cardToGive?.id
          )
          if (!!cardToStealOwner && !!cardToSteal && !!cardToGive && !!forcedDeal) {
            return {cardToStealOwner, cardToSteal, cardToGive, forcedDeal}
          }
        }
        return undefined
      })()

      return (
        <>
          {forcedDealData ? (
            <SingleButtonForm
              buttonStyle="primary"
              size="15px"
              disabled={!canTakeMajorAction}
              onSubmit={() => {
                this.setState({submittingMajorAction: true})
                return this.props.chooseMajorAction(forcedDealData.forcedDeal)
              }}
              submitSuccess={r => {
                this.setState({submittingMajorAction: false}, () =>
                  this.props.chooseMajorActionSubmitSuccess(r)
                )
              }}
              submitFailure={e => {
                console.error('error submitting major action: ', e)
                this.setState({submittingMajorAction: false})
              }}
            >
              {selectedCard.displayName(skin)}{' '}
              {forcedDealData.cardToStealOwner.username}
              {/* to give you their {forcedDealData.cardToSteal.displayName(skin)}{' '}
              for your {forcedDealData.cardToGive.displayName(skin)} */}
            </SingleButtonForm>
          ) : (
            <DSText size="body-15">
              Click the two cards to trade with your {selectedCard.displayName(skin)}
              . Or:
            </DSText>
          )}
          {actionListUI(otherActions)}
        </>
      )
    } else {
      return actionListUI(availableMoves)
    }
  }

  render() {
    const {
      skin,
      gameLobbySize,
      classNames,
      playerGamePosition,
      selectedCardId,
    } = this.props
    const {cards} = playerGamePosition.currentPlayerHand

    const selectedCard = cards.find(c => c.id === selectedCardId)

    const {cardWidth: cardWidthPx} = sizeData[gameLobbySize].hand
    const cardHeightPx = cardHeightFromWidth(cardWidthPx)
    const xOffsetPct = 1.1
    const yOffsetPct = 0

    const cardsWrapperStyle = {
      width: px(cardWidthPx + (cards.length - 1) * xOffsetPct * cardWidthPx),
      height: px(cardHeightPx + (cards.length - 1) * yOffsetPct * cardHeightPx),
    }

    return (
      <div
        className={classnames(
          styles.handWrapper,
          classNames?.wrapper,
          css`
            min-width: ${cardsWrapperStyle.width};
          `
        )}
      >
        <div className={styles.turnLabelAndButtons}>
          <DSTextDiv size="body-15">
            {playerGamePosition.isMyMajorTurn()
              ? 'Your'
              : playerGamePosition.majorActorPlayer.username + `'s`}{' '}
            turn: {movesLeftForPlay[playerGamePosition.play]}{' '}
            {pluralize(movesLeftForPlay[playerGamePosition.play], 'move')} left.
          </DSTextDiv>
          {this.renderEndOfTurnButtons()}
        </div>
        <div
          className={classnames(
            styles.cardsWrapper,
            (selectedCardId || this.state.discarding) && styles.selectionMade,
            css`
              width: ${cardsWrapperStyle.width};
              height: ${cardsWrapperStyle.height};
              min-height: ${cardsWrapperStyle.height};
            `
          )}
        >
          {cards.map((c, i) => (
            <CardImage
              key={c.id}
              model={c}
              skin={skin}
              invert={false}
              draggable={false}
              className={classnames(
                styles.cardImage,
                (c.id === selectedCardId ||
                  (this.state.discarding && this.state.discarding.has(c.id))) &&
                  styles.selected,
                css`
                  width: ${cardWidthPx}px;
                  height: ${cardHeightPx}px;
                  left: ${xOffsetPct * i * cardWidthPx}px;
                  top: ${yOffsetPct * i * cardHeightPx}px;
                `
              )}
              onClick={() => this.cardClicked(c)}
            />
          ))}
        </div>

        {selectedCard && (
          <div
            className={classnames(
              styles.selectedCardActionsWrapper,
              this.state.submittingMajorAction && styles.loading
            )}
          >
            <DSText size="body-15" tag="p">
              Available Actions
              {!playerGamePosition.isMyMajorTurn() && ` (once it is your turn)`}
            </DSText>
            {this.renderSelectedCardOptions(selectedCard)}
          </div>
        )}
      </div>
    )
  }
}
