import {TPlayerData} from '~/api/generated/types/common'
import ConcealedHand from '~/models/game/Hands/ConcealedHand'
import ConcealedPlayer from '~/models/game/Players/ConcealedPlayer'
import Portfolio from '~/models/game/Portfolio'

/*
  "other" players at the table when the currently-logged-in user is playing
  this isn't used when viewing as a spectator, `SpectatorGamePosition` just keeps
  a list of Concealed... and ExposedPlayers.
*/
export default class Opponent extends ConcealedPlayer {
  constructor(playerData: TPlayerData, portfolio: Portfolio, hand: ConcealedHand) {
    super(playerData, portfolio, hand)
  }
}
