import * as React from 'react'
import {TSubmitMajorActionResponse} from '~/api/generated/types/SubmitMajorAction'
import {TSubmitMinorActionResponse} from '~/api/generated/types/SubmitMinorAction'
import Card from '~/models/game/Card'
import {
  DealBreakerCard,
  DebtCollectorCard,
  ForcedDealCard,
  JustSayNoCard,
  SlyDealCard,
} from '~/models/game/Cards/ActionCard'
import {DirectionalRentCard, UniversalRentCard} from '~/models/game/Cards/RentCard'
import SpecialPropertyCard from '~/models/game/Cards/SpecialPropertyCard'
import GamePosition, {PlayerGamePosition} from '~/models/game/GamePosition'
import InProgressMajorAction from '~/models/game/InProgressMajorAction'
import MajorAction from '~/models/game/MajorAction'
import MajorActionDealBreaker from '~/models/game/MajorActions/MajorActionDealBreaker'
import MajorActionForcedDeal from '~/models/game/MajorActions/MajorActionForcedDeal'
import MajorActionPlayToNeighborhood from '~/models/game/MajorActions/MajorActionPlayToNeighborhood'
import MajorActionSlyDeal from '~/models/game/MajorActions/MajorActionSlyDeal'
import MinorAction from '~/models/game/MinorAction'
import {MAX_CARDS_IN_HAND, TRuleset} from '~/game/ruleset'
import GameData from '~/models/game/GameData'
import MinorActionPrompt from '~/models/game/MinorActionPrompt'
import MinorActionPromptDealBreaker from '~/models/game/MinorActionPrompts/MinorActionPromptDealBreaker'
import MinorActionPromptForcedDeal from '~/models/game/MinorActionPrompts/MinorActionPromptForcedDeal'
import MinorActionPromptJustSayNo from '~/models/game/MinorActionPrompts/MinorActionPromptJustSayNo'
import MinorActionPromptPaySomething from '~/models/game/MinorActionPrompts/MinorActionPromptPaySomething'
import MinorActionPromptSlyDeal from '~/models/game/MinorActionPrompts/MinorActionPromptSlyDeal'
import PastGamePosition from '~/models/game/PastGamePosition'
import Player from '~/models/game/Player'
import {switchOnMajorAction} from '~/models/game/types'
import CardInspectionModal from '~/pages/GameLobbyV2/CardInspectionModal'
import GameBoardV2UI from '~/pages/GameLobbyV2/GameBoard/stateless'
import GameLog from '~/pages/GameLobbyV2/GameLog/stateless'
import HandDrawerUI from '~/pages/GameLobbyV2/HandDrawer/stateless'
import {SettingsState} from '~/redux/reducers/settings'
import {compact, unreachableCase} from '~/utils'
import * as M from '~/utils/Map'
import {NEList} from '~/utils/NEList'
import * as S from '~/utils/Set'
import {isInstance} from '~/utils/types'
import styles from './styles.module.css'

interface IProps {
  gameData: GameData
  settingsState: SettingsState

  chooseMajorAction: (
    majorAction: MajorAction
  ) => Promise<TSubmitMajorActionResponse>
  chooseMajorActionSubmitSuccess: (response: TSubmitMajorActionResponse) => void
  chooseMinorAction: (
    minorAction: MinorAction<any>
  ) => Promise<TSubmitMinorActionResponse>
  chooseMinorActionSubmitSuccess: (response: TSubmitMinorActionResponse) => void

  allPastGamePositions: NEList<PastGamePosition> | undefined

  rules: TRuleset
}

interface IState {
  submittingTableMajorAction: boolean
  submittingMinorActionReply: boolean

  // for getDerivedStateFromProps
  lastKnownGameDataHash: string

  // moved up from CurrentPlayerHand since we want to read it for IClickableCardStateV2PlaySlyDeal
  currentPlayerHandSelectedCardId?: string

  clickableCardState: TClickableCardStateV2
}

export default class GameLobbyV2UI extends React.Component<IProps, IState> {
  state: IState = {
    submittingTableMajorAction: false,
    submittingMinorActionReply: false,
    lastKnownGameDataHash: '',
    // fixed in getDerivedStateFromProps
    clickableCardState: {
      mode: 'none',
      inProgressMajorAction: this.props.gameData.playerGamePosition
        ?.inProgressMajorAction,
      pendingMinorActionForMe: undefined,
      rules: this.props.rules,
    },
  }

  static getDerivedStateFromProps(
    nextProps: IProps,
    prevState: IState
  ): Partial<IState> | null {
    if (nextProps.gameData.hash === prevState.lastKnownGameDataHash) {
      // Not even sure we need to do this effort-saving early return tbh, but i'l leave it for now
      // console.log('skipping clickableCardState update b/c hash is equal', {
      //   nextProps,
      //   prevState,
      //   newTime: nextProps.gameData.currentGamePosition.time,
      //   oldTime: prevState.propTime,
      // })
      return null
    }

    let returnVal: Partial<IState> = {
      lastKnownGameDataHash: nextProps.gameData.hash,
    }

    const clickableCardState = maybeMutateClickableCardState(
      nextProps.gameData,
      nextProps.rules,
      {...prevState, ...returnVal}
    )

    return {
      ...returnVal,
      clickableCardState,
    }
  }

  chooseMinorAction = (
    minorAction: MinorAction<any>
  ): Promise<TSubmitMinorActionResponse> => {
    const {chooseMinorAction} = this.props

    this.setState({submittingMinorActionReply: true})

    // detect errors here, but also pass them on
    const promise = chooseMinorAction(minorAction)
    promise
      .catch(e => {
        console.error('error submitting minor action: ', e)
      })
      .finally(() => this.setState({submittingMinorActionReply: false}))
      .catch(e => undefined)

    return promise
  }

  cardClicked = (card: Card<any>) => {
    const {clickableCardState: currentClickableCardState} = this.state

    switch (currentClickableCardState.mode) {
      case 'paySomething':
        this.setState(prevState => {
          if (prevState.clickableCardState.mode !== 'paySomething') {
            console.error(
              'clickableCardState mode changed from paySomething before setState could modify it',
              prevState,
              currentClickableCardState
            )
            return null
          }

          // make going back and forth between your JSN and clicking valuable cards easier
          // by deselecting all the other irrelevant things automatically
          const cardsToAutoDeselect =
            card instanceof JustSayNoCard
              ? prevState.clickableCardState.selectedCardIds
              : new Set(
                  this.props.gameData.playerGamePosition?.currentPlayerHand.cards
                    .filter(isInstance(JustSayNoCard))
                    .map(c => c.id)
                )

          return {
            clickableCardState: {
              ...prevState.clickableCardState,
              selectedCardIds: S.difference(
                S.toggleMembership(
                  card.id,
                  prevState.clickableCardState.selectedCardIds
                ),
                cardsToAutoDeselect
              ),
            },
          }
        })
        break
      case 'awaitingYourMajorAction':
        this.setState(prevState => {
          if (prevState.clickableCardState.mode !== 'awaitingYourMajorAction') {
            console.error(
              'clickableCardState mode changed from awaitingYourMajorAction before setState could modify it',
              prevState,
              currentClickableCardState
            )
            return null
          }

          // since only one can be selected at a time, we first check
          // which place the selected card is, and then toggle it's selected state in that correct place
          const selectedCardInHandId = prevState.clickableCardState.clickableCardsInHandIds.has(
            card.id
          )
            ? prevState.clickableCardState.selectedCardInHandId === card.id
              ? undefined
              : card.id
            : undefined
          const selectedCardInMyPortfolioId = prevState.clickableCardState.clickableCardsInMyPortfolioIds.has(
            card.id
          )
            ? prevState.clickableCardState.selectedCardInMyPortfolioId === card.id
              ? undefined
              : card.id
            : undefined

          switch (prevState.clickableCardState.consideringMajorAction?.type) {
            case 'slyDeal':
              if (
                prevState.clickableCardState.clickableCardsInHandIds.has(card.id)
              ) {
                // clicked a card in our hand.
                return {
                  clickableCardState: {
                    ...prevState.clickableCardState,
                    consideringMajorAction: undefined,
                    selectedCardInHandId,
                    selectedCardInMyPortfolioId: undefined,
                  },
                }
              } else if (
                prevState.clickableCardState.consideringMajorAction.requestableCardIds.has(
                  card.id
                )
              ) {
                return {
                  clickableCardState: {
                    ...prevState.clickableCardState,
                    consideringMajorAction: {
                      ...prevState.clickableCardState.consideringMajorAction,
                      selectedCardToRequestId: card.id,
                    },
                  },
                }
              }
              console.log('nothing to do with a card click', {
                card,
                currentClickableCardState,
                prevState,
              })
              break
            case 'forcedDeal':
              if (
                prevState.clickableCardState.clickableCardsInHandIds.has(card.id)
              ) {
                // clicked a card in our hand.
                return {
                  clickableCardState: {
                    ...prevState.clickableCardState,
                    consideringMajorAction: undefined,
                    selectedCardInHandId,
                    selectedCardInMyPortfolioId: undefined,
                  },
                }
              } else if (
                prevState.clickableCardState.consideringMajorAction.requestableCardIds.has(
                  card.id
                )
              ) {
                return {
                  clickableCardState: {
                    ...prevState.clickableCardState,
                    consideringMajorAction: {
                      ...prevState.clickableCardState.consideringMajorAction,
                      selectedCardToRequestId: card.id,
                    },
                  },
                }
              } else if (
                prevState.clickableCardState.consideringMajorAction.offerableCardIds.has(
                  card.id
                )
              ) {
                return {
                  clickableCardState: {
                    ...prevState.clickableCardState,
                    consideringMajorAction: {
                      ...prevState.clickableCardState.consideringMajorAction,
                      selectedCardToOfferId: card.id,
                    },
                  },
                }
              }
              console.log('nothing to do with a card click', {
                card,
                currentClickableCardState,
                prevState,
              })

              break
            case 'discard':
              if (
                prevState.clickableCardState.clickableCardsInHandIds.has(card.id)
              ) {
                return {
                  clickableCardState: {
                    ...prevState.clickableCardState,
                    consideringMajorAction: {
                      ...prevState.clickableCardState.consideringMajorAction,
                      selectedCardsToDiscardIds: S.toggleMembership(
                        card.id,
                        prevState.clickableCardState.consideringMajorAction
                          .selectedCardsToDiscardIds
                      ),
                    },
                  },
                }
              } else if (
                prevState.clickableCardState.clickableCardsInMyPortfolioIds.has(
                  card.id
                )
              ) {
                return {
                  clickableCardState: {
                    ...prevState.clickableCardState,
                    selectedCardInMyPortfolioId,
                  },
                }
              }
              console.log('nothing to do with a card click', {
                card,
                currentClickableCardState,
                prevState,
              })
              break
            case 'needsNeighborhood':
              // clicked a card in our hand (SpecialPropertyCard, UniversalRent, DealBreaker)
              // or on the table (SpecialPropertyCard)
              return {
                clickableCardState: {
                  ...prevState.clickableCardState,
                  consideringMajorAction: undefined,
                  selectedCardInHandId,
                  selectedCardInMyPortfolioId,
                },
              }
            case 'debtCollector':
              // there aren't any other cards to click to engage a debt collector
              break
            case 'directionalRent':
              /* eslint-disable no-debugger */
              debugger
              break
            case undefined:
              // select the action card we clicked in our hand
              break
            default:
              return unreachableCase(
                prevState.clickableCardState.consideringMajorAction
              )
          }

          return {
            clickableCardState: {
              ...prevState.clickableCardState,
              selectedCardInHandId,
              selectedCardInMyPortfolioId,
            },
          }
        })
        break
      case 'acceptOrJustSayNo':
        this.setState(prevState => {
          if (prevState.clickableCardState.mode !== 'acceptOrJustSayNo') {
            console.error(
              'clickableCardState mode changed from acceptOrJustSayNo before setState could modify it',
              prevState,
              currentClickableCardState
            )
            return null
          }

          return {
            clickableCardState: {
              ...prevState.clickableCardState,
              selectedCardInHandId:
                prevState.clickableCardState.selectedCardInHandId === card.id
                  ? undefined
                  : card.id,
            },
          }
        })
        break
      case 'chooseNeighborhood':
      case 'none':
        return
      default:
        return unreachableCase(currentClickableCardState)
    }
  }

  neighborhoodClicked = (neighbhorhoodId: string): void => {
    const {clickableCardState: currentClickableCardState} = this.state

    switch (currentClickableCardState.mode) {
      case 'awaitingYourMajorAction':
        this.setState(prevState => {
          if (prevState.clickableCardState.mode !== 'awaitingYourMajorAction') {
            console.error(
              'clickableCardState mode changed from awaitingYourMajorAction before setState could modify it',
              prevState,
              currentClickableCardState
            )
            return null
          }

          switch (prevState.clickableCardState.consideringMajorAction?.type) {
            case 'needsNeighborhood':
            case 'directionalRent':
              return {
                clickableCardState: {
                  ...prevState.clickableCardState,
                  consideringMajorAction: {
                    ...prevState.clickableCardState.consideringMajorAction,
                    selectedPortfolioNeighborhoodId: neighbhorhoodId,
                  },
                },
              }
            case 'slyDeal':
            case 'forcedDeal':
            case 'discard':
            case 'debtCollector':
            case undefined:
              // there aren't any other players to click to engage these
              return null
            default:
              return unreachableCase(
                prevState.clickableCardState.consideringMajorAction
              )
          }
        })
        return
      case 'chooseNeighborhood':
        this.setState(prevState => ({
          clickableCardState: {
            ...prevState.clickableCardState,
            selectedPortfolioNeighborhoodId: neighbhorhoodId,
          },
        }))
        return
      case 'paySomething':
      case 'acceptOrJustSayNo':
      case 'none':
        return
      default:
        return unreachableCase(currentClickableCardState)
    }
  }

  playerClicked = (player: Player): void => {
    const {clickableCardState: currentClickableCardState} = this.state

    switch (currentClickableCardState.mode) {
      case 'awaitingYourMajorAction':
        this.setState(prevState => {
          if (prevState.clickableCardState.mode !== 'awaitingYourMajorAction') {
            console.error(
              'clickableCardState mode changed from awaitingYourMajorAction before setState could modify it',
              prevState,
              currentClickableCardState
            )
            return null
          }

          switch (prevState.clickableCardState.consideringMajorAction?.type) {
            case 'slyDeal':
            case 'forcedDeal':
            case 'needsNeighborhood':
            case 'discard':
            case undefined:
              // there aren't any other players to click to engage these
              return null
            case 'debtCollector':
            case 'directionalRent':
              return {
                clickableCardState: {
                  ...prevState.clickableCardState,
                  consideringMajorAction: {
                    ...prevState.clickableCardState.consideringMajorAction,
                    selectedPlayerId:
                      prevState.clickableCardState.consideringMajorAction
                        .selectedPlayerId === player.id
                        ? undefined
                        : player.id,
                  },
                },
              }
            default:
              return unreachableCase(
                prevState.clickableCardState.consideringMajorAction
              )
          }
        })
        return
      case 'paySomething':
      case 'acceptOrJustSayNo':
      case 'chooseNeighborhood':
      case 'none':
        return
      default:
        return unreachableCase(currentClickableCardState)
    }
  }

  render() {
    const {clickableCardState} = this.state
    const {skin, gameLobbySize} = this.props.settingsState

    return (
      <div className={styles.gameLobbyWrapper}>
        <GameBoardV2UI
          {...this.props}
          skin={skin}
          gameLobbySize={gameLobbySize}
          chooseMajorActionSubmitSuccess={response => {
            this.props.chooseMajorActionSubmitSuccess(response)
            this.setState(prevState => {
              if (prevState.clickableCardState.mode === 'awaitingYourMajorAction') {
                // we just completed a major action, so we can safely clear the state that was tracking the action
                // we were just considering
                return {
                  clickableCardState: maybeMutateClickableCardState(
                    this.props.gameData,
                    this.props.rules,
                    {
                      ...prevState,
                      clickableCardState: {
                        ...prevState.clickableCardState,
                        consideringMajorAction: undefined,
                        selectedCardInHandId: undefined,
                        selectedCardInMyPortfolioId: undefined,
                      },
                    }
                  ),
                }
              }
              return null
            })
          }}
          clickableCardState={clickableCardState}
          onCardClicked={this.cardClicked}
          onPlayerClicked={this.playerClicked}
          onNeighborhoodClicked={this.neighborhoodClicked}
          setClickableCardState={setFunc => {
            this.setState(prevState => {
              const mutatedClickableCardState = maybeMutateClickableCardState(
                this.props.gameData,
                this.props.rules,
                {
                  ...prevState,
                  clickableCardState: setFunc(prevState.clickableCardState),
                }
              )
              return {
                clickableCardState: mutatedClickableCardState,
              }
            })
          }}
        />

        <HandDrawerUI
          {...this.props}
          skin={skin}
          className={styles.handDrawer}
          clickableCardState={clickableCardState}
          onCardClicked={this.cardClicked}
        />

        {this.props.settingsState.betaFeatures.gameLog && (
          <GameLog
            skin={skin}
            pastGamePositions={this.props.allPastGamePositions}
            className={styles.gameLog}
          />
        )}

        <CardInspectionModal />
      </div>
    )
  }
}

// depending on the game state, the player can click one, many, or no cards already
// played on the table or in their hand as a part of the action they wish to take.
// e.g. if they are being asked to pay rent, they can choose multiple cards, but only
// those with a cash value. TClickableCardStateV2 carries that information.
type TClickableCardV2Mode =
  | 'awaitingYourMajorAction' // 'your' turn, rotate SpecialPropertyCards or play a card from your hand
  | 'paySomething' // choose the cards to pay a rent/etc charged against you
  | 'acceptOrJustSayNo' // someone attacked you or JSN'd your attack. accept or play a JSN.
  | 'chooseNeighborhood' // choose a whole PN (or JSN) for rent, playing a DB, or accepting a Dual FD, playing a wild
  | 'none' // no cards on the table can/need to be clicked by the user atm. includes spectators (can't click anything).
type TClickableCardStateV2Base = {
  mode: TClickableCardV2Mode
  rules: TRuleset // needed for clickableCardIdsFor
}
interface IClickableCardStateV2YourTurn extends TClickableCardStateV2Base {
  mode: 'awaitingYourMajorAction'
  clickableCardsInHandIds: Set<string>
  clickableCardsInMyPortfolioIds: Set<string>
  // NB: this is one of the clickableCardsInHandIds, sometimes the Sly/Forced deal card below, other times
  // a rent/etc other card in my hand
  selectedCardInHandId: string | undefined
  selectedCardInMyPortfolioId: string | undefined
  playerGamePosition: PlayerGamePosition // TODO do we need this? sly/forced deal have it b/c this has it.

  // TODO add moving residences to cash
  consideringMajorAction:
    | {
        // the player needs to select the card to steal
        type: 'slyDeal'
        card: SlyDealCard
        requestableCardIds: Set<string>
        selectedCardToRequestId: string | undefined
      }
    | {
        // the player needs to select the cards to trade
        type: 'forcedDeal'
        card: ForcedDealCard
        requestableCardIds: Set<string>
        offerableCardIds: Set<string>
        selectedCardToOfferId: string | undefined
        selectedCardToRequestId: string | undefined
      }
    | {
        // the player needs to choose the target player
        type: 'debtCollector'
        card: DebtCollectorCard
        clickablePlayerIds: Set<string>
        selectedPlayerId: string | undefined
      }
    | {
        // the player needs to choose a set to rent from & a player to rent
        type: 'directionalRent'
        card: DirectionalRentCard
        clickablePortfolioNeighborhoodIds: Set<string>
        selectedPortfolioNeighborhoodId: string | undefined
        clickablePlayerIds: Set<string>
        selectedPlayerId: string | undefined
      }
    | {
        type: 'needsNeighborhood'
        card:
          | UniversalRentCard // choose a set to rent from
          | DealBreakerCard // choose a set to deal break
          | SpecialPropertyCard<any> // play a dual or PWC property to one of your existing neighborhoods, or move one
        clickablePortfolioNeighborhoodIds: Set<string>
        selectedPortfolioNeighborhoodId: string | undefined
      }
    | {
        type: 'discard'
        selectedCardsToDiscardIds: Set<string>
      }
    | undefined
}
interface IClickableCardStateV2PaySomething extends TClickableCardStateV2Base {
  mode: 'paySomething'
  clickableCardIds: Set<string> // your cards with cash value, and your JSNs
  selectedCardIds: Set<string>
  inProgressMajorAction: InProgressMajorAction
  minorActionPrompt: MinorActionPromptPaySomething<any>
}
interface IClickableCardStateV2AcceptOrJustSayNo extends TClickableCardStateV2Base {
  mode: 'acceptOrJustSayNo'
  clickableCardIds: Set<string> // your JSNs
  selectedCardInHandId: string | undefined
  inProgressMajorAction: InProgressMajorAction // necessary for e.g. MinorAction.validResponses later
  minorActionPrompt:
    | MinorActionPromptDealBreaker
    | MinorActionPromptSlyDeal
    | MinorActionPromptJustSayNo
}
interface IClickableCardStateV2ChooseNeighborhood extends TClickableCardStateV2Base {
  mode: 'chooseNeighborhood'
  clickablePortfolioNeighborhoodIds: Set<string>
  selectedPortfolioNeighborhoodId: string | undefined

  for:
    | MinorActionPromptForcedDeal // choose the destination PN for a force-dealt card
    | MinorActionPromptJustSayNo // a JSN was played on your JSN of a Forced Deal, same as MinorActionPromptForcedDeal
}
interface IClickableCardStateV2None extends TClickableCardStateV2Base {
  mode: 'none' // added for spectators, also represents players who can't/don't need to take action atm
  inProgressMajorAction: InProgressMajorAction | undefined
  pendingMinorActionForMe: MinorActionPrompt<any> | undefined
}
export type TClickableCardStateV2 =
  | IClickableCardStateV2YourTurn
  | IClickableCardStateV2PaySomething
  | IClickableCardStateV2AcceptOrJustSayNo
  | IClickableCardStateV2ChooseNeighborhood
  | IClickableCardStateV2None

const maybeMutateClickableCardState = (
  gameData: GameData,
  rules: TRuleset,
  state: IState
): TClickableCardStateV2 => {
  const {currentGamePosition, playerGamePosition} = gameData
  if (!playerGamePosition) {
    // TODO not sure if/how the inProgressMajorAction here should work for spectators, we'll see. added it to the None
    //  type for convenience here in this file for now
    return {
      mode: 'none',
      inProgressMajorAction: currentGamePosition.inProgressMajorAction,
      pendingMinorActionForMe: undefined,
      rules,
    }
  }

  const {currentPlayer} = playerGamePosition

  if (playerGamePosition.inProgressMajorAction) {
    // there is an active/pending minorAction. if the action is on me, prompt me, else,
    // I can't do anything right now.
    const pendingMinorActionForMe = playerGamePosition.inProgressMajorAction.pendingMinorActions.find(
      pma => pma.minorActorPlayerId === currentPlayer.id
    )
    if (pendingMinorActionForMe) {
      if (pendingMinorActionForMe instanceof MinorActionPromptPaySomething) {
        const clickableCardIds = currentPlayer.portfolio
          .allValuableCards()
          .concat(currentPlayer.hand.cards.filter(isInstance(JustSayNoCard)))
          .map(c => c.id)

        return {
          mode: 'paySomething',
          clickableCardIds: new Set(clickableCardIds),
          selectedCardIds: new Set(),
          inProgressMajorAction: playerGamePosition.inProgressMajorAction,
          minorActionPrompt: pendingMinorActionForMe,
          rules,
        }
      } else if (
        pendingMinorActionForMe instanceof MinorActionPromptDealBreaker ||
        pendingMinorActionForMe instanceof MinorActionPromptSlyDeal
      ) {
        const clickableCardIds = new Set(
          currentPlayer.hand.cards.filter(isInstance(JustSayNoCard)).map(c => c.id)
        )
        return {
          mode: 'acceptOrJustSayNo',
          clickableCardIds,
          selectedCardInHandId: undefined,
          inProgressMajorAction: playerGamePosition.inProgressMajorAction,
          minorActionPrompt: pendingMinorActionForMe,
          rules,
        }
      } else if (pendingMinorActionForMe instanceof MinorActionPromptForcedDeal) {
        // choose the destination for a forced dealt card
        const clickablePortfolioNeighborhoodIds = new Set(
          pendingMinorActionForMe
            .possibleDestinationNeighborhoods({
              targetedPlayer: playerGamePosition.currentPlayer,
            })
            .map(pn => pn.id)
        )
        return {
          mode: 'chooseNeighborhood',
          clickablePortfolioNeighborhoodIds,
          selectedPortfolioNeighborhoodId:
            state.clickableCardState.mode === 'chooseNeighborhood'
              ? S.echoIfHas(
                  state.clickableCardState.selectedPortfolioNeighborhoodId,
                  clickablePortfolioNeighborhoodIds
                )
              : // NB: if there is only one neighborhood to choose from, autoselect it for convenience
              clickablePortfolioNeighborhoodIds.size === 1
              ? S.first(clickablePortfolioNeighborhoodIds)
              : undefined,
          for: pendingMinorActionForMe,
          rules,
        }
      } else if (pendingMinorActionForMe instanceof MinorActionPromptJustSayNo) {
        if (
          currentGamePosition.majorActorPlayer.id ===
          pendingMinorActionForMe.minorActorPlayerId
        ) {
          // the action is on me, and I have to respond to a JSN, and it was my major action

          const clickableCardIds = new Set(
            currentPlayer.hand.cards.filter(isInstance(JustSayNoCard)).map(c => c.id)
          )

          return {
            mode: 'acceptOrJustSayNo',
            inProgressMajorAction: playerGamePosition.inProgressMajorAction,
            selectedCardInHandId:
              state.clickableCardState.mode === 'acceptOrJustSayNo'
                ? S.echoIfHas(
                    state.clickableCardState.selectedCardInHandId,
                    clickableCardIds
                  )
                : undefined,
            rules,
            minorActionPrompt: pendingMinorActionForMe,
            clickableCardIds,
          }
        }

        // TODO there is a lot of work here, because if you JSN a rent, for example, and that is JSN'd back,
        //  we have to pay the rent if we accept that second JSN
        console.error(' TODO JSN code not done yet')
      } else {
        return unreachableCase(pendingMinorActionForMe)
      }
    } else {
      return {
        mode: 'none',
        inProgressMajorAction: playerGamePosition.inProgressMajorAction,
        pendingMinorActionForMe,
        rules,
      }
    }
  }

  // seems like nothing else applies, so defaults apply based on whether it is your turn
  if (playerGamePosition?.isMyMajorTurn()) {
    switch (state.clickableCardState.mode) {
      case 'chooseNeighborhood': {
        const clickablePortfolioNeighborhoodIds = ((): Set<string> => {
          // TODO the below check isn't possible? not sure what I wrote this to handle, it's been a while
          //  maybe this was before needsNeighborhood was built to handle playing SpecialPropertyCards
          if (state.clickableCardState.for instanceof SpecialPropertyCard) {
            const actions = state.clickableCardState.for.availableMovesFromHand({
              playerGamePosition,
              portfolio: playerGamePosition.currentPlayer.portfolio,
              rules,
            })
            return S.create(
              actions
                .filter(isInstance(MajorActionPlayToNeighborhood))
                .map(ptn => ptn.portfolioNeighborhoodId)
            )
          } else {
            return new Set()
          }
        })()
        return {
          mode: 'chooseNeighborhood',
          rules,
          clickablePortfolioNeighborhoodIds,
          for: state.clickableCardState.for,
          selectedPortfolioNeighborhoodId: S.echoIfHas(
            state.clickableCardState.selectedPortfolioNeighborhoodId,
            clickablePortfolioNeighborhoodIds
          ),
        }
      }
      case 'acceptOrJustSayNo':
      case 'none':
      case 'paySomething':
      case 'awaitingYourMajorAction': {
        const availableTableMoves = currentPlayer.availableMovesFromTableByCardId(
          rules,
          playerGamePosition
        )

        // NB: can only discard if you have more than the allowed number of cards
        const clickableCardsInHandIds = ((): Set<string> => {
          if (
            (playerGamePosition.play === 'discardPlay' ||
              (state.clickableCardState.mode === 'awaitingYourMajorAction' &&
                state.clickableCardState.consideringMajorAction?.type ===
                  'discard')) &&
            currentPlayer.hand.cards.length <= MAX_CARDS_IN_HAND
          ) {
            return new Set()
          }
          return S.create(currentPlayer.hand.cards.map(c => c.id))
        })()

        const clickableCardsInMyPortfolioIds = M.keySet(
          M.filter(arr => !!arr.length, availableTableMoves)
        )

        const isTryingFreeAction =
          state.clickableCardState.mode === 'awaitingYourMajorAction' &&
          (state.clickableCardState.consideringMajorAction?.type ===
            'needsNeighborhood' ??
            false)

        type TConsideringDiscardAction = IClickableCardStateV2YourTurn['consideringMajorAction'] & {
          type: 'discard'
        }
        // if you have no more plays left, we usually want to force you into the "considering discard" action
        // notable exception is if the player is trying to rotate cards (TODO & other free actions like residence->cash)
        const forcedDiscardAction: TConsideringDiscardAction | undefined =
          playerGamePosition.play === 'discardPlay' && !isTryingFreeAction
            ? {
                type: 'discard',
                selectedCardsToDiscardIds:
                  state.clickableCardState.mode === 'awaitingYourMajorAction' &&
                  state.clickableCardState.consideringMajorAction?.type === 'discard'
                    ? S.intersection(
                        clickableCardsInHandIds,
                        state.clickableCardState.consideringMajorAction
                          .selectedCardsToDiscardIds
                      )
                    : new Set(),
              }
            : undefined

        return {
          mode: 'awaitingYourMajorAction',
          rules,
          clickableCardsInHandIds,
          clickableCardsInMyPortfolioIds,

          // just in case of MDCs or other weirdness, I guess checking if the sets of selectable / selected cards
          // are still up to date doesn't hurt? not sure how necessary this is.
          selectedCardInHandId:
            state.clickableCardState.mode === 'awaitingYourMajorAction'
              ? S.echoIfHas(
                  state.clickableCardState.selectedCardInHandId,
                  clickableCardsInHandIds
                )
              : undefined,
          selectedCardInMyPortfolioId:
            state.clickableCardState.mode === 'awaitingYourMajorAction'
              ? S.echoIfHas(
                  state.clickableCardState.selectedCardInMyPortfolioId,
                  clickableCardsInMyPortfolioIds
                )
              : undefined,
          playerGamePosition,
          consideringMajorAction:
            forcedDiscardAction ??
            (state.clickableCardState.mode === 'awaitingYourMajorAction'
              ? state.clickableCardState.consideringMajorAction
              : undefined),
          // consideringMajorAction: safely(state.clickableCardState.consideringMajorAction, cma => {
          //   switch (cma.type) {
          //     case 'forcedDeal':
          //       return cma // TODO
          //     case 'slyDeal':
          //       return {
          //
          //       }
          //   }
          // })
        }
      }
      default:
        return unreachableCase(state.clickableCardState)
    }
  } else {
    // it's not my turn, and there are no minor actions etc. that i'm supposed to reply to.
    return {
      mode: 'none',
      inProgressMajorAction: playerGamePosition.inProgressMajorAction,
      pendingMinorActionForMe: undefined,
      rules,
    }
  }
}

export const clickableCardIdsFor = (
  clickableCardState: TClickableCardStateV2
): Set<string> => {
  switch (clickableCardState.mode) {
    case 'awaitingYourMajorAction':
      const myHandIds = clickableCardState.clickableCardsInHandIds
      switch (clickableCardState.consideringMajorAction?.type) {
        case 'slyDeal':
          return S.union(
            myHandIds,
            clickableCardState.consideringMajorAction.requestableCardIds
          )
        case 'forcedDeal':
          return S.union(
            myHandIds,
            clickableCardState.consideringMajorAction.requestableCardIds,
            clickableCardState.consideringMajorAction.offerableCardIds
          )
        case 'discard':
          return S.union(
            clickableCardState.clickableCardsInMyPortfolioIds,
            clickableCardState.clickableCardsInHandIds
          )
        case 'needsNeighborhood':
        case 'debtCollector':
        case 'directionalRent':
          return myHandIds
        case undefined:
          return S.union(
            clickableCardState.clickableCardsInHandIds,
            clickableCardState.clickableCardsInMyPortfolioIds
          )
        default:
          return unreachableCase(clickableCardState.consideringMajorAction)
      }
    case 'paySomething':
    case 'acceptOrJustSayNo':
      return S.create(clickableCardState.clickableCardIds)
    case 'none':
    case 'chooseNeighborhood':
      return new Set()
    default:
      return unreachableCase(clickableCardState)
  }
}

// probably replace / combine this with a check against highlightedCardIdsFor?
export const fadeUnclickableCardsFor = (
  clickableCardState: TClickableCardStateV2
): boolean => {
  switch (clickableCardState.mode) {
    case 'awaitingYourMajorAction':
      switch (clickableCardState.consideringMajorAction?.type) {
        case 'needsNeighborhood':
        case 'debtCollector':
        case 'directionalRent':
          return false
        case undefined:
          return clickableCardState.playerGamePosition.play === 'discardPlay'
        case 'forcedDeal':
        case 'slyDeal':
        case 'discard':
          return true
        default:
          return unreachableCase(clickableCardState.consideringMajorAction)
      }
    case 'acceptOrJustSayNo':
      return true
    case 'paySomething':
    case 'chooseNeighborhood':
    case 'none':
      return false
    default:
      return unreachableCase(clickableCardState)
  }
}

export const selectedCardIdsFor = (
  clickableCardState: TClickableCardStateV2
): Set<string> => {
  switch (clickableCardState.mode) {
    case 'awaitingYourMajorAction':
      switch (clickableCardState.consideringMajorAction?.type) {
        case 'slyDeal':
          return S.create(
            compact([
              clickableCardState.selectedCardInHandId,
              clickableCardState.consideringMajorAction.selectedCardToRequestId,
            ])
          )
        case 'forcedDeal':
          return S.create(
            compact([
              clickableCardState.selectedCardInHandId,
              clickableCardState.consideringMajorAction.selectedCardToRequestId,
              clickableCardState.consideringMajorAction.selectedCardToOfferId,
            ])
          )
        case 'discard':
          return S.union(
            S.createOrEmpty(clickableCardState.selectedCardInHandId),
            S.createOrEmpty(clickableCardState.selectedCardInMyPortfolioId),
            clickableCardState.consideringMajorAction.selectedCardsToDiscardIds
          )
        case 'needsNeighborhood':
        case 'debtCollector':
        case 'directionalRent':
          return S.create(clickableCardState.consideringMajorAction.card.id)
        case undefined:
          return S.union(
            S.createOrEmpty(clickableCardState.selectedCardInHandId),
            S.createOrEmpty(clickableCardState.selectedCardInMyPortfolioId)
          )
        default:
          return unreachableCase(clickableCardState.consideringMajorAction)
      }
    case 'paySomething':
      return clickableCardState.selectedCardIds
    case 'chooseNeighborhood':
      return new Set()
    case 'none':
      return new Set()
    case 'acceptOrJustSayNo':
      return S.createOrEmpty(clickableCardState.selectedCardInHandId)
    default:
      return unreachableCase(clickableCardState)
  }
}

// TODO WIP. "highlighted" is the opposite of "Faded" like in fadeUnclickableCardsFor
//  basically, we want to showcase which cards are being played/stolen/etc by other players sometimes
//  even if the viewing player can't click those cards
export const highlightedCardIdsFor = (
  clickableCardState: TClickableCardStateV2,
  currentGamePosition: GamePosition
): Set<string> => {
  const emptySet = new Set<string>()

  switch (clickableCardState.mode) {
    case 'awaitingYourMajorAction':
      return emptySet
    case 'paySomething':
      return emptySet
    case 'chooseNeighborhood':
      if (clickableCardState.for instanceof MinorActionPromptForcedDeal) {
        return S.union(
          S.create([
            clickableCardState.for.offeredCard.id,
            clickableCardState.for.requestedCard.id,
          ]),
          S.createOrEmpty(
            currentGamePosition instanceof PlayerGamePosition
              ? currentGamePosition.currentPlayerHand.cards
                  .filter(isInstance(JustSayNoCard))
                  .map(c => c.id)
              : undefined
          ),
          S.createOrEmpty(
            currentGamePosition instanceof PlayerGamePosition
              ? currentGamePosition.currentPlayer.portfolio.neighborhoods
                  .filter(pn =>
                    S.has(
                      pn.id,
                      clickableCardState.clickablePortfolioNeighborhoodIds
                    )
                  )
                  .map(pn => pn.propertiesAndResidences().map(c => c.id))
                  .flat()
              : undefined
          )
        )
      } else if (clickableCardState.for instanceof MinorActionPromptJustSayNo) {
        return emptySet
      } else {
        return unreachableCase(clickableCardState.for)
      }
    case 'none':
      if (clickableCardState.inProgressMajorAction) {
        return switchOnMajorAction<Set<string>>({
          whenBirthday: () => emptySet,
          whenChangeNeighborhood: () => emptySet,
          whenDealBreaker: (action: MajorActionDealBreaker) =>
            S.createOrEmpty(
              currentGamePosition.portfolios
                .map(p => p.neighborhoods)
                .flat()
                .find(pn => pn.id === action.portfolioNeighborhoodId)
                ?.propertiesAndResidences()
                .map(c => c.id)
            ),
          whenDebtCollector: () => emptySet,
          whenDirectionalRent: () => emptySet,
          whenDiscard: () => emptySet,
          whenDoubleTheRent: () => emptySet,
          whenForcedDeal: (action: MajorActionForcedDeal) =>
            S.create([action.offeredCard.id, action.requestedCard.id]),
          whenPassGo: () => emptySet,
          whenPlayAsCash: () => emptySet,
          whenPlayToNeighborhood: () => emptySet,
          whenResidenceToCash: () => emptySet,
          whenSlyDeal: (action: MajorActionSlyDeal) =>
            S.create(action.requestedCard.id),
          whenUniversalRent: () => emptySet,
        })(clickableCardState.inProgressMajorAction.majorAction)
      }
      return emptySet
    case 'acceptOrJustSayNo':
      if (clickableCardState.minorActionPrompt instanceof MinorActionPromptSlyDeal) {
        return S.create(clickableCardState.minorActionPrompt.requestedCard.id)
      } else if (
        clickableCardState.minorActionPrompt instanceof MinorActionPromptDealBreaker
      ) {
        const targetPNId =
          clickableCardState.minorActionPrompt.portfolioNeighborhoodId
        return S.createOrEmpty(
          currentGamePosition instanceof PlayerGamePosition
            ? currentGamePosition.currentPlayer.portfolio.neighborhoods
                .find(pn => pn.id === targetPNId)
                ?.propertiesAndResidences()
                .map(c => c.id)
            : undefined
        )
      } else if (
        clickableCardState.minorActionPrompt instanceof MinorActionPromptJustSayNo
      ) {
        return emptySet // idk, maybe this isn't right
      } else {
        return unreachableCase(clickableCardState.minorActionPrompt)
      }
    default:
      return unreachableCase(clickableCardState)
  }
}

export const clickableNeighborhoodIdsFor = (
  clickableCardState: TClickableCardStateV2
): Set<string> => {
  switch (clickableCardState.mode) {
    case 'awaitingYourMajorAction':
      switch (clickableCardState.consideringMajorAction?.type) {
        case 'needsNeighborhood':
        case 'directionalRent':
          return clickableCardState.consideringMajorAction
            .clickablePortfolioNeighborhoodIds
        case 'discard':
        case 'slyDeal':
        case 'forcedDeal':
        case 'debtCollector':
        case undefined:
          return new Set()
        default:
          return unreachableCase(clickableCardState.consideringMajorAction)
      }
    case 'paySomething':
    case 'acceptOrJustSayNo':
    case 'none':
      return new Set() // TODO or return undefined if we want to know the difference?
    case 'chooseNeighborhood':
      return clickableCardState.clickablePortfolioNeighborhoodIds
    default:
      return unreachableCase(clickableCardState)
  }
}

export const selectedNeighborhoodIdFor = (
  clickableCardState: TClickableCardStateV2
): string | undefined => {
  switch (clickableCardState.mode) {
    case 'awaitingYourMajorAction':
      switch (clickableCardState.consideringMajorAction?.type) {
        case 'needsNeighborhood':
        case 'directionalRent':
          return clickableCardState.consideringMajorAction
            .selectedPortfolioNeighborhoodId
        case 'discard':
        case 'slyDeal':
        case 'forcedDeal':
        case 'debtCollector':
        case undefined:
          return undefined
        default:
          return unreachableCase(clickableCardState.consideringMajorAction)
      }
    case 'paySomething':
    case 'acceptOrJustSayNo':
    case 'none':
      return undefined
    case 'chooseNeighborhood':
      return clickableCardState.selectedPortfolioNeighborhoodId
    default:
      return unreachableCase(clickableCardState)
  }
}

export const clickablePlayerIdsFor = (
  clickableCardState: TClickableCardStateV2
): Set<string> => {
  switch (clickableCardState.mode) {
    case 'awaitingYourMajorAction':
      switch (clickableCardState.consideringMajorAction?.type) {
        case 'directionalRent':
        case 'debtCollector':
          return clickableCardState.consideringMajorAction.clickablePlayerIds
        case 'needsNeighborhood':
        case 'discard':
        case 'slyDeal':
        case 'forcedDeal':
        case undefined:
          return new Set()
        default:
          return unreachableCase(clickableCardState.consideringMajorAction)
      }
    case 'paySomething':
    case 'acceptOrJustSayNo':
    case 'none':
    case 'chooseNeighborhood':
      return new Set() // TODO or return undefined if we want to know the difference?
    default:
      return unreachableCase(clickableCardState)
  }
}

export const selectedPlayerIdFor = (
  clickableCardState: TClickableCardStateV2
): string | undefined => {
  switch (clickableCardState.mode) {
    case 'awaitingYourMajorAction':
      switch (clickableCardState.consideringMajorAction?.type) {
        case 'directionalRent':
        case 'debtCollector':
          return clickableCardState.consideringMajorAction.selectedPlayerId
        case 'needsNeighborhood':
        case 'discard':
        case 'slyDeal':
        case 'forcedDeal':
        case undefined:
          return undefined
        default:
          return unreachableCase(clickableCardState.consideringMajorAction)
      }
    case 'paySomething':
    case 'acceptOrJustSayNo':
    case 'none':
    case 'chooseNeighborhood':
      return undefined
    default:
      return unreachableCase(clickableCardState)
  }
}
