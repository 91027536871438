import {faVolumeMute, faVolumeUp} from '@fortawesome/free-solid-svg-icons'
import {Howl} from 'howler'
import * as R from 'ramda'
import * as React from 'react'
import {connect} from 'react-redux'
import api from '~/api/endpoints'
import {TGameData, TGameSpectatorData} from '~/api/generated/types/common'
import {TGetGameDataResponse} from '~/api/generated/types/GetGameData'
import {defaultRuleset} from '~/game/ruleset'
import DashboardLayout from '~/layouts/DashboardLayout'
import GameData from '~/models/game/GameData'
import {SpectatorMenuButton} from '~/pages/GameLobby/SpectatorMenuButton/stateless'
import GameLobbyUI from '~/pages/GameLobby/stateless'
import NotFound from '~/pages/NotFound'
import {addPastGamePositionsForGame} from '~/redux/reducers/pastGamePositions'
import {ReduxState} from '~/redux/reducers/root'
import {setSpectatorData, spectatorsDataForPlayer} from '~/redux/reducers/spectator'
import {ISignedInRouteComponentProps} from '~/routing/Route/SignedIn'
import {setStatePromise} from '~/utils'
import {AlternateStateFontAwesomeIcon} from '~/utils/FontAwesome/HoverableFontAwesomeIcon'
import {values} from '~/utils/Map'
import {createSafe} from '~/utils/NEList'
import {TReactSetStateStateParameter} from '~/utils/types'
import * as S from '~/utils/Set'
import {sendNotification, TAttackDirection} from '~/utils/UserNotifications'

import styles from './styles.module.css'

export interface IGameLobbyPagePathPieces {
  gameId: string
}

interface IReduxProps
  extends Pick<
    ReduxState,
    'settingsState' | 'spectatorState' | 'pastGamePositionsState'
  > {}
interface IProps
  extends ISignedInRouteComponentProps<IGameLobbyPagePathPieces>,
    IReduxProps,
    TDispatch {}

interface IState {
  currentGameData: GameData | undefined
  soundsEnabled: boolean
  gameDataRequestsPending: number
}

class GameLobbyPage extends React.Component<IProps, IState> {
  state: IState = {
    currentGameData: undefined,
    soundsEnabled: false,
    gameDataRequestsPending: 0,
  }

  muteButtonSound = new Howl({
    src: ['/sounds/knob.mp3', '/sounds/knob.ogg'],
  })

  receiveNewGameDataResponse = (initialLoad: boolean) => (
    gameDataResponse: TGetGameDataResponse
  ) => {
    this.receiveNewGameData(gameDataResponse.gameData)
    this.receiveNewSpectatorData(initialLoad)(gameDataResponse.spectatorRequests)
  }

  receiveNewGameData = (gameData: TGameData) => {
    const oldGameData = this.state.currentGameData
    const newMD5 = GameData.hash(gameData)

    if (newMD5 === oldGameData?.hash) {
      return
    }

    const gameDataModel = new GameData(
      gameData,
      this.props.user.id,
      this.props.settingsState.betaFeatures.gameLog,
      newMD5
    )

    // store pastGamePositions in redux since the backend doesn't send the old ones?
    this.props.addPastGamePositionsForGame({
      gameId: gameDataModel.gameId,
      pastGamePositions: gameDataModel.pastGamePositions ?? [],
    })

    this.setState(
      {
        currentGameData: gameDataModel,
      },
      () => {
        // NB: this callback is all just for user notifications
        const newGameData = this.state.currentGameData
        if (!oldGameData || !newGameData) {
          return
        }
        const newGamePos = newGameData.playerGamePosition
        const oldGamePos = oldGameData.playerGamePosition

        if (oldGamePos?.isMyMajorTurn() !== newGamePos?.isMyMajorTurn()) {
          sendNotification(
            {
              type: 'turnChange',
              isMyTurn: newGamePos?.isMyMajorTurn() ?? false,
            },
            this.props.settingsState.notifications
          )
        } else if (
          oldGamePos?.inProgressMajorAction === undefined &&
          newGamePos?.inProgressMajorAction
        ) {
          // new attack
          const attackDirection: TAttackDirection =
            newGamePos.inProgressMajorAction.majorActor.id === this.props.user.id
              ? 'fromMe'
              : newGamePos.inProgressMajorAction.pendingMinorActions.find(
                  pma => pma.minorActorPlayerId === this.props.user.id
                )
              ? 'againstMe'
              : 'uninvolved'
          sendNotification(
            {
              type: 'attack',
              direction: attackDirection,
            },
            this.props.settingsState.notifications
          )
        }
      }
    )
  }

  receiveNewSpectatorData = (initialLoad: boolean) => (
    spectatorData: TGameSpectatorData[]
  ) => {
    // update Redux
    this.props.setSpectatorData(spectatorData)

    if (initialLoad) {
      // no notification on first page load
      return
    }

    // previously denied requests are treated the same as nonexistent requests
    const oldSpectatorData = spectatorsDataForPlayer(
      this.props.user.id,
      this.props.spectatorState.spectatorData
    ).filter(sd => sd.status !== 'denied')
    const newSpectatorData = spectatorsDataForPlayer(
      this.props.user.id,
      spectatorData
    ).filter(sd => sd.status !== 'denied')

    const oldSpectatorsIds = new Set(
      spectatorsDataForPlayer(this.props.user.id, oldSpectatorData).map(
        sd => sd.spectatorPlayer.playerId
      )
    )
    const newSpectatorsIds = new Set(
      spectatorsDataForPlayer(this.props.user.id, newSpectatorData).map(
        sd => sd.spectatorPlayer.playerId
      )
    )

    if (!S.subset(newSpectatorsIds, oldSpectatorsIds)) {
      // new spectator
      sendNotification({type: 'spectator'}, this.props.settingsState.notifications)
    }
  }

  static requestsPendingStateChange = (
    delta: -1 | 1
  ): TReactSetStateStateParameter<IState, any, any> => prevState => ({
    gameDataRequestsPending: Math.max(0, prevState.gameDataRequestsPending + delta),
  })

  render() {
    const rules = defaultRuleset
    const {settingsState} = this.props
    const {gameLobbySize, skin} = settingsState
    const {gameId} = this.props.match.params

    return (
      <DashboardLayout
        {...this.props}
        topRightStuff={
          <>
            {this.state.currentGameData?.playerGamePosition && (
              <SpectatorMenuButton
                className={styles.spectatorIconWrapper}
                spectatorData={this.props.spectatorState.spectatorData}
                playerGamePosition={this.state.currentGameData.playerGamePosition}
                approveSpectator={spectatorPlayerId =>
                  setStatePromise(this, GameLobbyPage.requestsPendingStateChange(1))
                    .then(() =>
                      api.inGame.approveSpectatorRequest.post(
                        {},
                        {spectatorPlayerId, gameId}
                      )
                    )
                    .then(this.receiveNewGameDataResponse(false))
                    .finally(() =>
                      this.setState(GameLobbyPage.requestsPendingStateChange(-1))
                    )
                }
                revokeSpectator={spectatorPlayerId =>
                  setStatePromise(this, GameLobbyPage.requestsPendingStateChange(1))
                    .then(() =>
                      api.inGame.revokeSpectatorPermission.post(
                        {},
                        {spectatorPlayerId, gameId}
                      )
                    )
                    .then(this.receiveNewGameDataResponse(false))
                    .finally(() =>
                      this.setState(GameLobbyPage.requestsPendingStateChange(-1))
                    )
                }
              />
            )}
            <div
              className={styles.soundIconWrapper}
              onClick={() => {
                this.setState(prevState => ({
                  soundsEnabled: !prevState.soundsEnabled,
                }))
                // Chrome (et. al) have rules about playing sounds when there hasn't been any user interaction
                // the common wisdom seems to be, have a sound play in a click handler, then other sounds can play
                // freely for a long while.
                if (!this.state.soundsEnabled) {
                  this.muteButtonSound.play()
                }
              }}
            >
              <AlternateStateFontAwesomeIcon
                icon={{
                  icon: faVolumeMute,
                  style: {
                    width: '30px',
                    height: '30px',
                  },
                  color: 'grey-dark',
                }}
                alternateIcon={{
                  icon: faVolumeUp,
                  color: 'blue-light',
                }}
                alternateState={this.state.soundsEnabled}
              />
            </div>
          </>
        }
      >
        <api.preGame.getGameData.PromiseManager
          params={{}}
          pathPieces={{gameId}}
          onSuccess={this.receiveNewGameDataResponse(true)}
          then={() => {
            const {currentGameData} = this.state
            if (!currentGameData) {
              return <NotFound />
            }

            return (
              <>
                <api.preGame.getGameData.PromisePoller
                  params={{}}
                  pathPieces={{gameId}}
                  onSuccess={this.receiveNewGameDataResponse(false)}
                  frequencyMs={[3500, 1000, 3000, 5000, -1]}
                  pollImmediatelyOnMount={false}
                  pause={this.state.gameDataRequestsPending > 0}
                  restartKey={this.state.currentGameData?.currentGamePosition.time}
                />
                <GameLobbyUI
                  gameData={currentGameData}
                  allPastGamePositions={createSafe(
                    R.sortWith(
                      [R.ascend(a => a.date), R.ascend(a => a.gamePosition.time)],
                      values(
                        this.props.pastGamePositionsState.games.get(
                          this.state.currentGameData?.gameId ?? ''
                        )?.pastGamePositionsMap ?? new Map()
                      )
                    )
                  )}
                  skin={skin}
                  gameLobbySize={gameLobbySize}
                  rules={rules}
                  chooseMajorAction={majorAction =>
                    setStatePromise(
                      this,
                      GameLobbyPage.requestsPendingStateChange(1)
                    ).then(() =>
                      api.inGame.submitMajorAction
                        .post(
                          {
                            time: currentGameData.currentGamePosition.time,
                            action: majorAction.datasource,
                          },
                          {gameId}
                        )
                        .finally(() =>
                          this.setState(GameLobbyPage.requestsPendingStateChange(-1))
                        )
                    )
                  }
                  chooseMajorActionSubmitSuccess={r =>
                    this.receiveNewGameData(r.gameData)
                  }
                  chooseMinorAction={minorAction =>
                    setStatePromise(
                      this,
                      GameLobbyPage.requestsPendingStateChange(1)
                    ).then(() =>
                      api.inGame.submitMinorAction
                        .post(
                          {
                            time: currentGameData.currentGamePosition.time,
                            action: minorAction.datasource,
                          },
                          {gameId}
                        )
                        .finally(() =>
                          this.setState(GameLobbyPage.requestsPendingStateChange(-1))
                        )
                    )
                  }
                  chooseMinorActionSubmitSuccess={r =>
                    this.receiveNewGameData(r.gameData)
                  }
                />
              </>
            )
          }}
        />
      </DashboardLayout>
    )
  }
}

const mapStateToProps = (state: ReduxState): IReduxProps => ({
  settingsState: state.settingsState,
  spectatorState: state.spectatorState,
  pastGamePositionsState: state.pastGamePositionsState,
})

const mapDispatchToProps = {setSpectatorData, addPastGamePositionsForGame}
type TDispatch = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(GameLobbyPage)
