import * as React from 'react'
import {connect} from 'react-redux'
import {TLogoutPagePayload} from '~/pages/Auth/SignOut'
import {ReduxState} from '~/redux/reducers/root'
import {Redirect} from '~/routing/ReactRouterLinks'
import {staticURLs} from '~/routing/sitemap'

interface IReduxProps
  extends Pick<ReduxState, 'authState' | 'userState' | 'router'> {}

interface IProps extends IReduxProps {}

interface IState {
  redirectToLogoutPage: boolean
}

// logout timer will be 119 minutes long (server sets it to 2 hours)
const LogoutSeconds = 119 * 60
// how often to check if we're signed out yet or not
// (if the computer sleeps, it could take up to this long for the signed-out check to run again)
const IntervalSeconds = 10

class LogoutTimerInternal extends React.Component<IProps, IState> {
  // we only ever want one of these intervals going so this is a global static
  static currentInterval: number | undefined = undefined

  state: IState = {
    redirectToLogoutPage: false,
  }

  // did update is not called on first render but did mount is
  componentDidMount() {
    this.updateSignoutCheck()
  }

  componentDidUpdate(prevProps: IProps) {
    const {lastAuthorizedAt, loggedOutRemotely} = this.props.authState
    const {
      lastAuthorizedAt: prevLastAuthorizedAt,
      loggedOutRemotely: prevLoggedOutRemotely,
    } = prevProps.authState

    const signedIn = !!this.props.userState.user
    const prevSignedIn = !!prevProps.userState.user

    const lastAuthorizedAtChanged: boolean =
      (lastAuthorizedAt === undefined && prevLastAuthorizedAt !== undefined) ||
      (lastAuthorizedAt !== undefined && prevLastAuthorizedAt === undefined) ||
      (!!lastAuthorizedAt &&
        !!prevLastAuthorizedAt &&
        lastAuthorizedAt.getTime() !== prevLastAuthorizedAt.getTime())

    if (
      lastAuthorizedAtChanged ||
      signedIn !== prevSignedIn ||
      loggedOutRemotely !== prevLoggedOutRemotely
    ) {
      this.updateSignoutCheck()
    }
  }

  componentWillUnmount() {
    this.clearExistingInterval()
  }

  clearExistingInterval = () => {
    if (LogoutTimerInternal.currentInterval) {
      clearInterval(LogoutTimerInternal.currentInterval)
      LogoutTimerInternal.currentInterval = undefined
    }
  }

  // one or more of our redux props have changed, so set up a new signed-out check
  updateSignoutCheck = () => {
    this.clearExistingInterval()

    if (this.props.router.location.pathname === staticURLs.auth.signout) {
      return
    }

    const signedIn = !!this.props.userState.user
    const {lastAuthorizedAt, loggedOutRemotely} = this.props.authState

    if (signedIn && loggedOutRemotely) {
      // something else detected a signed-out condition from the server.
      // immediately redirect to logout
      console.error('Server logged you out.')
      this.setState({redirectToLogoutPage: true})
    }

    if (signedIn && lastAuthorizedAt) {
      const expiryTimeMs = lastAuthorizedAt.getTime() + LogoutSeconds * 1000
      const msUntilExpiry = expiryTimeMs - Date.now()

      if (msUntilExpiry > 0) {
        // we check every so often if time has expired
        // (don't use setTimeout because it's unreliable, i.e. if the computer sleeps)
        LogoutTimerInternal.currentInterval = window.setInterval(() => {
          const timeLeft = expiryTimeMs - Date.now()

          if (timeLeft <= 0) {
            console.error('Signed out due to inactivity.')
            this.setState({redirectToLogoutPage: true})
          }
        }, IntervalSeconds * 1000)
      }
    }
  }

  render() {
    const signoutPayload: TLogoutPagePayload = {
      redirectTo: 'login',
    }

    return this.state.redirectToLogoutPage ? (
      <Redirect to={{pathname: staticURLs.auth.signout, state: signoutPayload}} />
    ) : (
      this.props.children
    )
  }
}
const mapStateToProps = (state: ReduxState): IReduxProps => ({
  authState: state.authState,
  userState: state.userState,
  router: state.router,
})

// not sure exactly what the issue is here but
// see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/16990
export default (connect(
  mapStateToProps,
  undefined
)(LogoutTimerInternal) as unknown) as React.ComponentType<{}>
