import {ExtractStrict} from 'type-zoo'
import {TMinorActionData} from '~/api/generated/types/common'
import {TSkin} from '~/game/skins'
import {TMinorActionBaseData} from '~/game/types'
import {DataBackedModel} from '~/models/base'
import GamePosition from '~/models/game/GamePosition'
import InProgressMajorAction from '~/models/game/InProgressMajorAction'
import Player from '~/models/game/Player'
import {Tense} from '~/utils/language'

export type TMinorActionUserFacingDescriptionData = {
  gamePosition: GamePosition

  // In order for there to be a MinorAction, there must also be these things in GamePosition, but there's no type
  // safety there, so we pass them separately
  inProgressMajorAction: InProgressMajorAction
  // TODO probably throw these in the MinorAction constructor instead, they are useful (did this for MinorActionPrompt)
  majorActor: Player
  minorActor: Player

  tense: ExtractStrict<Tense, 'past' | 'imperativePrompt'>
  skin: TSkin
}

type PublicMembersFromApi = Pick<TMinorActionData, 'minorActorPlayerId'>

export default abstract class MinorAction<
    ActionDataType extends TMinorActionBaseData
  >
  extends DataBackedModel<ActionDataType>
  implements PublicMembersFromApi {
  public type: ActionDataType['type']
  public minorActorPlayerId: string

  constructor(data: ActionDataType) {
    super(data)
    this.type = data.type
    this.minorActorPlayerId = data.minorActorPlayerId
  }

  public abstract userFacingDescription(
    data: TMinorActionUserFacingDescriptionData
  ): string
}
