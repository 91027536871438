import * as RA from 'ramda-adjunct'
import React from 'react'
import {OmitStrict} from 'type-zoo'
import {IFormFieldProps} from '~/components/ProvideForm/types'
import {IBasicStringConstraint} from '~/components/ProvideForm/utils'
import * as S from '~/utils/Set'

export interface IToggleGroupRenderProps<EnumType extends string> {
  makeSelection: (selected: EnumType) => void // should probably be named 'toggleSelection'
  isSelected: (item: EnumType) => boolean
  selectedItems: ReadonlySet<EnumType>
  clearSelection: () => void // reset all toggles to their default, unselected state
  unselect: (item: EnumType) => void // regardless of its state, set the toggle to unselected
}

export interface IToggleGroupProps<EnumType extends string> {
  children: (radioGroupProps: IToggleGroupRenderProps<EnumType>) => React.ReactNode
  selectionLimit: number | undefined
  allowDeselection?: boolean
  deselectOldestIfLimitReached?: boolean

  // controlled component props
  selected: Set<EnumType>
  makeSelection: (selected: Set<EnumType>) => void
}

/** A controlled component that manages the state of a selection group (i.e. radio buttons or checkboxes) */
export default class ToggleGroup<EnumType extends string> extends React.Component<
  IToggleGroupProps<EnumType>
> {
  makeSelection = (selected: EnumType) => {
    const oldSet = this.props.selected
    let newSet: Set<EnumType> | undefined

    const areRemovingSelection = oldSet.has(selected)

    if (
      this.props.selectionLimit !== undefined &&
      this.props.selected.size >= this.props.selectionLimit &&
      !areRemovingSelection
    ) {
      // we've reached capacity, but we might be able to swap out an old selection
      if (this.props.deselectOldestIfLimitReached) {
        newSet = S.pop(oldSet).add(selected)
      }
    } else {
      // either we are not at capacity (adding a selection is fine),
      // or we can't add anything but we're trying to remove a current member (if we're allowed)
      if (!areRemovingSelection || this.props.allowDeselection) {
        newSet = S.toggleMembership(selected, oldSet)
      }
    }

    if (newSet) {
      this.props.makeSelection(newSet)
    }
  }

  unselect = (selection: EnumType) => {
    const newSet = new Set(this.props.selected)
    if (newSet.delete(selection)) {
      this.props.makeSelection(newSet)
    }
  }

  render() {
    return this.props.children({
      makeSelection: this.makeSelection,
      isSelected: this.props.selected.has.bind(this.props.selected),
      selectedItems: this.props.selected,
      clearSelection: () => this.props.makeSelection(new Set()),
      unselect: this.unselect,
    })
  }
}

export interface IFormToggleGroupProps<EnumType extends string>
  extends OmitStrict<IToggleGroupProps<EnumType>, 'selected' | 'makeSelection'> {
  formFieldProps: IFormFieldProps
  onSelectionChange?: (selected: Set<EnumType>) => void
  blurOnChange?: boolean
}

/** A version of the ToggleGroup that can be controlled by a ProvideForm (the Set is converted to/from JSON) */
export class FormToggleGroup<EnumType extends string> extends React.Component<
  IFormToggleGroupProps<EnumType>
> {
  static formValueToFirstSelection = <StaticEnumType extends string>(
    formValue: string
  ): StaticEnumType | undefined => {
    const array: Array<StaticEnumType> = JSON.parse(formValue)
    return array[0]
  }

  static formValueToSet = <StaticEnumType extends string>(
    formValue: string
  ): Set<StaticEnumType> => {
    const array: Array<StaticEnumType> = JSON.parse(formValue)
    return new Set(array)
  }

  static setToFormValue = <StaticEnumType extends string>(
    set: Set<StaticEnumType>
  ): string => {
    return JSON.stringify(S.values(set))
  }

  static valueToFormValue = <StaticEnumType extends string>(
    val: StaticEnumType | StaticEnumType[] | undefined
  ): string => {
    return FormToggleGroup.setToFormValue(new Set(RA.ensureArray(val || [])))
  }

  render() {
    const {
      formFieldProps,
      onSelectionChange,
      blurOnChange,
      ...radioGroupProps
    } = this.props
    return (
      <ToggleGroup
        {...radioGroupProps}
        selected={FormToggleGroup.formValueToSet<EnumType>(formFieldProps.value)}
        makeSelection={set =>
          formFieldProps.onChange(FormToggleGroup.setToFormValue(set), () => {
            blurOnChange && formFieldProps.onBlur()
            onSelectionChange?.(set)
          })
        }
      />
    )
  }
}

export const mustMakeASelectionConstraint: IBasicStringConstraint = {
  constraint: s => S.nonempty(FormToggleGroup.formValueToSet(s)),
  message: _ => 'You must make a selection.',
}
