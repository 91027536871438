import * as React from 'react'
import {TSignInResponse} from '~/api/endpoints/auth/SignIn'
import LabeledLineTextInput from '~/components/FormFields/LabeledLineTextInput'
import ProvideForm from '~/components/ProvideForm/normal'
import {notEmpty} from '~/components/ProvideForm/utils'
import Spacer from '~/components/Spacer'
import DSButton from '~/design-system/Button'
import DSText, {DSTextDiv} from '~/design-system/DSText'
import DSLink from '~/design-system/Link'
import {staticURLs} from '~/routing/sitemap'
import styles from './styles.module.css'

interface IProps {
  signIn: (form: FormFields) => Promise<TSignInResponse>
  signInSuccess: (response: TSignInResponse) => void
}

interface IState {}

type FormFields = {
  email: string
  password: string
}

export default class SignInUI extends React.Component<IProps, IState> {
  state: IState = {}

  render() {
    return (
      <div className={styles.wrapper}>
        <DSTextDiv size="h2">Sign In</DSTextDiv>

        <ProvideForm<FormFields, TSignInResponse>
          onSubmit={this.props.signIn}
          wrapperClassName={styles.formWrapper}
          submitSuccess={(_, r) => this.props.signInSuccess(r)}
          toFormFields={(generateFormFieldProps, formProps) => {
            return (
              <div>
                <LabeledLineTextInput
                  label="Email"
                  placeholder=" "
                  formFieldProps={generateFormFieldProps('email', [notEmpty], {})}
                />

                <LabeledLineTextInput
                  label="Password"
                  placeholder=" "
                  formFieldProps={generateFormFieldProps('password', [notEmpty], {})}
                  inputType="password"
                />

                <Spacer height="40" />

                <DSButton
                  buttonStyle="primary"
                  size="19px"
                  onClick={() => formProps.onSubmit()}
                  loading={formProps.isFormSubmitting}
                  disabled={formProps.isFormSubmitting}
                  appearDisabled={formProps.anyErrors}
                >
                  Sign In
                </DSButton>

                <DSText size="body-15" color="grey-dark" className={styles.orLink}>
                  <DSLink to={staticURLs.auth.signup} underline="onHover">
                    Or, Sign Up
                  </DSLink>
                </DSText>
              </div>
            )
          }}
        />
      </div>
    )
  }
}
