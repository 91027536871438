import {IBasicStringConstraint, notEmpty} from '~/components/ProvideForm/utils'

export const passwordConstraints: IBasicStringConstraint[] = [
  notEmpty,
  {
    constraint: (s: string) => s.length >= 8,
    message: _ => '8 characters minimum',
  },
]

const passwordsMustMatch = (otherPassword: string): IBasicStringConstraint => {
  return {
    constraint: (passwordAgain: string) => otherPassword === passwordAgain,
    message: _ => `Passwords don't match`,
  }
}

export const repeatedPasswordConstraints = (
  otherPassword: string
): IBasicStringConstraint[] => [
  ...passwordConstraints,
  passwordsMustMatch(otherPassword),
]
