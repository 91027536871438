import * as React from 'react'
import {RouteComponentProps} from 'react-router-dom'
import {Route as Simple} from 'react-router-dom'
import Admin from './Admin'
import NotSignedIn from './NotSignedIn'
import SignedIn from './SignedIn'

// `Route.SignedIn` expects this key to be missing from the props of a RouteComponent,
// and the compiler errors if it is present on the `component` of a `Route.SignedIn`.
// if it is present, it means that a component expects to be a different type, i.e.: `Route.SignedInRequiresOrg`.
// similarly, a `Route.SignedInRequiresOrg` requires its `component`'s props to require the presence of this key
// with a specific route-specific value, otherwise, you used i.e.: `Route.SignedInRequiresOrg` when you didn't need to.
const ROUTE_TYPE_KEY = '__routeType'

// no Route.SignedIn component should be expecting a `ROUTE_TYPE_KEY` to be set, so we enforce that it is missing
// from the props of the component here (all `Route.SignedInRequiresOrg` and `Route.Admin` components expect the key)
// This allows the compiler to detect if we used `Route.SignedIn` where we should have used `Route.SignedInRequiresOrg`
// or `Route.Admin`.
//
// this type also allows `Route.SignedIn` components to not specify any need for `RouteComponentProps` in their own
// props for those simpler pages that don't need them. This is how the default Route (`Route.Simple`) types are, also.
// (this is something we've chosen not to allow in `Route.SignedInRequiresOrg` and `Route.Admin` for now for clarity)
interface WithRCP extends RouteComponentProps<any> {
  [ROUTE_TYPE_KEY]?: undefined
}
interface WithoutRCP {
  [ROUTE_TYPE_KEY]?: undefined
}

export type ComponentWithStandardRouteComponentProps = React.ComponentType<WithRCP>
export type ComponentWithoutRouteComponentProps = React.ComponentType<WithoutRCP>

export type SimpleRouteComponentType =
  | ComponentWithStandardRouteComponentProps
  | ComponentWithoutRouteComponentProps

export {Admin, SignedIn, NotSignedIn, Simple, ROUTE_TYPE_KEY}
