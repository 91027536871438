import * as R from 'ramda'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {skinsHuman, TSkin} from '~/game/skins'
import {gameLobbySizeHuman, TGameLobbySize} from '~/pages/GameLobby/types'
import store from '~/redux/store'
import {recordKeySet, runtimeTypeValidation, TExpectationDescriptor} from '~/utils'
import {
  LocalStorageManager,
  TLocalStorageGetResult,
  TLocalStorageKey,
} from '~/utils/LocalStorageManager'
import * as S from '~/utils/Set'
import {
  TUserNotificationPerSettingOptions,
  TUserNotificationSettings,
} from '~/utils/UserNotifications'

export type SettingsState = {
  gameLobbySize: TGameLobbySize
  skin: TSkin
  notifications: TUserNotificationSettings
  betaFeatures: TBetaFeatureSettings
  darkMode: boolean
}

export type TBetaFeatureSettings = {
  gameLog: boolean
}

const deserializeGameLobbySize = (
  stored: unknown
): TLocalStorageGetResult<TGameLobbySize> =>
  S.maybeHas(stored, recordKeySet(gameLobbySizeHuman))
    ? {result: stored, type: 'success'}
    : {type: 'failure', error: 'not a skin'}

const deserializeSkin = (stored: unknown): TLocalStorageGetResult<TSkin> =>
  S.maybeHas(stored, recordKeySet(skinsHuman))
    ? {result: stored, type: 'success'}
    : {type: 'failure', error: 'not a skin'}

const deserializeNotifications = (
  stored: unknown
): TLocalStorageGetResult<Partial<TUserNotificationSettings>> => {
  const perSettingRawExpectation: TExpectationDescriptor<TUserNotificationPerSettingOptions> = {
    sound: 'boolean',
    browserNotification: 'boolean',
  }
  const expectations: TExpectationDescriptor<TUserNotificationSettings> = {
    myTurn: [perSettingRawExpectation, 'undefined'],
    someoneElsesTurn: [perSettingRawExpectation, 'undefined'],
    attackFromMe: [perSettingRawExpectation, 'undefined'],
    attackAgainstMe: [perSettingRawExpectation, 'undefined'],
    attackUninvolved: [perSettingRawExpectation, 'undefined'],
    spectatorRequestReceived: [perSettingRawExpectation, 'undefined'],
  }

  if (
    runtimeTypeValidation<Partial<TUserNotificationSettings>>(expectations, stored)
  ) {
    return {result: stored, type: 'success'}
  }

  return R.tap(x => console.log('notification deserialization failed:', x), {
    type: 'failure',
    error: `notifications did not deserialize properly: ${JSON.stringify(stored)}`,
  })
}

const deserializeBetaFeatures = (
  stored: unknown
): TLocalStorageGetResult<Partial<TBetaFeatureSettings>> => {
  const expectations: TExpectationDescriptor<TBetaFeatureSettings> = {
    gameLog: ['boolean', 'undefined'],
  }

  if (runtimeTypeValidation<Partial<TBetaFeatureSettings>>(expectations, stored)) {
    return {result: stored, type: 'success'}
  }

  return R.tap(
    x => console.log('beta feature settings deserialization failed:', x),
    {
      type: 'failure',
      error: `beta feature settings did not deserialize properly: ${JSON.stringify(
        stored
      )}`,
    }
  )
}

const defaultSettings: SettingsState = {
  gameLobbySize: 'small',
  skin: 'mercury',
  notifications: {
    myTurn: {sound: true, browserNotification: true},
    someoneElsesTurn: {sound: true, browserNotification: false},

    attackFromMe: {sound: true, browserNotification: false},
    attackAgainstMe: {sound: true, browserNotification: true},
    attackUninvolved: {sound: true, browserNotification: false},

    spectatorRequestReceived: {sound: true, browserNotification: false},
  },
  betaFeatures: {
    gameLog: false,
  },
  darkMode: true,
}

const initialState = ((): SettingsState => {
  const gameLobbySizeResult = LocalStorageManager.mapSuccess(
    LocalStorageManager.get('settings:gameLobbySize'),
    deserializeGameLobbySize
  )

  const skinResult = LocalStorageManager.mapSuccess(
    LocalStorageManager.get('settings:skin'),
    deserializeSkin
  )

  const notificationsResult = LocalStorageManager.mapSuccess(
    LocalStorageManager.getObject('settings:notifications'),
    deserializeNotifications
  )

  const betaFeaturesResult = LocalStorageManager.mapSuccess(
    LocalStorageManager.getObject('settings:betaFeatures'),
    deserializeBetaFeatures
  )

  return {
    gameLobbySize: LocalStorageManager.successOr(
      gameLobbySizeResult,
      defaultSettings.gameLobbySize
    ),
    skin: LocalStorageManager.successOr(skinResult, defaultSettings.skin),
    notifications: {
      ...defaultSettings.notifications,
      ...LocalStorageManager.successOr(
        notificationsResult,
        defaultSettings.notifications
      ),
    },
    betaFeatures: {
      ...defaultSettings.betaFeatures,
      ...LocalStorageManager.successOr(
        betaFeaturesResult,
        defaultSettings.betaFeatures
      ),
    },
    darkMode: LocalStorageManager.successOr(
      LocalStorageManager.getObject('settings:darkMode'),
      defaultSettings.darkMode
    ),
  }
})()

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setGameLobbySize(state: SettingsState, action: PayloadAction<TGameLobbySize>) {
      const gameLobbySize = action.payload
      LocalStorageManager.set('settings:gameLobbySize', gameLobbySize)
      return {...state, gameLobbySize}
    },
    setSkin(state: SettingsState, action: PayloadAction<TSkin>) {
      const skin = action.payload
      LocalStorageManager.set('settings:skin', skin)
      return {...state, skin}
    },
    setNotifications(
      state: SettingsState,
      action: PayloadAction<TUserNotificationSettings>
    ) {
      const notifications = action.payload
      LocalStorageManager.setObject('settings:notifications', notifications)
      return {...state, notifications}
    },
    setBetaFeatures(
      state: SettingsState,
      action: PayloadAction<TBetaFeatureSettings>
    ) {
      const betaFeatures = action.payload
      LocalStorageManager.setObject('settings:betaFeatures', betaFeatures)
      return {...state, betaFeatures}
    },
    setDarkMode(state: SettingsState, action: PayloadAction<boolean>) {
      const darkMode = action.payload
      LocalStorageManager.setObject('settings:darkMode', darkMode)
      document
        .getElementsByTagName('html')[0]
        .setAttribute('class', darkMode ? 'dark' : 'light')
      return {...state, darkMode}
    },
    resetSettings() {
      return initialState
    },
  },
})

const tryJSONParse = (v: any): unknown => {
  try {
    return JSON.parse(v)
  } catch {
    return v
  }
}

window.addEventListener('storage', event => {
  const skinKey: TLocalStorageKey = 'settings:skin'
  const gameLobbySizeKey: TLocalStorageKey = 'settings:gameLobbySize'
  const notificationsKey: TLocalStorageKey = 'settings:notifications'
  const betaFeaturesKey: TLocalStorageKey = 'settings:betaFeatures'

  if (event.key === gameLobbySizeKey) {
    LocalStorageManager.whenSuccessful(
      deserializeGameLobbySize(event.newValue),
      size => store.dispatch(settingsSlice.actions.setGameLobbySize(size))
    )
  } else if (event.key === skinKey) {
    LocalStorageManager.whenSuccessful(deserializeSkin(event.newValue), skin =>
      store.dispatch(settingsSlice.actions.setSkin(skin))
    )
  } else if (event.key === notificationsKey) {
    LocalStorageManager.whenSuccessful(
      deserializeNotifications(tryJSONParse(event.newValue)),
      notifs =>
        store.dispatch(
          settingsSlice.actions.setNotifications({
            ...defaultSettings.notifications,
            ...notifs,
          })
        )
    )
  } else if (event.key === betaFeaturesKey) {
    LocalStorageManager.whenSuccessful(
      deserializeBetaFeatures(tryJSONParse(event.newValue)),
      notifs =>
        store.dispatch(
          settingsSlice.actions.setBetaFeatures({
            ...defaultSettings.betaFeatures,
            ...notifs,
          })
        )
    )
  }
})

const {
  setGameLobbySize,
  setSkin,
  setNotifications,
  setBetaFeatures,
  setDarkMode,
  resetSettings,
} = settingsSlice.actions
export {
  setGameLobbySize,
  setSkin,
  setNotifications,
  setBetaFeatures,
  setDarkMode,
  resetSettings,
}
export default settingsSlice.reducer
