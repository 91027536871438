import gameDataMock from '~/api/endpoints/game/GetGameData/mock'
import {TGetInitialDataResponse} from '~/api/generated/types/GetInitialData'

const globalDataMock: TGetInitialDataResponse = {
  userDetails: {
    id: gameDataMock.players[0].playerId,
    username: gameDataMock.players[0].username,
    email: 'monroe@ekilah.dev',
    admin: true,
  },
}

export default globalDataMock
