import {TMajorActionUniversalRentData} from '~/api/generated/types/common'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import {TMajorActionUserFacingDescriptionData} from '~/models/game/MajorAction'
import {UniversalRentCard} from '~/models/game/Cards/RentCard'
import MajorActionRentBase from '~/models/game/MajorActions/MajorActionRentBase'
import Portfolio from '~/models/game/Portfolio'
import StandardPortfolioNeighborhood from '~/models/game/PortfolioNeighborhoods/StandardPortfolioNeighborhood'
import {standardPNForIdThrows} from '~/models/game/utils'
import {compact} from '~/utils'
import {inTense, show$} from '~/utils/language'

export default class MajorActionUniversalRent extends MajorActionRentBase {
  card: UniversalRentCard

  // which neighborhood was used to initiate the rent
  portfolioNeighborhood: StandardPortfolioNeighborhood
  portfolioNeighborhoodId: string

  constructor(data: TMajorActionUniversalRentData, portfolio: Portfolio) {
    super(data)

    this.portfolioNeighborhoodId = data.portfolioNeighborhoodId
    this.portfolioNeighborhood = standardPNForIdThrows(portfolio)(
      data.portfolioNeighborhoodId
    )
    this.card = new UniversalRentCard(data.card)
  }

  public userFacingDescription({
    tense,
    skin,
    gamePosition,
  }: TMajorActionUserFacingDescriptionData): string {
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined
    const majorActor = gamePosition.majorActorPlayer
    const color = this.portfolioNeighborhood.displayColor(skin)
    const W = inTense(tense)
    const dropFirstNoun = tense === 'imperativePrompt'

    return compact([
      dropFirstNoun
        ? undefined
        : majorActor.id === currentPlayer?.id
        ? 'You'
        : majorActor.username,
      W(dropFirstNoun ? 'Play' : 'play'),
      `a universal`,
      show$(this.portfolioNeighborhood.rent()),
      this.card.displayName(skin),
      `(${color})`,
    ]).join(' ')
  }
}
