import {TCashCardData} from '~/api/generated/types/common'
import {TSkinData} from '~/game/skins'
import Card from '~/models/game/Card'
import {cardValue} from '~/game/types'
import MajorActionPlayAsCash from '~/models/game/MajorActions/MajorActionPlayAsCash'
import {AnyMajorAction} from '~/models/game/types'

export default class CashCard extends Card<TCashCardData> {
  public value: number

  constructor(data: TCashCardData) {
    super(data)
    this.value = cardValue[data.type]
  }

  public fileName(skinData: TSkinData): string {
    return `cash_${this.type}${skinData.cash === 'identical' ? '' : this.version}`
  }

  public availableMovesFromHand(): AnyMajorAction[] {
    return [
      new MajorActionPlayAsCash({
        card: this.datasource,
        type: 'playAsCash',
      }),
    ]
  }
}
