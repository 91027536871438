import * as H from 'history'
import * as React from 'react'
import {RouteComponentProps} from 'react-router-dom'
import api from '~/api/endpoints'
import {TSignInResponse} from '~/api/endpoints/auth/SignIn'
import MockConfig from '~/api/mocks/config'
import FEMLayout from '~/layouts/FEMLayout'
import {massUpdateRedux} from '~/redux/massUpdate'
import {navigateTo} from '~/routing/navigate'
import {staticURLs} from '~/routing/sitemap'
import {safely} from '~/utils'
import {partialFromPayload} from '~/utils/components'
import SignInUI from './stateless'

export type TNotSignedInYetReferrerPayload = {
  referrer:
    | Partial<
        Pick<H.LocationDescriptorObject, 'pathname' | 'search' | 'state' | 'hash'>
      >
    | undefined
}

interface IProps extends RouteComponentProps<any> {}

// app should refresh on login if it was loaded more than 10 minutes ago
const TIME_SINCE_APP_LOAD_TO_REFRESH_MS = 10 * 60 * 1000
const APP_LOADED_AT: Date = new Date()

export default class SignInPage extends React.Component<IProps> {
  /** Pages redirect to login automatically if they are auth-protected (Route.SignedIn). This sends them back. */
  referrer(): H.LocationDescriptorObject | undefined {
    // I'm not sure what the implications of sending other fields from `History.Location` would be
    // (`key`), so I'm choosing the ones I definitely want to forward instead.
    // (though the type of `this.props.referrer` says these are the only fields passed, there can be more
    // here at runtime)
    const referrer = partialFromPayload<TNotSignedInYetReferrerPayload>(this.props)
      .referrer
    return safely(referrer, ({pathname, search, state, hash}) => {
      return {
        pathname,
        search: MockConfig.enabled
          ? search?.replace(/mock=forcelogout&?/, '').replace(/&$/, '')
          : search,
        state,
        hash,
      }
    })
  }

  loginComplete = (response: TSignInResponse) => {
    MockConfig.loggedOut = false

    massUpdateRedux(response, 'signin')

    console.log('Sign in complete.')

    // NB: this does actually run before `Route.NotSignedIn`'s check that redux's `.signedIn` is no longer `false`
    navigateTo(this.referrer() || staticURLs.dashboard)

    // as a temporary solution to users having old frontend code,
    // just refresh on login if it's been a while since the app was reloaded.
    if (Date.now() - APP_LOADED_AT.getTime() > TIME_SINCE_APP_LOAD_TO_REFRESH_MS) {
      console.warn('Reloading the app after sign-in to get the latest version.')
      window.location.reload(true)
    }
  }

  render() {
    return (
      <FEMLayout>
        <SignInUI
          signIn={form => api.auth.signIn.post(form, {})}
          signInSuccess={this.loginComplete}
        />
      </FEMLayout>
    )
  }
}
