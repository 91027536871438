import {takeWhile} from 'ramda'
import {TMinorActionPromptDataJustSayNo} from '~/api/generated/types/common'
import {birthdayCost, debtCollectorCost} from '~/game/types'
import Card from '~/models/game/Card'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MajorActionBirthday from '~/models/game/MajorActions/MajorActionBirthday'
import MajorActionDealBreaker from '~/models/game/MajorActions/MajorActionDealBreaker'
import MajorActionDebtCollector from '~/models/game/MajorActions/MajorActionDebtCollector'
import MajorActionDirectionalRent from '~/models/game/MajorActions/MajorActionDirectionalRent'
import MajorActionDoubleTheRent from '~/models/game/MajorActions/MajorActionDoubleTheRent'
import MajorActionForcedDeal from '~/models/game/MajorActions/MajorActionForcedDeal'
import MajorActionSlyDeal from '~/models/game/MajorActions/MajorActionSlyDeal'
import MajorActionUniversalRent from '~/models/game/MajorActions/MajorActionUniversalRent'
import MinorAction from '~/models/game/MinorAction'
import MinorActionPrompt, {
  TMinorActionPromptUserFacingDescriptionData,
  TMinorActionPromptValidResponseData,
} from '~/models/game/MinorActionPrompt'
import MinorActionAcceptDealBreaker from '~/models/game/MinorActions/MinorActionAcceptDealBreaker'
import MinorActionAcceptForcedDeal from '~/models/game/MinorActions/MinorActionAcceptForcedDeal'
import MinorActionAcceptSlyDeal from '~/models/game/MinorActions/MinorActionAcceptSlyDeal'
import MinorActionAcquiesce from '~/models/game/MinorActions/MinorActionAcquiesce'
import MinorActionPayOther from '~/models/game/MinorActions/MinorActionPayOther'
import MinorActionPayRent from '~/models/game/MinorActions/MinorActionPayRent'
import Player from '~/models/game/Player'
import {switchOnMajorActionAllowsMinorAction} from '~/models/game/types'
import {sentenceCase, xVerbY} from '~/utils/language'
import * as S from '~/utils/Set'

export default class MinorActionPromptJustSayNo extends MinorActionPrompt<
  TMinorActionPromptDataJustSayNo
> {
  protected _validResponses(
    data: TMinorActionPromptValidResponseData
  ): MinorAction<any> | MinorAction<any>[] {
    const {majorAction, majorActor} = data.inProgressMajorAction

    const constructAcquiesceMinorAction = () => {
      // if we are allowed to Acquiesce, we were the major actor, and someone JSN'd us.
      // the JSN'er should be in the most recent inProgressMajorAction.previousMinorAction.
      const otherPlayerId =
        data.inProgressMajorAction.previousMinorActions[0]?.minorActorPlayerId

      if (!otherPlayerId) {
        console.warn(
          'error data',
          this.minorActorPlayerId,
          majorActor,
          data.inProgressMajorAction
        )
        throw new Error(
          `Couldn't find the otherPlayer in a MinorActionPromptJustSayNo`
        )
      }

      return new MinorActionAcquiesce({
        minorActorPlayerId: this.minorActorPlayerId,
        type: 'acquiesce',
        prevailingPlayerId: otherPlayerId,
      })
    }

    // if there were 2 JustSayNo's played so far, the originally-attacked player (whose minor turn it is to act) can't
    // just Acquiesce, because they have to do things like decide how to pay the rent that
    // was charged, etc.
    // But this code is also handling the 1- and 3-JustSayNo cases too (where the attacker
    // can lose the exchange via an Acquiesce and no e.g. rent is paid.)
    let minorAction =
      majorActor.id === this.minorActorPlayerId
        ? constructAcquiesceMinorAction()
        : switchOnMajorActionAllowsMinorAction<MinorAction<any>>({
            whenBirthday: (action: MajorActionBirthday) =>
              new MinorActionPayOther({
                type: 'birthday',
                minorActorPlayerId: this.minorActorPlayerId,
                millionsOwed: birthdayCost,
                paidWith: [], // this will be filled in later once the user chooses how to pay
              }),
            whenDealBreaker: (action: MajorActionDealBreaker) =>
              new MinorActionAcceptDealBreaker({
                minorActorPlayerId: this.minorActorPlayerId,
                type: 'dealBreaker',
                portfolioNeighborhoodId: action.portfolioNeighborhoodId,
              }),
            whenDebtCollector: (action: MajorActionDebtCollector) =>
              new MinorActionPayOther({
                type: 'debtCollector',
                minorActorPlayerId: this.minorActorPlayerId,
                millionsOwed: debtCollectorCost,
                paidWith: [], // this will be filled in later once the user chooses how to pay
              }),
            whenDirectionalRent: (action: MajorActionDirectionalRent) =>
              new MinorActionPayRent({
                minorActorPlayerId: this.minorActorPlayerId,
                type: 'directionalRent',
                millionsOwed: action.portfolioNeighborhood.rent(),
                paidWith: [], // this will be filled in later once the user chooses how to pay
                portfolioNeighborhoodId: action.portfolioNeighborhood.id,
              }),
            whenDoubleTheRent: (action: MajorActionDoubleTheRent) =>
              new MinorActionPayRent({
                minorActorPlayerId: this.minorActorPlayerId,
                type: 'directionalRent',
                millionsOwed: action.portfolioNeighborhood.rent(),
                paidWith: [], // this will be filled in later once the user chooses how to pay
                portfolioNeighborhoodId: action.portfolioNeighborhood.id,
              }),
            whenForcedDeal: (action: MajorActionForcedDeal) =>
              new MinorActionAcceptForcedDeal({
                minorActorPlayerId: this.minorActorPlayerId,
                type: 'forcedDeal',
                offeredCard: action.offeredCard.datasource,
                requestedCard: action.requestedCard.datasource,
                // we need to ask the user where to put the card eventually
                placedInPortfolioNeighborhoodId: 'unset_pnId',
              }),
            whenSlyDeal: (action: MajorActionSlyDeal) =>
              new MinorActionAcceptSlyDeal({
                minorActorPlayerId: this.minorActorPlayerId,
                type: 'slyDeal',
                requestedCard: action.requestedCard.datasource,
              }),
            whenUniversalRent: (action: MajorActionUniversalRent) =>
              new MinorActionPayRent({
                minorActorPlayerId: this.minorActorPlayerId,
                type: 'directionalRent',
                millionsOwed: action.portfolioNeighborhood.rent(),
                paidWith: [], // this will be filled in later once the user chooses how to pay
                portfolioNeighborhoodId: action.portfolioNeighborhood.id,
              }),
          })(majorAction)

    return this.withJustSayNo(data, minorAction)
  }

  public userFacingDescription(
    data: TMinorActionPromptUserFacingDescriptionData
  ): string {
    const {gamePosition, tense, skin, inProgressMajorAction} = data
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined
    const {majorActor, minorActor} = this

    // a MinorActionPromptJustSayNo is sent from the backend to a user whose action
    // has been rejected by a JustSayNo.
    //
    // in the case the minor actor is being asked to respond to the first JustSayNo,
    // the major actor (the initiator of the entire inProgressMajorAction)
    // will be the same as
    // the minor actor (the person currently deciding what to do next).
    //
    // for the purposes of displaying who is doing what, however,
    // we actually want to track one more piece of data - who last played a JustSayNo?
    // we'll call this the currentWinner.
    //
    // collect the IDs involved in the exchange. the initiator may or may not be in `previousMinorActions` yet but we
    // are guaranteed that the original defendant is in there, since someone JSN'd already.

    // (first filter previousMinorActions to remove, say, a previously acquiesced JSN. we only care about the "current"/
    // "active" JSN, even if another player played one on a previous step of, say, a universal rent)
    const previousJustSayNos = takeWhile(
      pma => pma.type === 'justSayNo',
      inProgressMajorAction.previousMinorActions
    )
    const playersInvolvedIds = new Set([
      ...previousJustSayNos.map(pma => pma.minorActorPlayerId),
      inProgressMajorAction.majorActor.id,
    ])
    if (playersInvolvedIds.size !== 2) {
      console.error(
        'more than 2 players seem to be involved in a JustSayNo battle.',
        {
          inProgressMajorAction,
          gamePosition,
          currentPlayer,
          majorActor,
          minorActor,
        }
      )
      throw new Error('more than 2 players in JustSayNo battle')
    }
    const [playerA, playerB] = S.map(
      id => gamePosition.players.find(p => p.id === id),
      playersInvolvedIds
    ) as [Player, Player]

    // whoever is currently being prompted to respond to the JSN is losing
    const [currentWinner, currentLoser] =
      this.minorActorPlayerId === playerA.id
        ? [playerB, playerA]
        : [playerA, playerB]

    const {conjugatedVerb, xNoun, yNounPossessive} = xVerbY(
      tense,
      currentLoser,
      'respondTo',
      currentWinner,
      currentPlayer?.id
    )

    return [
      xNoun === minorActor.username ? xNoun : sentenceCase(xNoun),
      conjugatedVerb,
      yNounPossessive,
      Card.displayNameForCard('justSayNo', skin),
    ].join(' ')
  }
}
