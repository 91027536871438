import React from 'react'
import {TAdminSignUpsResult} from '~/api/endpoints/admin/AdminSignUps'
import LabeledLineTextInput from '~/components/FormFields/LabeledLineTextInput'
import FormSubmissionButton from '~/components/ProvideForm/FormSubmissionButton'
import ProvideForm from '~/components/ProvideForm/normal'
import {notEmpty} from '~/components/ProvideForm/utils'
import {DSTextDiv} from '~/design-system/DSText'
import {formatDateByAge} from '~/utils'
import styles from './styles.module.css'

type TRowForm = {
  slackId: string
}

interface IProps {
  signups: TAdminSignUpsResult['signupRequests']
  approveSignUp: (
    signupId: string
  ) => (form: TRowForm) => Promise<TAdminSignUpsResult>
  approveSignUpSubmitSuccess: (result: TAdminSignUpsResult) => void
}

interface IState {}

export default class AdminSignUpsUI extends React.Component<IProps, IState> {
  state: IState = {}

  render() {
    return (
      <div>
        <div className={styles.signupsGrid}>
          <>
            <DSTextDiv size="body-15" bold>
              Date
            </DSTextDiv>
            <DSTextDiv size="body-15" bold>
              Username
            </DSTextDiv>
            <DSTextDiv size="body-15" bold>
              Email
            </DSTextDiv>
            <DSTextDiv size="body-15" bold>
              Slack ID (required)
            </DSTextDiv>
          </>
          {this.props.signups.map(su => (
            <React.Fragment key={su.id}>
              <DSTextDiv size="body-15">{formatDateByAge(su.createdAt)}</DSTextDiv>
              <DSTextDiv size="body-15">{su.username}</DSTextDiv>
              <DSTextDiv size="body-15">{su.email}</DSTextDiv>
              <ProvideForm<TRowForm, TAdminSignUpsResult>
                onSubmit={this.props.approveSignUp(su.id)}
                submitSuccess={(_, result) =>
                  this.props.approveSignUpSubmitSuccess(result)
                }
                toFormFields={(generateFormFieldProps, formProps) => {
                  return (
                    <div className={styles.rowForm}>
                      <LabeledLineTextInput
                        formFieldProps={generateFormFieldProps('slackId', [
                          notEmpty,
                        ])}
                        label=""
                        placeholder="Slack ID"
                      />
                      <FormSubmissionButton
                        formProps={formProps}
                        size="14px"
                        buttonStyle="primary"
                        style={
                          formProps.anyErrors ? {visibility: 'hidden'} : undefined
                        }
                      >
                        Approve
                      </FormSubmissionButton>
                    </div>
                  )
                }}
              />
            </React.Fragment>
          ))}

          {!this.props.signups.length && (
            <DSTextDiv size="body-15">No requests.</DSTextDiv>
          )}
        </div>
      </div>
    )
  }
}
