import classnames from 'classnames'
import * as React from 'react'
import {IFormFieldProps} from '~/components/ProvideForm/types'
import LoadingSpinner from '~/components/LoadingSpinner'
import {DISABLE_AUTOCOMPLETE_STRING} from '~/components/ProvideForm/utils'
import {isPresent} from '~/utils'
import {CSSSpacingProperties} from '~/utils/components'
import * as U from '~/utils/index'
import styles from './styles.module.css'

// `.default` at the end of this import was causing some weird react error on page transitions
// leaving pages with inputs on them. Not sure what that's all about.
// i think the `.default` was on there so SVGs worked in storybook? i can't remember for sure.
const GreenCheck = require('~/img/icons/rounded-checkbox.svg')

export type TAcceptedAttributesForInput = Pick<
  React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>,
  // <input> attributes that this Provider sends directly to `toInput` from it's props.
  | 'maxLength'
  | 'autoFocus'

  // <input> attributes accepted as props that this Provider modifies before passing to `toInput`.
  | 'id'
  | 'name'
  | 'onBlur'
  | 'onFocus'
  | 'onKeyDown'
  | 'placeholder'
  | 'onChange'
  | 'autoComplete'
  | 'disabled'
>

/**
 * These are passed on to the actual input via toInput(inputProps).
 * They should all be used on the provided-to <input>.
 */
export type TToInputProps = {
  value?: string | number
} & TAcceptedAttributesForInput &
  Pick<
    React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>,
    // <input> attributes that this Provider does not accept as a prop, but does send to `toInput`.
    'className'
  >

type SizeType = 'normal' | 'check-size' | 'large' | 'xlarge'

/**
 * Common input props. Wrappers of this class can extend this for their own props
 * Everything listed here is consumed/applied by this Provider except `inputRef`
 *   (`ref` isn't a prop we can pass off, React guarantees it will refer to the component
 *   it's directly set on)
 */
export interface IProvideLabeledLineTextInputProps
  extends TAcceptedAttributesForInput {
  /** These props are passed in from the Form provider. */
  formFieldProps: IFormFieldProps

  /* common props */
  /** labels text field after it's focused/typed in (placeholder no longer visible). */
  label?: string
  hideLabelWhileBlankAndUnfocused?: boolean
  keepPlaceholderWhileFocused?: boolean
  /** Text that shows, right-aligned, alongside the user input, visually 'inside' the input field. */
  hintText?: string

  /* styling props */
  size?: SizeType
  /** a visual-only prefix to the text input's input, like a '$' shown before a dollar amount */
  prefix?: React.ReactNode
  style?: CSSSpacingProperties
  /**
   * shows icons to the right of the input to show:
   * - if the field is being submitted (loading spinner)
   * - if the field's value is already saved (green checkmark)
   * useful for ProvideFormIncremental inputs.
   * also disables the enter button submission (submission is handled on blur instead)
   */
  incrementalUI?: boolean
  /** CSS classes to be set on the various sub-components of the TextInput */
  classNames?: {
    /** CSS class for the HTML <input> */
    input?: string
    /** CSS class for the outermost div that wraps everything inside the TextInput */
    wrapper?: string
    /** CSS class for the div that wraps the label inside the TextInput */
    label?: string
    hintText?: string
    /** CSS class for the div that wraps the loading/saved icon displayed next to incremental fields */
    incrementalUI?: string
  }
  /** The design calls for a margin on the top of most inputs. Turn it off here. */
  noWrapperMargin?: boolean

  focusOnEnter?: HTMLElement | null
  dontSubmitOnEnter?: boolean

  /* rare HTML input stuff */
  /**
   * If you need a `ref` on the input, for things like focusing or animations.
   * see https://reactjs.org/docs/refs-and-the-dom.html for more on refs.
   *
   * This must be manually set on the `<input>` that this Provider gives props to.
   */
  inputRef?:
    | React.Ref<HTMLInputElement | HTMLTextAreaElement>
    | ((instance: HTMLInputElement | HTMLTextAreaElement | null) => any)

  /** Render the error message in place of the label text instead of showing the error tooltip. */
  useErrorAsLabel?: boolean
}

// not to be extended for props of wrappers of this class, these props are only for the provider
interface IPrivateProvideLabeledLineInputProps
  extends IProvideLabeledLineTextInputProps {
  /**
   * Provides things a LabeledLineInput wrapper class should use when creating the HTML <input>
   * @param {TToInputProps} inputProps **mandatory** props/attributes the wrapper class must pass to the <input>.
   * @returns {JSX.Element | JSX.Element[]}
   */
  toInput: (inputProps: TToInputProps) => JSX.Element | JSX.Element[]
}

interface IState {
  inputFocused: boolean
}

class ProvideLabeledLineTextInput extends React.Component<
  IPrivateProvideLabeledLineInputProps,
  IState
> {
  state: IState = {inputFocused: false}

  onInputFocus = (
    focusEvent: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.props.onFocus?.(focusEvent)

    this.setState({inputFocused: true})
  }

  onInputBlur = (
    blurEvent: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.props.onBlur?.(blurEvent)

    this.props.formFieldProps.onBlur()

    this.setState({inputFocused: false})
  }

  onInputKeyDown = (
    keyEvent: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (keyEvent.isPropagationStopped()) {
      return
    }

    this.props.onKeyDown?.(keyEvent)

    // don't do focusOnEnter-ing or submitOnEnter-ing if shift is held down (to add newlines in a text area)
    const isShiftInTextArea =
      keyEvent.shiftKey &&
      // @ts-ignore: `type` exists according to the JS console.
      keyEvent.target.type === 'textarea'

    if (keyEvent.key === 'Enter' && !isShiftInTextArea) {
      let wasHandled = false

      if (!!this.props.focusOnEnter) {
        this.props.focusOnEnter.focus()
        wasHandled = true
      } else if (!this.props.incrementalUI && !this.props.dontSubmitOnEnter) {
        // incremental fields submit on blur, so don't do it on enter also
        this.props.formFieldProps.onSubmit()
        wasHandled = true
      }

      if (wasHandled) {
        // prevent the onKeyPress event from being called for this onKeyDown event.
        // in SendMoney, for instance, the Enter key handling here which submitted a form
        // was causing an onKeyPress on the next page's textArea that is autofocused.
        keyEvent.preventDefault()
      }
    }
  }

  onInputChange = (
    changeEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // this allows the changeEvent to be used asynchronously
    changeEvent.persist()

    // tell the caller when the form is done updating, not before/during
    // so the caller can react to form's updated state
    this.props.formFieldProps.onChange(changeEvent.target.value, () => {
      this.props.onChange?.(changeEvent)
    })
  }

  render() {
    const idString =
      this.props.id || (this.props.label ? U.slugify(this.props.label) : undefined)
    const nameString = this.props.name || idString

    const label =
      this.props.useErrorAsLabel && this.props.formFieldProps.errorMessage
        ? this.props.formFieldProps.errorMessage
        : this.props.label

    const isLabelPresent = isPresent(label)
    const hideLabel =
      !this.state.inputFocused &&
      this.props.hideLabelWhileBlankAndUnfocused &&
      !this.props.formFieldProps.value

    const givenPlaceholder = this.props.placeholder || this.props.label // default to label
    const disappearingPlaceholder =
      this.props.keepPlaceholderWhileFocused || !this.state.inputFocused
        ? givenPlaceholder
        : undefined
    const size: SizeType = this.props.size || 'normal'

    const extraClassNames = this.props.classNames || {}

    const inputProps: TToInputProps = {
      id: idString,
      name: nameString,
      onFocus: this.onInputFocus,
      onBlur: this.onInputBlur,
      onKeyDown: this.onInputKeyDown,
      onChange: this.onInputChange,
      maxLength: this.props.maxLength,

      className: classnames({
        [styles['input']]: true,
        [styles[size]]: true,
        [extraClassNames.input || '']: !!extraClassNames.input,
      }),
      placeholder: disappearingPlaceholder,
      autoFocus: this.props.autoFocus,
      autoComplete: this.props.formFieldProps.formDisabledAutoComplete
        ? DISABLE_AUTOCOMPLETE_STRING
        : this.props.autoComplete,
      value: this.props.formFieldProps.value,
      disabled: this.props.disabled,
    }

    let incrementalIcon = undefined
    // if there aren't any unsubmitted changes and all constraints are passing, I guess it's submitted/validated...
    if (
      !this.props.formFieldProps.unsubmittedChange &&
      !this.props.formFieldProps.failingConstraint &&
      // empty fields w/o failing constraints are 'optional', which we don't want to show as green when empty
      this.props.formFieldProps.value.length > 0
    ) {
      incrementalIcon = <GreenCheck />
    } else if (this.props.formFieldProps.isFieldSubmitting) {
      incrementalIcon = <LoadingSpinner />
    }

    const hasError = !!this.props.formFieldProps.errorMessage

    return (
      <div
        style={this.props.style}
        className={classnames(
          styles['provide-labeled-line-input'],
          this.props.noWrapperMargin && styles['no-wrapper-margin'],
          extraClassNames.wrapper
        )}
      >
        <div className={styles['label-and-input-wrapper']}>
          {isLabelPresent && (
            <LabeledLineInputLabel
              forInputId={idString}
              hideLabel={hideLabel}
              label={label}
              className={extraClassNames.label}
              error={hasError}
            />
          )}
          <div
            className={classnames(
              styles['input-line'],
              !!this.props.formFieldProps.errorMessage && styles['error']
            )}
          >
            {this.props.toInput(inputProps)}
            {this.props.prefix && (
              <div
                className={classnames({
                  [styles['prefix']]: true,
                  [styles['error']]: !!this.props.formFieldProps.errorMessage,
                  [styles[size]]: true,
                })}
              >
                {this.props.prefix}
              </div>
            )}
            {!this.props.useErrorAsLabel && (
              <LabeledLineErrorBubble
                errorMessage={this.props.formFieldProps.errorMessage}
                className={classnames(
                  !isLabelPresent && styles['no-label'],
                  styles['floating-error-built-in']
                )}
              />
            )}
            {!!this.props.hintText && (
              <div
                className={classnames(styles['hintText'], extraClassNames.hintText)}
              >
                {this.props.hintText}
              </div>
            )}
          </div>
        </div>
        {!!this.props.incrementalUI && (
          <div
            className={classnames(
              styles['incremental'],
              extraClassNames.incrementalUI
            )}
          >
            {incrementalIcon}
          </div>
        )}
      </div>
    )
  }
}

export default ProvideLabeledLineTextInput

interface ILabeledLineInputLabelProps {
  label?: string
  hideLabel?: boolean
  forInputId?: string
  className?: string
  error?: boolean
}

export function LabeledLineInputLabel(props: ILabeledLineInputLabelProps) {
  return (
    <label
      className={classnames(
        styles['label'],
        props.error && styles['error'],
        props.className,
        props.hideLabel && styles['invisible']
      )}
      htmlFor={props.forInputId}
    >
      {props.label}
    </label>
  )
}

interface ILabeledLineErrorBubbleProps {
  errorMessage: string | null | undefined
  className?: string
}

export function LabeledLineErrorBubble(props: ILabeledLineErrorBubbleProps) {
  if (!isPresent(props.errorMessage)) {
    return null
  }

  return (
    <div
      className={classnames({
        [styles['floating-error']]: true,
        [props.className || '']: !!props.className,
      })}
    >
      {props.errorMessage}
    </div>
  )
}
