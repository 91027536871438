import * as RA from 'ramda-adjunct'

export type ListCreationParameter<K> = NonNullable<K | K[]>

/** Get a new list back from one or many items */
export const create = <K>(items: ListCreationParameter<K>): Array<K> => {
  return RA.ensureArray(items)
}

/** Get the member of the list at i. Returns the last element if i overflows the size of the list. */
export const atOrLast = <T>(i: number, list: ReadonlyArray<T>): T | undefined => {
  const size = list.length
  return i >= size ? list[size - 1] : list[i]
}

// rotate an array such that the order is preserved, except the first member to pass the predicate
// will be rotated into the first position.
// example: rotateLeftUntil(x => x === 3, [0, 1, 2, 3, 4, 5]) === [3, 4, 5, 0, 1, 2]
export const rotateLeftUntil = <T extends any>(
  predicate: (val: T) => boolean,
  list: T[]
): T[] => {
  const idx = list.findIndex(predicate)
  if (idx <= 0) {
    // no match, or first elem matched the predicate
    return list
  }
  const newLeft = list.slice(idx)
  const newRight = list.slice(0, idx)
  return [...newLeft, ...newRight]
}
