import {playersMock} from '~/api/endpoints/game/GetGameData/mock'
import {TDashboardDataResponse} from '~/api/generated/types/GetDashboardData'
import Day from '~/utils/Day'

const dashboardDataMock: TDashboardDataResponse = {
  eloLeaderboard: [
    {playerData: playersMock[0], elo: 9000.01, gamesPlayed: 25},
    {playerData: playersMock[1], elo: 2882.0, gamesPlayed: 14},
    {playerData: playersMock[2], elo: 1200.0, gamesPlayed: 2},
  ],
  activeGames: [
    {
      createdAt: Day.today().toISOTimeUTC(),
      gameId: '1234',
      players: playersMock,
      createdBy: playersMock[0].playerId,
    },
    {
      createdAt: Day.today().toISOTimeUTC(),
      gameId: '5678',
      players: playersMock,
      createdBy: playersMock[1].playerId,
    },
  ],
}

export default dashboardDataMock
