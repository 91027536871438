import {PostApi} from '~/api/client'
import dashboardDataMock from '~/api/endpoints/dashboard/GetDashboardData/mock'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TDashboardDataResponse} from '~/api/generated/types/GetDashboardData'
import {TJSONError} from '~/api/types'

type Request = {}
type Response = TDashboardDataResponse
type PathPieces = {
  gameId: string
}

/**
 * Active games listed on the dashboard can be removed from the list by their
 * creator, e.g. if the game was abandoned. This will hide it for all users.
 */
export default class MarkGameAsStale extends PostApi<Request, Response, PathPieces> {
  protected mockRequestFailure(
    params: Request,
    pathPieces: PathPieces
  ): TJSONError | null {
    if (pathPieces.gameId === 'fail') {
      return {errors: {gameId: [`That game doesn't exist.`]}}
    }

    return super.mockRequestFailure(params, pathPieces)
  }

  protected getMockResponse(params: Request): Response {
    return dashboardDataMock
  }

  protected getPath(pathPieces: PathPieces) {
    return serverEndpoints.markGameAsStale(pathPieces)
  }

  protected transformResponse(r: Response) {
    return r
  }
}
