import {faCrown, faSignOutAlt} from '@fortawesome/free-solid-svg-icons'
import {identity} from 'ramda'
import * as React from 'react'
import {TJoinGameResponse} from '~/api/endpoints/game/PreGame/JoinGame'
import {TStartGameResponse} from '~/api/endpoints/game/PreGame/StartGame'
import {TWaitingRoomResponse} from '~/api/generated/types/GetGameWaitingRoom'
import Gravatar from '~/components/Gravatar/stateless'
import {
  AdvancedConstraint,
  floatRangeConstraint,
  TConstraint,
} from '~/components/ProvideForm/utils'
import {SingleButtonForm} from '~/components/SingleButtonForm'
import DSText, {DSTextDiv} from '~/design-system/DSText'
import {DSLinkTextDiv} from '~/design-system/Link'
import {MAX_NUMBER_OF_PLAYERS, MIN_NUMBER_OF_PLAYERS} from '~/game/ruleset'
import User from '~/models/app/User'
import {copyTextToClipboard} from '~/utils'
import {HoverableFontAwesomeIconDiv} from '~/utils/FontAwesome/HoverableFontAwesomeIcon'
import styles from './styles.module.css'

interface IProps {
  data: TWaitingRoomResponse

  joinGame: () => Promise<TJoinGameResponse>
  joinGameSuccess: () => void

  startGame: () => Promise<TStartGameResponse>
  kickPlayer: (playerId: string) => Promise<any>
  gameStarted: () => void
  user: User
}

const rangeConstraint = (playerCount: number) => {
  return new AdvancedConstraint<any>(() => {
    return floatRangeConstraint(
      MIN_NUMBER_OF_PLAYERS,
      MAX_NUMBER_OF_PLAYERS,
      '[]',
      ({value}) =>
        value < MIN_NUMBER_OF_PLAYERS ? 'Not enough players yet' : 'Too many players'
    ).test('' + playerCount)
  })
}

const joinableConstraints = (data: TWaitingRoomResponse): TConstraint<any>[] => [
  new AdvancedConstraint<any>(() => {
    return floatRangeConstraint(0, MAX_NUMBER_OF_PLAYERS, '[]').test(
      '' + data.playerData.length
    )
  }),
  {
    constraint: () => data.status === 'waiting',
    message: () => 'The game is no longer joinable',
  },
]

export default class WaitingRoomUI extends React.Component<IProps> {
  render() {
    const isHost = this.props.data.hostPlayerId === this.props.user.id
    const hasntJoinedYet = !this.props.data.playerData.find(
      p => p.playerId === this.props.user.id
    )

    return (
      <div className={styles.waitingRoomWrapper}>
        <DSTextDiv size="body-17">Players:</DSTextDiv>
        <div className={styles.playerList}>
          {this.props.data.playerData.map(p => (
            <div key={p.playerId} className={styles.playerRow}>
              {p.playerId === this.props.data.hostPlayerId && (
                <HoverableFontAwesomeIconDiv
                  className={styles.hostIcon}
                  icon={{
                    icon: faCrown,
                    style: {width: '14px', height: '14px'},
                  }}
                >
                  {identity}
                </HoverableFontAwesomeIconDiv>
              )}
              {(isHost
                ? p.playerId !== this.props.data.hostPlayerId
                : p.playerId === this.props.user.id) && (
                <HoverableFontAwesomeIconDiv
                  className={styles.kickPlayerIcon}
                  icon={{
                    icon: faSignOutAlt,
                    color: 'grey',
                    style: {width: '13px', height: '13px'},
                  }}
                  hoverIcon={{color: 'red-dark'}}
                  onClick={() =>
                    this.props.kickPlayer(p.playerId).catch(() => undefined)
                  }
                >
                  {identity}
                </HoverableFontAwesomeIconDiv>
              )}
              <Gravatar email={p.email} size={40} />
              <DSText tag="p" size="body-15-demi">
                {p.username}
              </DSText>
            </div>
          ))}
        </div>

        <DSLinkTextDiv
          underline
          size="body-15"
          onClick={() => copyTextToClipboard(window.location.href)}
        >
          Copy Invite URL
        </DSLinkTextDiv>
        {hasntJoinedYet && (
          <SingleButtonForm
            buttonStyle="primary"
            size="17px"
            onSubmit={() => this.props.joinGame()}
            submitSuccess={this.props.joinGameSuccess}
            peripheralConstraints={joinableConstraints(this.props.data)}
            classNames={{wrapper: styles.button}}
          >
            Join Game
          </SingleButtonForm>
        )}
        {isHost && (
          <SingleButtonForm
            buttonStyle="primary"
            size="17px"
            onSubmit={() => this.props.startGame()}
            submitSuccess={this.props.gameStarted}
            peripheralConstraints={[
              rangeConstraint(this.props.data.playerData.length),
            ]}
            classNames={{wrapper: styles.button}}
          >
            Start Game
          </SingleButtonForm>
        )}
      </div>
    )
  }
}
