// import {notifyBugsnag} from '~/bugsnag'

import {noop} from '~/utils/index'

const notifyBugsnag = (...inputs: any[]) => noop()

export type TLocalStorageGetResult<T> =
  | {type: 'success'; result: T}
  | {type: 'missing'; result: null}
  | {type: 'failure'; error: any}

export type TLocalStorageSetResult =
  | {type: 'success'}
  | {type: 'failure'; error: any}

export type TLocalStorageKey =
  | 'settings:gameLobbySize' // TGameLobbySize
  | 'settings:skin' // TGameLobbySize
  | 'settings:notifications' // TUserNotificationSettings
  | 'settings:betaFeatures' // TBetaFeatureSettings
  | 'settings:darkMode' // boolean

export class LocalStorageManager {
  private static _canUse: boolean | undefined

  private static hadError = (error: Error, method: string) => {
    // TBD if this will be noisy. Could do a breadcrumb instead if so, we aren't using this for critical stuff (yet)
    notifyBugsnag(
      {
        errorClass: 'warning',
        errorMessage: `Local storage access threw an exception (${method}). Disabling localStorage.`,
      },
      {
        metaData: {
          caughtError: error,
        },
      }
    )
    LocalStorageManager._canUse = false
  }

  public static canUseLocalStorage = () => {
    if (LocalStorageManager._canUse !== undefined) {
      return LocalStorageManager._canUse
    }

    const test = 'test'
    try {
      // try setting an item
      localStorage.setItem(test, test)
      if (localStorage.getItem(test) !== test) {
        throw new Error('localStorage returned a different value than we set.')
      }
      localStorage.removeItem(test)

      LocalStorageManager._canUse = true
    } catch (e) {
      LocalStorageManager.hadError(e, 'canUseLocalStorage')
    }
    return LocalStorageManager._canUse
  }

  public static set = (
    key: TLocalStorageKey,
    value: string
  ): TLocalStorageSetResult => {
    if (!LocalStorageManager.canUseLocalStorage()) {
      return {
        type: 'failure',
        error: `LocalStorageManager says we can't use local storage.`,
      }
    }

    try {
      localStorage.setItem(key, value)
      return {type: 'success'}
    } catch (e) {
      LocalStorageManager.hadError(e, 'set')
      return {type: 'failure', error: e}
    }
  }

  public static get = (key: TLocalStorageKey): TLocalStorageGetResult<string> => {
    if (!LocalStorageManager.canUseLocalStorage()) {
      return {
        type: 'failure',
        error: `LocalStorageManager says we can't use local storage.`,
      }
    }

    try {
      // supposedly getItem doesn't throw exceptions. It's nice to wrap it just in case anyway.
      const value = localStorage.getItem(key)
      return value === null
        ? {type: 'missing', result: null}
        : {type: 'success', result: value}
    } catch (e) {
      LocalStorageManager.hadError(e, 'get')
      return {type: 'failure', error: e}
    }
  }

  public static remove = (key: TLocalStorageKey): TLocalStorageSetResult => {
    if (!LocalStorageManager.canUseLocalStorage()) {
      return {
        type: 'failure',
        error: `LocalStorageManager says we can't use local storage.`,
      }
    }

    try {
      localStorage.removeItem(key)
      return {type: 'success'}
    } catch (e) {
      LocalStorageManager.hadError(e, 'remove')
      return {type: 'failure', error: e}
    }
  }

  // set or remove, depending on the value
  public static update = (
    key: TLocalStorageKey,
    value: string | undefined
  ): TLocalStorageSetResult => {
    if (value === undefined) {
      return LocalStorageManager.remove(key)
    } else {
      return LocalStorageManager.set(key, value)
    }
  }

  static successAnd = <T extends any>(
    result: TLocalStorageGetResult<T>,
    predicate: (res: T) => boolean
  ): boolean => {
    return result.type === 'success' ? predicate(result.result) : false
  }

  static mapSuccess = <T, U>(
    result: TLocalStorageGetResult<T>,
    f: (res: T) => TLocalStorageGetResult<U>
  ): TLocalStorageGetResult<U> => {
    return result.type === 'success' ? f(result.result) : result
  }

  static successOr = <T>(result: TLocalStorageGetResult<T>, or: T): T => {
    return result.type === 'success' ? result.result : or
  }

  static whenSuccessful = <T>(
    result: TLocalStorageGetResult<T>,
    then: (res: T) => void
  ): void => {
    result.type === 'success' && then(result.result)
  }

  public static setPromise = (
    key: TLocalStorageKey,
    value: string
  ): Promise<TLocalStorageSetResult> => {
    return new Promise((accept, reject) => {
      const result = LocalStorageManager.set(key, value)
      result.type === 'success' ? accept(result) : reject(result)
    })
  }

  public static getPromise = (
    key: TLocalStorageKey
  ): Promise<TLocalStorageGetResult<string>> => {
    return new Promise((accept, reject) => {
      const result = LocalStorageManager.get(key)
      result.type === 'success' ? accept(result) : reject(result)
    })
  }

  public static setObject = <T>(
    key: TLocalStorageKey,
    value: T
  ): TLocalStorageSetResult => {
    return LocalStorageManager.set(key, JSON.stringify(value))
  }

  public static getObject = <T>(
    key: TLocalStorageKey
  ): TLocalStorageGetResult<T> => {
    try {
      return LocalStorageManager.mapSuccess(LocalStorageManager.get(key), str => {
        const t: T = JSON.parse(str)
        return {type: 'success', result: t}
      })
    } catch (e) {
      // intended for catching JSON parsing exceptions
      return {type: 'failure', error: e}
    }
  }

  public static getObjPromise = <T>(
    key: TLocalStorageKey
  ): Promise<TLocalStorageGetResult<T>> => {
    return new Promise((accept, reject) => {
      const result = LocalStorageManager.getObject<T>(key)
      result.type === 'success' ? accept(result) : reject(result)
    })
  }

  public static setObjPromise = <T>(
    key: TLocalStorageKey,
    value: T
  ): Promise<TLocalStorageSetResult> => {
    return new Promise((accept, reject) => {
      const result = LocalStorageManager.setObject(key, value)
      result.type === 'success' ? accept(result) : reject(result)
    })
  }
}
