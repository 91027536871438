import {
  TBirthdayCardData,
  TDealBreakerCardData,
  TDebtCollectorCardData,
  TDoubleTheRentCardData,
  TForcedDealCardData,
  TPassGoCardData,
  TSlyDealCardData,
} from '~/api/generated/types/common'
import Card, {TAvailableMovesData} from '~/models/game/Card'
import {cardValue, TJustSayNoCardData, TNonRentActionCardData} from '~/game/types'
import MajorActionBirthday from '~/models/game/MajorActions/MajorActionBirthday'
import MajorActionDealBreaker from '~/models/game/MajorActions/MajorActionDealBreaker'
import MajorActionDebtCollector from '~/models/game/MajorActions/MajorActionDebtCollector'
import MajorActionDoubleTheRent from '~/models/game/MajorActions/MajorActionDoubleTheRent'
import MajorActionForcedDeal from '~/models/game/MajorActions/MajorActionForcedDeal'
import MajorActionPassGo from '~/models/game/MajorActions/MajorActionPassGo'
import MajorActionPlayAsCash from '~/models/game/MajorActions/MajorActionPlayAsCash'
import MajorActionSlyDeal from '~/models/game/MajorActions/MajorActionSlyDeal'
import {AnyMajorAction} from '~/models/game/types'

export default abstract class ActionCard<
  C extends TNonRentActionCardData
> extends Card<C> {
  public value: number

  constructor(data: C) {
    super(data)
    this.value = cardValue[data.type]
  }

  public fileName(): string {
    return `action_${this.type}`
  }

  protected playAsCashAction(): MajorActionPlayAsCash {
    return new MajorActionPlayAsCash({
      type: 'playAsCash',
      card: this.datasource,
    })
  }
}

export class BirthdayCard extends ActionCard<TBirthdayCardData> {
  public availableMovesFromHand({playerGamePosition}: TAvailableMovesData) {
    // only show as an option if someone has something to take
    if (playerGamePosition.opponentPortfoliosWithValue().length > 0) {
      return [
        new MajorActionBirthday({
          type: 'birthday',
          card: this.datasource,
        }),
        this.playAsCashAction(),
      ]
    } else {
      return this.playAsCashAction()
    }
  }
}

export class DealBreakerCard extends ActionCard<TDealBreakerCardData> {
  public availableMovesFromHand({
    playerGamePosition,
  }: TAvailableMovesData): AnyMajorAction[] {
    return [
      ...playerGamePosition
        .opponentPortfolios()
        .map(portfolio =>
          portfolio.completeSets().map(
            set =>
              new MajorActionDealBreaker({
                type: 'dealBreaker',
                card: this.datasource,
                minorActorPlayerId: portfolio.playerId,
                portfolioNeighborhoodId: set.id,
              })
          )
        )
        .flat(1),
      this.playAsCashAction(),
    ]
  }
}

export class DebtCollectorCard extends ActionCard<TDebtCollectorCardData> {
  public availableMovesFromHand({playerGamePosition}: TAvailableMovesData) {
    return [
      ...playerGamePosition.opponentPortfoliosWithValue().map(
        p =>
          new MajorActionDebtCollector({
            type: 'debtCollector',
            card: this.datasource,
            minorActorPlayerId: p.playerId,
          })
      ),
      this.playAsCashAction(),
    ]
  }
}

export class DoubleTheRentCard extends ActionCard<TDoubleTheRentCardData> {
  public availableMovesFromHand({playerGamePosition}: TAvailableMovesData) {
    const actions: AnyMajorAction[] = [this.playAsCashAction()]

    // NB: we shouldn't call this availableMovesFromHand when play === 'discardPlay',
    // nothing prevents that right now i guess, and the types don't either
    const currentPlay = playerGamePosition.play
    if (currentPlay === 'firstPlay' || currentPlay === 'discardPlay') {
      return actions
    }

    const {lastRentThisVisit} = playerGamePosition
    if (
      lastRentThisVisit &&
      playerGamePosition.opponentPortfoliosWithValue().length
    ) {
      actions.unshift(
        new MajorActionDoubleTheRent(
          {
            type: 'doubleTheRent',
            card: this.datasource,
            // having to set this is kinda dumb, it's a byproduct of reusing the "completed" TMajorActionData
            // objects for the TCreateMajorAction API.
            portfolioNeighborhoodId: lastRentThisVisit.portfolioNeighborhood.id,
          },
          lastRentThisVisit
        )
      )
    }

    return actions
  }
}

export class ForcedDealCard extends ActionCard<TForcedDealCardData> {
  public availableMovesFromHand({playerGamePosition}: TAvailableMovesData) {
    // TODO or portfolio.propertiesNotInSets() ?
    // https://boardgames.stackexchange.com/q/51140/32400
    // https://mercurytechnologies.slack.com/archives/C90N3EVND/p1589146333097500
    const myProperties = playerGamePosition.currentPlayer.portfolio.allProperties()

    const actions = playerGamePosition
      .opponentPortfolios()
      .map(oPort =>
        oPort.propertiesNotInSets().map(op =>
          myProperties.map(
            mp =>
              new MajorActionForcedDeal({
                type: 'forcedDeal',
                card: this.datasource,
                minorActorPlayerId: oPort.playerId,
                offeredCard: mp.datasource,
                requestedCard: op.datasource,
              })
          )
        )
      )
      .flat(2)

    return [...actions, this.playAsCashAction()]
  }
}
export class JustSayNoCard extends ActionCard<TJustSayNoCardData> {
  public availableMovesFromHand() {
    return this.playAsCashAction()
  }
}

export class PassGoCard extends ActionCard<TPassGoCardData> {
  public availableMovesFromHand() {
    return [
      new MajorActionPassGo({
        type: 'passGo',
        card: this.datasource,
      }),
      this.playAsCashAction(),
    ]
  }
}

export class SlyDealCard extends ActionCard<TSlyDealCardData> {
  public availableMovesFromHand({playerGamePosition}: TAvailableMovesData) {
    return [
      ...playerGamePosition
        .opponentPortfolios()
        .map(oPort =>
          oPort.propertiesNotInSets().map(
            op =>
              new MajorActionSlyDeal({
                type: 'slyDeal',
                card: this.datasource,
                minorActorPlayerId: oPort.playerId,
                requestedCard: op.datasource,
              })
          )
        )
        .flat(1),
      this.playAsCashAction(),
    ]
  }
}
