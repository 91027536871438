// Autogenerated, edits will not be preserved

export const serverEndpoints = {
  // GET
  robots(): string {
    return `/robots.txt`
  },

  // GET
  home(): string {
    return `/`
  },

  // POST
  signin(): string {
    return `/signin`
  },

  // POST
  signup(): string {
    return `/signup`
  },

  // POST
  logout(): string {
    return `/logout`
  },

  // GET
  getInitialData(): string {
    return `/get-player-data`
  },

  // GET
  dashboardData(): string {
    return `/dashboard-data`
  },

  // POST
  markGameAsStale(pathPieces: {gameId: string}): string {
    return `/game/${pathPieces.gameId}/mark-stale`
  },

  // POST
  createGame(): string {
    return `/create-game`
  },

  // GET POST
  createGameSlack(): string {
    return `/create-game-slack`
  },

  // POST
  joinGame(pathPieces: {gameId: string}): string {
    return `/join-game/${pathPieces.gameId}`
  },

  // POST
  startGame(pathPieces: {gameId: string}): string {
    return `/start-game/${pathPieces.gameId}`
  },

  // POST
  gameWaitingRoom(pathPieces: {gameId: string}): string {
    return `/waiting-room/${pathPieces.gameId}`
  },

  // POST
  kickFromWaitingRoom(pathPieces: {gameId: string; playerId: string}): string {
    return `/waiting-room/${pathPieces.gameId}/kick-player/${pathPieces.playerId}`
  },

  // POST
  getGameData(pathPieces: {gameId: string}): string {
    return `/game/${pathPieces.gameId}/get-game-data`
  },

  // POST
  submitMajorAction(pathPieces: {gameId: string}): string {
    return `/game/${pathPieces.gameId}/submit-major-action`
  },

  // POST
  submitMinorAction(pathPieces: {gameId: string}): string {
    return `/game/${pathPieces.gameId}/submit-minor-action`
  },

  // POST
  requestSpectatorPermission(pathPieces: {
    gameId: string
    playerId: string
  }): string {
    return `/game/${pathPieces.gameId}/spectate-request/${pathPieces.playerId}`
  },

  // POST
  approveSpectatorRequest(pathPieces: {gameId: string; playerId: string}): string {
    return `/game/${pathPieces.gameId}/spectate-approve/${pathPieces.playerId}`
  },

  // POST
  revokeSpectatorPermission(pathPieces: {gameId: string; playerId: string}): string {
    return `/game/${pathPieces.gameId}/spectate-revoke/${pathPieces.playerId}`
  },

  // POST
  adminAdminSignUps(): string {
    return `/admin/signups`
  },

  // POST
  adminAdminApproveSignUp(pathPieces: {signupRequestId: string}): string {
    return `/admin/signup/${pathPieces.signupRequestId}/approve`
  },
}
