import {BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID} from '~/api/types'
import GamePosition from '~/models/game/GamePosition'
import {TMajorActionUserFacingDescriptionData} from '~/models/game/MajorAction'
import Portfolio from '~/models/game/Portfolio'
import PortfolioNeighborhood from '~/models/game/PortfolioNeighborhood'
import {compact} from '~/utils'

type TUnambiguousNeighborhoodDescriptionData = {
  portfolioNeighborhood: PortfolioNeighborhood
  sameColorCount: number
  sameColorIndex: number
}

// For a given TMajorActionUserFacingDescriptionData and PortfolioNeighborhood's ID,
// returns some data describing the neighborhood's ambiguity or uniqueness
// for the purposes of displaying disambiguated information to the user.
// e.g. if there is more than one Red neighborhood, you can label them #1 and #2
// returns undefined if the neighborhood can't be found (including if BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID)
export const mUnambiguousNeighborhoodDescriptionDataFromGamePosition = (
  gamePosition: GamePosition,
  portfolioNeighborhoodId: string
): TUnambiguousNeighborhoodDescriptionData | undefined => {
  let portfolioNeighborhood: PortfolioNeighborhood | undefined = undefined
  const portfolio = gamePosition.portfolios.find(p =>
    p.neighborhoods.find(pn => {
      if (pn.id === portfolioNeighborhoodId) {
        portfolioNeighborhood = pn
        return true
      }
    })
  )

  if (!portfolioNeighborhood || !portfolio) {
    return undefined
  }

  return unambiguousNeighborhoodDescriptionData(portfolio, portfolioNeighborhood)
}

// assumes you've verified that the neighborhood is in the portfolio
export const unambiguousNeighborhoodDescriptionData = (
  portfolio: Portfolio,
  portfolioNeighborhood: PortfolioNeighborhood
): TUnambiguousNeighborhoodDescriptionData => {
  const sameColorNeighborhoods = portfolio
    .sortedNeighborhoods()
    .filter(pn => pn.neighborhood === portfolioNeighborhood.neighborhood)
  const sameColorIndex = sameColorNeighborhoods.findIndex(
    pn => pn.id === portfolioNeighborhood.id
  )
  return {
    portfolioNeighborhood,
    sameColorCount: sameColorNeighborhoods.length,
    sameColorIndex,
  }
}

// For a given TMajorActionUserFacingDescriptionData and PortfolioNeighborhood's ID,
// returns some data describing the neighborhood's ambiguity or uniqueness
// for the purposes of displaying disambiguated information to the user.
// e.g. if there is more than one Red neighborhood, you can label them #1 and #2
// NB: check for `BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID` first, this will cause an exception
const unambiguousNeighborhoodDescriptionFromGamePositionDataThrows = (
  gamePosition: GamePosition,
  portfolioNeighborhoodId: string
): TUnambiguousNeighborhoodDescriptionData => {
  const data = mUnambiguousNeighborhoodDescriptionDataFromGamePosition(
    gamePosition,
    portfolioNeighborhoodId
  )
  if (!data) {
    console.error('couldnt find portfolio or neighborhood', {
      portfolioNeighborhoodId,
      gamePosition,
    })
    throw new Error('couldnt build description')
  }

  return data
}

type TUnambiguousDescData = Pick<
  TMajorActionUserFacingDescriptionData,
  'skin' | 'gamePosition'
>

// For a given TMajorActionUserFacingDescriptionData and PortfolioNeighborhood's ID,
// returns a string describing the neighborhood, where the string has a numerical suffix to
// identify the neighborhood in the case that the player who owns the neighborhood has more
// than one neighborhood of that color.
export const unambiguousNeighborhoodDescription = (
  {skin, gamePosition}: TUnambiguousDescData,
  portfolioNeighborhoodId: string
): string => {
  if (portfolioNeighborhoodId === BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID) {
    return `its own neighborhood`
  }

  const {
    portfolioNeighborhood,
    sameColorCount,
    sameColorIndex,
  } = unambiguousNeighborhoodDescriptionFromGamePositionDataThrows(
    gamePosition,
    portfolioNeighborhoodId
  )
  const color = portfolioNeighborhood.displayColor(skin)

  return compact([
    color,
    `neighborhood`,
    sameColorCount > 1 && `#${sameColorIndex + 1}`,
  ]).join(' ')
}
