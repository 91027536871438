import classnames from 'classnames'
import * as React from 'react'
import {OmitStrict} from 'type-zoo'
import {ColorName, colorNameToColorClass} from '../Colors'
import styles from './styles.module.css'

export type TextSize =
  // Headers, largest to smallest
  | 'mega'
  | 'jumbo'
  | 'super-hero'
  | 'hero'
  | 'h1'
  | 'h2'
  | 'h3' // not currently in DS but markdown renderer uses it
  | 'section-title'
  // Body text, largest to smallest
  | 'subheader-demi'
  | 'subheader'
  | 'body-17-demi'
  | 'body-17'
  | 'body-16-demi'
  | 'body-16'
  | 'body-15-demi'
  | 'body-15'
  | 'caption-demi'
  | 'caption'
  | 'label-demi'
  | 'label'
  | 'tiny-demi'
  | 'tiny'
  | 'cent'
  | 'micro-demi'
  | 'micro'

/** For convenience in e.g. showing all sizes in Storybook */
export const allSizes: TextSize[] = [
  // Headers, largest to smallest
  'mega',
  'jumbo',
  'super-hero',
  'hero',
  'h1',
  'h2',
  'h3',
  'section-title',
  // Body text, largest to smallest
  'subheader-demi',
  'subheader',
  'body-17-demi',
  'body-17',
  'body-16-demi',
  'body-16',
  'body-15-demi',
  'body-15',
  'caption-demi',
  'caption',
  'label-demi',
  'label',
  'tiny-demi',
  'tiny',
  'cent',
  'micro-demi',
  'micro',
]

interface IGenericDSTextProps {
  size: TextSize
  color?: ColorName
  hoverColor?: ColorName
  underline?: boolean
  bold?: boolean
  italic?: boolean
}

interface IGenericDSTextState {
  hovered: boolean
}

export interface IDSTextProps<TDSTextElement extends HTMLElement = HTMLSpanElement>
  extends IGenericDSTextProps,
    OmitStrict<React.HTMLAttributes<TDSTextElement>, 'color'> {
  // The default is 'span'.
  tag?: keyof React.ReactHTML
}

export default class DSText<
  TDSTextElement extends HTMLElement = HTMLSpanElement
> extends React.Component<IDSTextProps<TDSTextElement>, IGenericDSTextState> {
  state: IGenericDSTextState = {
    hovered: false,
  }

  render() {
    const {
      size,
      color,
      hoverColor,
      underline,
      bold,
      italic,
      className,
      tag = 'span',
      ...elementProps
    } = this.props

    // don't default to 'black' here, we do that in global.css
    // instead, allow users to use the cascade to set colors if necessary/nice
    const colorToUse = (this.state.hovered && hoverColor) || color
    return React.createElement(tag, {
      ...elementProps,
      onMouseEnter: (e: React.MouseEvent<TDSTextElement>) => {
        this.setState({hovered: true})
        elementProps.onMouseEnter?.(e)
      },
      onMouseLeave: (e: React.MouseEvent<TDSTextElement>) => {
        this.setState({hovered: false})
        elementProps.onMouseLeave?.(e)
      },
      className: classnames(
        styles[size],
        underline && styles['underline'],
        bold && styles['force-demi'],
        italic && styles['italic'],
        colorToUse && colorNameToColorClass(colorToUse),
        className
      ),
    })
  }
}

export interface IDSTextDivProps
  extends OmitStrict<IDSTextProps<HTMLDivElement>, 'tag'> {}

export const DSTextDiv = (props: IDSTextDivProps) => <DSText {...props} tag="div" />

interface IHeaderProps extends OmitStrict<IDSTextDivProps, 'size'> {}

export class H2 extends React.Component<IHeaderProps> {
  render() {
    return (
      <DSTextDiv
        size="h2"
        className={classnames(this.props.className, styles['h2-el'])}
        {...this.props}
      >
        {this.props.children}
      </DSTextDiv>
    )
  }
}

export class SectionName extends React.Component<IHeaderProps> {
  render() {
    return (
      <DSTextDiv
        size="section-title"
        className={classnames(this.props.className, styles['section-name-el'])}
        {...this.props}
      >
        {this.props.children}
      </DSTextDiv>
    )
  }
}
