import * as R from 'ramda'
import {customProperties} from '~/styles/cssVariables'
import {recordKeys} from '~/utils'
import styles from './styles.module.css'

export type ColorName =
  // Primary
  | 'blue'
  | 'blue-lighter'
  | 'blue-light'
  | 'blue-light-hover'
  | 'blue-dark'
  | 'blue-dark-hover'
  // Supporting
  | 'mauve'
  | 'green-money-in'
  // Monochromes
  | 'white'
  | 'off-white'
  | 'off-white-darker'
  | 'pearl-grey'
  | 'pearl-grey-medium'
  | 'pearl-grey-dark'
  | 'pearl-grey-darker'
  | 'pearl-grey-darkest'
  | 'grey'
  | 'grey-medium'
  | 'grey-dark'
  | 'black'
  // Supplementary Colors
  | 'green-light'
  | 'green'
  | 'green-dark'
  | 'red-dark'
  | 'red-light'
  | 'red'
  // Table/Dropdown Highlight colors
  | 'highlight'
  | 'highlight-dark'
  | 'highlight-darker'
  // Poker table coloring
  | 'poker-green'
  | 'poker-green-darker'
  | 'poker-green-lighter'

/** To allow iterating through all colors in e.g. storybook */
export const allColors = recordKeys<ColorName>({
  'blue': true,
  'blue-lighter': true,
  'blue-light': true,
  'blue-light-hover': true,
  'blue-dark': true,
  'blue-dark-hover': true,
  'mauve': true,
  'green-money-in': true,
  'white': true,
  'off-white': true,
  'off-white-darker': true,
  'pearl-grey': true,
  'pearl-grey-medium': true,
  'pearl-grey-dark': true,
  'pearl-grey-darker': true,
  'pearl-grey-darkest': true,
  'grey': true,
  'grey-medium': true,
  'grey-dark': true,
  'black': true,
  'green-light': true,
  'green': true,
  'green-dark': true,
  'red-light': true,
  'red': true,
  'red-dark': true,
  'highlight': true,
  'highlight-dark': true,
  'highlight-darker': true,
  'poker-green': true,
  'poker-green-darker': true,
  'poker-green-lighter': true,
})

/** Safe cast from string to color name
 * Useful if e.g. a user is selecting a color from a UI input in storybook
 */
export const stringToColorNameThrows = (s: string): ColorName => {
  if (R.contains(s, allColors)) {
    return s as ColorName
  } else {
    throw new Error(`Invalid string for converting to a ColorName: ${s}`)
  }
}

export const colorNameToColorClass = (name: ColorName): string => {
  return styles[name]
}

export const colorNameToBackgroundColorClass = (name: ColorName): string => {
  return styles[`background-${name}`]
}

export const colorNameToHex = (name: ColorName): string => {
  // @ts-ignore
  const color = customProperties[`ds-${name}`]
  if (color) {
    return color
  } else {
    throw new Error(
      `Invalid ColorName: ${name}. Check that the color name is correct and that it is in the shared-variables file.`
    )
  }
}
