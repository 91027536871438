import {TCardsInHand} from '~/api/generated/types/common'
import Hand from '~/models/game/Hand'

export default class ConcealedHand extends Hand {
  private numberOfCards: number

  constructor(handData: TCardsInHand & {type: 'concealed'}) {
    super()
    this.numberOfCards = handData.cards
  }

  public cardCount(): number {
    return this.numberOfCards
  }
}
