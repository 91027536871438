import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import PastGamePosition from '~/models/game/PastGamePosition'
import * as M from '~/utils/Map'

type TPastGamePositionStateData = {
  // key: timestamp/etc
  pastGamePositionsMap: Map<string, PastGamePosition>
}

export type PastGamePositionsState = {
  // key: gameId
  games: Map<string, TPastGamePositionStateData>
}

type TAddToGamePayload = {
  gameId: string
  pastGamePositions: PastGamePosition[]
}

const initialState: PastGamePositionsState = {
  games: new Map(),
}

const pastGamePositionsSlice = createSlice({
  name: 'pastGamePositions',
  initialState,
  reducers: {
    addPastGamePositionsForGame(
      // I guess Redux (and/or its typings) doesn't like something about my model
      // class instances in here, but it works, so too bad.
      _state: any, // PastGamePositionsState
      action: PayloadAction<TAddToGamePayload>
    ) {
      const state = _state as PastGamePositionsState

      let games: Map<string, TPastGamePositionStateData> = M.clone(state.games)
      let pastGamePositionsMap = M.clone(
        games.get(action.payload.gameId)?.pastGamePositionsMap ?? new Map()
      )
      pastGamePositionsMap = M.addEntries(
        action.payload.pastGamePositions.map(pgp => [
          pgp.date.toISOString() + pgp.gamePosition.time,
          pgp,
        ]),
        pastGamePositionsMap
      )

      games = M.set(action.payload.gameId, {pastGamePositionsMap}, games)

      return {games}
    },
  },
})

const {addPastGamePositionsForGame} = pastGamePositionsSlice.actions
export {addPastGamePositionsForGame}
export default pastGamePositionsSlice.reducer
