import * as React from 'react'
import {connect} from 'react-redux'
import {ReduxState} from '~/redux/reducers/root'
import {Redirect} from '~/routing/ReactRouterLinks'
import {DSButtonLink} from '~/design-system/Button'
import FEMLayout from '~/layouts/FEMLayout'
import {staticURLs} from '~/routing/sitemap'
import styles from './styles.module.css'

interface IReduxProps extends Pick<ReduxState, 'userState'> {}

class HomePage extends React.Component<IReduxProps> {
  render() {
    const signedIn = !!this.props.userState.user
    return (
      <FEMLayout>
        {signedIn ? (
          <Redirect to={staticURLs.dashboard} />
        ) : (
          <div className={styles.signinSignupButtons}>
            <DSButtonLink
              buttonStyle="primary"
              size="19px"
              to={staticURLs.auth.signin}
              underline={false}
            >
              Sign In
            </DSButtonLink>
            <DSButtonLink
              buttonStyle="secondary"
              size="19px"
              to={staticURLs.auth.signup}
              underline={false}
            >
              Sign Up
            </DSButtonLink>
          </div>
        )}
      </FEMLayout>
    )
  }
}

const mapStateToProps = (state: ReduxState): IReduxProps => ({
  userState: state.userState,
})

export default connect(mapStateToProps)(HomePage)
