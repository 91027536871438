import * as R from 'ramda'
import * as React from 'react'
import classnames from 'classnames'
import {TSubmitMinorActionResponse} from '~/api/generated/types/SubmitMinorAction'
import {BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID} from '~/api/types'
import ProvideForm from '~/components/ProvideForm/normal'
import {FormRadioButtonGroup, RadioButton} from '~/components/RadioButtonGroup'
import {SingleButtonForm} from '~/components/SingleButtonForm'
import {FormToggleGroup} from '~/components/ToggleGroup'
import DSButton from '~/design-system/Button'
import DSText, {DSTextDiv} from '~/design-system/DSText'
import {DSLinkText} from '~/design-system/Link'
import {TRuleset} from '~/game/ruleset'
import {TSkin} from '~/game/skins'
import {cardsPerSet} from '~/game/types'
import {
  getObviousPayment,
  groupedCardDisplayNames,
  paymentPropriety,
} from '~/game/utils'
import PropertyWildCard from '~/models/game/Cards/PropertyWildCard'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import InProgressMajorAction from '~/models/game/InProgressMajorAction'
import {unambiguousNeighborhoodDescription} from '~/models/game/MajorActions/utils'
import MinorAction from '~/models/game/MinorAction'
import MinorActionAcceptForcedDeal from '~/models/game/MinorActions/MinorActionAcceptForcedDeal'
import MinorActionPaySomething from '~/models/game/MinorActions/MinorActionPaySomething'
import StandardPortfolioNeighborhood from '~/models/game/PortfolioNeighborhoods/StandardPortfolioNeighborhood'
import WildCardPortfolioNeighborhood from '~/models/game/PortfolioNeighborhoods/WildCardPortfolioNeighborhood'
import {switchOnPropertyCard} from '~/models/game/types'
import {valueOfCards} from '~/models/game/utils'
import {show$} from '~/utils/language'
import * as S from '~/utils/Set'
import styles from './styles.module.css'

type TAcceptForcedDealForm = {destinationPNId: string}

interface IProps {
  chooseMinorAction: (
    minorAction: MinorAction<any>
  ) => Promise<TSubmitMinorActionResponse>
  chooseMinorActionSubmitSuccess: (response: TSubmitMinorActionResponse) => void
  skin: TSkin
  rules: TRuleset
  playerGamePosition: PlayerGamePosition
  inProgressMajorAction: InProgressMajorAction
  selectedCardIds: Set<string>
}

interface IState {
  payForMinorAction?: MinorActionPaySomething<any>
  destinationForAcceptedForcedDeal?: MinorActionAcceptForcedDeal
  gamePositionHash?: string
}

export default class PendingMinorActionUI extends React.Component<IProps, IState> {
  state: IState = {}

  // don't make the user click the only action if it requires further input
  static getDerivedStateFromProps(
    nextProps: IProps,
    prevState: IState
  ): Partial<IState> | null {
    const {playerGamePosition, inProgressMajorAction, rules} = nextProps

    if (playerGamePosition.hash === prevState.gamePositionHash) {
      // already set this default once, don't override player's wishes anymore
      return null
    }
    const retVal: Partial<IState> = {
      gamePositionHash: playerGamePosition.hash,
    }

    const {currentPlayer} = playerGamePosition
    const currentUserId = currentPlayer.id

    // you can see your options for a minor action you're going to be asked about soon ('eventual'),
    // but you can only act on it if it is your turn to act (`GamePosition.isMyMinorTurn`)
    const myEventualPendingMinorAction = inProgressMajorAction?.pendingMinorActions.find(
      pma => pma.minorActorPlayerId === currentUserId
    )

    const validResponses =
      myEventualPendingMinorAction?.validResponses({
        portfolio: currentPlayer.portfolio,
        gamePosition: playerGamePosition,
        inProgressMajorAction,
        hand: currentPlayer.hand,
        rules,
      }) ?? []

    if (validResponses.length === 1) {
      const r = validResponses[0]
      if (r instanceof MinorActionPaySomething) {
        return {...retVal, payForMinorAction: r}
      } else if (r instanceof MinorActionAcceptForcedDeal) {
        return {...retVal, destinationForAcceptedForcedDeal: r}
      }
    }
    return retVal
  }

  renderPayForMinorAction = (payForMinorAction: MinorActionPaySomething<any>) => {
    const {
      playerGamePosition,
      inProgressMajorAction,
      selectedCardIds,
      skin,
    } = this.props
    const {currentPlayer} = playerGamePosition
    const currentUserId = currentPlayer.id

    const properPaymentData = paymentPropriety(
      payForMinorAction,
      playerGamePosition,
      selectedCardIds
    )

    const obviousPayment = getObviousPayment(payForMinorAction, playerGamePosition)

    return (
      <div
        className={classnames(
          styles.minorActionPrompt
          // this.state.submittingMinorActionReply && styles.loading
        )}
      >
        <DSTextDiv size="body-16" bold>
          <DSLinkText
            size="body-16"
            underline="onHover"
            color="blue"
            onClick={() => this.setState({payForMinorAction: undefined})}
          >
            &lt;
          </DSLinkText>{' '}
          You've chosen to pay.
          <br />
          <br />
          {obviousPayment && (
            <>
              <SingleButtonForm
                onSubmit={() => {
                  payForMinorAction.paidWithCards = obviousPayment.paidWith
                  return this.props.chooseMinorAction(payForMinorAction)
                }}
                submitSuccess={this.props.chooseMinorActionSubmitSuccess}
                size="15px"
                buttonStyle="primary"
                disabled={
                  inProgressMajorAction.pendingMinorActions[0]
                    ?.minorActorPlayerId !== currentUserId
                }
              >
                Make Suggested Payment (
                {groupedCardDisplayNames(obviousPayment.paidWith, skin).join(', ')})
              </SingleButtonForm>
              <br />
              Or,{' '}
            </>
          )}
          {obviousPayment ? 's' : 'S'}elect cards in front of you on the table to
          make a payment with.
          <br />
          {properPaymentData.type !== 'proper' && S.nonempty(selectedCardIds) && (
            <span>
              <br />
              The selected cards don't constitute a valid payment.{' '}
              {properPaymentData.type === 'tooManyCards' &&
                `There are too many cards selected (${show$(
                  properPaymentData.currentPayment
                )}).`}
              {properPaymentData.type === 'moreMoneyOwed' &&
                `There are too few cards selected (${show$(
                  properPaymentData.currentPayment
                )}).`}
              {properPaymentData.type === 'invalidCardSelected' &&
                `You've chosen an invalid card for payment.`}
            </span>
          )}
          {properPaymentData.type === 'proper' && (
            <SingleButtonForm
              onSubmit={() => {
                payForMinorAction.paidWithCards = properPaymentData.paidWith
                return this.props.chooseMinorAction(payForMinorAction)
              }}
              submitSuccess={this.props.chooseMinorActionSubmitSuccess}
              size="15px"
              buttonStyle="primary"
              disabled={
                inProgressMajorAction.pendingMinorActions[0]?.minorActorPlayerId !==
                currentUserId
              }
            >
              Pay {show$(valueOfCards(properPaymentData.paidWith))} (
              {groupedCardDisplayNames(properPaymentData.paidWith, skin).join(', ')})
            </SingleButtonForm>
          )}
        </DSTextDiv>
      </div>
    )
  }

  // TODO maybe refactor this out into the MinorActionAcceptForcedDeal class - is there an appropriate abstract method
  //   to make on MinorAction/MinorActionPrompt like `availableMovesFromHand` to handle this and other minor actions?
  renderDestinationForAcceptedForcedDeal = (
    acceptedForcedDeal: MinorActionAcceptForcedDeal
  ) => {
    const {playerGamePosition, inProgressMajorAction, skin} = this.props
    const {currentPlayer} = playerGamePosition
    const currentUserId = currentPlayer.id
    const offeredCard = acceptedForcedDeal.offeredCard

    const stolenFromNeighborhood = currentPlayer.portfolio.neighborhoods.find(pn =>
      pn.properties.find(c => c.id === acceptedForcedDeal.requestedCard.id)
    )

    if (!stolenFromNeighborhood) {
      throw new Error(
        `couldn't find target neighborhood containing the requestedCard of a forced deal`
      )
    }

    // a forced deal removes a card from a neighborhood, and gives us a card.
    // the given card can go into:
    //
    // 1. if it has room, a neighborhood of the same type
    //    - note that if e.g. a solo green/darkBlue is taken and replaced by a green, we first exclude the green/dkBlue
    //      neighborhood that will disappear, and then (3) below will add a new BY_ITESELF... option for it
    // 2. if it has room*, an alone neighborhood (it can join a PWC)
    //    * this gets tricky if the alone neighborhood has 2 PWCs in it, because in that scenario a 2-cards-per-set
    //      colored card (darkBlue/brown/utility) can't join the PWCs! Do you let them move a PWC to join the
    //      new brown/utility/darkBlue? Probably not, kinda complex. We don't allow it here for now.
    // 3. a (new) neighborhood of its own,
    //   - for standard properties, iff there are no incompleteSets of its type available
    //   - for dual properties, iff either/both of its colors aren't in incompleteSets
    //   - for PWCs, if there are no incompleteSets at all
    //
    // some discussion: https://mercurytechnologies.slack.com/archives/C012QHJQQHY/p1600740002032100

    // TODO things not handled here:
    //  a) choosing the orientation of an offered DualPropertyCard if it joins PWC(s) in an 'alone' neighborhood
    //  b) choosing the orientation of an offered DualPropertyCard if it goes into it's own, new neighborhood
    //     (though the code below sets a `StandardPortfolioNeighborhood.neighborhood` here, that value is not
    //     used in the TMinorActionDataAcceptForcedDeal type, just the BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID)

    const targetNeighborhoods = currentPlayer.portfolio
      .incompleteSets()
      .filter(pn => {
        if (
          pn.id === stolenFromNeighborhood.id &&
          stolenFromNeighborhood.properties.length === 1
        ) {
          // this neighborhood is about to be gone, so we can't add the offeredCard to it - (1)'s note above
          return false
        }

        // case 2
        if (pn.neighborhood === 'alone') {
          return (
            offeredCard instanceof PropertyWildCard ||
            // only if there is room for the card to join the PWCs (for 2-card-sets as noted above)
            // TODO and even then - (a) above - a utility/railroad dual card should only join as a railroad (no room
            //  for util) but the API doesn't have a `chosenOrientation?: TStandardPropertyType` for us to set (yet?)
            S.anyPass(
              cardType => pn.properties.length < cardsPerSet[cardType],
              offeredCard.cardTypes()
            )
          )
        }

        // else, case 1
        return offeredCard.cardTypes().has(pn.neighborhood)
      })

    // case 3, a (new) neighborhood of its own:
    switchOnPropertyCard<void>({
      //   - for standard properties, iff there are no incompleteSets of its type available
      whenStandard: spc => {
        if (R.none(pn => spc.type === pn.neighborhood, targetNeighborhoods)) {
          targetNeighborhoods.push(
            new StandardPortfolioNeighborhood({
              id: BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID,
              neighborhood: spc.type,
              properties: [],
              residences: [],
            })
          )
        }
      },
      //   - for dual properties, iff either/both of its colors aren't in incompleteSets
      whenDual: dpc => {
        // which of the Dual neighborhoods are missing in targetNeighborhoods?
        S.filter(
          dpcType =>
            targetNeighborhoods.filter(pn => pn.neighborhood === dpcType).length ===
            0,
          dpc.standardPropertyTypes()
        ).forEach(missingNeighborhood =>
          targetNeighborhoods.push(
            new StandardPortfolioNeighborhood({
              id: BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID,
              // TODO (b) above notes this isn't used in the TMinorActionDataAcceptForcedDeal yet, so
              //  the orientation isn't selectable. Unfortunate.
              neighborhood: missingNeighborhood,
              properties: [],
              residences: [],
            })
          )
        )
      },
      //   - for PWCs, if there are no incompleteSets at all
      whenWild: wpc => {
        if (targetNeighborhoods.length === 0) {
          targetNeighborhoods.push(
            new WildCardPortfolioNeighborhood({
              id: BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID,
              neighborhood: 'alone',
              properties: [],
            })
          )
        }
      },
    })(offeredCard)

    const allChoices = S.create(targetNeighborhoods.map(pn => pn.id))
    const humanChoices = Object.fromEntries(
      targetNeighborhoods.map(pn => [
        pn.id,
        unambiguousNeighborhoodDescription(
          {skin, gamePosition: playerGamePosition},
          pn.id
        ),
      ])
    )

    return (
      <div
        className={classnames(
          styles.minorActionPrompt
          // this.state.submittingMinorActionReply && styles.loading
        )}
      >
        <DSTextDiv size="body-16" bold>
          <DSLinkText
            size="body-16"
            underline="onHover"
            color="blue"
            onClick={() =>
              this.setState({destinationForAcceptedForcedDeal: undefined})
            }
          >
            &lt;
          </DSLinkText>{' '}
          You've chosen to accept the forced deal.
          <br />
          Select where the card you are receiving will go.
          <br />
          <br />
          {inProgressMajorAction.pendingMinorActions[0]?.minorActorPlayerId ===
            currentUserId && (
            <ProvideForm<TAcceptForcedDealForm, TSubmitMinorActionResponse>
              onSubmit={formState => {
                const destinationPNId = FormToggleGroup.formValueToFirstSelection(
                  formState.destinationPNId
                )!
                acceptedForcedDeal.datasource.placedInPortfolioNeighborhoodId = destinationPNId
                return this.props.chooseMinorAction(acceptedForcedDeal)
              }}
              submitSuccess={(_, r) => this.props.chooseMinorActionSubmitSuccess(r)}
              toFormFields={(generateFormFieldProps, formProps) => (
                <div className={styles['radio-list']}>
                  <FormRadioButtonGroup<string, TAcceptForcedDealForm>
                    allChoices={allChoices}
                    initialChoice={
                      allChoices.size === 1 ? S.first(allChoices) : undefined
                    }
                    allowDeselection
                    generateFormFieldProps={generateFormFieldProps}
                    formProps={formProps}
                    fieldName="destinationPNId"
                    humanChoices={humanChoices}
                    renderRadioButton={radioButtonProps => (
                      <RadioButton
                        {...radioButtonProps}
                        key={radioButtonProps.choice}
                        toggleStyle="radio"
                        classNames={{
                          choice: styles['radio-card'],
                          selectedChoice: styles['selected'],
                          toggle: styles['radio-checkbox'],
                          fadedToggle: styles['faded-toggle'],
                          choiceLabel: styles['radio-label'],
                        }}
                        label={{
                          size: 'body-15',
                          fadedColor: 'pearl-grey-darkest',
                        }}
                      />
                    )}
                  />
                  <DSButton
                    onClick={() => formProps.onSubmit()}
                    appearDisabled={formProps.anyErrors}
                    size="15px"
                    buttonStyle="primary"
                    loading={formProps.isFormSubmitting}
                    disabled={formProps.isFormSubmitting}
                  >
                    Accept Forced Deal
                  </DSButton>
                </div>
              )}
            />
          )}
        </DSTextDiv>
      </div>
    )
  }

  render() {
    const {playerGamePosition, inProgressMajorAction, skin, rules} = this.props
    const {currentPlayer} = playerGamePosition
    const currentUserId = currentPlayer.id
    const {payForMinorAction, destinationForAcceptedForcedDeal} = this.state

    if (payForMinorAction) {
      return this.renderPayForMinorAction(payForMinorAction)
    } else if (destinationForAcceptedForcedDeal) {
      return this.renderDestinationForAcceptedForcedDeal(
        destinationForAcceptedForcedDeal
      )
    }

    // you can see your options for a minor action you're going to be asked about soon ('eventual'),
    // but you can only act on it if it is your turn to act (`GamePosition.isMyMinorTurn`)
    const myEventualPendingMinorAction = inProgressMajorAction?.pendingMinorActions.find(
      pma => pma.minorActorPlayerId === currentUserId
    )

    if (myEventualPendingMinorAction && inProgressMajorAction) {
      return (
        <div
          className={classnames(
            styles.minorActionPrompt
            // this.state.submittingMinorActionReply && styles.loading
          )}
        >
          <DSTextDiv size="body-16" bold className={styles.minorActionPromptTitle}>
            {playerGamePosition.isMyMinorTurn()
              ? 'It is your turn to act, choose your response:'
              : '(Not your turn yet, but) Consider your response:'}
          </DSTextDiv>
          <ul>
            {myEventualPendingMinorAction
              ?.validResponses({
                portfolio: currentPlayer.portfolio,
                gamePosition: playerGamePosition,
                inProgressMajorAction,
                hand: currentPlayer.hand,
                rules,
              })
              .map(minorAction => {
                const desc = minorAction.userFacingDescription({
                  gamePosition: playerGamePosition,
                  inProgressMajorAction,
                  majorActor: playerGamePosition.majorActorPlayer,
                  minorActor: currentPlayer,
                  skin,
                  tense: 'imperativePrompt',
                })
                const moreInputNeeded =
                  minorAction instanceof MinorActionPaySomething ||
                  minorAction instanceof MinorActionAcceptForcedDeal

                return (
                  <DSText
                    size="body-15"
                    tag="li"
                    key={desc}
                    className={classnames(
                      styles.actionChoice,
                      styles[minorAction.type]
                    )}
                    onClick={() => {
                      if (minorAction instanceof MinorActionPaySomething) {
                        // if PaySomething, ask what cards to pay with
                        this.setState({payForMinorAction: minorAction})
                      } else if (
                        minorAction instanceof MinorActionAcceptForcedDeal
                      ) {
                        // if AcceptForcedDeal, ask where to put the new property
                        //   see MinorActionPromptForcedDeal
                        //   see MinorActionAcceptForcedDeal.placedInPortfolioNeighborhoodId
                        this.setState({
                          destinationForAcceptedForcedDeal: minorAction,
                        })
                      } else {
                        // "one-click" minor actions can't be clicked unless it is your turn
                        // unlike the above that have their own UIs that we let players enter
                        // before their turn
                        if (!playerGamePosition.isMyMinorTurn()) {
                          return
                        }

                        // TODO better loading state and error handling / UI for this promise...
                        this.props
                          .chooseMinorAction(minorAction)
                          .then(r => this.props.chooseMinorActionSubmitSuccess(r))
                          .catch(e => undefined)
                      }
                    }}
                  >
                    {desc}
                    {moreInputNeeded && `...`}
                  </DSText>
                )
              })}
          </ul>
        </div>
      )
    }

    return null
  }
}
