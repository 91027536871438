import * as React from 'react'
import {RouteComponentProps} from 'react-router-dom'
import api from '~/api/endpoints'
import FEMLayout from '~/layouts/FEMLayout'
import SignUpUI from './stateless'

interface IProps extends RouteComponentProps<any> {}

export default class SignUpPage extends React.Component<IProps> {
  render() {
    return (
      <FEMLayout>
        <SignUpUI signUp={form => api.auth.signUp.post(form, {})} />
      </FEMLayout>
    )
  }
}
