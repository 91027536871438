import {toLower} from 'ramda'
import * as React from 'react'
import * as QueryString from 'query-string'
import MD5 from 'crypto-js/md5'
import {OmitStrict} from 'type-zoo'
import {trim} from '~/utils'

interface IProps
  extends OmitStrict<React.ImgHTMLAttributes<HTMLImageElement>, 'src'> {
  email: string
  size: number // 1-2048, in px
}

interface IState {
  hash: string
  options: TGravatarOptions
}

export type TGravatarDefault =
  | '404' // HTTP 404 if not found
  | 'mp' // mystery-person (white, grey-bg, silhouette. doesn't vary w/ hash)
  | 'identicon' // geometric pattern, based on hash
  | 'monsterid' // a weird monster thing, based on hash
  | 'wavatar' // a weird face based on hash
  | 'retro' // 8-bit retro looking "face", based on hash
  | 'robohash' // robot character, based on hash
  | 'blank' // transparent PNG image

// https://en.gravatar.com/site/implement/images/
type TGravatarOptions = {
  d?: TGravatarDefault | string // URL-encoded default image override, for missing gravatars
  rating?: 'g' | 'pg' | 'r' | 'x' // max-allowed rating. `'g'` is the default
  s?: number // size, e.g. 200 for 200x200px. 1-2048. 80px default.
}

export default class Gravatar extends React.Component<IProps, IState> {
  state: IState = {
    hash: '',
    options: {},
  }

  static gravatarHashForEmail(email: string): string {
    return MD5(toLower(trim(email))).toString()
  }

  static getDerivedStateFromProps(
    nextProps: IProps,
    prevState: IState
  ): Partial<IState> | null {
    return {
      hash: Gravatar.gravatarHashForEmail(nextProps.email),
      options: {
        d: 'retro',
        s: nextProps.size * 2, // get higher quality images, we set the <img> size below
        rating: 'pg',
      },
    }
  }

  render() {
    const {email, size, ...imgProps} = this.props
    return (
      <img
        {...imgProps}
        width={imgProps.width ?? size}
        height={imgProps.height ?? size}
        src={`//www.gravatar.com/avatar/${this.state.hash}?${QueryString.stringify(
          this.state.options
        )}`}
      />
    )
  }
}
