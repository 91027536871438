import {PostApi} from '~/api/client'
import GetWaitingRoomData from '~/api/endpoints/game/PreGame/GetWaitingRoomData'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TCreateGameResponse} from '~/api/generated/types/CreateGame'

export type TCreateGameParams = {}
export type TCreateGamePathPieces = {}

/**
 * Create a new game that you can invite others to play in with you.
 */
export default class CreateGame extends PostApi<
  TCreateGameParams,
  TCreateGameResponse,
  TCreateGamePathPieces
> {
  protected getMockResponse(params: TCreateGameParams): TCreateGameResponse {
    GetWaitingRoomData.resetMockPlayerCount()
    return {
      gameId: 'mock-new-game-id',
    }
  }

  protected getPath(pathPieces: TCreateGamePathPieces) {
    return serverEndpoints.createGame()
  }

  protected transformResponse(r: TCreateGameResponse) {
    return r
  }
}
