import {TMinorActionPromptDataSlyDeal} from '~/api/generated/types/common'
import Card from '~/models/game/Card'
import {propertyCardFactory} from '~/models/game/factories'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MinorAction from '~/models/game/MinorAction'
import MinorActionPrompt, {
  TMinorActionPromptUserFacingDescriptionData,
  TMinorActionPromptValidResponseData,
} from '~/models/game/MinorActionPrompt'
import PropertyCard from '~/models/game/Cards/PropertyCard'
import MinorActionAcceptSlyDeal from '~/models/game/MinorActions/MinorActionAcceptSlyDeal'
import Player from '~/models/game/Player'
import {sentenceCase, xVerbY} from '~/utils/language'

export default class MinorActionPromptSlyDeal extends MinorActionPrompt<
  TMinorActionPromptDataSlyDeal
> {
  requestedCard: PropertyCard<any>

  constructor(
    data: TMinorActionPromptDataSlyDeal,
    majorActor: Player,
    minorActor: Player
  ) {
    super(data, majorActor, minorActor)
    this.requestedCard = propertyCardFactory(data.requestedCard)
  }

  protected _validResponses(
    data: TMinorActionPromptValidResponseData
  ): MinorAction<any> | MinorAction<any>[] {
    return this.withJustSayNo(
      data,
      new MinorActionAcceptSlyDeal({
        minorActorPlayerId: this.minorActorPlayerId,
        type: 'slyDeal',
        requestedCard: this.requestedCard.datasource,
      })
    )
  }

  public userFacingDescription(
    data: TMinorActionPromptUserFacingDescriptionData
  ): string {
    const {gamePosition, tense, skin} = data
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined
    const {majorActor, minorActor} = this

    const {conjugatedVerb, xNoun, yNounPossessive} = xVerbY(
      tense,
      minorActor,
      'respondTo',
      majorActor,
      currentPlayer?.id
    )

    return [
      xNoun === minorActor.username ? xNoun : sentenceCase(xNoun),
      conjugatedVerb,
      yNounPossessive,
      Card.displayNameForCard('slyDeal', skin),
    ].join(' ')
  }
}
