import React from 'react'
import DSText, {DSTextDiv} from '~/design-system/DSText'
import DSLink from '~/design-system/Link'
import AdminLayout from '~/layouts/AdminLayout'
import {
  IAdminRouteComponentProps,
  isolateAdminRouteComponentProps,
} from '~/routing/Route/Admin'
import {staticURLs} from '~/routing/sitemap'
import styles from './styles.module.css'

interface IProps extends IAdminRouteComponentProps<any> {}

export default class AdminIndexPage extends React.Component<IProps> {
  render() {
    return (
      <AdminLayout {...isolateAdminRouteComponentProps(this.props)}>
        <div className={styles.adminIndexWrapper}>
          <DSText size="h2" tag="h2">
            Deals Admin
          </DSText>
          <DSTextDiv size="body-15" color="blue">
            <DSLink underline="onHover" to={staticURLs.admin.signups}>
              Review New Signups
            </DSLink>
          </DSTextDiv>
        </div>
      </AdminLayout>
    )
  }
}
