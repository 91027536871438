import * as R from 'ramda'
import environment, {TBackendSource} from '~/config/environment'

export type TProgressEventHandler = (progressEvent: ProgressEvent) => void

/**
 * An alias for `undefined`, to make clear that it's usage represents an unset
 * ('optional', or left-out) value in a JSON object.
 * @type {TJSONUnset}
 */
export const jsonValueUnset: TJSONUnset = undefined
export type TJSONUnset = undefined

/** Core JSON data type */
export type JSONValue =
  | string
  | number
  | boolean
  | IJSONObject
  | IJSONArray
  | TJSONUnset
  | null // In the API, we convert incoming `null`s to `undefined`. But, we can still send outgoing `null`s.

export interface IJSONObject {
  [key: string]: JSONValue
}

export const backendHosts: Record<Exclude<TBackendSource, 'mock'>, string> = {
  localhost: 'http://localhost:3000',
  localhost_named: 'http://api.deal.local:3000',
  ip: `http://${environment.ipBackendIp}:3000`,
  ngrok: `http://${environment.ngrokSubdomain}.ngrok.io`,
  ngroks: `https://${environment.ngrokSubdomain}.ngrok.io`,
  production: `https://backend.${environment.domainName}`,
}

interface IJSONArray extends Array<JSONValue> {}

/** Interface to implement if the class returns a non-top-level JSON value like string or number  */
export interface IToJSONValue {
  toJSONValue: () => JSONValue
}

/** Interface to implement if the class returns a JSON object */
// We could support arrays as top level objects as well, but I want to discourage people from doing that.
// (It makes additions to the API very inflexible)
export interface IToJSONObjectConvertible {
  toJSONObject: () => IJSONObjectConvertible
}

/** Interface for objects  */
export interface IJSONObjectConvertible {
  [key: string]: TJSONValueConvertible
}

/** Interface for value of object  */
export type TJSONValueConvertible =
  | IToJSONValue
  | JSONValue
  | IToJSONObjectConvertible
  | IJSONObjectConvertible
  | IJSONObjectArray

/** Bit of a nuanced case */
interface IJSONObjectArray extends Array<IJSONObjectConvertible> {}

export const toJSON = (input: IJSONObjectConvertible): JSONValue => {
  if (input && typeof (input as any).toJSONObject === 'function') {
    return toJSON((input as any).toJSONObject())
  } else if (input && typeof (input as any).toJSONValue === 'function') {
    return (input as any).toJSONValue()
  } else if (input && typeof input === 'object') {
    // Arrays are typeof 'object' and R.map covers both Arrays and objects
    return R.map(toJSON, input as any)
  } else {
    return input
  }
}
