import * as R from 'ramda'
import {TMinorActionPromptDataForcedDeal} from '~/api/generated/types/common'
import {BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID} from '~/api/types'
import {cardsPerSet} from '~/game/types'
import Card from '~/models/game/Card'
import PropertyWildCard from '~/models/game/Cards/PropertyWildCard'
import {propertyCardFactory} from '~/models/game/factories'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MinorAction from '~/models/game/MinorAction'
import MinorActionPrompt, {
  TMinorActionPromptUserFacingDescriptionData,
  TMinorActionPromptValidResponseData,
} from '~/models/game/MinorActionPrompt'
import PropertyCard from '~/models/game/Cards/PropertyCard'
import MinorActionAcceptForcedDeal from '~/models/game/MinorActions/MinorActionAcceptForcedDeal'
import Player from '~/models/game/Player'
import PortfolioNeighborhood from '~/models/game/PortfolioNeighborhood'
import StandardPortfolioNeighborhood from '~/models/game/PortfolioNeighborhoods/StandardPortfolioNeighborhood'
import WildCardPortfolioNeighborhood from '~/models/game/PortfolioNeighborhoods/WildCardPortfolioNeighborhood'
import {switchOnPropertyCard} from '~/models/game/types'
import {sentenceCase, xVerbY} from '~/utils/language'
import * as S from '~/utils/Set'

export default class MinorActionPromptForcedDeal extends MinorActionPrompt<
  TMinorActionPromptDataForcedDeal
> {
  requestedCard: PropertyCard<any>
  offeredCard: PropertyCard<any>

  constructor(
    data: TMinorActionPromptDataForcedDeal,
    majorActor: Player,
    minorActor: Player
  ) {
    super(data, majorActor, minorActor)
    this.requestedCard = propertyCardFactory(data.requestedCard)
    this.offeredCard = propertyCardFactory(data.offeredCard)
  }

  protected _validResponses(
    data: TMinorActionPromptValidResponseData
  ): MinorAction<any> | MinorAction<any>[] {
    return this.withJustSayNo(
      data,
      new MinorActionAcceptForcedDeal({
        minorActorPlayerId: this.minorActorPlayerId,
        type: 'forcedDeal',
        offeredCard: this.offeredCard.datasource,
        requestedCard: this.requestedCard.datasource,
        placedInPortfolioNeighborhoodId: 'unset_pnId', // we need to ask the user where to put the card eventually
      })
    )
  }

  public userFacingDescription(
    data: TMinorActionPromptUserFacingDescriptionData
  ): string {
    const {gamePosition, tense, skin} = data
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined
    const {majorActor, minorActor} = this

    const {conjugatedVerb, xNoun, yNounPossessive} = xVerbY(
      tense,
      minorActor,
      'respondTo',
      majorActor,
      currentPlayer?.id
    )

    return [
      xNoun === minorActor.username ? xNoun : sentenceCase(xNoun),
      conjugatedVerb,
      yNounPossessive,
      Card.displayNameForCard('forcedDeal', skin),
    ].join(' ')
  }

  possibleDestinationNeighborhoods(data: {
    targetedPlayer: Player
  }): PortfolioNeighborhood[] {
    const {offeredCard, requestedCard} = this
    const {targetedPlayer} = data

    const stolenFromNeighborhood = targetedPlayer.portfolio.neighborhoods.find(pn =>
      pn.properties.find(c => c.id === requestedCard.id)
    )

    if (!stolenFromNeighborhood) {
      throw new Error(
        `couldn't find target neighborhood containing the requestedCard of a forced deal`
      )
    }

    // a forced deal removes a card from a neighborhood, and gives us a card.
    // the given card can go into:
    //
    // 1. if it has room, a neighborhood of the same type
    //    - note that if e.g. a solo green/darkBlue is taken and replaced by a green, we first exclude the green/dkBlue
    //      neighborhood that will disappear, and then (3) below will add a new BY_ITESELF... option for it
    // 2. if it has room*, an alone neighborhood (it can join a PWC)
    //    * this gets tricky if the alone neighborhood has 2 PWCs in it, because in that scenario a 2-cards-per-set
    //      colored card (darkBlue/brown/utility) can't join the PWCs! Do you let them move a PWC to join the
    //      new brown/utility/darkBlue? Probably not, kinda complex. We don't allow it here for now.
    // 3. a (new) neighborhood of its own,
    //   - for standard properties, iff there are no incompleteSets of its type available
    //   - for dual properties, iff either/both of its colors aren't in incompleteSets
    //   - for PWCs, if there are no incompleteSets at all
    //
    // some discussion: https://mercurytechnologies.slack.com/archives/C012QHJQQHY/p1600740002032100

    // TODO things not handled here:
    //  a) choosing the orientation of an offered DualPropertyCard if it joins PWC(s) in an 'alone' neighborhood
    //  b) choosing the orientation of an offered DualPropertyCard if it goes into it's own, new neighborhood
    //     (though the code below sets a `StandardPortfolioNeighborhood.neighborhood` here, that value is not
    //     used in the TMinorActionDataAcceptForcedDeal type, just the BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID)

    const targetNeighborhoods = targetedPlayer.portfolio
      .incompleteSets()
      .filter(pn => {
        if (
          pn.id === stolenFromNeighborhood.id &&
          stolenFromNeighborhood.properties.length === 1
        ) {
          // this neighborhood is about to be gone, so we can't add the offeredCard to it - (1)'s note above
          return false
        }

        // case 2
        if (pn.neighborhood === 'alone') {
          return (
            offeredCard instanceof PropertyWildCard ||
            // only if there is room for the card to join the PWCs (for 2-card-sets as noted above)
            // TODO and even then - (a) above - a utility/railroad dual card should only join as a railroad (no room
            //  for util) but the API doesn't have a `chosenOrientation?: TStandardPropertyType` for us to set (yet?)
            S.anyPass(
              cardType => pn.properties.length < cardsPerSet[cardType],
              offeredCard.cardTypes()
            )
          )
        }

        // else, case 1
        return offeredCard.cardTypes().has(pn.neighborhood)
      })

    // case 3, a (new) neighborhood of its own:
    switchOnPropertyCard<void>({
      //   - for standard properties, iff there are no incompleteSets of its type available
      whenStandard: spc => {
        if (R.none(pn => spc.type === pn.neighborhood, targetNeighborhoods)) {
          targetNeighborhoods.push(
            new StandardPortfolioNeighborhood({
              id: BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID,
              neighborhood: spc.type,
              properties: [],
              residences: [],
            })
          )
        }
      },
      //   - for dual properties, iff either/both of its colors aren't in incompleteSets
      whenDual: dpc => {
        // which of the Dual neighborhoods are missing in targetNeighborhoods?
        S.filter(
          dpcType =>
            targetNeighborhoods.filter(pn => pn.neighborhood === dpcType).length ===
            0,
          dpc.standardPropertyTypes()
        ).forEach(missingNeighborhood =>
          targetNeighborhoods.push(
            new StandardPortfolioNeighborhood({
              id: BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID,
              // TODO (b) above notes this isn't used in the TMinorActionDataAcceptForcedDeal yet, so
              //  the orientation isn't selectable. Unfortunate.
              neighborhood: missingNeighborhood,
              properties: [],
              residences: [],
            })
          )
        )
      },
      //   - for PWCs, if there are no incompleteSets at all
      whenWild: wpc => {
        if (targetNeighborhoods.length === 0) {
          targetNeighborhoods.push(
            new WildCardPortfolioNeighborhood({
              id: BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID,
              neighborhood: 'alone',
              properties: [],
            })
          )
        }
      },
    })(offeredCard)

    return targetNeighborhoods
  }
}
