import {PostApi} from '~/api/client'
import {playersMock} from '~/api/endpoints/game/GetGameData/mock'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TWaitingRoomResponse} from '~/api/generated/types/GetGameWaitingRoom'
import {TJSONError} from '~/api/types'

type Request = {}
export type TKickPlayerFromWaitingRoomPathPieces = {
  gameId: string
  playerId: string
}

/**
 * The creator of a game lobby can kick a player from the joined list.
 */
export default class KickPlayerFromWaitingRoom extends PostApi<
  Request,
  TWaitingRoomResponse,
  TKickPlayerFromWaitingRoomPathPieces
> {
  protected mockRequestFailure(
    params: Request,
    pathPieces: TKickPlayerFromWaitingRoomPathPieces
  ): TJSONError | null {
    if (pathPieces.gameId === 'fail') {
      return {errors: {gameId: [`That game doesn't exist.`]}}
    }

    return super.mockRequestFailure(params, pathPieces)
  }

  protected getMockResponse(
    params: Request,
    pathPieces: TKickPlayerFromWaitingRoomPathPieces
  ): TWaitingRoomResponse {
    return {
      status: 'waiting',
      playerData: playersMock.filter(p => p.playerId !== pathPieces.playerId),
      hostPlayerId: playersMock[0].playerId,
    }
  }

  protected getPath(pathPieces: TKickPlayerFromWaitingRoomPathPieces) {
    return serverEndpoints.kickFromWaitingRoom(pathPieces)
  }

  protected transformResponse(r: TWaitingRoomResponse) {
    return r
  }
}
