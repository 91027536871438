import {PayloadAction} from '@reduxjs/toolkit'
import {differenceInSeconds} from 'date-fns'
import {batchActions} from 'redux-batched-actions'
import {ExcludeStrict} from 'type-zoo'
import APILoggedOutError from '~/api/client/APIError/APILoggedOutError'
import APIError from '~/api/client/APIError/base'
import api from '~/api/endpoints'
import {TGetInitialDataResponse} from '~/api/generated/types/GetInitialData'
import {loggedOutRemotely, signinComplete} from '~/redux/reducers/auth'
import {setUser} from '~/redux/reducers/user'
import store from '~/redux/store'

// used alongside RefreshFrequencyInSeconds to decide when to automatically update GetInitialData
let lastRefresh: Date | undefined = undefined

// made this refresh window relatively small since its currently only triggered on user actions
const RefreshFrequencyInSeconds = 60

export const massUpdateRedux = (
  globalData: TGetInitialDataResponse,
  context: TGlobalDataLoadContext
): void => {
  const actions: PayloadAction<any>[] = [setUser(globalData.userDetails)]

  if (context === 'signin') {
    actions.push(signinComplete())
  }

  store.dispatch(batchActions(actions))
  lastRefresh = new Date()
}

export type TGlobalDataLoadContext = 'app-load' | 'pageTransition' | 'signin'

const shouldWeRefreshFromServer = (
  context: ExcludeStrict<TGlobalDataLoadContext, 'signin'>
) => {
  // this ignores the lastRefresh and forces an update
  if (context === 'app-load') {
    return true
  } else {
    return (
      !lastRefresh ||
      differenceInSeconds(new Date(), lastRefresh) > RefreshFrequencyInSeconds
    )
  }
}

export const updateGlobalDataFromServer = async (
  context: ExcludeStrict<TGlobalDataLoadContext, 'signin'>
) => {
  if (shouldWeRefreshFromServer(context)) {
    // console.log('updating global data from server (', context, ')')
    // updating lastRefresh here so that we dont fire two requests off to the server
    lastRefresh = new Date()
    return new Promise<void>((resolve, reject) => {
      api.globalData
        .get({}, {})
        .then(response => {
          massUpdateRedux(response, context)
          resolve()
        })
        .catch((e: APIError) => {
          if (e instanceof APILoggedOutError) {
            // this is just a logged out state not a failure
            // tell the LogoutTimer to trigger the auto-sign-out flow
            store.dispatch(loggedOutRemotely())
            resolve()
          } else {
            reject(e)
          }
        })
    })
  }
}
