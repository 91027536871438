import * as RA from 'ramda-adjunct'
import {TPlayerData} from '~/api/generated/types/common'
import {TRuleset} from '~/game/ruleset'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import ExposedHand from '~/models/game/Hands/ExposedHand'
import MajorAction from '~/models/game/MajorAction'
import Player from '~/models/game/Player'
import Portfolio from '~/models/game/Portfolio'
import {safelyOr} from '~/utils'
import * as M from '~/utils/Map'

/* a player whose hand we can see */
export default class ExposedPlayer extends Player {
  hand: ExposedHand

  constructor(playerData: TPlayerData, portfolio: Portfolio, hand: ExposedHand) {
    super(playerData, portfolio, hand)
    this.hand = hand
  }

  /*  public availableMovesFromHandByCardId = (
    gameData: GameData,
    rules: TRuleset
  ): Map<string, MajorAction[]> => {
    const obj: {[k: string]: MajorAction[]} = {}
    this.hand.cards.forEach(c => {
      obj[c.id] = RA.ensureArray(
        c.availableMovesFromHand({gameData, portfolio: this.portfolio, rules})
      )
    })

    return M.fromObj<string, MajorAction[]>(obj)
  }*/

  public availableMovesFromHandForCardId = (
    cardId: string,
    rules: TRuleset,
    playerGamePosition: PlayerGamePosition
  ): MajorAction[] =>
    safelyOr(
      this.hand.cards.find(c => c.id === cardId),
      c =>
        RA.ensureArray(
          c.availableMovesFromHand({
            portfolio: this.portfolio,
            rules,
            playerGamePosition,
          })
        ),
      []
    )

  public availableMovesFromTableByCardId = (
    rules: TRuleset,
    playerGamePosition: PlayerGamePosition
  ): Map<string, MajorAction[]> => {
    const obj: {[k: string]: MajorAction[]} = {}
    this.portfolio.allCards().forEach(c => {
      obj[c.id] = RA.ensureArray(
        c.availableMovesFromTable({
          portfolio: this.portfolio,
          rules,
          playerGamePosition,
        })
      )
    })

    return M.fromObj<string, MajorAction[]>(obj)
  }
  /*
  public availableMovesFromTableForCardId = (
    cardId: string,
    gameData: GameData,
    rules: TRuleset
  ): MajorAction[] =>
    safelyOr(
      this.portfolio.allCards().find(c => c.id === cardId),
      c =>
        RA.ensureArray(
          c.availableMovesFromTable({gameData, portfolio: this.portfolio, rules})
        ),
      []
    )*/
}
