import {
  TDualPropertyCardData,
  TStandardPropertyType,
} from '~/api/generated/types/common'
import SpecialPropertyCard from '~/models/game/Cards/SpecialPropertyCard'
import {
  cardValue,
  standardPropertyTypesForDualPropertyCard,
  TNeighborhood,
} from '~/game/types'
import {first} from '~/utils/Set'

export default class DualPropertyCard extends SpecialPropertyCard<
  TDualPropertyCardData
> {
  public value: number

  constructor(data: TDualPropertyCardData) {
    super(data)
    this.value = cardValue[data.type]
  }

  // overriding Card function
  public isFlippable = (): boolean => true

  upsideDownForNeighborhood = (neighborhood: TNeighborhood): boolean => {
    return first(this.standardPropertyTypes()) !== neighborhood
  }

  standardPropertyTypes = (): Set<TStandardPropertyType> => {
    return new Set(standardPropertyTypesForDualPropertyCard(this.type))
  }
}
