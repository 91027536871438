import {TPropertyCardData, TStandardPropertyType} from '~/api/generated/types/common'
import {BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID} from '~/api/types'
import Card, {TAvailableMovesData} from '~/models/game/Card'
import MajorActionPlayToNeighborhood from '~/models/game/MajorActions/MajorActionPlayToNeighborhood'

export default abstract class PropertyCard<
  CardDataType extends TPropertyCardData
> extends Card<CardDataType> {
  /**
   *  "what standard property types can this card represent itself as a part of?"
   *  TODO a NESet would be a better type here, if we had such a type/class
   */
  public abstract cardTypes(): Set<TStandardPropertyType>

  public availableMovesFromHand({
    portfolio,
  }: TAvailableMovesData): MajorActionPlayToNeighborhood[] {
    const cardTypes = this.cardTypes()
    const applicableNeighborhoods = portfolio.neighborhoods.filter(
      pn =>
        (pn.neighborhood === 'alone' || cardTypes.has(pn.neighborhood)) &&
        !pn.isCompleteSet()
    )
    if (applicableNeighborhoods.length) {
      return applicableNeighborhoods.map(
        an =>
          new MajorActionPlayToNeighborhood({
            type: 'playToNeighborhood',
            card: this.datasource,
            portfolioNeighborhoodId: an.id,
          })
      )
    } else {
      return [
        // TODO we could specify an orientation here for DualPropertyCards, but i guess
        //   it's not strictly necessary since the card can just be rotated for free afterwards
        new MajorActionPlayToNeighborhood({
          type: 'playToNeighborhood',
          card: this.datasource,
          portfolioNeighborhoodId: BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID,
        }),
      ]
    }
  }
}
