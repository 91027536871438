import {faChevronDown} from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'
import {format} from 'date-fns'
import * as React from 'react'
import DSText from '~/design-system/DSText'
import {TSkin} from '~/game/skins'
import PastGamePosition from '~/models/game/PastGamePosition'
import {joinElements} from '~/pages/utils'
import {compact} from '~/utils'
import {isAtEndOfScroll, scrollToBottom} from '~/utils/components'
import {AlternateStateFontAwesomeIcon} from '~/utils/FontAwesome/HoverableFontAwesomeIcon'
import {NEList} from '~/utils/NEList'
import styles from './styles.module.css'

interface IGameLogProps {
  skin: TSkin
  pastGamePositions: NEList<PastGamePosition> | undefined
  className?: string
}

interface IGameLogState {
  autoscroll: boolean
  collapsed: boolean
}

export default class GameLog extends React.PureComponent<
  IGameLogProps,
  IGameLogState
> {
  state: IGameLogState = {
    autoscroll: true,
    collapsed: false, // TODO could be a localstorage thing to save their preference
  }

  // if we end up wanting this behavior here and elsewhere, https://www.npmjs.com/package/react-bottom-scroll-listener
  // worked pretty nicely, but didn't detect the `autoscroll: false` case for me, so I removed it.
  // maybe making a component like that that also handled that would be nice
  scrollRef = React.createRef<HTMLDivElement>()

  componentDidMount() {
    this.maybeAutoscroll()
  }

  componentDidUpdate(prevProps: IGameLogProps, prevState: IGameLogState) {
    this.maybeAutoscroll()
  }

  maybeAutoscroll() {
    if (
      this.scrollRef.current &&
      this.state.autoscroll &&
      !isAtEndOfScroll(this.scrollRef.current)
    ) {
      scrollToBottom(this.scrollRef.current)
    }
  }

  render() {
    const {skin, pastGamePositions, className} = this.props
    const {collapsed} = this.state

    const CollapseArrow = () => (
      <AlternateStateFontAwesomeIcon
        alternateState={collapsed}
        alternateIcon={{
          style: {
            transform: 'rotate(180deg)',
          },
        }}
        icon={{
          icon: faChevronDown,
          style: {},
          color: 'pearl-grey-darkest',
        }}
      />
    )

    return (
      <div className={classnames(styles.gameLogWrapper, className)}>
        {!collapsed && (
          <div
            ref={this.scrollRef}
            className={styles.scrollableArea}
            onScroll={() => {
              const div = this.scrollRef.current
              if (!div) {
                return
              }

              const atEnd = isAtEndOfScroll(div)
              if (this.state.autoscroll && !atEnd) {
                this.setState({autoscroll: false})
              } else if (!this.state.autoscroll && atEnd) {
                this.setState({autoscroll: true})
              }
            }}
          >
            {pastGamePositions?.map(pgp => {
              // TODO `hh` for 12hr format if we have user settings for it
              return (
                <div key={pgp.date.valueOf() + pgp.gamePosition.time}>
                  <DSText size="tiny" color="pearl-grey-darker">
                    {format(pgp.date, `[HH:mm:ss]`)}
                  </DSText>{' '}
                  <DSText size="tiny" color="white">
                    {joinElements(
                      compact([
                        // NB: I don't think a major & minor action happening at the same time ever actually
                        // makes sense. However, the backend sometimes does this, we're looking into it.
                        // For now, the minor action usually seems to be the earlier of the two when it happens,
                        // so showing them both like this is a band-aid for now that doesn't hurt too much.
                        pgp.minorActionTaken?.userFacingDescription({
                          gamePosition: pgp.gamePosition,
                          inProgressMajorAction: pgp.gamePosition
                            .inProgressMajorAction!,
                          majorActor: pgp.gamePosition.majorActorPlayer,
                          minorActor: pgp.gamePosition.minorActorPlayer!,
                          skin,
                          tense: 'past',
                        }),
                        pgp.majorActionTaken?.userFacingDescription({
                          gamePosition: pgp.gamePosition,
                          skin,
                          tense: 'past',
                        }),
                      ]),
                      i => (
                        <br key={i} />
                      )
                    )}
                  </DSText>
                </div>
              )
            }) ?? (
              <DSText size="tiny" color="pearl-grey-darker">
                Waiting for data...
              </DSText>
            )}
          </div>
        )}

        <div
          className={classnames(styles.collapseButton)}
          onClick={() => this.setState({collapsed: !collapsed})}
        >
          <DSText size="tiny" color="white">
            {collapsed ? 'Game Log' : ''}
          </DSText>
          <CollapseArrow />
        </div>
      </div>
    )
  }
}
