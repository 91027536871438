import * as React from 'react'
import GameBoardUI, {IGameBoardUIProps} from '~/pages/GameLobby/GameBoard/stateless'

interface IProps extends IGameBoardUIProps {}

export default class GameBoard extends React.Component<IProps> {
  render() {
    return <GameBoardUI {...this.props} />
  }
}
