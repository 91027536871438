import * as R from 'ramda'
import {
  TCardData,
  TCardsInHand,
  TGamePosition,
  TLiquidatedCardData,
  TPastGamePosition,
  TPlayerData,
  TPlayerPortfolio,
  TPortfolioNeighborhoodData,
  TPropertyCardData,
} from '~/api/generated/types/common'
import {TGameData} from '~/api/generated/types/common'
import {
  isDualPropertyType,
  standardPropertyTypesForDualPropertyCard,
} from '~/game/types'
import {compact, safely} from '~/utils'
import {minsAgo} from '~/utils/mocks'
import * as NEL from '~/utils/NEList'
import * as S from '~/utils/Set'

const movePropertyCard = (
  _portfolios: TPlayerPortfolio[],
  cardId: string,
  fromPlayerId: string,
  toPlayerId: string,
  toPortfolioNeighborhoodId: string
): TPlayerPortfolio[] => {
  const cards: TPropertyCardData[] = _portfolios
    .map(p => p.neighborhoods.map(pn => pn.properties))
    .flat(2)
  const cardToMove = cards.find(c => c.id === cardId)

  if (!cardToMove) {
    console.warn('mocks cant move property card, not found.', cardId, {
      cards,
      portfolios: _portfolios,
    })
    return _portfolios
  }

  return _portfolios.map(p => {
    if (p.playerId === toPlayerId) {
      const [pnToAddTo, otherPNs] = R.partition(
        pn => pn.id === toPortfolioNeighborhoodId,
        p.neighborhoods
      )
      const newPN: TPortfolioNeighborhoodData =
        pnToAddTo[0] ?? cardToMove.type === 'propertyWildCard'
          ? {
              id: toPortfolioNeighborhoodId,
              neighborhood: 'alone',
              properties: [],
            }
          : {
              id: toPortfolioNeighborhoodId,
              neighborhood: isDualPropertyType(cardToMove.type)
                ? standardPropertyTypesForDualPropertyCard(cardToMove.type)[0]
                : cardToMove.type,
              properties: [],
              residences: [],
            }
      newPN.properties.push(cardToMove as any)

      return {
        ...p,
        neighborhoods: [...otherPNs, newPN],
      }
    } else if (p.playerId === fromPlayerId) {
      return {
        ...p,
        neighborhoods: compact(
          p.neighborhoods.map((pn): TPortfolioNeighborhoodData | undefined => {
            const [foundCardToRemove, otherCards] = R.partition(
              c => c.id === cardId,
              pn.properties
            )
            if (foundCardToRemove.length === 0) {
              return pn
            } else {
              return otherCards.length
                ? {
                    ...pn,
                    properties: otherCards as any,
                  }
                : undefined
            }
          })
        ),
      }
    } else {
      return p
    }
  })
}

const rotatePropertyCard = (
  _portfolios: TPlayerPortfolio[],
  cardId: string,
  playerId: string,
  toPortfolioNeighborhoodId: string
): TPlayerPortfolio[] => {
  const cards: TPropertyCardData[] = _portfolios
    .map(p => p.neighborhoods.map(pn => pn.properties))
    .flat(2)
  const cardToMove = cards.find(c => c.id === cardId)

  if (!cardToMove) {
    console.warn('mocks cant rotate property card, not found.', cardId, {
      cards,
      portfolios: _portfolios,
    })
    return _portfolios
  }

  return _portfolios.map(portfolio => {
    if (portfolio.playerId === playerId) {
      const [pnToAddTo, pnsToRemoveFromOrOthers] = R.partition(
        pn => pn.id === toPortfolioNeighborhoodId,
        portfolio.neighborhoods
      )

      const [pnsToRemoveFrom, otherPNs] = R.partition(
        pn => !!pn.properties.find(prop => prop.id === cardToMove.id),
        pnsToRemoveFromOrOthers
      )
      const pnToRemoveFrom = pnsToRemoveFrom && pnsToRemoveFrom[0]
      const prunedPNToRemoveFrom = safely(pnToRemoveFrom, (pn):
        | TPortfolioNeighborhoodData
        | undefined => {
        if (pn.properties.length === 1) {
          return undefined
        }

        return {
          ...pn,
          properties: pn.properties.filter(prop => prop.id !== cardToMove.id) as any,
        }
      })

      const newPN: TPortfolioNeighborhoodData =
        pnToAddTo[0] ?? cardToMove.type === 'propertyWildCard'
          ? {
              id: toPortfolioNeighborhoodId,
              neighborhood: 'alone',
              properties: [],
            }
          : {
              id: toPortfolioNeighborhoodId,
              neighborhood: isDualPropertyType(cardToMove.type)
                ? standardPropertyTypesForDualPropertyCard(cardToMove.type).filter(
                    t => t !== pnToRemoveFrom?.neighborhood
                  )[0]
                : cardToMove.type,
              properties: [],
              residences: [],
            }
      newPN.properties.push(cardToMove as any)

      const neighborhoods = compact([...otherPNs, newPN, prunedPNToRemoveFrom])

      return {
        ...portfolio,
        neighborhoods,
      }
    } else {
      return portfolio
    }
  })
}

const moveLiquidatedCards = (
  _portfolios: TPlayerPortfolio[],
  cardIds: string | string[],
  fromPlayerId: string,
  toPlayerId: string
): TPlayerPortfolio[] => {
  const cards: TLiquidatedCardData[] = _portfolios.map(p => p.cashPile).flat(1)
  const cardIdSet = S.create(cardIds)
  const cardsToMove = cards.filter(c => cardIdSet.has(c.id))

  if (cardsToMove.length !== cardIds.length) {
    console.warn('mocks cant move liquidated cards, not all cards found.', {
      cards,
      cardIds,
      cardsToMove,
      portfolios: _portfolios,
    })
    return _portfolios
  }

  return _portfolios.map(p => {
    if (p.playerId === fromPlayerId) {
      return {
        ...p,
        cashPile: p.cashPile.filter(c => !cardIdSet.has(c.id)),
      }
    } else if (p.playerId === toPlayerId) {
      return {
        ...p,
        cashPile: [...p.cashPile, ...cardsToMove],
      }
    } else {
      return p
    }
  })
}

export const playersMock: TPlayerData[] = [
  {
    playerId: 'player_0',
    username: 'manroe',
    email: 'monroe@mercury.com',
  },
  {
    playerId: 'player_1',
    username: 'amibroj',
    email: 'amitoj@mercury.com',
  },
  {
    playerId: 'player_2',
    username: 'charlie_T',
    email: 'immad@mercury.com',
  },
  {
    playerId: 'player_3',
    username: 'xXalexeys_momXx',
    email: 'alexey@mercury.com',
  },
  // {
  //   playerId: 'player_4',
  //   username: 'bigJ',
  //   email: 'juliana@mercury.com',
  // },
  // {
  //   playerId: 'player_5',
  //   username: 'bigW',
  //   email: 'whitney@mercury.com',
  // },
]

const houseOnDarkBlue: boolean = false
const portfolios: TPlayerPortfolio[] = [
  // {
  //   neighborhoods: [],
  //   cashPile: [],
  //   playerId: playersMock[4].playerId,
  // },
  // {
  //   neighborhoods: [],
  //   cashPile: [],
  //   playerId: playersMock[5].playerId,
  // },
  {
    neighborhoods: [
      {
        id: 'darkblue-pn-0',
        neighborhood: 'darkBlue',
        properties: [
          {
            type: 'darkBlue',
            version: 0,
            id: 'darkBlue-0',
          },
          {
            type: 'propertyWildCard',
            version: 1,
            id: 'propertyWildCard-1',
          },
        ],
        residences: houseOnDarkBlue
          ? [
              {
                type: 'house',
                version: 1,
                id: 'house-1',
              },
            ]
          : [],
      },
      {
        id: 'yellow-pn-0',
        neighborhood: 'yellow',
        properties: [
          {
            type: 'yellow',
            version: 0,
            id: 'yellow-0',
          },
          {
            type: 'wildRedAndYellow',
            version: 1,
            id: 'wildRedAndYellow-1',
          },
          {
            type: 'yellow',
            version: 2,
            id: 'yellow-2',
          },
        ],
        residences: [],
      },
      {
        id: 'railroad-pn-0',
        neighborhood: 'railroad',
        properties: [
          {
            type: 'railroad',
            version: 3,
            id: 'railroad-3',
          },
        ],
        residences: [],
      },
    ],
    cashPile: [
      {
        type: 'passGo',
        version: 0,
        id: 'passGo-0',
      },
      {
        type: 'oneMil',
        version: 0,
        id: 'oneMil-0',
      },
      {
        type: 'twoMil',
        version: 0,
        id: 'twoMil-0',
      },
      {
        type: 'twoMil',
        version: 1,
        id: 'twoMil-1',
      },
    ],
    playerId: playersMock[0].playerId,
  },
  {
    neighborhoods: [
      {
        id: 'green-pn-1',
        neighborhood: 'green',
        properties: [
          {
            type: 'wildDarkBlueAndGreen',
            version: 0,
            id: 'wildDarkBlueAndGreen-0',
          },
          {
            type: 'green',
            version: 1,
            id: 'green-1',
          },
        ],
        residences: [],
      },
      {
        id: 'yellow-pn-1',
        neighborhood: 'yellow',
        properties: [
          {
            type: 'yellow',
            version: 1,
            id: 'yellow-1',
          },
        ],
        residences: [],
      },
    ],
    cashPile: [],
    playerId: playersMock[1].playerId,
  },
  {
    neighborhoods: [
      {
        id: 'lightBlue-pn-2',
        neighborhood: 'lightBlue',
        properties: [
          {
            type: 'lightBlue',
            version: 2,
            id: 'lightBlue-2',
          },
        ],
        residences: [],
      },
      {
        id: 'orange-pn-2',
        neighborhood: 'orange',
        properties: [
          {
            type: 'orange',
            version: 0,
            id: 'orange-0',
          },
          {
            type: 'wildPinkAndOrange',
            version: 0,
            id: 'wildPinkAndOrange-0',
          },
        ],
        residences: [],
      },
      {
        id: 'utility-pn-2',
        neighborhood: 'utility',
        properties: [
          {
            type: 'utility',
            version: 1,
            id: 'utility-1',
          },
        ],
        residences: [],
      },
    ],
    cashPile: [
      {
        type: 'passGo',
        version: 1,
        id: 'passGo-1',
      },
      {
        type: 'passGo',
        version: 2,
        id: 'passGo-2',
      },
      {
        type: 'passGo',
        version: 3,
        id: 'passGo-3',
      },
      {
        type: 'tenMil',
        version: 0,
        id: 'tenMil-0',
      },
    ],
    playerId: playersMock[2].playerId,
  },
  {
    neighborhoods: [
      // {
      //   id: 'darkBlue-pn-3',
      //   neighborhood: 'darkBlue',
      //   properties: [
      //     {
      //       type: 'darkBlue',
      //       version: 1,
      //       id: 'darkBlue-1',
      //     },
      //   ],
      //   residences: [],
      // },
      // {
      //   id: 'railroad-pn-3',
      //   neighborhood: 'railroad',
      //   properties: [
      //     {
      //       type: 'wildUtilityAndRailroad',
      //       version: 1,
      //       id: 'wildUtilityAndRailroad-1',
      //     },
      //   ],
      //   residences: [],
      // },
      // {
      //   id: 'pink-pn-3',
      //   neighborhood: 'pink',
      //   properties: [
      //     {
      //       type: 'pink',
      //       version: 0,
      //       id: 'pink-0',
      //     },
      //     {
      //       type: 'pink',
      //       version: 1,
      //       id: 'pink-1',
      //     },
      //   ],
      //   residences: [],
      // },
      // {
      //   id: 'orange-pn-3',
      //   neighborhood: 'orange',
      //   properties: [
      //     {
      //       type: 'wildPinkAndOrange',
      //       version: 1,
      //       id: 'wildPinkAndOrange-1',
      //     },
      //   ],
      //   residences: [],
      // },
    ],
    cashPile: [
      // {
      //   type: 'fourMil',
      //   version: 0,
      //   id: 'fourMil-0',
      // },
      // {
      //   type: 'fourMil',
      //   version: 1,
      //   id: 'fourMil-1',
      // },
      // {
      //   type: 'threeMil',
      //   version: 1,
      //   id: 'threeMil-1',
      // },
      // {
      //   type: 'dealBreaker',
      //   version: 0,
      //   id: 'dealBreaker-0',
      // },
    ],
    playerId: playersMock[3].playerId,
  },
]

let time = 0

const player1PaysPlayer0 = movePropertyCard(
  portfolios,
  'yellow-1',
  playersMock[1].playerId,
  playersMock[0].playerId,
  'yellow-pn-1'
)
const player2PaysPlayer0 = moveLiquidatedCards(
  player1PaysPlayer0,
  ['passGo-1', 'passGo-2'],
  playersMock[2].playerId,
  playersMock[0].playerId
)
const player3PaysPlayer0 = movePropertyCard(
  player2PaysPlayer0,
  'wildPinkAndOrange-1',
  playersMock[3].playerId,
  playersMock[0].playerId,
  'pink-pn-0' // new PN for player 0
)
const player0RotatesPinkOrange = rotatePropertyCard(
  player3PaysPlayer0,
  'wildPinkAndOrange-1',
  playersMock[0].playerId,
  'orange-pn-0'
)

const cardCounts: TCardsInHand[] = [
  {type: 'concealed', playerId: playersMock[1].playerId, cards: 7},
  {type: 'concealed', playerId: playersMock[2].playerId, cards: 7},
  {type: 'concealed', playerId: playersMock[3].playerId, cards: 7},
  // {type: 'concealed', playerId: playersMock[4].playerId, cards: 7},
  // {type: 'concealed', playerId: playersMock[5].playerId, cards: 7},
]

const player0sCards: TCardData[] = [
  {
    type: 'birthday',
    version: 0,
    id: 'birthday-0',
  },
  {
    type: 'house',
    version: 0,
    id: 'house-0',
  },
  {
    type: 'slyDeal',
    version: 0,
    id: 'slyDeal-0',
  },
  {
    type: 'justSayNo',
    version: 0,
    id: 'justSayNo-0',
  },
  {
    type: 'wildLightBlueAndBrown',
    version: 0,
    id: 'wildLightBlueAndBrown-0',
  },
  {
    type: 'rentDarkBlueGreen',
    version: 0,
    id: 'rentDarkBlueGreen-0',
  },
  {
    type: 'forcedDeal',
    version: 0,
    id: 'forcedDeal-0',
  },
  {
    type: 'doubleTheRent',
    version: 0,
    id: 'doubleTheRent-0',
  },
  {
    type: 'directionalRent',
    version: 0,
    id: 'directionalRent-0',
  },
]

const gamePosition0: TGamePosition = {
  cardsInHands: [
    ...cardCounts,
    {
      type: 'exposed',
      playerId: playersMock[0].playerId,
      cards: player0sCards,
    },
  ],
  portfolios,
  majorActorPlayerId: playersMock[0].playerId,
  play: 'firstPlay' as const,
  inProgressMajorAction: undefined,
  recentlyPlayedCards: [],
  cardsInDeck: 21,
  time: time++,
}
const gamePosition1: TGamePosition = {
  cardsInHands: [
    ...cardCounts,
    {
      type: 'exposed',
      playerId: playersMock[0].playerId,
      cards: player0sCards.slice(1),
    },
  ],
  portfolios,
  majorActorPlayerId: playersMock[0].playerId,
  play: 'firstPlay' as const,
  inProgressMajorAction: {
    majorActionData: {
      type: 'birthday' as const,
      card: {
        type: 'birthday',
        version: 0,
        id: 'birthday-0',
      },
    },
    pendingMinorActions: [
      {
        type: 'birthday',
        minorActorPlayerId: playersMock[1].playerId,
        millionsOwed: 2,
      },
      {
        type: 'birthday',
        minorActorPlayerId: playersMock[2].playerId,
        millionsOwed: 2,
      },
      {
        type: 'birthday',
        minorActorPlayerId: playersMock[3].playerId,
        millionsOwed: 2,
      },
    ],
    previousMinorActions: [],
  },
  recentlyPlayedCards: [
    {
      type: 'birthday',
      version: 0,
      id: 'birthday-0',
    },
  ],
  cardsInDeck: 21,
  time: time++,
}
const gamePosition2: TGamePosition = {
  cardsInHands: [
    ...cardCounts,
    {
      type: 'exposed',
      playerId: playersMock[0].playerId,
      cards: player0sCards.slice(1),
    },
  ],
  portfolios: player1PaysPlayer0,
  majorActorPlayerId: playersMock[0].playerId,
  play: 'firstPlay' as const,
  inProgressMajorAction: {
    majorActionData: {
      type: 'birthday' as const,
      card: {
        type: 'birthday',
        version: 0,
        id: 'birthday-0',
      },
    },
    pendingMinorActions: [
      {
        type: 'birthday',
        minorActorPlayerId: playersMock[2].playerId,
        millionsOwed: 2,
      },
      {
        type: 'birthday',
        minorActorPlayerId: playersMock[3].playerId,
        millionsOwed: 2,
      },
    ],
    previousMinorActions: [
      {
        type: 'birthday',
        minorActorPlayerId: playersMock[1].playerId,
        millionsOwed: 2,
        paidWith: [
          {
            type: 'yellow',
            version: 1,
            id: 'yellow-1',
          },
        ],
      },
    ],
  },
  recentlyPlayedCards: [
    {
      type: 'birthday',
      version: 0,
      id: 'birthday-0',
    },
  ],
  cardsInDeck: 21,
  time: time++,
}
const gamePosition3: TGamePosition = {
  cardsInHands: [
    ...cardCounts,
    {
      type: 'exposed',
      playerId: playersMock[0].playerId,
      cards: player0sCards.slice(1),
    },
  ],
  portfolios: player2PaysPlayer0,
  majorActorPlayerId: playersMock[0].playerId,
  play: 'firstPlay' as const,
  inProgressMajorAction: {
    majorActionData: {
      type: 'birthday' as const,
      card: {
        type: 'birthday',
        version: 0,
        id: 'birthday-0',
      },
    },
    pendingMinorActions: [
      {
        type: 'birthday',
        minorActorPlayerId: playersMock[3].playerId,
        millionsOwed: 2,
      },
    ],
    previousMinorActions: [
      {
        type: 'birthday',
        minorActorPlayerId: playersMock[1].playerId,
        millionsOwed: 2,
        paidWith: [
          {
            type: 'yellow',
            version: 1,
            id: 'yellow-1',
          },
        ],
      },
      {
        type: 'birthday',
        minorActorPlayerId: playersMock[2].playerId,
        millionsOwed: 2,
        paidWith: [
          {
            type: 'passGo',
            version: 1,
            id: 'passGo-1',
          },
          {
            type: 'passGo',
            version: 2,
            id: 'passGo-2',
          },
        ],
      },
    ],
  },
  recentlyPlayedCards: [
    {
      type: 'birthday',
      version: 0,
      id: 'birthday-0',
    },
  ],
  cardsInDeck: 21,
  time: time++,
}
const gamePosition4: TGamePosition = {
  cardsInHands: [
    ...cardCounts,
    {
      type: 'exposed',
      playerId: playersMock[0].playerId,
      cards: player0sCards.slice(1),
    },
  ],
  portfolios: player3PaysPlayer0,
  majorActorPlayerId: playersMock[0].playerId,
  play: 'secondPlay' as const,
  recentlyPlayedCards: [
    {
      type: 'birthday',
      version: 0,
      id: 'birthday-0',
    },
  ],
  cardsInDeck: 21,
  time: time++,
}
const gamePosition5: TGamePosition = {
  cardsInHands: [
    ...cardCounts,
    {
      type: 'exposed',
      playerId: playersMock[0].playerId,
      cards: player0sCards.slice(1),
    },
  ],
  portfolios: player0RotatesPinkOrange,
  majorActorPlayerId: playersMock[0].playerId,
  play: 'secondPlay' as const,
  inProgressMajorAction: undefined,
  recentlyPlayedCards: [
    {
      type: 'birthday',
      version: 0,
      id: 'birthday-0',
    },
  ],
  cardsInDeck: 21,
  time: time++,
}

export const gamePositionMocks = NEL.create([
  gamePosition0,
  gamePosition1,
  gamePosition2,
  gamePosition3,
  gamePosition4,
  gamePosition5,
])

const gameDataMock: TGameData = {
  gameId: '1234',
  currentGamePosition: NEL.head(gamePositionMocks),
  pastGamePositions: [],
  players: playersMock,
}

export default gameDataMock

export const gameDataMockAtTime = (t: number): TGameData => {
  if (t >= gamePositionMocks.length) {
    console.warn('gameDataMockAtTime given invalid/high time value', t)
    t = gamePositionMocks.length - 1
  }

  return {
    ...gameDataMock,
    currentGamePosition: {
      ...gamePositionMocks[t],
    },
    pastGamePositions: pastGamePositionsMock.slice(0, Math.max(0, t - 1)),
  }
}

const numGamePositions = gamePositionMocks.length
let pgpCount = 1
export const pastGamePositionsMock: TPastGamePosition[] = [
  {
    timestamp: minsAgo(numGamePositions - pgpCount),
    gamePosition: gamePositionMocks[pgpCount++],
    majorActionTaken: gamePosition1.inProgressMajorAction!.majorActionData,
    minorActionTaken: undefined,
  },
  {
    timestamp: minsAgo(numGamePositions - pgpCount),
    gamePosition: gamePositionMocks[pgpCount++],
    majorActionTaken: undefined,
    minorActionTaken: gamePosition2.inProgressMajorAction!.previousMinorActions[0],
  },
  {
    timestamp: minsAgo(numGamePositions - pgpCount),
    gamePosition: gamePositionMocks[pgpCount++],
    majorActionTaken: undefined,
    minorActionTaken: gamePosition3.inProgressMajorAction!.previousMinorActions[1],
  },
  {
    timestamp: minsAgo(numGamePositions - pgpCount),
    gamePosition: gamePositionMocks[pgpCount++],
    majorActionTaken: undefined,
    minorActionTaken: {
      type: 'birthday',
      minorActorPlayerId: playersMock[3].playerId,
      millionsOwed: 2,
      paidWith: [
        {
          type: 'wildPinkAndOrange',
          version: 1,
          id: 'wildPinkAndOrange-1',
        },
      ],
    },
  },
  {
    timestamp: minsAgo(numGamePositions - pgpCount),
    gamePosition: gamePositionMocks[pgpCount++],
    majorActionTaken: {
      type: 'changeNeighborhood',
      portfolioNeighborhoodId: 'orange-pn-0',
      card: {
        type: 'wildPinkAndOrange',
        version: 1,
        id: 'wildPinkAndOrange-1',
      },
    },
    minorActionTaken: undefined,
  },
]
