import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import SinglePageApplication from '~/pages/SPA'
import * as serviceWorker from '~/serviceWorker'
import store from '~/redux/store'
import {isDarkMode} from '~/utils'
import './global.css'

// set dark mode on startup
document
  .getElementsByTagName('html')[0]
  .setAttribute('class', isDarkMode ? 'dark' : 'light')

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SinglePageApplication />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
