import * as React from 'react'

// When navigating to a new page, you want it to scroll to the top on mount
export default class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return null
  }
}
