import classnames from 'classnames'
import {css} from 'emotion'
import * as React from 'react'
import {Menu, Item, Separator, ItemParams, contextMenu} from 'react-contexify'
import {TGameSpectatorData} from '~/api/generated/types/common'
import Gravatar from '~/components/Gravatar/stateless'
import SquareBorderAnimation from '~/components/SquareBorderAnimation/stateless'
import DSText from '~/design-system/DSText'
import TooltipWrapper from '~/design-system/Tooltip'
import {movesLeftForPlay} from '~/game/types'
import Card from '~/models/game/Card'
import PropertyCard from '~/models/game/Cards/PropertyCard'
import ResidenceCard from '~/models/game/Cards/ResidenceCard'
import GamePosition, {SpectatorGamePosition} from '~/models/game/GamePosition'
import Player from '~/models/game/Player'
import CardImage, {cardHeightFromWidth} from '~/pages/GameLobby/GameCard/stateless'
import PortfolioNeighborhood from '~/models/game/PortfolioNeighborhood'
import {px} from '~/components/utils'
import DualPropertyCard from '~/models/game/Cards/DualPropertyCard'
import {TSkin} from '~/game/skins'
import Portfolio from '~/models/game/Portfolio'
import {unreachableCase} from '~/utils'
import {pluralize} from '~/utils/language'
import * as NEL from '~/utils/NEList'
import styles from './styles.module.css'

import 'react-contexify/dist/ReactContexify.css'

export interface IPlayerPortfolioUIProps {
  player: Player
  gameId: string
  currentGamePosition: GamePosition
  currentUserId: string

  spectatorData: TGameSpectatorData[]
  requestSpectatorPermission: () => void

  skin: TSkin
  cardWidthPx: number
  classNames?: {
    portfolioRotationWrapper?: string
    portfolioInnerWrapper?: string
  }

  clickableCardIds: Set<string>
  onCardClicked: (card: Card<any>, event: React.MouseEvent<any>) => void
  selectedCardIds: Set<string>

  invertedRotationDeg: number
}

const SPECTATOR_AVATAR_CONTEXT_MENU_ID = (playerId: string) =>
  `SPECTATOR_AVATAR_CONTEXT_MENU_ID_${playerId}`

type TContextMenuOption = 'requestSpectate'

export default class PlayerPortfolioUI extends React.Component<
  IPlayerPortfolioUIProps
> {
  handleContextMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault()
    contextMenu.show({
      event,
      id: SPECTATOR_AVATAR_CONTEXT_MENU_ID(this.props.player.id),
    })
  }

  handleItemClick = (option: TContextMenuOption) => (_itemParams: ItemParams) => {
    switch (option) {
      case 'requestSpectate':
        this.props.requestSpectatorPermission()
        return
      default:
        return unreachableCase(option)
    }
  }

  renderContextMenu() {
    if (!(this.props.currentGamePosition instanceof SpectatorGamePosition)) {
      return null
    }

    const spectatorStatus = this.props.spectatorData.find(
      sd =>
        sd.spectatedPlayer.playerId === this.props.player.id &&
        sd.spectatorPlayer.playerId === this.props.currentUserId
    )?.status
    const [spectatorCopy, canRequest] = ((): [string, boolean] => {
      switch (spectatorStatus) {
        case 'approved':
          return [`Already spectating`, false]
        case 'denied':
          return [`Send another spectator req.`, true]
        case 'pending':
          return [`Spectator request pending`, false]
        case undefined:
          return [`Send spectator request`, true]
        default:
          return unreachableCase(spectatorStatus)
      }
    })()

    return (
      <Menu
        theme="light"
        id={SPECTATOR_AVATAR_CONTEXT_MENU_ID(this.props.player.id)}
      >
        <Item disabled>{this.props.player.username}</Item>
        <Separator />
        <Item
          disabled={!canRequest}
          onClick={this.handleItemClick('requestSpectate')}
        >
          {spectatorCopy}
        </Item>
      </Menu>
    )
  }

  render() {
    const {
      player,
      currentGamePosition,
      skin,
      cardWidthPx,
      clickableCardIds,
      onCardClicked,
      selectedCardIds,
      invertedRotationDeg,
    } = this.props
    const portfolio = player.portfolio

    const isMyMajorTurn = currentGamePosition.majorActorPlayerId === player.id
    const isMyMinorTurn = currentGamePosition.minorActorPlayer?.id === player.id

    const useContextMenu =
      this.props.currentGamePosition instanceof SpectatorGamePosition
    return (
      <>
        {this.renderContextMenu()}
        <div
          className={classnames(
            styles.portfolioRotationWrapper,
            this.props.classNames?.portfolioRotationWrapper
          )}
        >
          <div
            className={classnames(
              styles.portfolioInnerWrapper,
              this.props.classNames?.portfolioInnerWrapper
            )}
          >
            <div className={styles.playerIdentificationAndBadges}>
              <TooltipWrapper
                classNames={{
                  wrapper: classnames(
                    styles.gravatarTooltipWrapper,
                    css`
                      transform: rotate(${invertedRotationDeg}deg);
                    `
                  ),
                  tooltipBubble: styles.gravatarTooltipBubble,
                }}
                tooltipStyle="light"
                tipText={
                  <DSText size="label" className={styles.gravatarTooltipText}>
                    {player.username}
                  </DSText>
                }
              >
                <SquareBorderAnimation
                  onContextMenu={useContextMenu ? this.handleContextMenu : undefined}
                  sizePx={30}
                  clipDistancePct={0.1}
                  pathWidthPx={2.5}
                  color={isMyMajorTurn ? 'white' : 'red'}
                  hideAnimation={!isMyMajorTurn && !isMyMinorTurn}
                  speedSec={isMyMajorTurn ? 7 : 4}
                >
                  <Gravatar email={player.email} size={30} />
                </SquareBorderAnimation>
                <DSText
                  tag="p"
                  size="tiny"
                  color="white"
                  className={styles.handCount}
                >
                  {player.hand.cardCount()} cards
                  {isMyMajorTurn && (
                    <>
                      <br />
                      {movesLeftForPlay[currentGamePosition.play]}{' '}
                      {pluralize(movesLeftForPlay[currentGamePosition.play], 'move')}
                    </>
                  )}
                </DSText>
              </TooltipWrapper>
            </div>
            {portfolio.sortedNeighborhoods().map(pn => (
              <SingleNeighborhood
                key={pn.id}
                model={pn}
                skin={skin}
                cardWidthPx={cardWidthPx}
                clickableCardIds={clickableCardIds}
                onCardClicked={onCardClicked}
                selectedCardIds={selectedCardIds}
              />
            ))}
            <CashPile
              portfolio={portfolio}
              skin={skin}
              cardWidthPx={cardWidthPx}
              clickableCardIds={clickableCardIds}
              onCardClicked={onCardClicked}
              selectedCardIds={selectedCardIds}
            />
          </div>
        </div>
      </>
    )
  }
}

interface ISingleNeighborhoodProps {
  model: PortfolioNeighborhood
  skin: TSkin
  className?: string
  cardWidthPx: number

  clickableCardIds: Set<string>
  onCardClicked: (card: Card<any>, event: React.MouseEvent<any>) => void
  selectedCardIds: Set<string>
}

const SingleNeighborhood = (props: ISingleNeighborhoodProps) => {
  const {cardWidthPx, model, skin} = props
  const cardHeightPx = cardHeightFromWidth(cardWidthPx)

  // offset percentages based on the (variable) width of the card
  const xOffsetPct = 0.1
  const yOffsetPct = 0.09

  const properties = NEL.createSafe(model.sortedProperties())
  if (!properties) {
    console.warn(
      'Not rendering an empty portfolio neighborhood:',
      model.neighborhood,
      model
    )
    return null
  }

  const cardsToRender: NEL.NEList<PropertyCard<any> | ResidenceCard> = [
    ...properties,
    ...model.residences,
  ]

  return (
    <div
      className={classnames(
        styles.neighborhoodWrapper,
        styles[`neighborhood-${model.neighborhood}`]
      )}
      style={{
        width: px(
          cardWidthPx + (cardsToRender.length - 1) * xOffsetPct * cardWidthPx
        ),
        height: px(
          cardHeightPx + (cardsToRender.length - 1) * yOffsetPct * cardHeightPx
        ),
        margin: px(cardWidthPx * 0.05),
      }}
    >
      {cardsToRender.map((p, i) => (
        <CardImage
          key={p.id}
          model={p}
          skin={skin}
          invert={
            p instanceof DualPropertyCard &&
            p.upsideDownForNeighborhood(model.neighborhood)
          }
          className={classnames(
            styles.cardImage,
            props.clickableCardIds.has(p.id) && styles.clickable,
            props.selectedCardIds.has(p.id) && styles.selected,
            css`
              width: ${cardWidthPx}px;
              height: ${cardHeightPx}px;
              left: ${xOffsetPct * i * cardWidthPx}px;
              top: ${yOffsetPct * i * cardHeightPx}px;
            `
          )}
          onClick={e => {
            props.clickableCardIds.has(p.id) && props.onCardClicked(p, e)
          }}
        />
      ))}
    </div>
  )
}

interface ICashPileProps {
  portfolio: Portfolio
  skin: TSkin
  className?: string
  cardWidthPx: number

  clickableCardIds: Set<string>
  onCardClicked: (card: Card<any>, event: React.MouseEvent<any>) => void
  selectedCardIds: Set<string>
}

const CashPile = (props: ICashPileProps) => {
  const {cardWidthPx, portfolio, skin} = props

  if (portfolio.liquidatedCards.length === 0) {
    return null
  }

  const cardHeightPx = cardHeightFromWidth(cardWidthPx)
  // offset percentages based on the (variable) width of the card
  const xGroupOffsetPct = 0.15
  const xGroupeeOffsetPct = 0.1
  const yGroupOffsetPct = 0.06
  const yGroupeeOffsetPct = 0

  let cardImages: React.ReactNodeArray = []
  let largestGroupLength = 0
  const sortedCashPile = portfolio.sortedCashPile()
  let [totalXOffsetPct, totalYOffsetPct] = [0, 0]

  sortedCashPile.map((valueGroup, i) => {
    largestGroupLength = Math.max(largestGroupLength, valueGroup.length)

    valueGroup.map((card, j) => {
      cardImages.push(
        <CardImage
          key={card.id}
          model={card}
          skin={skin}
          invert={false}
          className={classnames(
            styles.cardImage,
            props.clickableCardIds.has(card.id) && styles.clickable,
            props.selectedCardIds.has(card.id) && styles.selected,
            css`
              width: ${cardWidthPx}px;
              height: ${cardHeightPx}px;
              left: ${totalXOffsetPct * cardWidthPx}px;
              top: ${totalYOffsetPct * cardHeightPx}px;
            `
          )}
          onClick={e => {
            props.clickableCardIds.has(card.id) && props.onCardClicked(card, e)
          }}
        />
      )

      if (j < valueGroup.length - 1) {
        totalXOffsetPct += xGroupeeOffsetPct
        totalYOffsetPct += yGroupeeOffsetPct
      }
    })

    if (i < sortedCashPile.length - 1) {
      totalXOffsetPct += xGroupOffsetPct
      totalYOffsetPct += yGroupOffsetPct
    }
  })

  return (
    <div
      className={styles.cashPileWrapper}
      style={{
        width: px(totalXOffsetPct * cardWidthPx + cardWidthPx),
        height: px(totalYOffsetPct * cardHeightPx + cardHeightPx),
        margin: px(cardWidthPx * 0.05),
      }}
    >
      {cardImages}
    </div>
  )
}
