import {faUsers} from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'
import React from 'react'
import {TGameSpectatorData, TPlayerData} from '~/api/generated/types/common'
import Gravatar from '~/components/Gravatar/stateless'
import ExternalClickDetector from '~/components/ExternalClickDetector'
import {SingleButtonForm} from '~/components/SingleButtonForm'
import DSText, {DSTextDiv} from '~/design-system/DSText'
import {shadowStyleToClass} from '~/design-system/ShadowUtils'
import TooltipWrapper from '~/design-system/Tooltip'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import {AlternateStateFontAwesomeIcon} from '~/utils/FontAwesome/HoverableFontAwesomeIcon'
import * as M from '~/utils/Map'

import styles from './styles.module.css'

interface ISpectatorButtonProps {
  className?: string
  spectatorData: TGameSpectatorData[]
  playerGamePosition: PlayerGamePosition

  approveSpectator: (spectatorId: string) => Promise<any>
  revokeSpectator: (spectatorId: string) => Promise<any>
}

interface ISpectatorButtonState {
  menuOpen: boolean
}

export class SpectatorMenuButton extends React.Component<
  ISpectatorButtonProps,
  ISpectatorButtonState
> {
  state: ISpectatorButtonState = {
    menuOpen: false,
  }
  menuRef: React.RefObject<any> = React.createRef()
  iconRef: React.RefObject<any> = React.createRef()

  renderGravatarWithTooltip(player: TPlayerData, size: number) {
    return (
      <TooltipWrapper
        classNames={{
          wrapper: styles.gravatarTooltipWrapper,
          tooltipBubble: styles.gravatarTooltipBubble,
        }}
        tooltipStyle="dark"
        tipText={
          <DSText size="label" className={styles.gravatarTooltipText}>
            {player.username}
          </DSText>
        }
      >
        <Gravatar email={player.email} size={size} />
      </TooltipWrapper>
    )
  }

  render() {
    const currentPlayerId = this.props.playerGamePosition.currentPlayer.id

    const filterOutRejected = this.props.spectatorData.filter(
      sd => sd.status !== 'denied'
    )

    // spectatedPlayerId -> TGameSpectatorData[]
    const spectatorDataPerPlayer: Map<string, TGameSpectatorData[]> = M.defaultAllTo(
      this.props.playerGamePosition.players.map(p => p.id),
      [],
      M.groupBy(sd => sd.spectatedPlayer.playerId, filterOutRejected)
    )
    const sortedSpectatorDataPerPlayer = M.sortKeys(
      [
        (a, b) => {
          return a === currentPlayerId ? -1 : b === currentPlayerId ? 1 : 0
        },
      ],
      spectatorDataPerPlayer
    )

    const myPendingRequests =
      spectatorDataPerPlayer
        .get(currentPlayerId)
        ?.filter(sd => sd.status === 'pending').length ?? 0
    const activeSpectators =
      !!spectatorDataPerPlayer.get(currentPlayerId)?.length ||
      M.anyPass(
        sd => !!sd.find(d => d.status === 'approved'),
        M.omit(currentPlayerId, spectatorDataPerPlayer)
      )

    return (
      <div className={classnames(this.props.className, styles.spectatorIconWrapper)}>
        <ExternalClickDetector
          needsDetection={this.state.menuOpen}
          targetRefs={[
            this.menuRef,
            // treat the icon as "internal" to the menu. it handles closing on its clicks separately.
            // if we don't include this, the menu will close itself here, but then re-open itself in its onClick
            this.iconRef,
          ]}
          externalClickDetected={() => this.setState({menuOpen: false})}
        />
        <AlternateStateFontAwesomeIcon
          forwardedRef={this.iconRef}
          icon={{
            icon: faUsers,
            style: {
              width: '30px',
              height: '30px',
            },
            color: 'grey-dark',
            className: styles.spectatorIcon,
          }}
          alternateIcon={{
            color: 'blue-light',
          }}
          alternateState={activeSpectators}
          onClick={() =>
            this.setState(prevState => ({menuOpen: !prevState.menuOpen}))
          }
        />
        {myPendingRequests > 0 && (
          <div className={styles.spectatorIconNotification}>{myPendingRequests}</div>
        )}
        {this.state.menuOpen && (
          <div
            ref={this.menuRef}
            className={classnames(
              styles.spectatorMenuWrapper,
              shadowStyleToClass('white', true)
            )}
          >
            <DSTextDiv size="label-demi" className={styles.centered}>
              Spectators
            </DSTextDiv>
            <div className={styles.spectatorLists}>
              <div className={styles.divider} />
              {M.mapEntries((spectatorDatas, spectatedPlayerId, idx) => {
                if (
                  spectatedPlayerId ===
                  this.props.playerGamePosition.currentPlayer.id
                ) {
                  return (
                    <div
                      key={spectatedPlayerId}
                      className={classnames(
                        styles.spectatorListWrapper,
                        styles.mySpectators
                      )}
                    >
                      {this.renderGravatarWithTooltip(
                        this.props.playerGamePosition.currentPlayer.datasource,
                        40
                      )}
                      <div className={styles.spectatorList}>
                        {spectatorDatas.length === 0 && (
                          <DSTextDiv size="tiny" color="grey-dark">
                            (none)
                          </DSTextDiv>
                        )}
                        {spectatorDatas.map(spectatorData => {
                          const {spectatorPlayer, status} = spectatorData
                          if (status === 'denied') {
                            return null
                          }
                          return (
                            <div
                              className={styles.spectatorWrapper}
                              key={spectatedPlayerId + spectatorPlayer.playerId}
                            >
                              {this.renderGravatarWithTooltip(spectatorPlayer, 30)}
                              <div className={styles.spectatorListButtons}>
                                <SingleButtonForm
                                  style={{
                                    visibility:
                                      status === 'approved' ? 'hidden' : undefined,
                                  }}
                                  onSubmit={() =>
                                    this.props.approveSpectator(
                                      spectatorPlayer.playerId
                                    )
                                  }
                                  loadingStyle="none"
                                >
                                  ✅
                                </SingleButtonForm>
                                <SingleButtonForm
                                  onSubmit={() =>
                                    this.props.revokeSpectator(
                                      spectatorPlayer.playerId
                                    )
                                  }
                                  loadingStyle="none"
                                >
                                  🚫
                                </SingleButtonForm>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div
                      className={styles.spectatorListWrapper}
                      key={spectatedPlayerId}
                    >
                      {this.renderGravatarWithTooltip(
                        this.props.playerGamePosition.players.find(
                          p => p.id === spectatedPlayerId
                        )!.datasource,
                        40
                      )}
                      <div className={styles.spectatorList}>
                        {spectatorDatas.filter(sd => sd.status === 'approved')
                          .length === 0 && (
                          <DSTextDiv size="tiny" color="grey-dark">
                            (none)
                          </DSTextDiv>
                        )}
                        {spectatorDatas.map(spectatorData => {
                          const {spectatorPlayer, status} = spectatorData
                          if (status !== 'approved') {
                            return null
                          }
                          return (
                            <div
                              className={styles.spectatorWrapper}
                              key={spectatedPlayerId + spectatorPlayer.playerId}
                            >
                              {this.renderGravatarWithTooltip(spectatorPlayer, 30)}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                }
              }, sortedSpectatorDataPerPlayer)}
            </div>
          </div>
        )}
      </div>
    )
  }
}
