import classnames from 'classnames'
import * as React from 'react'
import {px} from '~/components/utils'
import styles from './styles.module.css'

interface IProps {
  sizePx: number
  className?: string
}

export default function Octagon(props: IProps) {
  return (
    <div className={props.className}>
      <div
        className={classnames(styles.octagon)}
        style={{width: px(props.sizePx), height: px(props.sizePx)}}
      />
    </div>
  )
}
