import {TMajorActionChangeNeighborhoodData} from '~/api/generated/types/common'
import {cardFactory} from '~/models/game/factories'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MajorAction, {
  TMajorActionUserFacingDescriptionData,
} from '~/models/game/MajorAction'
import SpecialPropertyCard from '~/models/game/Cards/SpecialPropertyCard'
import {unambiguousNeighborhoodDescription} from '~/models/game/MajorActions/utils'
import {compact, mutateIf} from '~/utils'
import {inTense, sentenceCase} from '~/utils/language'

type PublicMembersFromApi = Pick<
  TMajorActionChangeNeighborhoodData,
  'portfolioNeighborhoodId'
>

export default class MajorActionChangeNeighborhood
  extends MajorAction
  implements PublicMembersFromApi {
  // TODO can StandardPropertyCards be moved neighborhoods?
  //   E.g. You have 2 yellow stacks, one a set of 3 w/ a wildcard, and another lone yellow.
  //   Can you move a standard yellow from the set to the lone card, so you have 2 stacks of 2?
  //   https://mercurytechnologies.slack.com/archives/C90N3EVND/p1592548366247800
  //
  //   If the answer is no to the 2&2 question, then I don't think standard properties can ever change neighborhoods
  //   except forcibly (i.e. the server merges your neighborhoods for you, because the rules require it, if you remove
  //   the wildcard in the above example, for example, the server would return a new game state that had the 3 standard
  //   properties combined).
  //
  //   But then you must also wonder, when would the server force the neighborhoods to merge? Say you pay rent with
  //   the wildcard in the set - do your standard yellows all become a set instantly, or only at the start of your next
  //   turn?
  card: SpecialPropertyCard<any>
  portfolioNeighborhoodId: string // what neighborhood the card was moved to

  constructor(data: TMajorActionChangeNeighborhoodData) {
    super(data)
    this.card = cardFactory(data.card)
    this.portfolioNeighborhoodId = data.portfolioNeighborhoodId
  }

  public userFacingDescription({
    tense,
    skin,
    gamePosition,
  }: TMajorActionUserFacingDescriptionData): string {
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined
    const majorActor = gamePosition.majorActorPlayer
    const dropFirstNoun = tense === 'imperativePrompt'

    return compact([
      dropFirstNoun
        ? undefined
        : currentPlayer?.id === majorActor.id
        ? 'You'
        : majorActor.username,
      mutateIf(inTense(tense)('move'), dropFirstNoun, sentenceCase),
      this.card.displayName(skin),
      `to`,
      unambiguousNeighborhoodDescription(
        {skin, gamePosition},
        this.portfolioNeighborhoodId
      ),
    ]).join(' ')
  }
}
