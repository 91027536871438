import {OmitStrict} from 'type-zoo'
import {TMinorActionPromptPaySomethingBaseData} from '~/game/types'
import MinorAction from '~/models/game/MinorAction'
import MinorActionPrompt, {
  TMinorActionPromptValidResponseData,
} from '~/models/game/MinorActionPrompt'
import Player from '~/models/game/Player'

type PublicMembersFromApi = OmitStrict<
  TMinorActionPromptPaySomethingBaseData,
  'type'
>

export default abstract class MinorActionPromptPaySomething<
    DataType extends TMinorActionPromptPaySomethingBaseData
  >
  extends MinorActionPrompt<DataType>
  implements PublicMembersFromApi {
  millionsOwed: number

  constructor(data: DataType, majorActor: Player, minorActor: Player) {
    super(data, majorActor, minorActor)
    this.millionsOwed = data.millionsOwed
  }

  // override from MinorActionPrompt
  public validResponses(
    data: TMinorActionPromptValidResponseData
  ): MinorAction<any>[] {
    // the server should not send minor action prompts to pay something to players that can't pay anything
    const totalValue = data.portfolio.totalValue()
    if (totalValue <= 0) {
      throw new Error(
        'Minor action to pay rent received by a player with no portfolio value.'
      )
    }

    return super.validResponses(data)
  }
}
