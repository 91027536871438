import {TPropertyWildCardPortfolioNeighborhoodData} from '~/api/generated/types/common'
import PortfolioNeighborhood from '~/models/game/PortfolioNeighborhood'

// Represents property wild cards that are alone on the table in front of a player
// this is a relatively common edge case of game play, where the only property
// in front of a player is a PWC. If a player has any other properties that aren't
// a part of a complete set, the PWC is required to be paired with one of those instead.
export default class WildCardPortfolioNeighborhood extends PortfolioNeighborhood {
  // protected raw: TPropertyWildCardPortfolioNeighborhoodData

  constructor(data: TPropertyWildCardPortfolioNeighborhoodData) {
    super(data)
    // this.raw = data
  }

  // even 2 PWCs alone don't count as a set - you can't win with them this way and you can't put residences on them
  public isCompleteSet(): boolean {
    return false
  }

  isStandardPortfolioNeighborhood() {
    return false
  }

  rent(): number | undefined {
    return undefined
  }
}
