import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {TUserDetails} from '~/api/generated/types/common'
import User from '~/models/app/User'

export type UserState = {
  user: User | undefined
}

const initialState: UserState = {
  user: undefined,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state: UserState, action: PayloadAction<TUserDetails>) {
      const user = new User(action.payload)
      return {user}
    },
    clearUser() {
      return {user: undefined}
    },
  },
})

const {setUser, clearUser} = userSlice.actions
export {setUser, clearUser}
export default userSlice.reducer
