import * as R from 'ramda'
import * as React from 'react'
import {connect} from 'react-redux'
import {Route, RouteComponentProps, RouteProps} from 'react-router-dom'
import {OmitStrict} from 'type-zoo'
import User from '~/models/app/User'
import {TNotSignedInYetReferrerPayload} from '~/pages/Auth/SignIn'
import {ReduxState} from '~/redux/reducers/root'
import {UserState} from '~/redux/reducers/user'
import {Redirect} from '~/routing/ReactRouterLinks'
import {ROUTE_TYPE_KEY, SimpleRouteComponentType} from '~/routing/Route'
import {staticURLs} from '~/routing/sitemap'

export interface ISignedInRouteComponentProps<T>
  extends RouteComponentProps<T>,
    ExtraProps {}

interface PropsFromRouter extends OmitStrict<RouteProps, 'component'> {
  component:
    | React.ComponentType<ISignedInRouteComponentProps<any>>
    // this allows a Route.SignedIn component w/o ISignedInRequiresOrgRouteComponentProps
    // included in its props type, see `render` below for more
    | SimpleRouteComponentType
}

interface ReduxProps extends Pick<UserState, 'user'> {}

interface IProps extends ReduxProps, PropsFromRouter {}

const ROUTE_TYPE = 'SignedInRoute'

// all SignedIn routes get these props, extending what RouteComponentProps provides
export interface IPropsGuaranteedBySignedInRoutes {
  user: User
}
// IPropsGuaranteedBySignedInRoutes with the ROUTE_TYPE thing added to it for internal use
interface ExtraProps extends IPropsGuaranteedBySignedInRoutes {
  // This is what gets the TS compiler to check that we've used the correct Route/RouteComponent pairing in routes.tsx
  // The props type of `this.props.component` MUST match this interface, which requires that this special key/value
  // pair exists, with the correct value. (the value is `typeof ROUTE_TYPE` which is `'SignedInRoute'` [not `string`])
  // If a route component's props don't extend this (via ISignedInRouteComponentProps), the types won't match
  // and the compiler will complain.
  [ROUTE_TYPE_KEY]: typeof ROUTE_TYPE
}

const SignedIn = ({
  // ReduxProps
  user,

  component,
  ...routeProps
}: IProps) => {
  if (!user) {
    const payload: TNotSignedInYetReferrerPayload = {
      referrer: routeProps.location,
    }

    console.warn(
      'Not logged in yet. Redirecting to log in. Referrer: ',
      payload.referrer
    )
    return (
      <Redirect
        to={{
          pathname: staticURLs.auth.signin,
          state: payload,
        }}
      />
    )
  }

  return (
    <Route
      {...routeProps}
      render={routeComponentProps => {
        return React.createElement<ISignedInRouteComponentProps<any>>(
          // the cast here is necessary for the above `| SimpleRouteComponentType` to be allowed -
          // we want to allow components that don't need a guaranteed `user` but are still using `Route.SignedIn`
          // to have a simple constructor / type signature. Without this cast, every `Route.SignedIn` would have
          // to declare in it's props that it takes `ISignedInRouteComponentProps`.
          component as React.ComponentType<ISignedInRouteComponentProps<any>>,
          {
            ...routeComponentProps,
            user,
            [ROUTE_TYPE_KEY]: ROUTE_TYPE,
          }
        )
      }}
    />
  )
}

// NB: the `ownProps: RouteProps` bit here is necessary for TS to infer the correct version of `connect` we're
// using here (Redux overloads this function heavily with generics, so we need the type `RouteProps` declared here)
const mapStateToProps = (state: ReduxState, ownProps: RouteProps): ReduxProps => ({
  user: state.userState.user,
})

export default connect(mapStateToProps)(SignedIn)

// since we sometimes need to pass the modified RouteComponentProps down to sub-components
// this helper can filter out all the other props a RouteComponent might have (say, from redux)
// without needing a large {redux1, ..., reduxN, ...routeComponentProps} rest operator
// (we could also try implementing a custom withRouter, but this works for now)
export const isolateSignedInRouteComponentProps = <T extends any = any>(
  props: ISignedInRouteComponentProps<T>
): ISignedInRouteComponentProps<T> => {
  return R.pick(
    [ROUTE_TYPE_KEY, 'user', 'history', 'match', 'location', 'staticContext'],
    props
  )
}

export const isolateRouteComponentProps = <T extends object = any>(
  props: RouteComponentProps<T>
): RouteComponentProps<T> => {
  return R.pick(['user', 'history', 'match', 'location', 'staticContext'], props)
}
