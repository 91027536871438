import * as R from 'ramda'
import {TSkin} from '~/game/skins'
import Card from '~/models/game/Card'
import PropertyCard from '~/models/game/Cards/PropertyCard'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MinorActionPaySomething from '~/models/game/MinorActions/MinorActionPaySomething'
import {AnyValuableCard} from '~/models/game/types'
import {valueOfCards} from '~/models/game/utils'
import {combinations} from '~/utils'
import * as S from '~/utils/Set'

type TImproperPaymentResponseType =
  | 'tooManyCards'
  | 'invalidCardSelected' // a PWC is selected
  | 'moreMoneyOwed'
type TImproperPaymentResponse = {type: TImproperPaymentResponseType} & (
  | {type: 'tooManyCards'; currentPayment: number}
  | {type: 'invalidCardSelected'}
  | {type: 'moreMoneyOwed'; currentPayment: number; minimumPayment: number}
)
export type TProperPaymentResponse = {
  type: 'proper'
  paidWith: AnyValuableCard[]
}

// Given a MinorActionPaySomething that doesn't have it's paidWith array filled out, a PlayerGamePosition,
// and a list of currently-selected-to-pay-with card IDs, determine whether or not
// the player's selected cards constitute a proper payment for the MinorActionPaySomething.
// TODO write some tests for this guy
export const paymentPropriety = (
  payFor: MinorActionPaySomething<any>,
  playerGamePosition: PlayerGamePosition,
  selectedCardIds: Set<string>
): TProperPaymentResponse | TImproperPaymentResponse => {
  const {currentPlayer} = playerGamePosition
  const {millionsOwed} = payFor
  const maxCapablePayment = currentPlayer.portfolio.totalValue()
  const allValuableCards = currentPlayer.portfolio.allValuableCards()
  const allValuableCardIds = S.create(allValuableCards.map(c => c.id))
  const currentPayment = valueOfCards(
    allValuableCards.filter(c => selectedCardIds.has(c.id))
  )

  // these two cases are probably somewhat mutually exclusive, and maybe this isn't even possible, depends on
  // the logic where selectable cards are chosen, but either way these don't hurt
  if (maxCapablePayment === 0) {
    // if they only have a PWC, they can't pay, they might select it? weird case.
    return selectedCardIds.size === 0
      ? {type: 'proper', paidWith: []}
      : {type: 'invalidCardSelected'}
  }
  if (S.difference(selectedCardIds, allValuableCardIds).size > 0) {
    return {type: 'invalidCardSelected'}
  }

  if (maxCapablePayment <= millionsOwed) {
    // they owe everything they have.
    return S.equal(allValuableCardIds, selectedCardIds)
      ? {type: 'proper', paidWith: allValuableCards}
      : {type: 'moreMoneyOwed', currentPayment, minimumPayment: maxCapablePayment}
  }

  // they have more money than they owe, this is where they actually have some choices
  const selectedCards = allValuableCards.filter(c => selectedCardIds.has(c.id))

  if (currentPayment < millionsOwed) {
    return {type: 'moreMoneyOwed', currentPayment, minimumPayment: millionsOwed}
  } else if (currentPayment === millionsOwed) {
    return {type: 'proper', paidWith: selectedCards}
  }

  // players are allowed to overpay, as long as removing any one card makes the payment Improper again
  // (e.g. you can't overpay a $4m rent with a 2x$5m cards, but you can overpay with 2x$3m cards
  for (const selectedCard of selectedCards) {
    if (currentPayment - selectedCard.value >= millionsOwed) {
      return {type: 'tooManyCards', currentPayment}
    }
  }

  return {type: 'proper', paidWith: selectedCards}
}

export const isProperPayment = (
  payFor: MinorActionPaySomething<any>,
  playerGamePosition: PlayerGamePosition,
  selectedCardIds: Set<string>
): boolean => {
  return (
    paymentPropriety(payFor, playerGamePosition, selectedCardIds).type === 'proper'
  )
}

type TObviousPaymentResponseType =
  | 'exactValue' // they have a single cash card for exactly the charged amount. this is usually the right play.
  | 'wipedOut' // they owe everything they have.
  | 'onlyChoice' // there is only one legal way to make the payment.
  | 'lowestDenomination' // ~exactValue but overpaying. e.g. $2m rent, but all I have is $3m cash cards
interface IObviousPaymentResponseBase {
  type: TObviousPaymentResponseType
}
interface TObviousPaymentResponseExactValue extends IObviousPaymentResponseBase {
  type: 'exactValue'
  paidWith: AnyValuableCard[] // actually just one card, but simpler for now to always pass an array
}
interface TObviousPaymentResponseWipedOut extends IObviousPaymentResponseBase {
  type: 'wipedOut'
  paidWith: AnyValuableCard[]
}
interface TObviousPaymentResponseOnlyChoice extends IObviousPaymentResponseBase {
  type: 'onlyChoice'
  paidWith: AnyValuableCard[]
}
interface TObviousPaymentResponseLowestDenomination
  extends IObviousPaymentResponseBase {
  type: 'lowestDenomination'
  paidWith: AnyValuableCard[] // actually just one card, but simpler for now to always pass an array
}
type TObviousPaymentResponse =
  | TObviousPaymentResponseExactValue
  | TObviousPaymentResponseWipedOut
  | TObviousPaymentResponseOnlyChoice
  | TObviousPaymentResponseLowestDenomination

/**
 * given a scenario where the player owes money, there may be some obvious
 * payment we can present to the user as a shortcut.
 */
export const getObviousPayment = (
  payFor: MinorActionPaySomething<any>,
  playerGamePosition: PlayerGamePosition
): TObviousPaymentResponse | undefined => {
  const {currentPlayer} = playerGamePosition
  const {millionsOwed} = payFor
  const maxCapablePayment = currentPlayer.portfolio.totalValue()
  const allValuableCards = currentPlayer.portfolio.allValuableCards()
  const liquidatedCards = currentPlayer.portfolio.liquidatedCards
  const allValuableCardIds = S.create(allValuableCards.map(c => c.id))

  if (maxCapablePayment <= millionsOwed) {
    // they owe everything they have.
    return {type: 'wipedOut', paidWith: allValuableCards}
  }

  // search the cash pile for obvious payments
  const exactValueCard = liquidatedCards.find(lc => lc.value === millionsOwed)
  if (exactValueCard) {
    return {type: 'exactValue', paidWith: [exactValueCard]}
  }

  // if paying with every card we have isProperPayment, then we are also wipedOut.
  // e.g. I have 2x$2m cards and the rent is $3m, even though I have more than $3m i have no choice.
  if (isProperPayment(payFor, playerGamePosition, allValuableCardIds)) {
    return {type: 'wipedOut', paidWith: allValuableCards}
  }

  const cardLessThanOrEqualToPayment = allValuableCards.find(
    lc => lc.value <= millionsOwed
  )
  if (!cardLessThanOrEqualToPayment) {
    // they have no cards valued less than the payment amount. If they have a cash card
    // with a value equal to their lowest value card (say, a $3m cash and a $3m property)
    // they likely just want to use the cash.
    const lowestCardValue = allValuableCards.reduce(
      (v, c) => Math.min(v, c.value),
      allValuableCards[0].value ?? 0
    )
    const cashCardWithLowestValue = liquidatedCards.find(
      c => c.value === lowestCardValue
    )
    if (cashCardWithLowestValue) {
      return {type: 'lowestDenomination', paidWith: [cashCardWithLowestValue]}
    }
  }

  if (allValuableCards.length < 5) {
    // for small hand counts, we can try something more complex.
    // if only one valid combination of cards can pay, then we can preselect it, too.
    // say, the rent is $3m and we have 2x$1m and a 2x$5m, we have to pay with a $5m.
    // limited to small card counts to limit performance effects (untested, could be fine higher?)
    // but theoretically this situation is also harder to come across with many cards
    const validCombos = combinations(allValuableCards).filter(
      cs =>
        cs.length &&
        isProperPayment(payFor, playerGamePosition, S.create(cs.map(c => c.id)))
    )

    // treat all non-properties as identical before checking if there is only one way to pay,
    // since all equal-value cash/residences/action cards/rents are identical once liquidated to the cash pile
    const stringifiedCombos = new Set<string>(
      validCombos.map(vc =>
        vc
          .map(c => (c instanceof PropertyCard ? c.id : c.value))
          .sort()
          .join(',')
      )
    )

    if (stringifiedCombos.size === 1) {
      return {type: 'onlyChoice', paidWith: validCombos[0]}
    }
  }

  return undefined
}

// converts ['Pass Go', 'Pass Go', '$5M'] to ['2x Pass Go', '$5M']
export const groupedCardDisplayNames = (
  cards: Card<any>[],
  skin: TSkin
): string[] => {
  const cardNames = cards.map(c => c.shortDisplayName(skin))
  const dupesGrouped = R.groupWith(R.equals, cardNames)
  return dupesGrouped.map(grouping =>
    grouping.length > 1 ? `${grouping.length}x ${grouping[0]}` : grouping[0]
  )
}
