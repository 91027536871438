import {
  TStandardPropertyCardData,
  TStandardPropertyType,
} from '~/api/generated/types/common'
import PropertyCard from '~/models/game/Cards/PropertyCard'
import {cardValue} from '~/game/types'

export default class StandardPropertyCard extends PropertyCard<
  TStandardPropertyCardData
> {
  public value: number

  constructor(data: TStandardPropertyCardData) {
    super(data)
    this.value = cardValue[data.type]
  }

  public fileName(): string {
    return `prop_${this.type}${this.version}`
  }

  public cardTypes(): Set<TStandardPropertyType> {
    return new Set([this.type])
  }
}
