import {TMajorActionPlayToNeighborhoodData} from '~/api/generated/types/common'
import {BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID} from '~/api/types'
import {neighborhoodCardFactory} from '~/models/game/factories'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MajorAction, {
  TMajorActionUserFacingDescriptionData,
} from '~/models/game/MajorAction'
import PropertyCard from '~/models/game/Cards/PropertyCard'
import ResidenceCard from '~/models/game/Cards/ResidenceCard'
import {unambiguousNeighborhoodDescription} from '~/models/game/MajorActions/utils'
import {compact, mutateIf} from '~/utils'
import {inTense, sentenceCase} from '~/utils/language'

/**
 *  Used to play a card from your hand.
 *  MajorActionChangeNeighborhood moves a SpecialPropertyCard to a different neighborhood.
 */
export default class MajorActionPlayToNeighborhood extends MajorAction {
  card: ResidenceCard | PropertyCard<any>

  // NB: if this is the first card in a neighborhood (not possible for residences), we won't have an ID.
  // we currently send BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID here if necessary
  portfolioNeighborhoodId: string

  constructor(data: TMajorActionPlayToNeighborhoodData) {
    super(data)
    this.card = neighborhoodCardFactory(data.card)
    this.portfolioNeighborhoodId = data.portfolioNeighborhoodId
  }

  public userFacingDescription({
    tense,
    skin,
    gamePosition,
  }: TMajorActionUserFacingDescriptionData): string {
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined
    const majorActor = gamePosition.majorActorPlayer
    const dropFirstNoun = tense === 'imperativePrompt'

    const placement =
      this.portfolioNeighborhoodId === BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID
        ? `the table`
        : unambiguousNeighborhoodDescription(
            {skin, gamePosition},
            this.portfolioNeighborhoodId
          )

    return compact([
      dropFirstNoun
        ? undefined
        : currentPlayer?.id === majorActor.id
        ? 'You'
        : majorActor.username,
      mutateIf(inTense(tense)('place'), dropFirstNoun, sentenceCase),
      this.card.displayName(skin),
      `on`,
      placement,
    ]).join(' ')
  }
}
