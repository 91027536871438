import {TCard, TCardData} from '~/api/generated/types/common'
import {TRuleset} from '~/game/ruleset'
import {skins, TSkin, TSkinData, TSpecificCards} from '~/game/skins'
import {cardsPerSet} from '~/game/types'
import {DataBackedModel} from '~/models/base'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import Portfolio from '~/models/game/Portfolio'
import {AnyMajorAction, AnyValuableCard} from '~/models/game/types'

export type TAvailableMovesData = {
  // the portfolio of the player making the decision.
  portfolio: Portfolio
  rules: TRuleset
  // since GameData doesn't guarantee one of these, but we can when considering available moves (for now?)
  playerGamePosition: PlayerGamePosition
}

export default abstract class Card<CardDataType extends TCardData>
  extends DataBackedModel<CardDataType>
  implements TCardData {
  // public klass: TCardClass
  /* declare */ public type!: CardDataType['type']
  /**
   * A unique identifier per each type of card in the deck.
   * e.g. there are 10 PassGo cards in a deck, so we'd have one each of versions 0-9 of PassGo cards in the deck.
   *
   * NB: If we ever support multiple decks in one game, either this version would
   * be 0-20 for two decks worth of PassGo cards, or we would have a `deck: number` also.
   */
  /* declare */ public version!: number
  /* declare */ public id!: string
  specificCardType: TSpecificCards

  public abstract value: number | undefined

  constructor(data: CardDataType) {
    super(data)
    // this.klass = cardClass[data.type]
    // workaround here necessary maybe until `declare` works
    // right now the properties above w/o initializers cause the `DataBackedModel` constructor's
    // dynamically assigned values to be overwritten by `undefined`
    Object.assign(this, data)

    this.specificCardType = ((): TSpecificCards => {
      const genericType: TCard = this.datasource.type
      switch (genericType) {
        case 'darkBlue':
        case 'brown':
        case 'utility':
        case 'green':
        case 'yellow':
        case 'red':
        case 'orange':
        case 'pink':
        case 'lightBlue':
        case 'railroad':
          // e.g. `darkBlue0` and `darkBlue1`
          return `${this.type}${
            this.version % cardsPerSet[genericType]
          }` as TSpecificCards
        default:
          return genericType
      }
    })()
  }

  public abstract fileName(skinData: TSkinData): string

  public abstract availableMovesFromHand(
    data: TAvailableMovesData
  ): AnyMajorAction | AnyMajorAction[]

  // most cards can't be removed / changed once on the table, except properties and residences, hence the default
  // this is overridden when necessary
  public availableMovesFromTable(
    data: TAvailableMovesData
  ): AnyMajorAction | AnyMajorAction[] {
    return []
  }

  public imagePath(skin: TSkin) {
    const skinData = skins[skin]
    return `/cards/${skin}/${this.fileName(skinData)}.${skinData.ext}`
  }

  public displayName(skin: TSkin): string {
    return skins[skin].cardNames[this.specificCardType]
  }

  public shortDisplayName(skin: TSkin): string {
    return skins[skin].shortCardNames[this.specificCardType]
  }

  public static displayNameForCard(cardType: TSpecificCards, skin: TSkin): string {
    return skins[skin].cardNames[cardType]
  }

  public shortDescription(skin: TSkin): string {
    return skins[skin].shortCardDescriptions(this.specificCardType)
  }

  public isValuable = (): this is AnyValuableCard => this.type !== 'propertyWildCard'

  // this is overridden when applicable
  public isFlippable = (): boolean => false
}
