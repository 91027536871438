import * as React from 'react'
import {connect} from 'react-redux'
import {OmitStrict} from 'type-zoo'
import api from '~/api/endpoints'
import GameData from '~/models/game/GameData'
import PlayerPortfolioUI, {
  IPlayerPortfolioUIProps,
} from '~/pages/GameLobby/GameBoard/Portfolio/stateless'
import {ReduxState} from '~/redux/reducers/root'
import {setSpectatorData} from '~/redux/reducers/spectator'

interface IReduxProps extends Pick<ReduxState, 'spectatorState'> {}
interface IProps
  extends IReduxProps,
    TDispatch,
    OmitStrict<
      IPlayerPortfolioUIProps,
      | 'spectatorData'
      | 'requestSpectatorPermission'
      | 'gameId'
      | 'currentGamePosition'
      | 'currentUserId'
    > {
  gameData: GameData
}

class PlayerPortfolio extends React.Component<IProps> {
  render() {
    const {
      gameData,
      spectatorState,
      setSpectatorData: createSetSpectatorAction,
      ...uiProps
    } = this.props
    return (
      <PlayerPortfolioUI
        gameId={gameData.gameId}
        currentGamePosition={gameData.currentGamePosition}
        spectatorData={spectatorState.spectatorData}
        currentUserId={gameData.currentUserId}
        requestSpectatorPermission={() => {
          void api.inGame.requestSpectatorPermission
            .post(
              {},
              {gameId: gameData.gameId, spectatedPlayerId: uiProps.player.id}
            )
            .then(r => {
              createSetSpectatorAction(r.spectatorRequests)
            })
        }}
        {...uiProps}
      />
    )
  }
}

const mapStateToProps = (state: ReduxState): IReduxProps => ({
  spectatorState: state.spectatorState,
})

const mapDispatchToProps = {setSpectatorData}
type TDispatch = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(PlayerPortfolio)
