import {OmitStrict} from 'type-zoo'
import {TMajorActionForcedDealData} from '~/api/generated/types/common'
import {ForcedDealCard} from '~/models/game/Cards/ActionCard'
import {propertyCardFactory} from '~/models/game/factories'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MajorAction, {
  TMajorActionUserFacingDescriptionData,
} from '~/models/game/MajorAction'
import PropertyCard from '~/models/game/Cards/PropertyCard'
import {unambiguousNeighborhoodDescriptionData} from '~/models/game/MajorActions/utils'
import {compact} from '~/utils'
import {sentenceCase, xVerbY} from '~/utils/language'

type PublicMembersFromApi = OmitStrict<
  TMajorActionForcedDealData,
  'card' | 'type' | 'offeredCard' | 'requestedCard'
>

export default class MajorActionForcedDeal
  extends MajorAction
  implements PublicMembersFromApi {
  card: ForcedDealCard
  minorActorPlayerId: string
  offeredCard: PropertyCard<any>
  requestedCard: PropertyCard<any>

  constructor(data: TMajorActionForcedDealData) {
    super(data)
    this.card = new ForcedDealCard(data.card)
    this.minorActorPlayerId = data.minorActorPlayerId
    this.offeredCard = propertyCardFactory(data.offeredCard)
    this.requestedCard = propertyCardFactory(data.requestedCard)
  }

  public userFacingDescription({
    tense,
    skin,
    gamePosition,
  }: TMajorActionUserFacingDescriptionData): string {
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined
    const majorActor = gamePosition.majorActorPlayer
    const minorActor = gamePosition.players.find(
      o => o.id === this.minorActorPlayerId
    )
    if (!minorActor) {
      throw new Error(`Minor actor not found in MajorActionForcedDeal description`)
    }

    const portfolioNeighborhoodOfOfferedCard = majorActor.portfolio.neighborhoods.find(
      pn => pn.properties.find(p => p.id === this.offeredCard.id)
    )
    if (!portfolioNeighborhoodOfOfferedCard) {
      // TODO - the major actor isn't always the player whose perspective we currently have
      //  e.g. if I look at my options (`availableMovesFromHand...`) during someone else's turn
      //  maybe this idea of a perspective would be useful as a first-class idea in e.g. GameData
      //  for this issue, and for spectators & replays. could also use tense here to understand
      //  when 'future' that we're looking at "our" future options w/ this card?
      throw new Error(
        `Forced Deal's offered card not found in major actor's portfolio`
      )
    }
    const {
      sameColorCount: offeredSameColorCount,
      sameColorIndex: offeredSameColorIndex,
    } = unambiguousNeighborhoodDescriptionData(
      minorActor.portfolio,
      portfolioNeighborhoodOfOfferedCard
    )

    const portfolioNeighborhoodOfRequestedCard = minorActor.portfolio.neighborhoods.find(
      pn => pn.properties.find(p => p.id === this.requestedCard.id)
    )
    if (!portfolioNeighborhoodOfRequestedCard) {
      throw new Error(
        `Forced Deal's requested card not found in minor actor's portfolio`
      )
    }
    const {
      sameColorCount: requestedSameColorCount,
      sameColorIndex: requestedSameColorIndex,
    } = unambiguousNeighborhoodDescriptionData(
      minorActor.portfolio,
      portfolioNeighborhoodOfRequestedCard
    )

    const dropFirstNoun = tense === 'imperativePrompt'
    const {conjugatedVerb, xNoun, yNounPossessive, xNounPossessive} = xVerbY(
      tense,
      majorActor,
      dropFirstNoun ? 'Play' : 'play',
      minorActor,
      currentPlayer?.id
    )

    return compact([
      dropFirstNoun
        ? undefined
        : xNoun === majorActor.username
        ? xNoun
        : sentenceCase(xNoun),
      conjugatedVerb,
      this.card.displayName(skin),
      'against',
      yNounPossessive,
      this.requestedCard.displayName(skin),
      requestedSameColorCount > 1
        ? `from ${portfolioNeighborhoodOfRequestedCard.displayColor(
            skin
          )} neighborhood #${requestedSameColorIndex + 1}`
        : undefined,
      'for',
      xNounPossessive.startsWith(majorActor.username) ? 'their' : xNounPossessive,
      this.offeredCard.displayName(skin),
      offeredSameColorCount > 1
        ? `from ${portfolioNeighborhoodOfRequestedCard.displayColor(
            skin
          )} neighborhood #${offeredSameColorIndex + 1}`
        : undefined,
    ]).join(' ')
  }
}
