import {TPlayerData} from '~/api/generated/types/common'
import ExposedHand from '~/models/game/Hands/ExposedHand'
import ExposedPlayer from '~/models/game/Players/ExposedPlayer'
import Portfolio from '~/models/game/Portfolio'

/* the logged-in, playing via this instance of the client player ("you") */
export default class CurrentPlayer extends ExposedPlayer {
  constructor(playerData: TPlayerData, portfolio: Portfolio, hand: ExposedHand) {
    super(playerData, portfolio, hand)
  }
}
