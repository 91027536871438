import classnames from 'classnames'
import shadowStyles from './shadows.module.css'

// what kind of background is the element with the shadow sitting on?
export type TShadowBackdrop = 'off-white' | 'white' | 'dark' | 'none'

export const shadowStyleToClass = (
  backdrop: TShadowBackdrop,
  reactToHover: boolean
): string => {
  return classnames(
    shadowStyles[`shadow-style-${backdrop}`],
    reactToHover && shadowStyles['hover']
  )
}
