import classnames from 'classnames'
import {css} from 'emotion'
import React from 'react'
import Gravatar from '~/components/Gravatar/stateless'
import {ToggleForGroup} from '~/components/Toggle'
import ToggleGroup, {IToggleGroupRenderProps} from '~/components/ToggleGroup'
import {px} from '~/components/utils'
import {DSTextDiv} from '~/design-system/DSText'
import {TSkin} from '~/game/skins'
import {movesLeftForPlay} from '~/game/types'
import Card from '~/models/game/Card'
import DualPropertyCard from '~/models/game/Cards/DualPropertyCard'
import PropertyCard from '~/models/game/Cards/PropertyCard'
import ResidenceCard from '~/models/game/Cards/ResidenceCard'
import GamePosition from '~/models/game/GamePosition'
import Player from '~/models/game/Player'
import PortfolioNeighborhood from '~/models/game/PortfolioNeighborhood'
import {AnyLiquidatedCard} from '~/models/game/types'
import {cardHeightFromWidth} from '~/pages/GameLobby/GameCard/stateless'
import {
  clickableCardIdsFor,
  clickableNeighborhoodIdsFor,
  clickablePlayerIdsFor,
  fadeUnclickableCardsFor,
  highlightedCardIdsFor,
  selectedCardIdsFor,
  selectedNeighborhoodIdFor,
  selectedPlayerIdFor,
  TClickableCardStateV2,
} from '~/pages/GameLobbyV2/stateless'
import {V2LobbyCardImage} from '~/pages/GameLobbyV2/utils'
import {unreachableCase} from '~/utils'
import * as NEL from '~/utils/NEList'
import * as S from '~/utils/Set'
import styles from './styles.module.css'

type TPlayerPortfolioUIV2Orientation =
  | 'singleFileBottom'
  | 'singleFileTop'
  | 'stackedStageLeft'
  | 'stackedStageRight'
const classNamesForOrientation = (
  orientation: TPlayerPortfolioUIV2Orientation
): string => {
  switch (orientation) {
    case 'singleFileTop':
    case 'singleFileBottom':
      return classnames(styles.singleFile, styles[orientation])
    case 'stackedStageLeft':
    case 'stackedStageRight':
      return classnames(styles.stacked, styles[orientation])
    default:
      return unreachableCase(orientation)
  }
}

export interface IPlayerPortfolioUIV2Props {
  orientation: TPlayerPortfolioUIV2Orientation

  player: Player
  gameId: string
  currentGamePosition: GamePosition
  currentUserId: string

  skin: TSkin
  cardWidthPx: number
  className?: string

  clickableCardState: TClickableCardStateV2

  onCardClicked: (card: Card<any>, event: React.MouseEvent<any>) => void
  onPlayerClicked: (player: Player, event: React.MouseEvent<any>) => void
  onNeighborhoodClicked: (neighbhorhoodId: string) => void
}

interface IState {}

export default class PlayerPortfolioV2UI extends React.Component<
  IPlayerPortfolioUIV2Props,
  IState
> {
  state: IState = {}

  render() {
    const {
      orientation,
      player,
      currentUserId,
      currentGamePosition,
      skin,
      cardWidthPx,
      onCardClicked,
      onPlayerClicked,
      onNeighborhoodClicked,
      clickableCardState,
    } = this.props
    const portfolio = player.portfolio

    const isMyMajorTurn = currentGamePosition.majorActorPlayerId === player.id
    const isMyMinorTurn = currentGamePosition.minorActorPlayer?.id === player.id

    const movesLeft = movesLeftForPlay[currentGamePosition.play]
    const isMajorActionInProgress = !!currentGamePosition.inProgressMajorAction

    const clickableCardIds = clickableCardIdsFor(clickableCardState)
    const selectedCardIds = selectedCardIdsFor(clickableCardState)
    const fadeUnclickableCards = fadeUnclickableCardsFor(clickableCardState)
    const highlightedCardIds = highlightedCardIdsFor(
      clickableCardState,
      currentGamePosition
    )

    const clickableNeighborhoodIds = clickableNeighborhoodIdsFor(clickableCardState)
    const selectedNeighborhoodId = selectedNeighborhoodIdFor(clickableCardState)

    const clickablePlayerIds = clickablePlayerIdsFor(clickableCardState)
    const selectedPlayerId = selectedPlayerIdFor(clickableCardState)

    const showToggleForPNId = (pnId: string) => clickableNeighborhoodIds.has(pnId)

    const orientationClassNames = classNamesForOrientation(orientation)
    const isCurrentPlayer = player.id === currentUserId
    const hideGravatar = false // orientation === 'singleFileBottom' && isCurrentPlayer

    return (
      <div
        className={classnames(
          styles.playerPortfolioWrapper,
          styles[
            currentGamePosition.players.length > 4
              ? 'moreThanFourPlayers'
              : 'fourPlayersOrLess'
          ],
          orientationClassNames,
          isCurrentPlayer && styles.isCurrentPlayer,
          this.props.className
        )}
      >
        {!hideGravatar && (
          <div
            className={classnames(
              styles.gravatarWrapper,
              clickablePlayerIds.has(player.id) && styles.clickable,
              selectedPlayerId === player.id && styles.selected,
              isMyMajorTurn && styles.isMyMajorTurn,
              isMyMinorTurn && styles.isMyMinorTurn
            )}
            onClick={e =>
              clickablePlayerIds.has(player.id) && onPlayerClicked(player, e)
            }
          >
            {isMyMajorTurn && (
              <div className={styles.turnArcs}>
                <img
                  src="/icons/arc-60deg.svg"
                  className={classnames(
                    styles.turnArc,
                    styles.turnArc1,
                    movesLeft <= 0 && styles.faded,
                    movesLeft === 0 && isMajorActionInProgress && styles.blink
                  )}
                />
                <img
                  src="/icons/arc-60deg.svg"
                  className={classnames(
                    styles.turnArc,
                    styles.turnArc2,
                    movesLeft <= 1 && styles.faded,
                    movesLeft === 1 && isMajorActionInProgress && styles.blink
                  )}
                />
                <img
                  src="/icons/arc-60deg.svg"
                  className={classnames(
                    styles.turnArc,
                    styles.turnArc3,
                    movesLeft <= 2 && styles.faded,
                    movesLeft === 2 && isMajorActionInProgress && styles.blink
                  )}
                />
              </div>
            )}
            <Gravatar className={styles.gravatar} email={player.email} size={32} />
            <DSTextDiv size="label-demi" color="white">
              {player.username}
            </DSTextDiv>
            <DSTextDiv size="tiny" color="white" className={styles.cardCount}>
              <img src="/icons/masked-cards-small-no-fill.svg" />
              {player.hand.cardCount()}
            </DSTextDiv>
          </div>
        )}
        <div
          className={classnames(
            styles.playerNeighborhoodsWrapper,
            orientationClassNames
          )}
        >
          <ToggleGroup
            selectionLimit={1}
            allowDeselection={true}
            deselectOldestIfLimitReached
            selected={S.createOrEmpty(selectedNeighborhoodId)}
            makeSelection={selectedSet =>
              onNeighborhoodClicked(S.first(selectedSet)!)
            }
          >
            {(groupProps: IToggleGroupRenderProps<string>) => {
              return portfolio.sortedNeighborhoods().map(pn => (
                <ToggleForGroup
                  stopPropagationFromLabelClicks
                  toggleValue={pn.id}
                  groupProps={groupProps}
                  className={classnames(
                    styles.neighborhoodToggle,
                    orientationClassNames,
                    !showToggleForPNId(pn.id) && styles.hiddenToggle
                  )}
                  key={pn.id}
                  toggleStyle="radio"
                  disabled={!showToggleForPNId(pn.id)}
                >
                  {input => (
                    <SingleNeighborhood
                      key={pn.id}
                      model={pn}
                      skin={skin}
                      cardWidthPx={cardWidthPx}
                      clickableCardIds={clickableCardIds}
                      onCardClicked={onCardClicked}
                      selectedCardIds={selectedCardIds}
                      fadeUnclickableCards={fadeUnclickableCards}
                      highlightedCardIds={highlightedCardIds}
                    >
                      {/* TODO unclear if this being a child of the neighborhood is really necessary or beneficial */}
                      {input}
                    </SingleNeighborhood>
                  )}
                </ToggleForGroup>
              ))
            }}
          </ToggleGroup>
        </div>
        <div className={styles.playerCashPileWrapper}>
          {portfolio.sortedCashPile().map(cashPileSegment => (
            <CashPile
              key={cashPileSegment[0].value}
              cashPileSegment={cashPileSegment}
              skin={skin}
              cardWidthPx={cardWidthPx * 0.6}
              clickableCardIds={clickableCardIds}
              onCardClicked={onCardClicked}
              selectedCardIds={selectedCardIds}
              fadeUnclickableCards={fadeUnclickableCards}
              highlightedCardIds={highlightedCardIds}
            />
          ))}
        </div>
      </div>
    )
  }
}

interface ISingleNeighborhoodProps {
  model: PortfolioNeighborhood
  skin: TSkin
  cardWidthPx: number

  clickableCardIds: Set<string>
  onCardClicked: (card: Card<any>, event: React.MouseEvent<any>) => void
  selectedCardIds: Set<string>
  fadeUnclickableCards: boolean
  highlightedCardIds: Set<string>

  children?: React.ReactNode
}

const SingleNeighborhood = (props: ISingleNeighborhoodProps) => {
  const {cardWidthPx, model, skin} = props
  const cardHeightPx = cardHeightFromWidth(cardWidthPx)

  // offset percentages based on the (variable) width of the card
  const xOffsetPct = 0.1
  const yOffsetPct = 0.09

  const properties = NEL.createSafe(model.sortedProperties())
  if (!properties) {
    console.warn(
      'Not rendering an empty portfolio neighborhood:',
      model.neighborhood,
      model
    )
    return null
  }

  const cardsToRender: NEL.NEList<PropertyCard<any> | ResidenceCard> = [
    ...properties,
    ...model.residences,
  ]

  return (
    <div
      className={classnames(
        styles.neighborhoodWrapper,
        styles[`neighborhood-${model.neighborhood}`]
      )}
      style={{
        width: px(
          cardWidthPx + (cardsToRender.length - 1) * xOffsetPct * cardWidthPx
        ),
        height: px(
          cardHeightPx + (cardsToRender.length - 1) * yOffsetPct * cardHeightPx
        ),
        margin: px(cardWidthPx * 0.05),
      }}
    >
      {cardsToRender.map((p, i) => (
        <V2LobbyCardImage
          key={p.id}
          model={p}
          skin={skin}
          roundedCorners
          invert={
            p instanceof DualPropertyCard &&
            p.upsideDownForNeighborhood(model.neighborhood)
          }
          className={classnames(
            styles.cardImage,
            props.clickableCardIds.has(p.id) && styles.clickable,
            props.selectedCardIds.has(p.id) && styles.selected,
            props.fadeUnclickableCards && styles.fadeUnclickable,
            props.highlightedCardIds.size
              ? props.highlightedCardIds.has(p.id)
                ? styles.highlighted
                : styles.faded
              : undefined,
            css`
              width: ${cardWidthPx}px;
              height: ${cardHeightPx}px;
              left: ${xOffsetPct * i * cardWidthPx}px;
              top: ${yOffsetPct * i * cardHeightPx}px;
            `
          )}
          onClick={e => {
            props.clickableCardIds.has(p.id) && props.onCardClicked(p, e)
          }}
        />
      ))}
      {props.children}
    </div>
  )
}

interface ICashPileProps {
  cashPileSegment: NEL.NEList<AnyLiquidatedCard>
  skin: TSkin
  className?: string
  cardWidthPx: number

  clickableCardIds: Set<string>
  onCardClicked: (card: Card<any>, event: React.MouseEvent<any>) => void
  selectedCardIds: Set<string>
  fadeUnclickableCards: boolean
  highlightedCardIds: Set<string>
}

const CashPile = (props: ICashPileProps) => {
  const {cardWidthPx, cashPileSegment, skin} = props

  if (cashPileSegment.length === 0) {
    return null
  }

  const cardHeightPx = cardHeightFromWidth(cardWidthPx)

  // offset percentages based on the (variable) width of the card
  const xOffsetPct = 0.1
  const yOffsetPct = 0.09

  return (
    <div
      className={styles.cashPileWrapper}
      style={{
        width: px(
          cardWidthPx + (cashPileSegment.length - 1) * xOffsetPct * cardWidthPx
        ),
        height: px(
          cardHeightPx + (cashPileSegment.length - 1) * yOffsetPct * cardHeightPx
        ),
        margin: px(cardWidthPx * 0.05),
      }}
    >
      {cashPileSegment.map((card, i) => (
        <V2LobbyCardImage
          key={card.id}
          model={card}
          skin={skin}
          roundedCorners
          invert={false}
          className={classnames(
            styles.cardImage,
            props.clickableCardIds.has(card.id) && styles.clickable,
            props.selectedCardIds.has(card.id) && styles.selected,
            props.fadeUnclickableCards && styles.fadeUnclickable,
            props.highlightedCardIds.size
              ? props.highlightedCardIds.has(card.id)
                ? styles.highlighted
                : styles.faded
              : undefined,
            css`
              width: ${cardWidthPx}px;
              height: ${cardHeightPx}px;
              left: ${xOffsetPct * i * cardWidthPx}px;
              top: ${yOffsetPct * i * cardHeightPx}px;
            `
          )}
          onClick={e => {
            props.clickableCardIds.has(card.id) && props.onCardClicked(card, e)
          }}
        />
      ))}
    </div>
  )
}
