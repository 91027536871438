import {TMajorActionPaySomethingData} from '~/game/types'
import {DataBackedModel} from '~/models/base'
import {TMajorActionUserFacingDescriptionData} from '~/models/game/MajorAction'
import PortfolioNeighborhood from '~/models/game/PortfolioNeighborhood'

type PublicMembersFromApi = Pick<TMajorActionPaySomethingData, 'type'>

export default abstract class MajorActionRentBase
  extends DataBackedModel<TMajorActionPaySomethingData>
  implements PublicMembersFromApi {
  // public id: string
  public type: TMajorActionPaySomethingData['type']

  // which neighborhood was used to initiate the rent
  public abstract portfolioNeighborhood: PortfolioNeighborhood

  constructor(
    data: TMajorActionPaySomethingData
    // , portfolio: Portfolio
    // , context: TMajorActionConstructorContext
  ) {
    super(data)
    // this.id = data.id
    this.type = data.type
  }

  public abstract userFacingDescription(
    data: TMajorActionUserFacingDescriptionData
  ): string
}
