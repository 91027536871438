import {ExcludeStrict} from 'type-zoo'
import {TCard, TStandardPropertyType} from '~/api/generated/types/common'
import {mapRecord} from '~/utils'
import {
  birthdayCost,
  debtCollectorCost,
  residenceRentValue,
  standardPropertyTypesForUniversalRentCard,
} from '~/game/types'
import {unreachableCase} from '~/utils'
import {show$} from '~/utils/language'

export type TSkinData = {
  cardNames: Record<TSpecificCards, string>
  shortCardNames: Record<TSpecificCards, string>
  colorNames: Record<TStandardPropertyType, string>
  shortCardDescriptions: (specificCardType: TSpecificCards) => string

  hexCodesForColors: Record<TStandardPropertyType, string>

  cash: 'identical' | 'individual' // whether all cash cards of a certain value use the same image or not
  ext: 'svg' | 'jpg'
  backOfCardFilename: string
}

// would be neat if this could somehow be generated based on some data
// or from the backend, but for now it's not
export type TSpecificStandardPropertyCard =
  | 'darkBlue1'
  | 'darkBlue0'
  | 'green2'
  | 'green1'
  | 'green0'
  | 'yellow2'
  | 'yellow1'
  | 'yellow0'
  | 'red2'
  | 'red1'
  | 'red0'
  | 'orange2'
  | 'orange1'
  | 'orange0'
  | 'pink2'
  | 'pink1'
  | 'pink0'
  | 'lightBlue2'
  | 'lightBlue1'
  | 'lightBlue0'
  | 'brown1'
  | 'brown0'
  | 'railroad3'
  | 'railroad2'
  | 'railroad1'
  | 'railroad0'
  | 'utility1'
  | 'utility0'

/*
export type TPropertyCard =
  | TSpecificStandardPropertyCard
  | TDualPropertyCard
  | TPropertyWildCard
*/

// the backend doesn't do e.g. `utilityN` it just has `utility`
// but we want each to be skinnable, hence `TSpecificCards`
export type TSpecificCards =
  | TSpecificStandardPropertyCard
  | ExcludeStrict<TCard, TStandardPropertyType>

const officialSkinCardNames: Record<TSpecificCards, string> = {
  darkBlue1: 'Boardwalk',
  darkBlue0: 'Park Place',
  green2: 'Pennsylvania Avenue',
  green1: 'North Carolina Avenue',
  green0: 'Pacific Avenue',
  yellow2: 'Marvin Gardens',
  yellow1: 'Ventnor Avenue',
  yellow0: 'Atlantic Avenue',
  red2: 'Illinois Avenue',
  red1: 'Indiana Avenue',
  red0: 'Kentucky Avenue',
  orange2: 'New York Avenue',
  orange1: 'Tennessee Avenue',
  orange0: 'St. James Place',
  pink2: 'Virginia Avenue',
  pink1: 'States Avenue',
  pink0: 'St. Charles Place',
  lightBlue2: 'Connecticut Avenue',
  lightBlue1: 'Vermont Avenue',
  lightBlue0: 'Oriental Avenue',
  brown1: 'Baltic Avenue',
  brown0: 'Mediterranean Avenue',
  railroad3: 'Short Line',
  railroad2: 'B & O Railroad',
  railroad1: 'Pennsylvania Railroad',
  railroad0: 'Reading Railroad',
  utility1: 'Water Works',
  utility0: 'Electric Company',

  // Dual Wilds
  wildDarkBlueAndGreen: 'Dark Blue/Green Wildcard',
  wildGreenAndRailroad: 'Green/Railroad Wildcard',
  wildUtilityAndRailroad: 'Utility/Railroad Wildcard',
  wildLightBlueAndRailroad: 'Light Blue/Railroad Wildcard',
  wildLightBlueAndBrown: 'Light Blue/Brown Wildcard',
  wildPinkAndOrange: 'Pink/Orange Wildcard',
  wildRedAndYellow: 'Red/Yellow Wildcard',

  // Rents
  directionalRent: 'Directional Rent',
  rentDarkBlueGreen: 'Dark Blue/Green Rent',
  rentLightBlueBrown: 'Light Blue/Brown Rent',
  rentPinkOrange: 'Pink/Orange Rent',
  rentUtilityRailroad: 'Utility/Railroad Rent',
  rentRedYellow: 'Red/Yellow Rent',

  // Actions
  dealBreaker: 'Deal Breaker',
  debtCollector: 'Debt Collector',
  doubleTheRent: 'Double The Rent',
  slyDeal: 'Sly Deal',
  forcedDeal: 'Forced Deal',
  birthday: 'It’s My Birthday',
  justSayNo: 'Just Say No',
  passGo: 'Pass Go',

  // Residences
  house: 'House',
  hotel: 'Hotel',

  // Cash
  tenMil: show$(10),
  fiveMil: show$(5),
  fourMil: show$(4),
  threeMil: show$(3),
  twoMil: show$(2),
  oneMil: show$(1),

  // PWC
  propertyWildCard: 'Property Wildcard',
}

const officialColorNames: Record<TStandardPropertyType, string> = {
  darkBlue: 'Dark Blue',
  brown: 'Brown',
  utility: 'Utility',
  green: 'Green',
  yellow: 'Yellow',
  red: 'Red',
  orange: 'Orange',
  pink: 'Pink',
  lightBlue: 'Light Blue',
  railroad: 'Railroad',
}

const mercurySkinCardNames: Record<TSpecificCards, string> = {
  ...officialSkinCardNames,
  darkBlue1: 'Northstar Walk',
  darkBlue0: 'Tigershark Place',
  green2: 'Alpinebird Avenue',
  green1: 'Nighthawk Avenue',
  green0: 'Malachite Avenue',
  yellow2: 'Moonriver Gardens',
  yellow1: 'Samba Avenue',
  yellow0: 'Tennessee Avenue',
  red2: 'Redranger Avenue',
  red1: 'Wolfmoon Avenue',
  red0: 'Plainswarrior Avenue',
  orange2: 'Desertbloom Avenue',
  orange1: 'Wildebeest Avenue',
  orange0: 'Magnolia Place',
  pink2: 'Stonecold Avenue',
  pink1: 'Desertrose Avenue',
  pink0: 'Sundance Place',
  lightBlue2: 'Hotrod Avenue',
  lightBlue1: 'Cosmos Avenue',
  lightBlue0: 'Davidcain Avenue',
  brown1: 'Oldhand Avenue',
  brown0: 'Wildcard Avenue',
  railroad3: 'Quantum Line',
  railroad2: 'Westwind Railroad',
  railroad1: 'Maverick Railroad',
  railroad0: 'Bullmoose Railroad',
  utility1: 'Foxglove Works',
  utility0: 'Rebelleader Company',
}

const defaultShortCardDescriptions = (
  colorNames: Record<TStandardPropertyType, string>
) => (specificCardType: TSpecificCards): string => {
  switch (specificCardType) {
    case 'darkBlue1':
    case 'darkBlue0':
    case 'green2':
    case 'green1':
    case 'green0':
    case 'yellow2':
    case 'yellow1':
    case 'yellow0':
    case 'red2':
    case 'red1':
    case 'red0':
    case 'orange2':
    case 'orange1':
    case 'orange0':
    case 'pink2':
    case 'pink1':
    case 'pink0':
    case 'lightBlue2':
    case 'lightBlue1':
    case 'lightBlue0':
    case 'brown1':
    case 'brown0':
    case 'railroad3':
    case 'railroad2':
    case 'railroad1':
    case 'railroad0':
    case 'utility1':
    case 'utility0':
      return `Properties help you generate rent, and eventually, win the game.`

    // Dual Wilds
    case 'wildDarkBlueAndGreen':
    case 'wildGreenAndRailroad':
    case 'wildUtilityAndRailroad':
    case 'wildLightBlueAndRailroad':
    case 'wildLightBlueAndBrown':
    case 'wildPinkAndOrange':
    case 'wildRedAndYellow':
      return `This property can be played as one of two property colors.`

    // Rents
    case 'directionalRent':
      return `Charge one other player rent from any of your property sets.`
    case 'rentDarkBlueGreen':
    case 'rentLightBlueBrown':
    case 'rentPinkOrange':
    case 'rentUtilityRailroad':
    case 'rentRedYellow':
      return `Charge every other player rent from a ${standardPropertyTypesForUniversalRentCard(
        specificCardType
      )
        .map(t => colorNames[t])
        .join(' or ')} property set.`

    // Actions
    case 'dealBreaker':
      return `Steal a complete property set from another player.`
    case 'debtCollector':
      return `Charge one other player ${show$(debtCollectorCost)}.`
    case 'doubleTheRent':
      return `Re-charge the rent you played on your previous move this turn.`
    case 'slyDeal':
      return `Take a property from another player’s incomplete property set.`
    case 'forcedDeal':
      return `Take a property from another player’s incomplete property set, but give one of yours in return.`
    case 'birthday':
      return `Charge every other player ${show$(birthdayCost)}.`
    case 'justSayNo':
      return `Refuse to comply with another player’s action played against you (stackable).`
    case 'passGo':
      return `Draw two more cards from the deck.`

    // Residences
    case 'house':
    case 'hotel':
      return `Add ${show$(
        residenceRentValue(specificCardType)
      )} to your complete property set’s rent value.`

    // Cash
    case 'tenMil':
    case 'fiveMil':
    case 'fourMil':
    case 'threeMil':
    case 'twoMil':
    case 'oneMil':
      return `Cash helps you pay other players without giving up your valuable properties.`

    // PWC
    case 'propertyWildCard':
      return `Add this special property to any existing, incomplete property set.`
    default:
      return unreachableCase(specificCardType)
  }
}

export const shortenCardName = (cardName: string): string =>
  cardName
    .replace('Railroad', 'RR')
    .replace('Place', 'Pl.')
    .replace('Avenue', 'Ave.')
    .replace('Company', 'Co.')
    .replace('Wildcard', 'Wild')
    .replace('Pennsylvania', 'Penn.')
    .replace(/((N)orth|(S)outh|(E)ast|(W)est)/, '$2$3$4$5.')

const defaultHexCodesForColors: Record<TStandardPropertyType, string> = {
  darkBlue: '#345DB4',
  brown: '#6C3910',
  utility: '#CBECB2',
  green: '#4FB43A',
  yellow: '#FFF500',
  red: '#E51E1E',
  orange: '#EE7709',
  pink: '#DE3891',
  lightBlue: '#A7D1F3',
  railroad: '#1F1F30',
}

export type TSkin = 'official' | 'mercury' | 'classic'

export const officialSkin: TSkinData = {
  cardNames: officialSkinCardNames,
  shortCardNames: mapRecord(shortenCardName, officialSkinCardNames),
  colorNames: officialColorNames,
  shortCardDescriptions: defaultShortCardDescriptions(officialColorNames),
  hexCodesForColors: defaultHexCodesForColors,
  cash: 'identical',
  ext: 'jpg',
  backOfCardFilename: 'back.png',
}

export const mercurySkin: TSkinData = {
  cardNames: mercurySkinCardNames,
  shortCardNames: mapRecord(shortenCardName, mercurySkinCardNames),
  colorNames: officialColorNames,
  shortCardDescriptions: defaultShortCardDescriptions(officialColorNames),
  hexCodesForColors: defaultHexCodesForColors,
  cash: 'individual',
  ext: 'svg',
  backOfCardFilename: 'back.svg',
}

export const classicSkin: TSkinData = {
  cardNames: officialSkinCardNames,
  shortCardNames: mapRecord(shortenCardName, officialSkinCardNames),
  colorNames: officialColorNames,
  shortCardDescriptions: defaultShortCardDescriptions(officialColorNames),
  hexCodesForColors: defaultHexCodesForColors,
  cash: 'identical',
  ext: 'svg',
  backOfCardFilename: 'back1.svg',
}

export const skins: Record<TSkin, TSkinData> = {
  official: officialSkin,
  mercury: mercurySkin,
  classic: classicSkin,
}

export const skinsHuman: Record<TSkin, string> = {
  official: 'Official',
  mercury: 'Mercury',
  classic: 'Classic',
}

export const orDefault = (skin: TSkin | undefined): TSkin => skin ?? 'official'
