import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {faChevronDown} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import * as H from 'history'
import React from 'react'
import * as M from 'react-aria-menubutton'
import {ExtractStrict, OmitStrict} from 'type-zoo'
import {MenuLinkItem} from '~/components/ThirdParty/ARIAMenu'
import {IFontAwesomeIconProps} from '~/utils/FontAwesome/types'
import * as MapUtils from '~/utils/Map'
import * as S from '~/utils/Set'
import styles from './styles.module.css'

export type TStyleableDropdownRenderButtonData<KeyType extends string | number> = {
  isOpen: boolean
  selectedItem: KeyType | undefined
}

export type TStyleableDropdownRenderChoiceData = {
  selected: boolean
  isShowingOnButton: boolean
}

type TStyleableDropdownArrowType = 'none' | 'fontawesome' | 'svg'
type TStyleableDropdownArrowSettingsBase = {type: TStyleableDropdownArrowType}
interface IStyleableDropdownArrowSettingsNone
  extends TStyleableDropdownArrowSettingsBase {
  type: 'none'
}
interface IStyleableDropdownArrowSettingsFontAwesome
  extends TStyleableDropdownArrowSettingsBase {
  type: 'fontawesome'
  // icon defaults to `faChevronDown`, otherwise it's unstyled
  icon: Partial<IFontAwesomeIconProps>
}
interface IStyleableDropdownArrowSettingsSVG
  extends TStyleableDropdownArrowSettingsBase {
  type: 'svg'
  svg: JSX.Element
  wrapperClass?: string
}
type TStyleableDropdownArrowSettings =
  | IStyleableDropdownArrowSettingsNone
  | IStyleableDropdownArrowSettingsFontAwesome
  | IStyleableDropdownArrowSettingsSVG

export type TStyleableDropdownChoiceFunction = (
  data: TStyleableDropdownRenderChoiceData
) => JSX.Element

interface IStyleableDropdownChoiceDataBase {
  render: TStyleableDropdownChoiceFunction
  type: 'link' | 'onClick'
}
type TStyleableDropdownChoiceDataLink = IStyleableDropdownChoiceDataBase & {
  type: ExtractStrict<IStyleableDropdownChoiceDataBase['type'], 'link'>
  location: H.LocationDescriptor
}
type TStyleableDropdownChoiceDataOnClick = IStyleableDropdownChoiceDataBase & {
  type: ExtractStrict<IStyleableDropdownChoiceDataBase['type'], 'onClick'>
}
export type TStyleableDropdownChoiceData =
  | TStyleableDropdownChoiceDataLink
  | TStyleableDropdownChoiceDataOnClick

export type TStyleableDropdownChoices<KeyType extends string | number> = Record<
  KeyType,
  TStyleableDropdownChoiceData
>

export interface IStyleableDropdownProps<KeyType extends string | number>
  extends OmitStrict<M.WrapperProps<any>, 'onSelection' | 'children'> {
  // NB: redefined only for the type of `selectedItem` to be correct
  onSelection: (selectedItem: KeyType) => void
  selectedItem: KeyType | undefined
  choices: TStyleableDropdownChoices<KeyType>
  // undefined is a shortcut to `{type: 'none'}`
  arrowSettings: TStyleableDropdownArrowSettings | undefined
  classes?: {
    buttonWrapper?: string
    menu?: string
    choice?: string
    buttonWrapperWhileOpen?: string

    // if the menu is opened and there are no choices to display
    buttonWrapperWhileOpenButEmpty?: string
    menuEmpty?: string
  }
  renderButton: (data: TStyleableDropdownRenderButtonData<KeyType>) => JSX.Element

  showSelectionInMenu?: boolean
  blurOnSelection?: boolean

  // turns off the default absolute positioning of the dropdown menu
  menuInDocumentFlow?: boolean

  hiddenChoices?: S.SetCreationParameter<KeyType>
}

interface IState {
  isOpen: boolean
}

// KeyType extends string | number so it can be used as the `key` on the `MenuItem`
export default class StyleableDropdown<
  KeyType extends string | number
> extends React.Component<IStyleableDropdownProps<KeyType>, IState> {
  state: IState = {
    isOpen: false,
  }

  render() {
    const {
      onSelection,
      choices,
      selectedItem,
      arrowSettings: arrowSettingsProp,
      renderButton,
      classes = {},
      showSelectionInMenu,
      blurOnSelection,
      menuInDocumentFlow,
      hiddenChoices,
      ...wrapperProps
    } = this.props

    const arrowSettings: TStyleableDropdownArrowSettings = arrowSettingsProp || {
      type: 'none',
    }

    const choicesMap = MapUtils.fromRecord(choices)

    const showingEmptyMenu =
      this.state.isOpen &&
      (choicesMap.size === 0 ||
        (choicesMap.size === 1 && !showSelectionInMenu && selectedItem))

    return (
      <M.Wrapper
        {...wrapperProps}
        className={classnames([styles['menu-frame'], this.props.className])}
        onSelection={(selected: KeyType) => {
          onSelection(selected)

          // Blur the menu after selecting. we don't have a way to get a ref for the menu, so this will have to do
          // https://github.com/davidtheclark/react-aria-menubutton/issues/93
          if (blurOnSelection) {
            // @ts-ignore
            document.activeElement &&
              // @ts-ignore
              document.activeElement.blur &&
              // @ts-ignore
              typeof document.activeElement.blur === 'function' &&
              // @ts-ignore
              document.activeElement.blur()
          }
        }}
        onMenuToggle={({isOpen}) => {
          this.setState({isOpen})
        }}
      >
        <M.Button
          className={classnames([
            styles['dropdown-button'],
            classes.buttonWrapper,
            this.state.isOpen && classes.buttonWrapperWhileOpen,
            showingEmptyMenu && classes.buttonWrapperWhileOpenButEmpty,
          ])}
        >
          {renderButton({
            isOpen: this.state.isOpen,
            selectedItem: selectedItem,
          })}
        </M.Button>
        {arrowSettings.type === 'fontawesome' && (
          <FontAwesomeIcon
            {...arrowSettings.icon}
            className={classnames(
              styles['arrow-base'],
              arrowSettings.icon.className
            )}
            icon={(arrowSettings.icon.icon as IconProp) || faChevronDown}
            flip={this.state.isOpen ? 'vertical' : undefined}
          />
        )}
        {arrowSettings.type === 'svg' && (
          <div
            className={classnames(styles['arrow-base'], arrowSettings.wrapperClass)}
            style={{transform: this.state.isOpen ? 'rotate(180deg)' : undefined}}
          >
            {arrowSettings.svg}
          </div>
        )}
        <M.Menu
          className={classnames([
            styles['dropdown-menu'],
            menuInDocumentFlow && styles['in-document-flow'],
            classes.menu,
            showingEmptyMenu && classes.menuEmpty,
          ])}
        >
          <>
            {MapUtils.entries(choicesMap).map(([itemKey, data]) => {
              const selected = itemKey === selectedItem
              if (selected && !showSelectionInMenu) {
                return null
              } else if (S.create(hiddenChoices ?? []).has(itemKey)) {
                return null
              }

              if (data.type === 'link') {
                return (
                  <MenuLinkItem
                    tabIndex={0}
                    to={data.location}
                    className={classnames([
                      styles['dropdown-choice'],
                      classes.choice,
                    ])}
                    key={itemKey}
                    value={itemKey}
                  >
                    {data.render({selected, isShowingOnButton: false})}
                  </MenuLinkItem>
                )
              }

              return (
                <M.MenuItem
                  tabIndex={0}
                  className={classnames([styles['dropdown-choice'], classes.choice])}
                  key={itemKey}
                  value={itemKey}
                >
                  {data.render({selected, isShowingOnButton: false})}
                </M.MenuItem>
              )
            })}
          </>
        </M.Menu>
      </M.Wrapper>
    )
  }
}
