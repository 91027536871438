import {OmitStrict} from 'type-zoo'
import {TMinorActionDataPayOther} from '~/api/generated/types/common'
import MinorActionPaySomething from '~/models/game/MinorActions/MinorActionPaySomething'

type PublicMembersFromApi = OmitStrict<TMinorActionDataPayOther, 'type' | 'paidWith'>

// Paying off a Debt Collector or Birthday
export default class MinorActionPayOther
  extends MinorActionPaySomething<TMinorActionDataPayOther>
  implements PublicMembersFromApi {}
