import {PostApi} from '~/api/client'
import gameDataMock, {
  gameDataMockAtTime,
} from '~/api/endpoints/game/GetGameData/mock'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TGameData} from '~/api/generated/types/common'
import {TGetGameDataResponse} from '~/api/generated/types/GetGameData'
import MockConfig from '~/api/mocks/config'
import {TJSONError} from '~/api/types'

export type TGetGameDataParams = {}
export type TGetGameDataPathPieces = {
  gameId: string
}

export default class GetGameData extends PostApi<
  TGetGameDataParams,
  TGetGameDataResponse,
  TGetGameDataPathPieces
> {
  public static mockResponseWithMockConfig = (data?: {
    params?: TGetGameDataParams
    pathPieces: TGetGameDataPathPieces
  }): TGetGameDataResponse => {
    // TODO eventually configure the response here with MockConfig
    return {
      spectatorRequests: [],
      gameData: {
        ...gameDataMockAtTime(MockConfig.time),
        gameId: data?.pathPieces.gameId ?? gameDataMock.gameId,
      },
    }
  }

  mockDelay() {
    return 500
  }

  static alwaysFail: boolean = false

  protected mockRequestFailure(
    params: TGetGameDataParams,
    pathPieces: TGetGameDataPathPieces
  ): TJSONError | null {
    if (GetGameData.alwaysFail /* || getRandomInt(10) === 10 */) {
      // uncomment this out to test what happens when this api fails several times in a row
      // GetGameData.alwaysFail = true
      return {errors: {idk: ['Something went wrong']}}
    }
    return null
  }

  protected getMockResponse(
    params: TGetGameDataParams,
    pathPieces: TGetGameDataPathPieces
  ): TGetGameDataResponse {
    return GetGameData.mockResponseWithMockConfig()
  }

  protected getPath(pathPieces: TGetGameDataPathPieces) {
    return serverEndpoints.getGameData(pathPieces)
  }

  protected transformResponse(
    r: TGetGameDataResponse,
    params: TGetGameDataParams,
    pathPieces: TGetGameDataPathPieces
  ): TGetGameDataResponse {
    return {
      ...r,
      gameData: transformGameData(r.gameData),
    }
  }

  public mocksRequireLogin() {
    return true
  }
}

export const transformGameData = (r: TGameData): TGameData => {
  // NB: was using this for some data transformation earlier, don't need it now
  // but other things call it which will be useful later if we need more transformations
  return r
}
