import {TMajorActionPlayAsCashData} from '~/api/generated/types/common'
import {cardFactory} from '~/models/game/factories'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MajorAction, {
  TMajorActionUserFacingDescriptionData,
} from '~/models/game/MajorAction'
import {AnyLiquidatedCard} from '~/models/game/types'
import {compact, mutateIf} from '~/utils'
import {inTense, sentenceCase} from '~/utils/language'

export default class MajorActionPlayAsCash extends MajorAction {
  card: AnyLiquidatedCard

  constructor(data: TMajorActionPlayAsCashData) {
    super(data)
    this.card = cardFactory(data.card)
  }

  public userFacingDescription({
    tense,
    skin,
    gamePosition,
  }: TMajorActionUserFacingDescriptionData): string {
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined
    const majorActor = gamePosition.majorActorPlayer
    const dropFirstNoun = tense === 'imperativePrompt'

    return compact([
      dropFirstNoun
        ? undefined
        : currentPlayer?.id === majorActor.id
        ? 'You'
        : majorActor.username,
      mutateIf(inTense(tense)('play'), dropFirstNoun, sentenceCase),
      this.card.displayName(skin),
      `as cash`,
    ]).join(' ')
  }
}
