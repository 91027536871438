import {faEyeSlash} from '@fortawesome/free-regular-svg-icons'
import * as R from 'ramda'
import * as React from 'react'
import {OmitStrict} from 'type-zoo'
import {TCreateGameResponse} from '~/api/generated/types/CreateGame'
import {defaultValuePreprocessor} from '~/components/ProvideForm/base'
import {SingleButtonForm} from '~/components/SingleButtonForm'
import {TJoinGameResponse} from '~/api/endpoints/game/PreGame/JoinGame'
import LabeledLineTextInput from '~/components/FormFields/LabeledLineTextInput'
import ProvideForm from '~/components/ProvideForm/normal'
import {notEmpty} from '~/components/ProvideForm/utils'
import Toggle from '~/components/Toggle'
import DSButton from '~/design-system/Button'
import DSLink, {DSExternalLink} from '~/design-system/Link'
import User from '~/models/app/User'
import {joinElements} from '~/pages/utils'
import {dynamicURLs} from '~/routing/sitemap'
import {extractUUID, formatDateByAge, id} from '~/utils'
import {TDashboardDataResponse} from '~/api/generated/types/GetDashboardData'
import DSText, {DSTextDiv} from '~/design-system/DSText'
import {TActiveGameData, TEloLeaderboardData} from '~/api/generated/types/common'
import {HoverableFontAwesomeIconDiv} from '~/utils/FontAwesome/HoverableFontAwesomeIcon'
import styles from './styles.module.css'

type TFormData = {
  gameId: string
}

type TActiveGameDataInternal = OmitStrict<
  TActiveGameData,
  'createdAt' | 'players'
> & {
  createdDate: Date
  players: JSX.Element[]
  isInGame: boolean
}

interface IProps {
  dashboardData: TDashboardDataResponse
  user: User

  joinGame: (form: TFormData) => Promise<TJoinGameResponse>
  joinGameSuccess: (formData: TFormData, response: TJoinGameResponse) => void

  createGame: () => Promise<TCreateGameResponse>
  createGameSuccess: (response: TCreateGameResponse) => void

  markGameAsStale: (gameId: string) => void
}

interface IState {
  showGamesToSpectate: boolean
  activeGames: TActiveGameDataInternal[]
  sortLeaderboardBy: 'name' | 'elo' | 'gameCount'
}

export default class DashboardUI extends React.Component<IProps, IState> {
  state: IState = {
    showGamesToSpectate: true,
    activeGames: [],
    sortLeaderboardBy: 'elo',
  }

  static activeGames = (
    list: TActiveGameData[],
    showGamesToSpectate: boolean,
    userId: string
  ): IState['activeGames'] => {
    const filtered = showGamesToSpectate
      ? list
      : list.filter(ag => ag.players.find(p => p.playerId === userId))

    return filtered.map(({createdAt, players, ...ag}) => {
      const isInGame = players.find(p => p.playerId === userId) !== undefined

      return {
        ...ag,
        createdDate: new Date(createdAt),
        players: R.sortWith(
          [R.descend(p => p.playerId === userId), R.ascend(p => p.username)],
          players
        ).map(p =>
          p.playerId === userId ? (
            <span key={p.playerId}>
              <b>{p.username}</b>
            </span>
          ) : (
            <span key={p.playerId}>{p.username}</span>
          )
        ),
        isInGame,
      }
    })
  }

  static getDerivedStateFromProps(
    nextProps: IProps,
    prevState: IState
  ): Partial<IState> | null {
    return {
      activeGames: DashboardUI.activeGames(
        nextProps.dashboardData.activeGames,
        prevState.showGamesToSpectate,
        nextProps.user.id
      ),
    }
  }

  setShowGamesToSpectate = (showGamesToSpectate: boolean) => {
    this.setState({
      showGamesToSpectate,
      activeGames: DashboardUI.activeGames(
        this.props.dashboardData.activeGames,
        showGamesToSpectate,
        this.props.user.id
      ),
    })
  }

  renderLeaderboardDataHeaders = () => {
    return (
      <>
        <DSText
          size="body-15-demi"
          className={styles.rankingsHeader}
          style={{cursor: 'pointer'}}
          onClick={() => this.setState({sortLeaderboardBy: 'name'})}
        >
          Player
        </DSText>
        <DSText
          size="body-15-demi"
          className={styles.rankingsHeader}
          style={{textAlign: 'center'}}
          onClick={() => this.setState({sortLeaderboardBy: 'elo'})}
        >
          ELO
        </DSText>
        <DSText
          size="body-15-demi"
          className={styles.rankingsHeader}
          style={{textAlign: 'right'}}
          onClick={() => this.setState({sortLeaderboardBy: 'gameCount'})}
        >
          #/Games
        </DSText>
      </>
    )
  }

  renderPlayerWithLeaderboardData = (leaderboardData: TEloLeaderboardData) => {
    const {playerData, elo, gamesPlayed} = leaderboardData
    return (
      <React.Fragment key={playerData.playerId}>
        <DSText size="body-15">{playerData.username}</DSText>
        <DSText size="body-15-demi" style={{textAlign: 'right'}}>
          {elo}
        </DSText>
        <DSText size="body-15-demi" style={{textAlign: 'right'}}>
          {gamesPlayed}
        </DSText>
      </React.Fragment>
    )
  }

  render() {
    const leaderboardData =
      this.state.sortLeaderboardBy === 'elo'
        ? R.sortWith(
            [
              R.descend(R.prop('elo')),
              R.descend(R.prop('gamesPlayed')),
              R.ascend(d => d.playerData.username),
            ],
            this.props.dashboardData.eloLeaderboard
          )
        : this.state.sortLeaderboardBy === 'gameCount'
        ? R.sortWith(
            [
              R.descend(R.prop('gamesPlayed')),
              R.descend(R.prop('elo')),
              R.ascend(d => d.playerData.username),
            ],
            this.props.dashboardData.eloLeaderboard
          )
        : R.sortWith(
            [
              R.ascend(d => d.playerData.username),
              R.descend(R.prop('elo')),
              R.descend(R.prop('gamesPlayed')),
            ],
            this.props.dashboardData.eloLeaderboard
          )

    return (
      <div className={styles.dashboardWrapper}>
        <div className={styles.leftHalf}>
          <ProvideForm<TFormData, TJoinGameResponse>
            onSubmit={this.props.joinGame}
            disableAutoComplete
            submitSuccess={this.props.joinGameSuccess}
            valuePreprocessor={(fieldName, value) => {
              if (fieldName === 'gameId') {
                return extractUUID(value) ?? value
              }
              return defaultValuePreprocessor(fieldName, value)
            }}
            toFormFields={(generateFormFieldProps, formProps) => {
              return (
                <div className={styles.dashboardJoinForm}>
                  <LabeledLineTextInput
                    label="Game ID"
                    classNames={{wrapper: styles.gameIdInputWrapper}}
                    placeholder="Paste a Game ID to join manually"
                    hideLabelWhileBlankAndUnfocused
                    formFieldProps={generateFormFieldProps('gameId', [notEmpty], {})}
                  />

                  <DSButton
                    buttonStyle="primary"
                    size="15px"
                    onClick={() => formProps.onSubmit()}
                    className={styles.joinButton}
                    loading={formProps.isFormSubmitting}
                    disabled={formProps.isFormSubmitting}
                    appearDisabled={formProps.anyErrors}
                  >
                    Join Game
                  </DSButton>

                  <SingleButtonForm
                    buttonStyle="primary-dark"
                    size="15px"
                    onSubmit={this.props.createGame}
                    submitSuccess={this.props.createGameSuccess}
                    classNames={{wrapper: styles.createButton}}
                  >
                    Create New Game
                  </SingleButtonForm>
                </div>
              )
            }}
          />

          <div className={styles.activeGameListWrapper}>
            <DSTextDiv size="h3" className={styles.activeGameListHeader}>
              Active Games
              <Toggle
                toggleStyle="checkbox"
                wrapperClassName={styles.activeGameListToggle}
                checked={this.state.showGamesToSpectate}
                onChange={this.setShowGamesToSpectate}
              >
                {input => (
                  <>
                    {input}
                    <DSText size="body-15">Show games to spectate</DSText>
                  </>
                )}
              </Toggle>
            </DSTextDiv>
            {this.state.activeGames.find(
              ag => ag.createdBy === this.props.user.id
            ) && (
              <DSTextDiv size="caption" color="grey" italic>
                Games that you started can be permanently removed using the eye icon.
              </DSTextDiv>
            )}

            <div className={styles.activeGameList}>
              {this.state.activeGames.map(agd => {
                return (
                  <React.Fragment key={agd.gameId}>
                    <DSText size="body-15" color="pearl-grey-darkest">
                      {formatDateByAge(agd.createdDate)}
                    </DSText>
                    <DSText size="body-15">
                      {joinElements(agd.players, i => (
                        <span key={i}>, </span>
                      ))}
                    </DSText>
                    {agd.createdBy === this.props.user.id ? (
                      <HoverableFontAwesomeIconDiv
                        className={styles.activeGameListTrashIcon}
                        icon={{icon: faEyeSlash, style: {fontSize: '12px'}}}
                        children={id}
                        hoverIcon={{color: 'red-dark'}}
                        onClick={() => this.props.markGameAsStale(agd.gameId)}
                      />
                    ) : (
                      <div />
                    )}
                    <DSText size="body-15">
                      <DSLink
                        underline="onHover"
                        color="blue"
                        to={dynamicURLs.game.lobbyV2({gameId: agd.gameId})}
                      >
                        {agd.isInGame ? 'Continue' : 'Watch'}
                      </DSLink>
                    </DSText>
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        </div>
        <div className={styles.rightHalf}>
          <DSText size="h1">
            <DSExternalLink
              underline="onHover"
              href="https://en.wikipedia.org/wiki/Elo_rating_system"
              newTab
            >
              Elo
            </DSExternalLink>{' '}
            Leaderboard
          </DSText>
          <div className={styles.rankingsWrapper}>
            {this.renderLeaderboardDataHeaders()}
            {leaderboardData.map(this.renderPlayerWithLeaderboardData)}
          </div>
        </div>
      </div>
    )
  }
}
