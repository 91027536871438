import {PostApi} from '~/api/client'
import {
  AdminSignupsMock,
  TAdminSignUpsResult,
  transformAdminSignUpsResponse,
} from '~/api/endpoints/admin/AdminSignUps'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TAdminApproveSignUpParams} from '~/api/generated/types/AdminApproveSignUp'
import {TAdminSignUpsResponse} from '~/api/generated/types/AdminSignUps'

type PathPieces = {
  signupRequestId: string
}
export default class AdminApproveSignUp extends PostApi<
  TAdminApproveSignUpParams,
  TAdminSignUpsResponse,
  PathPieces,
  TAdminSignUpsResult
> {
  protected getMockResponse(
    params: TAdminApproveSignUpParams,
    pathPieces: PathPieces
  ): TAdminSignUpsResponse {
    return {
      signupRequests: AdminSignupsMock.filter(
        asm => asm.id !== pathPieces.signupRequestId
      ),
    }
  }

  protected getPath(pathPieces: PathPieces) {
    return serverEndpoints.adminAdminApproveSignUp(pathPieces)
  }

  protected transformResponse(r: TAdminSignUpsResponse): TAdminSignUpsResult {
    return transformAdminSignUpsResponse(r)
  }
}
