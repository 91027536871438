import * as React from 'react'
import {connect} from 'react-redux'
import {TSkin} from '~/game/skins'
import DashboardLayout from '~/layouts/DashboardLayout'
import {TGameLobbySize} from '~/pages/GameLobby/types'
import SettingsUI from '~/pages/Settings/stateless'
import {ReduxState} from '~/redux/reducers/root'
import {
  setGameLobbySize,
  setSkin,
  setNotifications,
  TBetaFeatureSettings,
  setBetaFeatures,
  setDarkMode,
} from '~/redux/reducers/settings'
import {ISignedInRouteComponentProps} from '~/routing/Route/SignedIn'
import {
  TUserNotificationPerSettingOptions,
  TUserNotificationSettings,
} from '~/utils/UserNotifications'

interface IReduxDispatchProps {
  setSkin: (skin: TSkin) => void
  setGameLobbySize: (gameLobbySize: TGameLobbySize) => void
  setNotifications: (notifications: TUserNotificationSettings) => void
  setBetaFeatures: (betaFeatures: TBetaFeatureSettings) => void
  setDarkMode: (darkMode: boolean) => void
}
interface IReduxProps extends Pick<ReduxState, 'settingsState'> {}
interface IProps
  extends ISignedInRouteComponentProps<any>,
    IReduxProps,
    IReduxDispatchProps {}

interface IState {}

class SettingsPage extends React.Component<IProps, IState> {
  state: IState = {}

  render() {
    return (
      <DashboardLayout {...this.props}>
        <SettingsUI
          gameLobbySize={this.props.settingsState.gameLobbySize}
          setGameLobbySize={gameLobbySize => {
            return new Promise(resolve => {
              this.props.setGameLobbySize(gameLobbySize)
              resolve()
            })
          }}
          skin={this.props.settingsState.skin}
          setSkin={skin => {
            return new Promise(resolve => {
              this.props.setSkin(skin)
              resolve()
            })
          }}
          notifications={this.props.settingsState.notifications}
          setNotificationSetting={(
            setting: keyof TUserNotificationSettings,
            subsettings: TUserNotificationPerSettingOptions
          ) => {
            return new Promise(resolve => {
              this.props.setNotifications({
                ...this.props.settingsState.notifications,
                [setting]: subsettings,
              })
              resolve()
            })
          }}
          betaFeatures={this.props.settingsState.betaFeatures}
          setBetaFeatureSetting={(
            setting: keyof TBetaFeatureSettings,
            value: boolean
          ) => {
            return new Promise(resolve => {
              this.props.setBetaFeatures({
                ...this.props.settingsState.betaFeatures,
                [setting]: value,
              })
              resolve()
            })
          }}
          darkMode={this.props.settingsState.darkMode}
          setDarkMode={(isDarkMode: boolean) => {
            return new Promise(resolve => {
              this.props.setDarkMode(isDarkMode)
              resolve()
            })
          }}
        />
      </DashboardLayout>
    )
  }
}

const mapStateToProps = (state: ReduxState): IReduxProps => ({
  settingsState: state.settingsState,
})
const mapDispatchToProps = {
  setSkin,
  setGameLobbySize,
  setNotifications,
  setBetaFeatures,
  setDarkMode,
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage)
