import {MD5} from 'object-hash'
import {OmitStrict} from 'type-zoo'
import {
  TGamePosition,
  TLastRentThisVisit,
  TPlay,
  TPlayerData,
} from '~/api/generated/types/common'
import {MAX_NUMBER_OF_PLAYERS, MIN_NUMBER_OF_PLAYERS} from '~/game/ruleset'
import {TTime} from '~/game/types'
import {DataBackedModel} from '~/models/base'
import ActionCard from '~/models/game/Cards/ActionCard'
import RentCard from '~/models/game/Cards/RentCard'
import {cardFactory} from '~/models/game/factories'
import ConcealedHand from '~/models/game/Hands/ConcealedHand'
import InProgressMajorAction from '~/models/game/InProgressMajorAction'
import ConcealedPlayer from '~/models/game/Players/ConcealedPlayer'
import CurrentPlayer from '~/models/game/Players/CurrentPlayer'
import ExposedHand from '~/models/game/Hands/ExposedHand'
import ExposedPlayer from '~/models/game/Players/ExposedPlayer'
import Opponent from '~/models/game/Players/Opponent'
import Player from '~/models/game/Player'
import Portfolio from '~/models/game/Portfolio'
import {safely} from '~/utils'
import {rotateLeftUntil} from '~/utils/List'

type PublicMembersFromApi = OmitStrict<
  TGamePosition,
  'portfolios' | 'cardsInHands' | 'inProgressMajorAction' | 'recentlyPlayedCards'
>

// this contains all the data about the state of the game at a given moment in time
// TODO make subclasses for the various kinds of plays / major actions? might not need that extra complexity...
export default abstract class GamePosition
  extends DataBackedModel<TGamePosition>
  implements PublicMembersFromApi {
  majorActorPlayerId: string // whose turn is it
  majorActorPlayer: Player

  minorActorPlayer: Player | undefined
  // pendingMinorActorPlayers: Player[]

  play: TPlay // which step of their turn are they on
  time: TTime
  hash: string

  lastRentThisVisit: TLastRentThisVisit | undefined

  portfolios: Portfolio[] // the CurrentPlayer and Opponents' cards on the table

  inProgressMajorAction: InProgressMajorAction | undefined

  // moved this from GameData because we want the Portfolios to build Players
  players: Player[]

  recentlyPlayedCards: (ActionCard<any> | RentCard<any>)[]
  cardsInDeck: number

  protected constructor(
    data: TGamePosition,
    players: TPlayerData[],
    currentUserId: string
  ) {
    super(data)
    this.majorActorPlayerId = data.majorActorPlayerId
    this.play = data.play
    this.time = data.time
    this.hash = MD5(data)

    this.portfolios = data.portfolios.map(p => new Portfolio(p))

    this.players = players.map(player => {
      const portfolio = this.portfolios.find(
        port => port.playerId === player.playerId
      )

      if (!portfolio) {
        throw new Error(
          'The list of players in a TGameData had a playerId that was not in the TGamePosition.portfolios list!'
        )
      }

      const handData = data.cardsInHands.find(
        cih => cih.playerId === player.playerId
      )
      switch (handData?.type) {
        case 'concealed':
          return new ConcealedPlayer(player, portfolio, new ConcealedHand(handData))
        case 'exposed':
          return new ExposedPlayer(player, portfolio, new ExposedHand(handData))
        case undefined:
        default:
          throw new Error(
            `Player ${player.playerId} not found in TGameData.cardsInHands`
          )
      }
    })

    const majorActorPlayer = this.players.find(p => p.id === this.majorActorPlayerId)
    if (!majorActorPlayer) {
      throw new Error('major actor player not found in players list')
    }
    this.majorActorPlayer = majorActorPlayer

    const minorActorPlayerId =
      data.inProgressMajorAction?.pendingMinorActions[0]?.minorActorPlayerId
    this.minorActorPlayer = safely(minorActorPlayerId, id =>
      this.players.find(p => p.id === id)
    )

    // the backend seems to send incorrect/outdated lastRentThisVisit data right now
    this.lastRentThisVisit = safely(data.lastRentThisVisit, lrtv => {
      const pnIdToDoubleRentOn = lrtv.portfolioNeighborhood.id
      if (
        !majorActorPlayer.portfolio.neighborhoods.find(
          pn => pn.id === pnIdToDoubleRentOn
        )
      ) {
        console.log(
          `Removing lastRentThisVisit because it doesn't seem to apply to the current majorActors' portfolio`
        )
        return undefined
      }
      return lrtv
    })

    this.inProgressMajorAction = safely(
      data.inProgressMajorAction,
      ipma =>
        new InProgressMajorAction(
          ipma,
          majorActorPlayer,
          this.players,
          this.lastRentThisVisit
        )
    )

    if (!this.minorActorPlayer && !!this.inProgressMajorAction) {
      throw new Error(
        'minor actor player not found in players list, or pendingMinorActions was empty'
      )
    }

    this.recentlyPlayedCards = data.recentlyPlayedCards.map(c => cardFactory(c))
    this.cardsInDeck = data.cardsInDeck
  }

  static build = (
    data: TGamePosition,
    players: TPlayerData[],
    currentUserId: string
  ): GamePosition => {
    if (players.find(p => p.playerId === currentUserId)) {
      return new PlayerGamePosition(data, players, currentUserId)
    } else {
      return new SpectatorGamePosition(data, players, currentUserId)
    }
  }

  // "is it the currently logged in player's majorTurn?"
  abstract isMyMajorTurn(): boolean
  // "is it the currently logged in player's minorTurn?"
  abstract isMyMinorTurn(): boolean

  // matching player is returned in rotatedPlayerList[0], if playing
  playerListRotatedForPlayer(playerId: string) {
    return rotateLeftUntil(p => p.id === playerId, this.players)
  }
}

// this contains all the data about the state of the game at a given moment in time
// this subclass adds data about the CurrentPlayer's hand.
export class PlayerGamePosition extends GamePosition {
  currentPlayerHand: ExposedHand // "your" hand from the perspective of the client

  // moved this stuff from GameData because we want the Portfolios to build Players
  currentPlayer: CurrentPlayer
  opponents: Opponent[]

  constructor(data: TGamePosition, players: TPlayerData[], currentUserId: string) {
    super(data, players, currentUserId)

    const currentPlayers: CurrentPlayer[] = []
    const opponents: Opponent[] = []

    this.players.map(player => {
      if (player.id === currentUserId && player instanceof ExposedPlayer) {
        const cp = new CurrentPlayer(
          player.datasource,
          player.portfolio,
          player.hand
        )
        currentPlayers.push(cp)
        return cp
      } else if (player.id !== currentUserId && player instanceof ConcealedPlayer) {
        const op = new Opponent(player.datasource, player.portfolio, player.hand)
        opponents.push(op)
        return op
      } else {
        throw new Error(
          `mismatched expectations when building PlayerGamePosition. ${
            player.id
          } ${currentUserId} ${player instanceof ExposedPlayer} ${
            player instanceof ConcealedPlayer
          }`
        )
      }
    })

    const currentPlayer: CurrentPlayer | undefined = currentPlayers[0]
    // assertions about the list of players
    if (!currentPlayer) {
      throw new Error(
        `PlayerGamePosition: no current player! current user id: ${currentUserId}`
      )
    } else if (currentPlayers.length !== 1) {
      throw new Error(`more than one current player (${currentPlayers.length})!`)
    } else if (
      this.players.length > MAX_NUMBER_OF_PLAYERS ||
      this.players.length < MIN_NUMBER_OF_PLAYERS
    ) {
      throw new Error(`invalid player count (${this.players.length})!`)
    }

    this.currentPlayer = currentPlayer
    this.opponents = opponents
    this.currentPlayerHand = currentPlayer.hand
  }

  public opponentPortfolios(): Portfolio[] {
    return this.opponents.map(o => o.portfolio).flat(1)
  }

  public opponentPortfoliosWithValue(): Portfolio[] {
    return this.opponentPortfolios().filter(p => p.totalValue() > 0)
  }

  public isMyMajorTurn(): boolean {
    return this.majorActorPlayerId === this.currentPlayer.id
  }

  public isMyMinorTurn(): boolean {
    return this.minorActorPlayer?.id === this.currentPlayer.id
  }

  // current player is returned in rotatedPlayerList[0]
  playerListRotatedForCurrentPlayer() {
    return super.playerListRotatedForPlayer(this.currentPlayer.id)
  }
}

export class SpectatorGamePosition extends GamePosition {
  constructor(data: TGamePosition, players: TPlayerData[], spectatorUserId: string) {
    super(data, players, spectatorUserId)
  }

  public isMyMajorTurn(): boolean {
    return false
  }

  public isMyMinorTurn(): boolean {
    return false
  }
}
