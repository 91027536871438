import * as React from 'react'
import {connect} from 'react-redux'
import {Route, RouteProps} from 'react-router-dom'
import {OmitStrict} from 'type-zoo'
import {ReduxState} from '~/redux/reducers/root'
import {Redirect} from '~/routing/ReactRouterLinks'
import {SimpleRouteComponentType} from '~/routing/Route'
import {staticURLs} from '~/routing/sitemap'

interface IPassInProps extends OmitStrict<RouteProps, 'component'> {
  // prevent a `Route.NotSignedIn` component from asking for e.g. ISignedInRouteComponentProps
  component: SimpleRouteComponentType
}
interface IReduxProps extends Pick<ReduxState, 'userState'> {}
interface IProps extends IReduxProps, IPassInProps {}

const NotSignedIn = (props: IProps) => {
  if (props.userState.user) {
    console.log('You are already logged in. Redirecting to the appropriate page.')
    return (
      <Redirect
        to={{
          pathname: staticURLs.dashboard,
        }}
      />
    )
  }
  return <Route {...props} />
}

const mapStateToProps = (
  state: ReduxState,
  ownProps: IPassInProps
): IReduxProps => ({
  userState: state.userState,
})

export default connect(mapStateToProps)(NotSignedIn)
