import {playersMock} from '~/api/endpoints/game/GetGameData/mock'
import {PostApi} from '~/api/client'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TWaitingRoomResponse} from '~/api/generated/types/GetGameWaitingRoom'
import {TJSONError} from '~/api/types'

export type TGetWaitingRoomDataParams = {}

export type TGetWaitingRoomDataPathPieces = {
  gameId: string
}

/**
 * Polled to get the latest status of the waiting room a player is waiting in before the game starts.
 */
export default class GetWaitingRoomData extends PostApi<
  TGetWaitingRoomDataParams,
  TWaitingRoomResponse,
  TGetWaitingRoomDataPathPieces
> {
  // so the mocks can gradually increase the number of players in the response
  private static mockPlayerCount = 1
  public static resetMockPlayerCount() {
    this.mockPlayerCount = 1
  }

  protected mockRequestFailure(
    params: TGetWaitingRoomDataParams,
    pathPieces: TGetWaitingRoomDataPathPieces
  ): TJSONError | null {
    if (pathPieces.gameId === 'fail') {
      return {errors: {gameId: [`That game doesn't exist.`]}}
    }

    return super.mockRequestFailure(params, pathPieces)
  }

  protected getMockResponse(
    params: TGetWaitingRoomDataParams
  ): TWaitingRoomResponse {
    return {
      status: 'waiting',
      playerData: playersMock.slice(0, GetWaitingRoomData.mockPlayerCount++),
      hostPlayerId: playersMock[0].playerId,
    }
  }

  protected getPath(pathPieces: TGetWaitingRoomDataPathPieces) {
    return serverEndpoints.gameWaitingRoom(pathPieces)
  }

  protected transformResponse(r: TWaitingRoomResponse) {
    return {
      ...r,
      playerData: r.playerData.map(pd => ({
        ...pd,
        email: pd.email ?? pd.username + '@mercury.com', // TODO remove once email is in the backend response
      })),
    }
  }
}
