import {ExcludeStrict, ExtractStrict} from 'type-zoo'
import {
  TActionCard,
  TActionCardData,
  TCard,
  TCardData,
  TDualPropertyCard,
  TLiquidatedCardData,
  TMajorActionData,
  TMajorActionDataThatAllowsMinorAction,
  TMajorActionDirectionalRentData,
  TMajorActionDoubleTheRentData,
  TMajorActionUniversalRentData,
  TMinorActionData,
  TMinorActionDataAcceptDealBreaker,
  TMinorActionDataAcceptForcedDeal,
  TMinorActionDataAcceptSlyDeal,
  TMinorActionDataAcquiesce,
  TMinorActionDataJustSayNo,
  TMinorActionDataPayOther,
  TMinorActionDataPayRent,
  TMinorActionDebtCollectorBirthdayType,
  TMinorActionPromptData,
  TMinorActionRentType,
  TPlay,
  TPortfolioNeighborhoodData,
  TPropertyCardData,
  TResidenceCard,
  TStandardPropertyType,
  TUniversalRentCard,
} from '~/api/generated/types/common'
import {recordKeySet, unreachableCase} from '~/utils'
import {withOrdinalSuffix} from '~/utils/language'
import * as NEL from '~/utils/NEList'
import * as S from '~/utils/Set'

export type TTime = number

// NB: order here is used in neighborhoodOrderMap - lowest to highest "rank" visually
export const allStandardPropertyTypes = recordKeySet<TStandardPropertyType>({
  railroad: true,
  utility: true,
  brown: true,
  lightBlue: true,
  pink: true,
  orange: true,
  red: true,
  yellow: true,
  green: true,
  darkBlue: true,
})

export type TNeighborhood = TPortfolioNeighborhoodData['neighborhood']

export const neighborhoodOrderMap = (() => {
  const map = S.toMap<TNeighborhood, number>((_, i) => i, allStandardPropertyTypes)
  map.set('alone', allStandardPropertyTypes.size)
  return map
})()

export const rentValue = (
  neighborhood: TStandardPropertyType,
  count: number
): number => {
  let values: NEL.NEList<number>
  switch (neighborhood) {
    case 'darkBlue':
      values = [3, 8]
      break
    case 'green':
      values = [2, 4, 7]
      break
    case 'yellow':
      values = [2, 4, 6]
      break
    case 'red':
      values = [2, 3, 6]
      break
    case 'orange':
      values = [1, 3, 5]
      break
    case 'pink':
      values = [1, 2, 4]
      break
    case 'lightBlue':
      values = [1, 2, 3]
      break
    case 'brown':
      values = [1, 2]
      break
    case 'railroad':
      values = [1, 2, 3, 4]
      break
    case 'utility':
      values = [1, 2]
      break
    default:
      return unreachableCase(neighborhood)
  }

  if (count > values.length) {
    console.error('rentValue was asked about charging rent for too many cards!', {
      neighborhood,
      count,
    })
  }

  return NEL.atOrLast(count, [0, ...values])
}

export const residenceRentValue = (residence: TResidenceCard): number => {
  switch (residence) {
    case 'house':
      return 3
    case 'hotel':
      return 4
    default:
      return unreachableCase(residence)
  }
}

export const debtCollectorCost = 5
export const birthdayCost = 2

export type TPropertyType = TStandardPropertyType | 'wild'

// notably excludes 'doubleTheRent' which is a TActionCard instead
export type TRentCard = ExtractStrict<
  TActionCard,
  | 'directionalRent'
  | 'rentDarkBlueGreen'
  | 'rentLightBlueBrown'
  | 'rentPinkOrange'
  | 'rentUtilityRailroad'
  | 'rentRedYellow'
>
export type TRentCardData = TCardData & {type: TRentCard}
export type TNonRentActionCard = ExcludeStrict<TActionCard, TRentCard>
export type TNonRentActionCardData = TCardData & {type: TNonRentActionCard}
export type TPlayableCardData = TActionCardData // === TNonRentActionCard | TRentCardData

export const cardValue: Record<ExcludeStrict<TCard, 'propertyWildCard'>, number> = {
  darkBlue: 4,
  green: 4,
  yellow: 3,
  red: 3,
  orange: 2,
  pink: 2,
  lightBlue: 1,
  brown: 1,
  railroad: 2,
  utility: 2,

  wildDarkBlueAndGreen: 4,
  wildGreenAndRailroad: 4,
  wildLightBlueAndRailroad: 4,
  wildRedAndYellow: 3,
  wildUtilityAndRailroad: 2,
  wildPinkAndOrange: 2,
  wildLightBlueAndBrown: 1,

  directionalRent: 3,
  rentDarkBlueGreen: 1,
  rentLightBlueBrown: 1,
  rentPinkOrange: 1,
  rentUtilityRailroad: 1,
  rentRedYellow: 1,

  dealBreaker: 5,
  justSayNo: 4,
  slyDeal: 3,
  forcedDeal: 3,
  debtCollector: 3,
  birthday: 2,
  doubleTheRent: 1,
  passGo: 1,

  house: 3,
  hotel: 4,

  tenMil: 10,
  fiveMil: 5,
  fourMil: 4,
  threeMil: 3,
  twoMil: 2,
  oneMil: 1,
}

export const cardsInDeck: Record<TCard, number> = {
  darkBlue: 2,
  green: 3,
  yellow: 3,
  red: 3,
  orange: 3,
  pink: 3,
  lightBlue: 3,
  brown: 2,
  railroad: 4,
  utility: 2,

  wildDarkBlueAndGreen: 1,
  wildGreenAndRailroad: 1,
  wildLightBlueAndRailroad: 1,
  wildRedAndYellow: 2,
  wildUtilityAndRailroad: 1,
  wildPinkAndOrange: 2,
  wildLightBlueAndBrown: 1,

  propertyWildCard: 2,

  directionalRent: 3,
  rentDarkBlueGreen: 2,
  rentLightBlueBrown: 2,
  rentPinkOrange: 2,
  rentUtilityRailroad: 2,
  rentRedYellow: 2,

  dealBreaker: 2,
  justSayNo: 3,
  slyDeal: 3,
  forcedDeal: 3,
  debtCollector: 3,
  birthday: 3,
  doubleTheRent: 2,
  passGo: 10,

  house: 3,
  hotel: 2,

  tenMil: 1,
  fiveMil: 2,
  fourMil: 3,
  threeMil: 3,
  twoMil: 5,
  oneMil: 6,
}

export const cardStarRating: Record<TCard, 1 | 2 | 3 | 4 | 5> = {
  propertyWildCard: 5,
  darkBlue: 5,
  wildDarkBlueAndGreen: 5,

  green: 4,
  wildGreenAndRailroad: 4,
  yellow: 4,
  wildRedAndYellow: 4,

  red: 3,
  orange: 3,
  wildPinkAndOrange: 3,
  utility: 3,
  brown: 3,

  wildUtilityAndRailroad: 3,
  wildLightBlueAndBrown: 3,

  wildLightBlueAndRailroad: 2,
  pink: 2,
  lightBlue: 1,

  railroad: 1,

  directionalRent: 3,
  rentDarkBlueGreen: 5,
  rentLightBlueBrown: 1,
  rentPinkOrange: 2,
  rentUtilityRailroad: 2,
  rentRedYellow: 3,

  dealBreaker: 5,
  justSayNo: 5,
  slyDeal: 4,
  forcedDeal: 4,
  debtCollector: 4,
  birthday: 3,
  doubleTheRent: 3,
  passGo: 1,

  house: 2,
  hotel: 2,

  tenMil: 2,
  fiveMil: 4,
  fourMil: 3,
  threeMil: 3,
  twoMil: 3,
  oneMil: 3,
}

export type TCardClass =
  | 'cash'
  | 'residence'
  | 'action'
  | 'rent'
  | 'standardProperty'
  | 'dualProperty'
  | 'propertyWildCard'

export const cardClass: Record<TCard, TCardClass> = {
  darkBlue: 'standardProperty',
  green: 'standardProperty',
  yellow: 'standardProperty',
  red: 'standardProperty',
  orange: 'standardProperty',
  pink: 'standardProperty',
  lightBlue: 'standardProperty',
  brown: 'standardProperty',
  railroad: 'standardProperty',
  utility: 'standardProperty',

  wildDarkBlueAndGreen: 'dualProperty',
  wildGreenAndRailroad: 'dualProperty',
  wildUtilityAndRailroad: 'dualProperty',
  wildLightBlueAndRailroad: 'dualProperty',
  wildLightBlueAndBrown: 'dualProperty',
  wildPinkAndOrange: 'dualProperty',
  wildRedAndYellow: 'dualProperty',

  directionalRent: 'rent',
  rentDarkBlueGreen: 'rent',
  rentLightBlueBrown: 'rent',
  rentPinkOrange: 'rent',
  rentUtilityRailroad: 'rent',
  rentRedYellow: 'rent',

  dealBreaker: 'action',
  debtCollector: 'action',
  doubleTheRent: 'action',
  slyDeal: 'action',
  forcedDeal: 'action',
  birthday: 'action',
  justSayNo: 'action',
  passGo: 'action',

  house: 'residence',
  hotel: 'residence',

  tenMil: 'cash',
  fiveMil: 'cash',
  fourMil: 'cash',
  threeMil: 'cash',
  twoMil: 'cash',
  oneMil: 'cash',

  propertyWildCard: 'propertyWildCard',
}

export const cardsPerSet: Record<TStandardPropertyType, 2 | 3 | 4> = {
  darkBlue: 2,
  green: 3,
  yellow: 3,
  red: 3,
  orange: 3,
  pink: 3,
  lightBlue: 3,
  brown: 2,
  railroad: 4,
  utility: 2,
}

export type TMajorActionType = TMajorActionData['type']
// only some major actions can have associated minor actions (pay, just say no, accept, acquiesce)
// since e.g. playing cards as cash can't have minor turn responses / be "in-progress"
export type TMajorActionTypesThatAllowMinorActions = TMajorActionDataThatAllowsMinorAction['type']

export type TMajorActionTypesThatDontAllowMinorActions = ExcludeStrict<
  TMajorActionType,
  TMajorActionTypesThatAllowMinorActions
>

export type TMajorActionPaySomethingData =
  | TMajorActionDirectionalRentData
  | TMajorActionUniversalRentData
  | TMajorActionDoubleTheRentData

// this is backend -> frontend data only
// this describes what happened to cause a minor action to be requested from a player
export type TMinorActionPromptType = TMinorActionPromptData['type']

// all TMinorActionPromptData share these common traits
export type TMinorActionPromptDataBase = {
  type: TMinorActionPromptType

  // "who must act?"
  minorActorPlayerId: string

  // the rest of the data describes "what must they act upon?"
  //
  // note this is different than a `TMajorActionData` because:
  // - the server will calculate e.g. `millionsOwed` for rents / debt collectors / birthdays
  // - `minorActorPlayerId` on e.g. `TMajorActionDirectionalRentData` doesn't need to be set,
  //    that's the `minorActorPlayerId` above.
  // - there is also the case that the prompt is a JustSayNo (not a Major type); if
  //    someone JustSayNo'd you, and you must respond.
}
export type TMinorActionPromptPaySomethingBaseData = TMinorActionPromptDataBase & {
  type:
    | 'universalRent'
    | 'directionalRent'
    | 'doubleTheRent'
    | 'debtCollector'
    | 'birthday'
  millionsOwed: number
}

export type TMinorActionType = TMinorActionData['type']

export type TMinorActionBaseData = {
  type: TMinorActionType
  minorActorPlayerId: string // who took this minor action
}

export type TMinorActionPaySomethingType =
  | TMinorActionRentType
  | TMinorActionDebtCollectorBirthdayType // lovingly referred to as 'Other' elsewhere

// either paying all rent types or debtCollectors / birthdays, they all look like this
export type TMinorActionDataPaySomething = {
  type: TMinorActionPaySomethingType
  millionsOwed: number
  paidWith: (TLiquidatedCardData | TPropertyCardData)[]
  minorActorPlayerId: string // who took this minor action
}

export type TBetterMinorActionDataAcquiesce = TMinorActionDataAcquiesce & {
  // the API doesn't specify the player we're acquiescing _to_ explicitly,
  // but the data is there in `previousMinorActions` and `inProgressMinorAction`
  // so we can reconstruct it.
  prevailingPlayerId: string
}

export type TBetterMinorActionData =
  | TBetterMinorActionDataAcquiesce
  | TMinorActionDataAcceptDealBreaker
  | TMinorActionDataPayOther
  | TMinorActionDataAcceptForcedDeal
  | TMinorActionDataJustSayNo
  | TMinorActionDataPayRent
  | TMinorActionDataAcceptSlyDeal

export const isDualPropertyType = (
  type: TDualPropertyCard | TStandardPropertyType
): type is TDualPropertyCard => {
  return cardClass[type] === 'dualProperty'
}

export type TPassGoCardData = TActionCardData & {
  type: 'passGo'
  version: number
  id: string
}

export type TJustSayNoCardData = TActionCardData & {
  type: 'justSayNo'
  version: number
  id: string
}

export type TForcedDealCardData = TActionCardData & {
  type: 'forcedDeal'
  version: number
  id: string
}

export type TDealBreakerCardData = TActionCardData & {
  type: 'dealBreaker'
  version: number
  id: string
}

export type TDebtCollectorCardData = TActionCardData & {
  type: 'debtCollector'
  version: number
  id: string
}

export type TBirthdayCardData = TActionCardData & {
  type: 'birthday'
  version: number
  id: string
}

export type TSlyDealCardData = TActionCardData & {
  type: 'slyDeal'
  version: number
  id: string
}

export const standardPropertyTypesForDualPropertyCard = (
  type: TDualPropertyCard
): [TStandardPropertyType, TStandardPropertyType] => {
  switch (type) {
    case 'wildDarkBlueAndGreen':
      return ['darkBlue', 'green']
    case 'wildGreenAndRailroad':
      return ['green', 'railroad']
    case 'wildLightBlueAndBrown':
      return ['lightBlue', 'brown']
    case 'wildLightBlueAndRailroad':
      return ['lightBlue', 'railroad']
    case 'wildPinkAndOrange':
      return ['pink', 'orange']
    case 'wildRedAndYellow':
      return ['red', 'yellow']
    case 'wildUtilityAndRailroad':
      return ['utility', 'railroad']
    default:
      return unreachableCase(type)
  }
}

export const standardPropertyTypesForUniversalRentCard = (
  type: TUniversalRentCard
): [TStandardPropertyType, TStandardPropertyType] => {
  switch (type) {
    case 'rentDarkBlueGreen':
      return ['darkBlue', 'green']
    case 'rentLightBlueBrown':
      return ['lightBlue', 'brown']
    case 'rentPinkOrange':
      return ['pink', 'orange']
    case 'rentRedYellow':
      return ['red', 'yellow']
    case 'rentUtilityRailroad':
      return ['utility', 'railroad']
    default:
      return unreachableCase(type)
  }
}

export const previousPlay = (
  currentPlay: ExcludeStrict<TPlay, 'firstPlay' | 'discardPlay'>
): ExtractStrict<TPlay, 'firstPlay' | 'secondPlay'> => {
  switch (currentPlay) {
    case 'secondPlay':
      return 'firstPlay'
    case 'thirdPlay':
      return 'secondPlay'
    default:
      return unreachableCase(currentPlay)
  }
}

export const playHuman: Record<TPlay, string> = {
  firstPlay: 'First play',
  secondPlay: `Second Play`,
  thirdPlay: `Third Play`,
  discardPlay: `Discard`,
}

export const playHumanNumeric: Record<TPlay, string> = {
  firstPlay: '1/3',
  secondPlay: `2/3`,
  thirdPlay: `3/3`,
  discardPlay: `Discard`,
}

export const playHumanOrdinal: Record<
  ExcludeStrict<TPlay, 'discardPlay'>,
  string
> = {
  firstPlay: withOrdinalSuffix(1),
  secondPlay: withOrdinalSuffix(2),
  thirdPlay: withOrdinalSuffix(3),
}

export const movesLeftForPlay: Record<TPlay, 3 | 2 | 1 | 0> = {
  firstPlay: 3,
  secondPlay: 2,
  thirdPlay: 1,
  discardPlay: 0,
}
