import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {TCardData} from '~/api/generated/types/common'

export type UIState = {
  inspectingCardData: TCardData | undefined
}

const initialState: UIState = {
  inspectingCardData: undefined,
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setInspectingCardData(
      state: UIState,
      action: PayloadAction<TCardData | undefined>
    ) {
      return {inspectingCardData: action.payload}
    },
  },
})

const {setInspectingCardData} = uiSlice.actions
export {setInspectingCardData}
export default uiSlice.reducer
