import * as React from 'react'
import ProvideFormIncremental from '~/components/ProvideForm/incremental'
import {TrueOrFalse, TTrueOrFalse} from '~/components/ProvideForm/utils'
import {FormRadioButtonGroup, RadioButton} from '~/components/RadioButtonGroup'
import {FormToggle, ToggleForGroup} from '~/components/Toggle'
import {FormToggleGroup, IToggleGroupRenderProps} from '~/components/ToggleGroup'
import DSText from '~/design-system/DSText'
import {skinsHuman, TSkin} from '~/game/skins'
import {
  BirthdayCard,
  JustSayNoCard,
  PassGoCard,
} from '~/models/game/Cards/ActionCard'
import DualPropertyCard from '~/models/game/Cards/DualPropertyCard'
import StandardPropertyCard from '~/models/game/Cards/StandardPropertyCard'
import CardImage from '~/pages/GameLobby/GameCard/stateless'
import {gameLobbySizeHuman, TGameLobbySize} from '~/pages/GameLobby/types'
import {TBetaFeatureSettings} from '~/redux/reducers/settings'
import {recordKeySet, unreachableCase, isDarkMode} from '~/utils'
import * as M from '~/utils/Map'
import * as S from '~/utils/Set'
import {
  allUserNotificationPerSettingOptionsFalse,
  humanPerSettingOptions,
  humanSettingDescriptions,
  TUserNotificationPerSettingOptions,
  TUserNotificationSettings,
} from '~/utils/UserNotifications'
import styles from './styles.module.css'

type TFormData =
  // each string is a JSON'd Set<keyof TUserNotificationPerSettingOptions>
  Record<keyof TUserNotificationSettings, string> &
    // TTrueOrFalse
    Record<keyof TBetaFeatureSettings, string> & {
      gameLobbySize: string // JSON'd Set<TGameLobbySize>
      skin: string // JSON'd Set<TSkin>
    } & Record<'darkMode', TTrueOrFalse>

interface IProps {
  gameLobbySize: TGameLobbySize
  setGameLobbySize: (gameLobbySize: TGameLobbySize) => Promise<void>

  skin: TSkin
  setSkin: (skin: TSkin) => Promise<void>

  notifications: TUserNotificationSettings
  setNotificationSetting: (
    setting: keyof TUserNotificationSettings,
    subsettings: TUserNotificationPerSettingOptions
  ) => Promise<void>

  betaFeatures: TBetaFeatureSettings
  setBetaFeatureSetting: (
    setting: keyof TBetaFeatureSettings,
    value: boolean
  ) => Promise<void>

  darkMode: boolean
  setDarkMode: (value: boolean) => Promise<void>
}

export default class SettingsUI extends React.Component<IProps> {
  render() {
    return (
      <ProvideFormIncremental<TFormData, void>
        onSubmit={(fieldName, value) => {
          switch (fieldName) {
            case 'gameLobbySize':
              return this.props.setGameLobbySize(
                FormToggleGroup.formValueToFirstSelection<TGameLobbySize>(value)!
              )
            case 'skin':
              return this.props.setSkin(
                FormToggleGroup.formValueToFirstSelection<TSkin>(value)!
              )
            case 'gameLog':
              return this.props.setBetaFeatureSetting(
                fieldName,
                TrueOrFalse.toBool(value as TTrueOrFalse)
              )
            case 'darkMode':
              return this.props.setDarkMode(
                TrueOrFalse.toBool(value as TTrueOrFalse)
              )
            default:
              if (S.has(fieldName, recordKeySet(humanSettingDescriptions))) {
                const enabledSubsettings = FormToggleGroup.formValueToSet<
                  keyof TUserNotificationPerSettingOptions
                >(value)
                const enabledSubsettingsObj = S.reduce(
                  (acc, v) => ({...acc, [v]: true}),
                  enabledSubsettings,
                  {}
                )
                return this.props.setNotificationSetting(fieldName, {
                  ...allUserNotificationPerSettingOptionsFalse,
                  ...enabledSubsettingsObj,
                })
              } else {
                return unreachableCase(fieldName)
              }
          }
        }}
        toFormFields={(generateFormFieldProps, formProps) => {
          return (
            <div className={styles.settingsPageWrapper}>
              <DSText tag="h2" size="section-title">
                Game Lobby Size
              </DSText>
              <div className={styles.radioList}>
                <FormRadioButtonGroup<TGameLobbySize, TFormData>
                  allChoices={recordKeySet(gameLobbySizeHuman)}
                  initialChoice={this.props.gameLobbySize}
                  generateFormFieldProps={generateFormFieldProps}
                  formProps={formProps}
                  fieldName="gameLobbySize"
                  humanChoices={gameLobbySizeHuman}
                  blurOnChange
                  renderRadioButton={radioButtonProps => (
                    <RadioButton
                      {...radioButtonProps}
                      key={radioButtonProps.choice}
                      toggleStyle="radio"
                      classNames={{
                        choice: styles.radioChoice,
                        selectedChoice: styles.selectedRadioChoice,
                        toggle: styles.radioToggle,
                        fadedToggle: styles.fadedToggle,
                        choiceLabel: styles.radioLabel,
                      }}
                      label={{
                        size: 'body-15',
                        fadedColor: 'pearl-grey-darkest',
                      }}
                    />
                  )}
                />
              </div>

              <DSText tag="h2" size="section-title">
                Game Skin
              </DSText>
              <div className={styles.radioList}>
                <FormRadioButtonGroup<TSkin, TFormData>
                  allChoices={recordKeySet(skinsHuman)}
                  initialChoice={this.props.skin}
                  generateFormFieldProps={generateFormFieldProps}
                  formProps={formProps}
                  fieldName="skin"
                  humanChoices={skinsHuman}
                  blurOnChange
                  renderRadioButton={radioButtonProps => (
                    <RadioButton
                      {...radioButtonProps}
                      key={radioButtonProps.choice}
                      toggleStyle="radio"
                      classNames={{
                        choice: styles.radioChoice,
                        selectedChoice: styles.selectedRadioChoice,
                        toggle: styles.radioToggle,
                        fadedToggle: styles.fadedToggle,
                        choiceLabel: styles.radioLabel,
                      }}
                      label={{
                        size: 'body-15',
                        fadedColor: 'pearl-grey-darkest',
                      }}
                    />
                  )}
                />
              </div>

              <CardImage
                model={new JustSayNoCard({id: 'jsn', type: 'justSayNo', version: 0})}
                invert={false}
                skin={this.props.skin}
                className={styles.cardPreview}
              />
              <CardImage
                model={new PassGoCard({id: 'go', type: 'passGo', version: 0})}
                invert={false}
                skin={this.props.skin}
                className={styles.cardPreview}
              />
              <CardImage
                model={
                  new DualPropertyCard({
                    id: 'utilBlack',
                    type: 'wildUtilityAndRailroad',
                    version: 0,
                  })
                }
                invert={true}
                skin={this.props.skin}
                className={styles.cardPreview}
              />
              <CardImage
                model={
                  new StandardPropertyCard({id: 'red', type: 'red', version: 0})
                }
                invert={false}
                skin={this.props.skin}
                className={styles.cardPreview}
              />
              <CardImage
                model={new BirthdayCard({id: 'bd', type: 'birthday', version: 0})}
                invert={false}
                skin={this.props.skin}
                className={styles.cardPreview}
              />

              <DSText tag="h2" size="section-title">
                Notifications
              </DSText>

              <table className={styles.notificationsTable}>
                <thead>
                  <tr>
                    <th />
                    {M.mapEntries(
                      (subsettingHuman, key) => (
                        <DSText
                          tag="th"
                          size="section-title"
                          color="grey-dark"
                          key={key + 'header'}
                        >
                          {subsettingHuman}
                        </DSText>
                      ),
                      M.fromRecord(humanPerSettingOptions)
                    )}
                  </tr>
                </thead>
                <tbody>
                  {M.mapEntries((settingHuman, settingKey) => {
                    return (
                      <tr key={settingKey}>
                        <DSText
                          tag="td"
                          size="body-15"
                          className={styles.notifTableLabel}
                        >
                          {settingHuman}
                        </DSText>
                        <FormToggleGroup<keyof TUserNotificationPerSettingOptions>
                          allowDeselection
                          blurOnChange
                          selectionLimit={undefined}
                          formFieldProps={generateFormFieldProps(settingKey, [], {
                            initialValue: FormToggleGroup.setToFormValue(
                              truthyKeys(this.props.notifications[settingKey])
                            ),
                          })}
                        >
                          {(
                            groupProps: IToggleGroupRenderProps<
                              keyof TUserNotificationPerSettingOptions
                            >
                          ) =>
                            M.mapEntries(
                              (subsettingHuman, subsettingKey) => (
                                <td
                                  key={subsettingKey}
                                  className={styles.notifTableToggle}
                                >
                                  <ToggleForGroup<
                                    keyof TUserNotificationPerSettingOptions
                                  >
                                    toggleValue={subsettingKey}
                                    groupProps={groupProps}
                                    toggleStyle="checkbox"
                                    wrapperClassName={styles.subsettingWrapper}
                                  />
                                </td>
                              ),
                              M.fromRecord(humanPerSettingOptions)
                            )
                          }
                        </FormToggleGroup>
                      </tr>
                    )
                  }, M.fromRecord(humanSettingDescriptions))}
                </tbody>
              </table>

              <DSText tag="h2" size="section-title">
                Beta Features (YRWYS)
              </DSText>

              <FormToggle<TTrueOrFalse, typeof TrueOrFalse>
                formFieldProps={generateFormFieldProps('gameLog', [], {
                  initialValue: TrueOrFalse.toString(
                    this.props.betaFeatures.gameLog
                  ),
                })}
                booleanString={TrueOrFalse}
                toggleStyle="checkbox"
                blurOnChange
              >
                {input => (
                  <span className={styles.betaFeatureInputAndLabel}>
                    {input}
                    <DSText size="body-15">
                      Game Log (mostly works but occasionally causes crashes)
                    </DSText>
                  </span>
                )}
              </FormToggle>

              <DSText tag="h2" size="section-title">
                Dark Mode
              </DSText>

              <FormToggle<TTrueOrFalse, typeof TrueOrFalse>
                formFieldProps={generateFormFieldProps('darkMode', [], {
                  initialValue: isDarkMode.toString(),
                })}
                booleanString={TrueOrFalse}
                toggleStyle="checkbox"
                blurOnChange
              >
                {input => (
                  <span className={styles.betaFeatureInputAndLabel}>
                    {input}
                    <DSText size="body-15">Dark Mode</DSText>
                  </span>
                )}
              </FormToggle>
            </div>
          )
        }}
      />
    )
  }
}

const truthyKeys = <T extends string>(record: Record<T, boolean>): Set<T> => {
  return M.keySet(M.filter(v => !!v, M.fromRecord(record)))
}
