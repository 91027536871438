import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import * as React from 'react'
import {Link as RRDLink, LinkProps} from 'react-router-dom'
import {OmitStrict} from 'type-zoo'
import {ColorName, colorNameToColorClass} from '~/design-system/Colors'
import DSText, {
  DSTextDiv,
  IDSTextDivProps,
  IDSTextProps,
} from '~/design-system/DSText'
import styles from './styles.module.css'

// `true` and `'always'` are functionally the same, as are `false` and `'never'`
type TLinkUnderline = 'never' | 'always' | 'onHover' | boolean

interface IDSLinkBaseProps {
  color?: ColorName
  newTab?: boolean
  underline: TLinkUnderline
  chevron?: 'left' | 'right' | boolean // true === 'right' since most links will use that
  wrapperClassName?: string
  div?: boolean // use a div instead of a span as the wrapper
}

export interface IDSLinkProps
  extends IDSLinkBaseProps,
    OmitStrict<LinkProps, 'color'> {}

/** Wraps `Link` from react-router-dom so we can style it
 * For now assumes that this Link is in DSText already and does not apply any style/size
 * only color.
 *
 * NB: wrapped in a `<span>` (or a `<div>`) so that the `color: inherit` trick (see styles.css) works properly
 */
const DSLink = ({
  color,
  newTab,
  className,
  wrapperClassName,
  target,
  chevron,
  underline,
  div,
  ...linkProps
}: IDSLinkProps) => {
  return React.createElement(div ? 'div' : 'span', {
    className: classnames(color && colorNameToColorClass(color), wrapperClassName),
    children: (
      <RRDLink
        {...linkProps}
        target={newTab ? '_blank' : target}
        className={classnames([
          className,
          'hoverable',
          styles['link'],
          styles['no-visited-coloring'],
          styles[`underline-${underline}`],
        ])}
      >
        {chevron !== 'left' && linkProps.children}
        {chevron && (
          <FontAwesomeIcon
            style={{
              marginLeft: chevron === 'left' ? undefined : '8px',
              marginRight: chevron === 'left' ? '8px' : undefined,
              // marginBottom: '0.15em',
              height: '0.7em',
              transform: chevron === 'left' ? 'rotate(180deg)' : undefined,
            }}
            icon={faChevronRight}
          />
        )}
        {chevron === 'left' && linkProps.children}
      </RRDLink>
    ),
  })
}
export default DSLink

export interface IExternalLinkProps
  extends IDSLinkBaseProps,
    OmitStrict<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'color' | 'onClick'> {}

/** Wraps `<a>` so we can style it
 * For now assumes that this `<a>` is in DSText already and does not apply any style/size
 * only color.
 *
 * NB: wrapped in a `<span>` (or a `<div>`) so that the `color: inherit` trick (see styles.css) works properly
 */
export const DSExternalLink = ({
  color,
  newTab,
  className,
  wrapperClassName,
  underline,
  target,
  chevron,
  div,
  ...anchorProps
}: IExternalLinkProps) =>
  React.createElement(div ? 'div' : 'span', {
    className: classnames(color && colorNameToColorClass(color), wrapperClassName),
    children: (
      <a
        {...anchorProps}
        target={newTab ? '_blank' : target}
        className={classnames([
          className,
          styles['link'],
          styles['no-visited-coloring'],
          styles[`underline-${underline}`],
        ])}
      >
        {chevron !== 'left' && anchorProps.children}
        {chevron && (
          <FontAwesomeIcon
            style={{
              marginLeft: chevron === 'left' ? undefined : '8px',
              marginRight: chevron === 'left' ? '8px' : undefined,
              // marginBottom: '0.15em',
              height: '0.7em',
              transform: chevron === 'left' ? 'rotate(180deg)' : undefined,
            }}
            icon={faChevronRight}
          />
        )}
        {chevron === 'left' && anchorProps.children}
      </a>
    ),
  })

interface ILinkTextDivProps
  extends OmitStrict<IDSLinkBaseProps, 'newTab' | 'color'>,
    OmitStrict<IDSTextDivProps, 'underline' | 'onClick'>,
    Required<Pick<IDSTextDivProps, 'onClick'>> {}

/** Wraps `<DSTextDiv>` so we can style it like a link */
export const DSLinkTextDiv = ({
  className,
  underline,
  chevron,
  ...dsTextProps
}: ILinkTextDivProps) => (
  <DSTextDiv
    {...dsTextProps}
    className={classnames([
      className,
      styles['link'],
      styles[`underline-${underline}`],
    ])}
  >
    {chevron !== 'left' && dsTextProps.children}
    {chevron && (
      <FontAwesomeIcon
        style={{
          marginLeft: chevron === 'left' ? undefined : '8px',
          marginRight: chevron === 'left' ? '8px' : undefined,
          // marginBottom: '0.15em',
          height: '0.7em',
          transform: chevron === 'left' ? 'rotate(180deg)' : undefined,
        }}
        icon={faChevronRight}
      />
    )}
    {chevron === 'left' && dsTextProps.children}
  </DSTextDiv>
)

interface ILinkTextProps
  extends OmitStrict<IDSLinkBaseProps, 'newTab' | 'color'>,
    OmitStrict<IDSTextProps, 'underline' | 'onClick'>,
    Required<Pick<IDSTextProps, 'onClick'>> {}

/** Wraps `<DSText>` so we can style it like a link */
export const DSLinkText = ({
  className,
  underline,
  chevron,
  ...dsTextProps
}: ILinkTextProps) => (
  <DSText
    {...dsTextProps}
    className={classnames([
      className,
      styles['link'],
      styles[`underline-${underline}`],
    ])}
  >
    {chevron !== 'left' && dsTextProps.children}
    {chevron && (
      <FontAwesomeIcon
        style={{
          marginLeft: chevron === 'left' ? undefined : '8px',
          marginRight: chevron === 'left' ? '8px' : undefined,
          // marginBottom: '0.15em',
          height: '0.7em',
          transform: chevron === 'left' ? 'rotate(180deg)' : undefined,
        }}
        icon={faChevronRight}
      />
    )}
    {chevron === 'left' && dsTextProps.children}
  </DSText>
)
