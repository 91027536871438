import AdminApproveSignUp from '~/api/endpoints/admin/AdminApproveSignUp'
import AdminSignUps from '~/api/endpoints/admin/AdminSignUps'
import SignIn from '~/api/endpoints/auth/SignIn'
import SignOut from '~/api/endpoints/auth/SignOut'
import SignUp from '~/api/endpoints/auth/SignUp'
import MarkGameAsStale from '~/api/endpoints/dashboard/MarkGameAsStale'
import ApproveSpectatorRequest from '~/api/endpoints/game/InGame/ApproveSpectatorRequest'
import RequestSpectatorPermission from '~/api/endpoints/game/InGame/RequestSpectatorPermission'
import RevokeSpectatorPermission from '~/api/endpoints/game/InGame/RevokeSpectatorPermission'
import SubmitMajorAction from '~/api/endpoints/game/InGame/SubmitMajorAction'
import SubmitMinorAction from '~/api/endpoints/game/InGame/SubmitMinorAction'
import CreateGame from '~/api/endpoints/game/PreGame/CreateGame'
import GetGameData from '~/api/endpoints/game/GetGameData'
import GetWaitingRoomData from '~/api/endpoints/game/PreGame/GetWaitingRoomData'
import JoinGame from '~/api/endpoints/game/PreGame/JoinGame'
import KickPlayerFromWaitingRoom from '~/api/endpoints/game/PreGame/KickPlayerFromWaitingRoom'
import StartGame from '~/api/endpoints/game/PreGame/StartGame'
import GetInitialData from '~/api/endpoints/GetInitialData'
import GetDashboardData from '~/api/endpoints/dashboard/GetDashboardData'

const api = {
  admin: {
    signUps: new AdminSignUps(),
    approveSignUp: new AdminApproveSignUp(),
  },
  auth: {
    signIn: new SignIn(),
    signOut: new SignOut(),
    signUp: new SignUp(),
  },
  globalData: new GetInitialData(),
  dashboard: {
    getData: new GetDashboardData(),
    markGameAsStale: new MarkGameAsStale(),
  },
  preGame: {
    getWaitingRoomData: new GetWaitingRoomData(),
    getGameData: new GetGameData(),
    createGame: new CreateGame(),
    joinGame: new JoinGame(),
    startGame: new StartGame(),
    kickPlayer: new KickPlayerFromWaitingRoom(),
  },
  inGame: {
    submitMajorAction: new SubmitMajorAction(),
    submitMinorAction: new SubmitMinorAction(),
    requestSpectatorPermission: new RequestSpectatorPermission(),
    approveSpectatorRequest: new ApproveSpectatorRequest(),
    revokeSpectatorPermission: new RevokeSpectatorPermission(),
  },
}

export default api
