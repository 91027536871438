import classnames from 'classnames'
import React from 'react'
import {OmitStrict} from 'type-zoo'
import {IFormFieldProps, IFormPropsBase} from '~/components/ProvideForm/types'
import {IFieldValues, IGenerateFormFieldProps} from '~/components/ProvideForm/base'
import {TFormConstraint} from '~/components/ProvideForm/utils'
import {ToggleForGroup, TToggleStyle} from '~/components/Toggle'
import ToggleGroup, {
  FormToggleGroup,
  IFormToggleGroupProps,
  IToggleGroupProps,
  IToggleGroupRenderProps,
  mustMakeASelectionConstraint,
} from '~/components/ToggleGroup'
import HoverTrackingDiv from '~/components/UtilityDivs/HoverTrackingDiv'
import {ColorName} from '~/design-system/Colors'
import {DSTextDiv, TextSize} from '~/design-system/DSText'
import * as S from '~/utils/Set'

// the props the `RadioButtonGroup`s send to a RadioButton
interface IRadioButtonRenderPropsFromRadioGroup<EnumType extends string> {
  groupProps: IToggleGroupRenderProps<EnumType>
  choice: EnumType
  humanChoices: Record<EnumType, string>
}

interface IRadioButtonProps<EnumType extends string>
  extends IRadioButtonRenderPropsFromRadioGroup<EnumType>,
    OmitStrict<
      React.HTMLAttributes<HTMLDivElement>,
      'className' | 'onClick' | 'children'
    > {
  classNames?: {
    choice?: string
    selectedChoice?: string
    fadedChoice?: string

    toggle?: string
    // used when another toggle is selected
    fadedToggle?: string
    choiceLabel?: string

    toggleWrapper?: string
    toggleLabel?: string
  }

  toggleStyle: TToggleStyle

  label: {
    focusedColor?: ColorName
    fadedColor?: ColorName
    size: TextSize
  }
}

/**
 * a good default radio button element you can style yourself and use in a
 * FormRadioButtonGroup's `radioRenderButton` method
 */
export class RadioButton<EnumType extends string> extends React.Component<
  IRadioButtonProps<EnumType>
> {
  render() {
    const {
      groupProps,
      choice,
      humanChoices,
      classNames,
      toggleStyle,
      label,
      ...divProps
    } = this.props

    const isSelected = groupProps.isSelected(choice)
    const anySelected = groupProps.selectedItems.size > 0
    const isOtherSelected = !isSelected && anySelected

    const focusedLabelColor = label.focusedColor
    const fadedLabelColor = label.fadedColor
    const labelColor: ColorName | undefined = isOtherSelected
      ? fadedLabelColor
      : focusedLabelColor

    return (
      <HoverTrackingDiv
        {...divProps}
        className={classnames(
          classNames?.choice,
          isSelected && classNames?.selectedChoice,
          isOtherSelected && classNames?.fadedChoice
        )}
        key={choice}
        onClick={() => {
          groupProps.makeSelection(choice)
        }}
      >
        {hovered => (
          <ToggleForGroup<EnumType>
            stopPropagationFromLabelClicks
            toggleStyle={toggleStyle}
            toggleValue={choice}
            groupProps={groupProps}
            hovered={hovered}
            className={classnames(
              classNames?.toggle,
              isOtherSelected && classNames?.fadedToggle
            )}
            wrapperClassName={classNames?.toggleWrapper}
          >
            {input => (
              <>
                {input}
                <DSTextDiv
                  size={label.size}
                  color={hovered ? focusedLabelColor : labelColor}
                  className={classNames?.choiceLabel}
                >
                  {humanChoices[choice]}
                </DSTextDiv>
              </>
            )}
          </ToggleForGroup>
        )}
      </HoverTrackingDiv>
    )
  }
}

// shared between the form and non-form RadioButtonGroup components
interface IAllRadioButtonGroupProps<EnumType extends string>
  extends Pick<IRadioButtonRenderPropsFromRadioGroup<EnumType>, 'humanChoices'> {
  initialChoice: EnumType | undefined
  allChoices: Set<EnumType>

  renderRadioButton: (
    radioButtonProps: IRadioButtonRenderPropsFromRadioGroup<EnumType>
  ) => React.ReactElement

  onSelectionChanged?: (selected: EnumType | undefined) => void
}

interface IFormRadioButtonGroupProps<
  EnumType extends string,
  FormFieldsType extends IFieldValues
> extends IAllRadioButtonGroupProps<EnumType>,
    Pick<IFormToggleGroupProps<EnumType>, 'allowDeselection' | 'blurOnChange'> {
  generateFormFieldProps: IGenerateFormFieldProps<FormFieldsType>
  formProps: IFormPropsBase<FormFieldsType>
  fieldName: Extract<keyof FormFieldsType, string>

  // defaults to `[mustMakeASelectionConstraint]`. Note that the constraint is passed the JSON'd Set
  constraints?: TFormConstraint[]

  // use this to render e.g. an error state around the toggle group. you bear the responsibility to render the group.
  // (there is no generic design for what radio button groups or their errors should look like)
  // if you don't define this prop, the toggle group will be rendered as the sole child of this component.
  children?: (
    toggleGroup: React.ReactElement,
    formFieldProps: IFormFieldProps<FormFieldsType>
  ) => React.ReactElement
}

/**
 * A wrapper around `FormToggleGroup` that sets defaults for a radio button group, with nicer types for that case.
 * e.g. Exactly one selection is required & allowed, and `onSelectionChanged` receives one selection (not a Set).
 */
export class FormRadioButtonGroup<
  EnumType extends string,
  FormFieldsType extends IFieldValues
> extends React.Component<IFormRadioButtonGroupProps<EnumType, FormFieldsType>> {
  render() {
    const formFieldProps = this.props.generateFormFieldProps(
      this.props.fieldName,
      this.props.constraints ?? [mustMakeASelectionConstraint],
      {
        initialValue: FormToggleGroup.valueToFormValue(this.props.initialChoice),
      }
    )

    const toggleGroup = (
      <FormToggleGroup
        formFieldProps={formFieldProps}
        selectionLimit={1}
        allowDeselection={this.props.allowDeselection}
        blurOnChange={this.props.blurOnChange}
        deselectOldestIfLimitReached
        onSelectionChange={selected => {
          this.props.formProps.runAllConstraints()
          this.props.onSelectionChanged?.(S.first(selected))
        }}
      >
        {(groupProps: IToggleGroupRenderProps<EnumType>) => {
          return S.map(
            choice =>
              this.props.renderRadioButton({
                groupProps,
                humanChoices: this.props.humanChoices,
                choice,
              }),
            this.props.allChoices
          )
        }}
      </FormToggleGroup>
    )

    return this.props.children?.(toggleGroup, formFieldProps) ?? toggleGroup
  }
}

interface IRadioButtonGroupProps<EnumType extends string>
  extends IAllRadioButtonGroupProps<EnumType>,
    Pick<IToggleGroupProps<EnumType>, 'allowDeselection'> {}

interface IRadioButtonGroupState<EnumType extends string> {
  selected: EnumType | undefined
}

/**
 * A wrapper around `ToggleGroup` that sets defaults for a radio button group, with nicer types for that case.
 * e.g. Exactly one selection is required & allowed, and `onSelectionChanged` receives one selection (not a Set).
 */
export class RadioButtonGroup<EnumType extends string> extends React.Component<
  IRadioButtonGroupProps<EnumType>,
  IRadioButtonGroupState<EnumType>
> {
  state = {
    selected: this.props.initialChoice,
  }

  render() {
    return (
      <ToggleGroup
        selectionLimit={1}
        allowDeselection={this.props.allowDeselection}
        deselectOldestIfLimitReached
        selected={
          this.state.selected === undefined
            ? new Set<EnumType>()
            : new Set<EnumType>([this.state.selected])
        }
        makeSelection={selectedSet => {
          this.setState({selected: S.first(selectedSet)}, () => {
            this.props.onSelectionChanged?.(S.first(selectedSet))
          })
        }}
      >
        {(groupProps: IToggleGroupRenderProps<EnumType>) => {
          return S.map(
            choice =>
              this.props.renderRadioButton({
                groupProps,
                humanChoices: this.props.humanChoices,
                choice,
              }),
            this.props.allChoices
          )
        }}
      </ToggleGroup>
    )
  }
}
