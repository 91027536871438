import Axios from 'axios'
import * as React from 'react'
import {IApiPathPieces, PostApi} from '~/api/client'
import APILoggedOutError from '~/api/client/APIError/APILoggedOutError'
import APIError from '~/api/client/APIError/base'
import {IJSONObject, IJSONObjectConvertible} from '~/api/client/types'
import PromiseManager from '~/components/PromiseManager'
import {navigateTo} from '~/routing/navigate'
import {staticURLs} from '~/routing/sitemap'

interface IProps<
  PostRequestParamsType extends IJSONObjectConvertible,
  ResponseType extends IJSONObject,
  PathPiecesType extends IApiPathPieces,
  ResultType = ResponseType,
  UnprocessedRequestParamsType = PostRequestParamsType
> {
  params: UnprocessedRequestParamsType
  pathPieces: PathPiecesType
  /**
   * Sometimes, you may want to get a new promise result without changing the params/pathPieces.
   * By setting/changing this prop, you can force the manager to re-request the API.
   */
  refreshTrigger?: any

  /**
   * Provider function called with the result of the promise.  Return value will be rendered.
   * If you don't provide this function and/or `catch`, no loading UI will be rendered in the 'loading' state.
   */
  then?: (result: ResultType) => JSX.Element | JSX.Element[]

  /**
   * Provider function called with the result of a failed promise. Return value will be rendered.
   * If you don't provide this function and/or `then`, no loading UI will be rendered in the 'loading' state.
   */
  catch?: (error: any) => JSX.Element | JSX.Element[]

  /** Function called with the result of the promise as it changes, useful to set state outside of render() */
  onSuccess?: (result: ResultType) => void
  onLoading?: () => void
  onFailed?: (error: APIError) => void
}

/**
 * This class builder shouldn't be used directly.
 * Instead you can use the version of this on any
 * PostApi by using the `PromiseManager` member variable
 * The generic types are just passed through to the PostApi
 */
export const buildPostApiPromiseManager = <
  PostRequestParamsType extends IJSONObjectConvertible,
  ResponseType extends IJSONObject,
  PathPiecesType extends IApiPathPieces,
  ResultType = ResponseType,
  UnprocessedRequestParamsType = PostRequestParamsType
>(
  api: PostApi<
    PostRequestParamsType,
    ResponseType,
    PathPiecesType,
    ResultType,
    UnprocessedRequestParamsType
  >
) => {
  return class PostApiPromiseManager extends React.Component<
    IProps<
      PostRequestParamsType,
      ResponseType,
      PathPiecesType,
      ResultType,
      UnprocessedRequestParamsType
    >
  > {
    cancelSource = Axios.CancelToken.source()

    componentDidMount() {
      this.cancelSource = Axios.CancelToken.source()
    }

    componentWillUnmount() {
      /* cancel this promise on unmount */
      this.cancelSource.cancel()
    }

    render() {
      return (
        <PromiseManager<
          ResultType,
          {
            params: UnprocessedRequestParamsType
            pathPieces: PathPiecesType
            refreshTrigger?: any
          }
        >
          promiseProps={{
            params: this.props.params,
            pathPieces: this.props.pathPieces,
            refreshTrigger: this.props.refreshTrigger,
          }}
          generatePromise={promiseProps => {
            const apiCall = api.post(
              promiseProps.params,
              promiseProps.pathPieces,
              this.cancelSource.token
            )

            apiCall.catch((e: APIError) => {
              if (e instanceof APILoggedOutError) {
                // in the case of a logged-out error we should force logout the user
                // otherwise they are going to get weird errors
                console.warn('Session timed out. You must log in again to continue.')
                navigateTo(staticURLs.auth.signout)
              }
            })

            return apiCall
          }}
          then={this.props.then}
          catch={this.props.catch}
          onSuccess={this.props.onSuccess}
          onFailed={this.props.onFailed}
          onLoading={this.props.onLoading}
        />
      )
    }
  }
}
