// stops redux from complaining about our use of Map in our stores
// https://github.com/reduxjs/redux-toolkit/issues/466
// https://stackoverflow.com/a/67220847/2544629
import {enableMapSet} from 'immer'
enableMapSet()

import {History} from 'history'
import * as Redux from 'redux'
import {connectRouter, RouterState} from 'connected-react-router'
import pastGamePositionsReducer, {
  PastGamePositionsState,
} from '~/redux/reducers/pastGamePositions'
import store from '~/redux/store'
import authReducer, {AuthState} from '~/redux/reducers/auth'
import userReducer, {UserState} from './user'
import uiReducer, {UIState} from './ui'
import settingsReducer, {SettingsState} from './settings'
import spectatorReducer, {SpectatorState} from './spectator'

export type ReduxState = {
  router: RouterState // this has to have the key `router` for `connected-react-router` to work, iirc.

  authState: AuthState
  settingsState: SettingsState
  spectatorState: SpectatorState
  pastGamePositionsState: PastGamePositionsState
  uiState: UIState
  userState: UserState
}

const createReducers = (
  history: History
): Record<keyof ReduxState, Redux.Reducer<any, any>> => ({
  router: connectRouter(history),

  authState: authReducer,
  settingsState: settingsReducer,
  spectatorState: spectatorReducer,
  pastGamePositionsState: pastGamePositionsReducer,
  uiState: uiReducer,
  userState: userReducer,
})

const createAppReducer = (history: History) =>
  Redux.combineReducers(createReducers(history))

export const ROOT_LOGOUT_ACTION_KEY = '_ROOT_LOGOUT'

export const createRootReducer = (history: History) => (
  state: ReduxState | undefined,
  action: any // Redux.AnyAction
) => {
  if (action.type === ROOT_LOGOUT_ACTION_KEY) {
    // in the case of logout we want to wipe out all the state, except the routing state.
    // This is achieved here by passing in no state to the combineReducers above
    // idea from https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
    return createAppReducer(history)({router: state?.router} as any, action)
  }

  return createAppReducer(history)(state, action)
}

export const logoutRedux = () => {
  store.dispatch({type: ROOT_LOGOUT_ACTION_KEY})
}
