import {OmitStrict} from 'type-zoo'
import {TMajorActionDebtCollectorData} from '~/api/generated/types/common'
import {debtCollectorCost} from '~/game/types'
import {DebtCollectorCard} from '~/models/game/Cards/ActionCard'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MajorAction, {
  TMajorActionUserFacingDescriptionData,
} from '~/models/game/MajorAction'
import {compact} from '~/utils'
import {sentenceCase, show$, xVerbY} from '~/utils/language'

type PublicMembersFromApi = OmitStrict<
  TMajorActionDebtCollectorData,
  'card' | 'type'
>

export default class MajorActionDebtCollector
  extends MajorAction
  implements PublicMembersFromApi {
  card: DebtCollectorCard
  minorActorPlayerId: string

  constructor(data: TMajorActionDebtCollectorData) {
    super(data)
    this.card = new DebtCollectorCard(data.card)
    this.minorActorPlayerId = data.minorActorPlayerId
  }

  public userFacingDescription({
    tense,
    skin,
    gamePosition,
  }: TMajorActionUserFacingDescriptionData): string {
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined
    const majorActor = gamePosition.majorActorPlayer
    const minorActor = gamePosition.players.find(
      o => o.id === this.minorActorPlayerId
    )
    if (!minorActor) {
      throw new Error(
        `Minor actor not found in MajorActionDebtCollector description`
      )
    }

    const dropFirstNoun = tense === 'imperativePrompt'
    const {conjugatedVerb, xNoun, yNoun, possessivePronoun} = xVerbY(
      tense,
      majorActor,
      dropFirstNoun ? 'Charge' : 'charge',
      minorActor,
      currentPlayer?.id
    )

    return compact([
      dropFirstNoun
        ? undefined
        : xNoun === majorActor.username
        ? xNoun
        : sentenceCase(xNoun),
      conjugatedVerb,
      yNoun,
      show$(debtCollectorCost),
      'via',
      possessivePronoun,
      this.card.displayName(skin),
    ]).join(' ')
  }
}
