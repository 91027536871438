// this file configures postcss-preset-env with some custom CSS variables
// that we can also access from TS if we need to.
// https://github.com/csstools/postcss-preset-env#importfrom
// I couldn't get this to work :(
module.exports = {
  customMedia: {},
  customSelectors: {},
  environmentVariables: {},
  customProperties: {
    'ds-blue': '#4165ff',
    'ds-blue-lighter': '#607AFF',
    'ds-blue-light': '#5466F9',
    'ds-blue-light-hover': '#4B5FEA',
    'ds-blue-dark': '#3645bb',
    'ds-blue-dark-hover': '#3041a8',
    'ds-white': '#ffffff',
    'ds-off-white': '#f9f9fb',
    'ds-off-white-dark': '#F2F2F7',
    'ds-pearl-grey': '#efeffd',
    'ds-pearl-grey-medium': '#e4e5fc',
    'ds-pearl-grey-dark': '#d8d8e9',
    'ds-pearl-grey-darker': '#cacadd',
    'ds-pearl-grey-darkest': '#9d9db0',
    'ds-grey': '#71718E',
    'ds-grey-medium': '#565674',
    'ds-grey-dark': '#41415A',
    'ds-black': '#1F1F30',
    'ds-pearl-grey-hover': '#e4e5fc',
    'ds-off-white-hover': '#efeffd',
    'ds-green-money-in': '#008375',
    'ds-mauve': '#d9beff',
    'ds-highlight': '#eaf3fe',
    'ds-highlight-dark': '#cce3fc',
    'ds-highlight-darker': '#aac7f8',
    'ds-green-light': '#c0ffee',
    'ds-green': '#0ad19f',
    'ds-green-dark': '#004f49',
    'ds-red-light': '#FBE5F0',
    'ds-red-dark': '#C5046C',
    'ds-red': '#D9177E',
    'ds-poker-green': '#35654d',
    'ds-poker-green-darker': '#1b3428',
    'ds-poker-green-lighter': '#1e4532',
  },
}
