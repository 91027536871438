import {TMinorActionDataAcceptDealBreaker} from '~/api/generated/types/common'
import Card from '~/models/game/Card'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MinorAction, {
  TMinorActionUserFacingDescriptionData,
} from '~/models/game/MinorAction'
import {compact} from '~/utils'
import {sentenceCase, xVerbY} from '~/utils/language'

export default class MinorActionAcceptDealBreaker extends MinorAction<
  TMinorActionDataAcceptDealBreaker
> {
  portfolioNeighborhoodId: string // the dealbroken portfolio

  constructor(data: TMinorActionDataAcceptDealBreaker) {
    super(data)
    this.portfolioNeighborhoodId = data.portfolioNeighborhoodId
  }

  userFacingDescription(data: TMinorActionUserFacingDescriptionData): string {
    const {tense, skin, gamePosition, minorActor, majorActor} = data
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined

    const {conjugatedVerb, xNoun, yNounPossessive} = xVerbY(
      tense,
      minorActor,
      tense === 'imperativePrompt' ? 'Accept' : 'accept',
      majorActor,
      currentPlayer?.id
    )

    if (tense === 'imperativePrompt') {
      // when presenting a minor action in the UI as a prompt/choice,
      // avoid saying 'You accept Deal Breaker from Foo', instead 'Accept Deal Breaker'
      return compact([
        conjugatedVerb,
        Card.displayNameForCard('dealBreaker', skin),
        // TODO say "(forfeit your Dark Blue set") here?
      ]).join(' ')
    }

    return compact([
      xNoun === minorActor.username ? xNoun : sentenceCase(xNoun),
      conjugatedVerb,
      yNounPossessive,
      Card.displayNameForCard('dealBreaker', skin),
    ]).join(' ')
  }
}
