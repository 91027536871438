import * as React from 'react'
import {OmitStrict} from 'type-zoo'
import {TSubmitMajorActionResponse} from '~/api/generated/types/SubmitMajorAction'
import {TSubmitMinorActionResponse} from '~/api/generated/types/SubmitMinorAction'
import {TRuleset, TValidNumberOfPlayers} from '~/game/ruleset'
import {TSkin} from '~/game/skins'
import Card from '~/models/game/Card'
import GameData from '~/models/game/GameData'
import MajorAction from '~/models/game/MajorAction'
import MinorAction from '~/models/game/MinorAction'
import Player from '~/models/game/Player'
import {TGameLobbySize} from '~/pages/GameLobby/types'
import GameBoardCenter from '~/pages/GameLobbyV2/GameBoard/GameBoardCenter/stateless'
import PlayerPortfolioV2UI, {
  IPlayerPortfolioUIV2Props,
} from '~/pages/GameLobbyV2/GameBoard/PlayerPortfolio/stateless'
import {TClickableCardStateV2} from '~/pages/GameLobbyV2/stateless'
import {safely, safelyOr, unreachableCase} from '~/utils'
import styles from './styles.module.css'

interface IProps {
  gameData: GameData
  chooseMajorAction: (
    majorAction: MajorAction
  ) => Promise<TSubmitMajorActionResponse>
  chooseMajorActionSubmitSuccess: (response: TSubmitMajorActionResponse) => void
  chooseMinorAction: (
    minorAction: MinorAction<any>
  ) => Promise<TSubmitMinorActionResponse>
  chooseMinorActionSubmitSuccess: (response: TSubmitMinorActionResponse) => void

  gameLobbySize: TGameLobbySize
  skin: TSkin
  rules: TRuleset

  clickableCardState: TClickableCardStateV2
  setClickableCardState: (
    setFunc: (prevState: TClickableCardStateV2) => TClickableCardStateV2
  ) => void

  onCardClicked: (card: Card<any>, event: React.MouseEvent<any>) => void
  onPlayerClicked: (player: Player, event: React.MouseEvent<any>) => void
  onNeighborhoodClicked: (neighborhoodId: string) => void
}

export default class GameBoardV2UI extends React.Component<IProps> {
  render() {
    const {
      skin,
      gameData,
      onCardClicked,
      onPlayerClicked,
      onNeighborhoodClicked,
      clickableCardState,
    } = this.props
    const {gameId, currentGamePosition} = gameData

    // current user is rotatedPlayerList[0] if they are playing
    // TODO could rotate the current player into the bottom position for spectators?
    const rotatedPlayerList = safelyOr(
      gameData.playerGamePosition,
      pgp => pgp.playerListRotatedForCurrentPlayer(),
      currentGamePosition.players
    )
    const numberOfPlayers = rotatedPlayerList.length as TValidNumberOfPlayers

    // left is ordered bottom-up, right is ordered top-down (think clockwise)
    const {leftPlayers, topPlayer, rightPlayers} = ((): {
      leftPlayers?: Player[]
      topPlayer?: Player
      rightPlayers?: Player[]
    } => {
      switch (numberOfPlayers) {
        case 2:
          return {topPlayer: rotatedPlayerList[1]}
        case 3:
          return {
            leftPlayers: [rotatedPlayerList[1]],
            rightPlayers: [rotatedPlayerList[2]],
          }
        case 4:
          return {
            leftPlayers: [rotatedPlayerList[1]],
            topPlayer: rotatedPlayerList[2],
            rightPlayers: [rotatedPlayerList[3]],
          }
        case 5:
          return {
            leftPlayers: [rotatedPlayerList[1], rotatedPlayerList[2]],
            rightPlayers: [rotatedPlayerList[3], rotatedPlayerList[4]],
          }
        case 6:
          return {
            leftPlayers: [rotatedPlayerList[1], rotatedPlayerList[2]],
            topPlayer: rotatedPlayerList[3],
            rightPlayers: [rotatedPlayerList[4], rotatedPlayerList[5]],
          }
        case 7:
          return {
            leftPlayers: [
              rotatedPlayerList[1],
              rotatedPlayerList[2],
              rotatedPlayerList[3],
            ],
            rightPlayers: [
              rotatedPlayerList[4],
              rotatedPlayerList[5],
              rotatedPlayerList[6],
            ],
          }
        case 8:
          return {
            leftPlayers: [
              rotatedPlayerList[1],
              rotatedPlayerList[2],
              rotatedPlayerList[3],
            ],
            topPlayer: rotatedPlayerList[4],
            rightPlayers: [
              rotatedPlayerList[5],
              rotatedPlayerList[6],
              rotatedPlayerList[7],
            ],
          }
        default:
          return unreachableCase(numberOfPlayers)
      }
    })()

    const commonPlayerPortfolioProps: OmitStrict<
      IPlayerPortfolioUIV2Props,
      'orientation' | 'player'
    > = {
      gameId,
      currentGamePosition,
      currentUserId: gameData.currentUserId,
      skin,
      cardWidthPx: 56,
      clickableCardState,
      onCardClicked,
      onPlayerClicked,
      onNeighborhoodClicked,
    }

    return (
      <div className={styles.gameBoardWrapper}>
        <div className={styles.gameBoardLeft}>
          {leftPlayers?.reverse().map(p => (
            <PlayerPortfolioV2UI
              key={p.id}
              {...commonPlayerPortfolioProps}
              orientation="stackedStageLeft"
              player={p}
            />
          ))}
        </div>
        <div className={styles.gameBoardTop}>
          {safely(topPlayer, p => (
            <PlayerPortfolioV2UI
              key={p.id}
              {...commonPlayerPortfolioProps}
              orientation="singleFileTop"
              player={p}
            />
          ))}
        </div>
        <div className={styles.gameBoardRight}>
          {rightPlayers?.map(p => (
            <PlayerPortfolioV2UI
              key={p.id}
              {...commonPlayerPortfolioProps}
              orientation="stackedStageRight"
              player={p}
            />
          ))}
        </div>

        <div className={styles.gameBoardCenter}>
          <GameBoardCenter {...this.props} />
        </div>

        <div className={styles.gameBoardBottom}>
          <PlayerPortfolioV2UI
            {...commonPlayerPortfolioProps}
            className={styles.player0}
            orientation="singleFileBottom"
            player={rotatedPlayerList[0]}
          />
        </div>
      </div>
    )
  }
}
