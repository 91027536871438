import classnames from 'classnames'
import {css} from 'emotion'
import React from 'react'
import {TSubmitMajorActionResponse} from '~/api/generated/types/SubmitMajorAction'
import {TSubmitMinorActionResponse} from '~/api/generated/types/SubmitMinorAction'
import {TRuleset} from '~/game/ruleset'
import {TSkin} from '~/game/skins'
import Card from '~/models/game/Card'
import GameData from '~/models/game/GameData'
import ExposedHand from '~/models/game/Hands/ExposedHand'
import MajorAction from '~/models/game/MajorAction'
import MinorAction from '~/models/game/MinorAction'
import {
  clickableCardIdsFor,
  fadeUnclickableCardsFor,
  highlightedCardIdsFor,
  selectedCardIdsFor,
  TClickableCardStateV2,
} from '~/pages/GameLobbyV2/stateless'
import {V2LobbyCardImage} from '~/pages/GameLobbyV2/utils'
import {isInstance} from '~/utils/types'
import styles from './styles.module.css'

interface IProps {
  gameData: GameData
  chooseMajorAction: (majorAction: MajorAction) => Promise<any>
  chooseMajorActionSubmitSuccess: (response: TSubmitMajorActionResponse) => void
  chooseMinorAction: (
    minorAction: MinorAction<any>
  ) => Promise<TSubmitMinorActionResponse>
  chooseMinorActionSubmitSuccess: (response: TSubmitMinorActionResponse) => void

  skin: TSkin
  rules: TRuleset

  className?: string

  clickableCardState: TClickableCardStateV2
  onCardClicked: (card: Card<any>, event: React.MouseEvent<any>) => void
}

interface IState {}

export default class HandDrawerUI extends React.Component<IProps, IState> {
  state: IState = {}

  render() {
    const {skin, gameData, clickableCardState, onCardClicked} = this.props
    const {currentGamePosition, playerGamePosition} = gameData

    const currentPlayerHand = playerGamePosition?.currentPlayer.hand
    // TODO Could rotate this for spectators. Also this isn't tied to whoever's turn it is, or who's in seat 0, so...
    const exposedHand: ExposedHand | undefined =
      currentPlayerHand ??
      currentGamePosition.players.map(p => p.hand).find(isInstance(ExposedHand))

    if (!exposedHand) {
      // TODO some UI for spectators to ask to spectate?
      return null
    }

    const highlightedCardIds = highlightedCardIdsFor(
      clickableCardState,
      currentGamePosition
    )

    return (
      <div className={classnames(styles.handDrawerWrapper, this.props.className)}>
        <div className={styles.cards}>
          {exposedHand?.cards.map(c => (
            <V2LobbyCardImage
              key={c.id}
              className={classnames(
                styles.card,
                clickableCardIdsFor(clickableCardState).has(c.id) &&
                  styles.clickable,
                selectedCardIdsFor(clickableCardState).has(c.id) && styles.selected,
                fadeUnclickableCardsFor(clickableCardState) &&
                  styles.fadeUnclickable,
                highlightedCardIds.size
                  ? highlightedCardIds.has(c.id)
                    ? styles.highlighted
                    : styles.faded
                  : undefined,
                css`
                  width: ${64}px;
                `
              )}
              model={c}
              invert={false}
              skin={skin}
              roundedCorners
              onClick={e => {
                clickableCardIdsFor(clickableCardState).has(c.id) &&
                  onCardClicked(c, e)
              }}
            />
          ))}
        </div>
      </div>
    )
  }
}
