import classnames from 'classnames'
import * as React from 'react'
import {Redirect as RRRedirect, RedirectProps} from 'react-router-dom'
import {Link as RRDLink, LinkProps} from 'react-router-dom'
import styles from './styles.module.css'

/** Wraps `Link` from react-router-dom so we can style it without a global `<a>` tag style.
 * This component removes the browser's <a> default styles for text
 */
export const NoStyleLink = (props: LinkProps) => (
  <RRDLink
    {...props}
    className={classnames([props.className, styles['noStyleLink']])}
  />
)

export const Redirect = (props: RedirectProps) => {
  console.log('Redirecting to:', props.to, 'with props: ', props)
  return <RRRedirect {...props} />
}
