import {TMajorActionBirthdayData} from '~/api/generated/types/common'
import {BirthdayCard} from '~/models/game/Cards/ActionCard'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MajorAction, {
  TMajorActionUserFacingDescriptionData,
} from '~/models/game/MajorAction'
import {compact} from '~/utils'
import {inTense} from '~/utils/language'

export default class MajorActionBirthday extends MajorAction {
  card: BirthdayCard

  constructor(data: TMajorActionBirthdayData) {
    super(data)
    this.card = new BirthdayCard(data.card)
  }

  public userFacingDescription({
    tense,
    skin,
    gamePosition,
  }: TMajorActionUserFacingDescriptionData): string {
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined
    const majorActor = gamePosition.majorActorPlayer

    return compact([
      tense === 'past'
        ? majorActor.id === currentPlayer?.id
          ? 'You'
          : majorActor.username
        : undefined,
      inTense(tense)(tense === 'past' ? 'play' : 'Play'),
      this.card.displayName(skin),
    ]).join(' ')
  }
}
