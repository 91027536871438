import {ExtractStrict} from 'type-zoo'
import {
  TDualPropertyCardData,
  TPropertyWildCardData,
  TStandardPropertyType,
} from '~/api/generated/types/common'
import {BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID} from '~/api/types'
import {TAvailableMovesData} from '~/models/game/Card'
import {TPropertyType} from '~/game/types'
import PropertyCard from '~/models/game/Cards/PropertyCard'
import MajorActionChangeNeighborhood from '~/models/game/MajorActions/MajorActionChangeNeighborhood'
import Portfolio from '~/models/game/Portfolio'
import * as S from '~/utils/Set'

export default abstract class SpecialPropertyCard<
  CardDataType extends TDualPropertyCardData | TPropertyWildCardData
> extends PropertyCard<CardDataType> {
  public propertyType: ExtractStrict<TPropertyType, 'wild'> = 'wild'

  // "Which colors does this special property card represent?"
  abstract standardPropertyTypes(): Set<TStandardPropertyType>

  public fileName(): string {
    return `prop_${this.type}`
  }

  public cardTypes(): Set<TStandardPropertyType> {
    return this.standardPropertyTypes()
  }

  public possibleDestinationNeighborhoods(
    portfolio: Portfolio
  ): MajorActionChangeNeighborhood[] {
    const cardTypes = this.cardTypes()

    const currentNeighborhood = portfolio.neighborhoods.find(pn =>
      pn.properties.find(p => p.id === this.id)
    )
    if (!currentNeighborhood) {
      throw new Error(
        `SpecialPropertyCard asked about availableMovesFromTable but it isn't on the table.`
      )
    }

    // the backend now correctly handles this & moves the residence for you afterwards
    // if (currentNeighborhood.residences.length) {
    //   return [] // can't move properties that are "under" a residence until you remove the residence
    // }

    const applicableNeighborhoods = portfolio.neighborhoods.filter(
      pn =>
        pn.id !== currentNeighborhood.id &&
        (pn.neighborhood === 'alone' || cardTypes.has(pn.neighborhood)) &&
        !pn.isCompleteSet()
    )

    const actions = applicableNeighborhoods.map(
      pn =>
        new MajorActionChangeNeighborhood({
          type: 'changeNeighborhood',
          card: this.datasource,
          portfolioNeighborhoodId: pn.id,
        })
    )

    if (this.datasource.type === 'propertyWildCard') {
      return actions
    }

    // dual property cards can go to their own neighborhood, but only if a neighborhood of that color with space
    // doesn't exist already (and it's not already on that color)
    //
    // NB: This means if you have (2 yellows + red/yellow) and a third yellow, you must rotate the dual to red,
    // then move the standard yellow to the set, and then re-rotate the dual to yellow, if your goal is to move the
    // dual card off of the set. the only issue there besides the verbosity of the move is that the intermediate
    // state is actually illegal (2 partial yellow sets co-existing).
    // TODO the API might need a new major action like `swapNeighborhoods` for this scenario? Or will the server
    //  coalesce the standard yellows if we rotate the dual red/yellow to red automatically? That would work, but the
    //  inverse scenario (replace a standard yellow of a full set w/ a dual red/yellow) wouldn't be possible still w/o
    //  further work.
    const extraNeighborhoods = S.map(
      n =>
        new MajorActionChangeNeighborhood({
          // TODO would be nice to tell the API which color we're trying to send this card to
          type: 'changeNeighborhood',
          card: this.datasource,
          portfolioNeighborhoodId: BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID,
        }),
      S.filter(
        n =>
          currentNeighborhood.neighborhood !== n &&
          applicableNeighborhoods.find(an => an.neighborhood === n) === undefined,
        cardTypes
      )
    )

    return [...actions, ...extraNeighborhoods]
  }

  public availableMovesFromTable({
    portfolio,
  }: TAvailableMovesData): MajorActionChangeNeighborhood[] {
    return this.possibleDestinationNeighborhoods(portfolio)
  }
}
