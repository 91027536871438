import {IGameLobbyPagePathPieces} from '~/pages/GameLobby'

export const dynamicURLs = {
  game: {
    waitingRoom: (pp: IGameLobbyPagePathPieces) => `/game/${pp.gameId}/waiting-room`,
    lobby: (pp: IGameLobbyPagePathPieces) => `/game/${pp.gameId}/lobby`,
    lobbyV2: (pp: IGameLobbyPagePathPieces) => `/game/${pp.gameId}/lobbyV2`,
  },
}

export const staticURLs = {
  home: '/',

  admin: {
    index: '/admin',
    signups: '/admin/sign-ups',
  },

  auth: {
    signin: '/sign-in',
    signout: '/sign-out',
    signup: '/sign-up',
  },

  dashboard: '/dashboard',
  settings: {
    index: '/settings',
  },
}
