import {TMajorActionPassGoData} from '~/api/generated/types/common'
import {PassGoCard} from '~/models/game/Cards/ActionCard'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MajorAction, {
  TMajorActionUserFacingDescriptionData,
} from '~/models/game/MajorAction'
import {compact, mutateIf} from '~/utils'
import {inTense, sentenceCase} from '~/utils/language'

export default class MajorActionPassGo extends MajorAction {
  card: PassGoCard

  constructor(data: TMajorActionPassGoData) {
    super(data)
    this.card = new PassGoCard(data.card)
  }

  public userFacingDescription({
    tense,
    skin,
    gamePosition,
  }: TMajorActionUserFacingDescriptionData): string {
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined
    const majorActor = gamePosition.majorActorPlayer
    const dropFirstNoun = tense === 'imperativePrompt'

    return compact([
      dropFirstNoun
        ? undefined
        : currentPlayer?.id === majorActor.id
        ? 'You'
        : majorActor.username,
      mutateIf(inTense(tense)('play'), dropFirstNoun, sentenceCase),
      this.card.displayName(skin),
    ]).join(' ')
  }
}
