import {PostApi} from '~/api/client'
import GetWaitingRoomData from '~/api/endpoints/game/PreGame/GetWaitingRoomData'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TJSONError} from '~/api/types'

export type TStartGameResponse = {}
export type TStartGameParams = {}
export type TStartGamePathPieces = {
  gameId: string
}

/**
 * Start a game lobby from the waiting room, once all players have joined.
 */
export default class StartGame extends PostApi<
  TStartGameParams,
  TStartGameResponse,
  TStartGamePathPieces
> {
  protected mockRequestFailure(
    params: TStartGameParams,
    pathPieces: TStartGamePathPieces
  ): TJSONError | null {
    if (pathPieces.gameId === 'fail') {
      return {errors: {gameId: [`That game doesn't exist.`]}}
    }

    return super.mockRequestFailure(params, pathPieces)
  }

  protected getMockResponse(params: TStartGameParams): TStartGameResponse {
    GetWaitingRoomData.resetMockPlayerCount()
    return {}
  }

  protected getPath(pathPieces: TStartGamePathPieces) {
    return serverEndpoints.startGame(pathPieces)
  }

  protected transformResponse(r: TStartGameResponse) {
    return r
  }
}
