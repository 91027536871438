import React from 'react'
import api from '~/api/endpoints'
import {TAdminSignUpsResult} from '~/api/endpoints/admin/AdminSignUps'
import AdminLayout from '~/layouts/AdminLayout'
import AdminSignUpsUI from '~/pages/Admin/SignUps/stateless'
import {
  IAdminRouteComponentProps,
  isolateAdminRouteComponentProps,
} from '~/routing/Route/Admin'

interface IProps extends IAdminRouteComponentProps<any> {}

interface IState {
  result: TAdminSignUpsResult | undefined
}

export default class AdminSignupsPage extends React.Component<IProps, IState> {
  state: IState = {
    result: undefined,
  }

  render() {
    return (
      <AdminLayout {...isolateAdminRouteComponentProps(this.props)}>
        <api.admin.signUps.PromiseManager
          params={{}}
          pathPieces={{}}
          onSuccess={result => this.setState({result})}
          then={() => (
            <AdminSignUpsUI
              signups={this.state.result!.signupRequests}
              approveSignUp={signupRequestId => form =>
                api.admin.approveSignUp.post(form, {signupRequestId})}
              approveSignUpSubmitSuccess={result => this.setState({result})}
            />
          )}
        />
      </AdminLayout>
    )
  }
}
