import * as R from 'ramda'
import {
  TCardData,
  TPastGamePosition,
  TPlayerData,
} from '~/api/generated/types/common'
import {BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID} from '~/api/types'
import {MAX_CARDS_IN_HAND} from '~/game/ruleset'
import {DataBackedModel} from '~/models/base'
import {majorActionFactory, minorActionFactory} from '~/models/game/factories'
import GamePosition from '~/models/game/GamePosition'
import MajorAction from '~/models/game/MajorAction'
import MinorAction from '~/models/game/MinorAction'
import {noop, safely} from '~/utils'

export default class PastGamePosition extends DataBackedModel<TPastGamePosition> {
  gamePosition: GamePosition

  // TODO the types allow both to be missing, not sure if that would ever make sense though.
  majorActionTaken?: MajorAction
  minorActionTaken?: MinorAction<any>
  date: Date

  constructor(
    data: TPastGamePosition,
    players: TPlayerData[],
    currentUserId: string
  ) {
    super(data)
    this.date = new Date(data.timestamp)
    this.gamePosition = GamePosition.build(data.gamePosition, players, currentUserId)

    const majorActionData = data.majorActionTaken
    switch (majorActionData?.type) {
      case 'changeNeighborhood':
      case 'playToNeighborhood':
        // `unambiguousNeighborhoodDescription` can't handle the fact that the GamePosition we have
        // (which is from before this action took place) doesn't know about the neighborhood
        // that a card was moved to, for cards that moved to a new neighborhood.
        // To work around this, we can just remove the PNId, which that code handles already.
        if (
          !this.gamePosition.portfolios.find(p =>
            p.neighborhoods.find(
              pn => pn.id === majorActionData.portfolioNeighborhoodId
            )
          )
        ) {
          console.log(
            'Removing a reference to a PNId so the game log doesnt crash: ',
            majorActionData.portfolioNeighborhoodId,
            majorActionData,
            this.gamePosition
          )
          majorActionData.portfolioNeighborhoodId = BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID
        }
        break
      case 'discard':
        // the backend sends back an empty array intentionally, but we can work out the number of cards
        // that were discarded here. nothing reads the card data here so faking it seems alright for now
        const excessCardCount =
          this.gamePosition.majorActorPlayer.hand.cardCount() - MAX_CARDS_IN_HAND
        majorActionData.cards = R.times<TCardData>(
          i => ({
            id: '' + i,
            type: 'passGo',
            version: i,
          }),
          excessCardCount
        )
        break
      default:
        noop()
    }

    this.majorActionTaken = safely(majorActionData, m =>
      majorActionFactory(m, this.gamePosition.majorActorPlayer.portfolio, {
        lastRentThisVisit: this.gamePosition.lastRentThisVisit,
      })
    )
    this.minorActionTaken = safely(data.minorActionTaken, mat => {
      if (mat.type === 'acquiesce') {
        // if the minor action performed just now was an Acquiesce, then a JSN was the
        // gamePosition.inProgressMajorAction.previousMinorActions[0]
        const mostRecentMinorAction = this.gamePosition.inProgressMajorAction
          ?.previousMinorActions[0]
        if (!mostRecentMinorAction) {
          throw new Error(
            `Can't have an Acquiesce PastGamePosition.minorActionTaken if there was no JSN just before it.`
          )
        }
        return minorActionFactory({
          ...mat,
          prevailingPlayerId: mostRecentMinorAction.minorActorPlayerId,
        })
      } else {
        return minorActionFactory(mat)
      }
    })
  }
}
