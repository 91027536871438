// this is what `jsonError`s from the backend look like
// (the keys are formField names / keys used in the request body,
// the array of strings are error messages for that key)
export type TJSONError = {
  errors: {
    [k: string]: string[]
  }
}

// TODO maybe resolve all the places that need this w/ new API data types for choosing orientation / designating
//  new neighborhoods as the destination
/**
 *  Certain actions need to communicate to the backend about neighborhoods that don't exist yet. E.g.:
 *  - play a property card to the table when there are no other cards for it to join in a neighborhood yet
 *  - move a DualPropertyCard to it's own neighborhood from it's old neighborhood
 */
export const BY_ITSELF_PORTFOLIO_NEIGHBORHOOD_ID =
  '00000000-0000-0000-0000-000000000000'
