import {OmitStrict} from 'type-zoo'
import {TMinorActionDataPaySomething} from '~/game/types'
import {groupedCardDisplayNames} from '~/game/utils'
import {cardFactory} from '~/models/game/factories'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MinorAction, {
  TMinorActionUserFacingDescriptionData,
} from '~/models/game/MinorAction'
import {AnyLiquidatedCard, AnyPropertyCard} from '~/models/game/types'
import {valueOfCards} from '~/models/game/utils'
import {compact} from '~/utils'
import {sentenceCase, show$, xVerbY} from '~/utils/language'

type PublicMembersFromApi = OmitStrict<
  TMinorActionDataPaySomething,
  'type' | 'paidWith'
>

export default abstract class MinorActionPaySomething<
    PaySomethingType extends TMinorActionDataPaySomething
  >
  extends MinorAction<PaySomethingType>
  implements PublicMembersFromApi {
  millionsOwed: number
  private _paidWithCards: (AnyLiquidatedCard | AnyPropertyCard)[]

  get paidWithCards(): (AnyLiquidatedCard | AnyPropertyCard)[] {
    return this._paidWithCards
  }

  // TODO probably kill the setter and just make a copy of the model to modify it a la MW
  //  to maintain immutability.
  set paidWithCards(paidWith: (AnyLiquidatedCard | AnyPropertyCard)[]) {
    // NB: since we create a MinorActionPaySomething with an empty paidWith[] when generating
    // a list of validResponses to a MinorActionPrompt, we want to be able to set
    // this property later. The API call will read `.datasource` though so we need to update both.
    this._paidWithCards = paidWith
    this.datasource.paidWith = paidWith.map(pw => pw.datasource)
  }

  constructor(data: PaySomethingType) {
    super(data)
    this.millionsOwed = data.millionsOwed
    this._paidWithCards = data.paidWith.map(lc => cardFactory(lc))
  }

  userFacingDescription(data: TMinorActionUserFacingDescriptionData): string {
    const {tense, skin, gamePosition, minorActor, majorActor} = data
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined

    const {conjugatedVerb, xNoun, yNoun} = xVerbY(
      tense,
      minorActor,
      tense === 'imperativePrompt' ? 'Pay' : 'pay',
      majorActor,
      currentPlayer?.id
    )

    // converts ['Pass Go', 'Pass Go', '$5M'] to ['2x Pass Go', '$5M']
    const cardList = groupedCardDisplayNames(this.paidWithCards, skin)

    // when presenting a minor action in the UI as a prompt, paidWithCards
    // isn't set yet (the user has to choose still)
    if (tense === 'imperativePrompt') {
      // avoid saying 'You pay Foo $0M', instead say 'Pay Foo {expectedPayment}'
      return compact([
        conjugatedVerb,
        yNoun,
        show$(Math.min(minorActor.portfolio.totalValue(), this.millionsOwed)),
      ]).join(' ')
    }

    return compact([
      xNoun === minorActor.username ? xNoun : sentenceCase(xNoun),
      conjugatedVerb,
      yNoun,
      show$(valueOfCards(this.paidWithCards)),
      `(${cardList.join(', ')})`,
    ]).join(' ')
  }
}
