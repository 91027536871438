import * as React from 'react'
import api from '~/api/endpoints'
import {TDashboardDataResponse} from '~/api/generated/types/GetDashboardData'
import DashboardLayout from '~/layouts/DashboardLayout'
import DashboardUI from '~/pages/Dashboard/stateless'
import {navigateTo} from '~/routing/navigate'
import {ISignedInRouteComponentProps} from '~/routing/Route/SignedIn'
import {dynamicURLs} from '~/routing/sitemap'

interface IProps extends ISignedInRouteComponentProps<any> {}

interface IState {
  dashboardData: TDashboardDataResponse
}
export default class DashboardPage extends React.Component<IProps, IState> {
  joinGame = (params: {gameId: string}) =>
    navigateTo(dynamicURLs.game.waitingRoom(params))

  receiveDashboardData = (dashboardData: TDashboardDataResponse) => {
    this.setState({dashboardData})
  }

  render() {
    return (
      <DashboardLayout {...this.props}>
        <api.dashboard.getData.PromiseManager
          requestParams={{}}
          pathPieces={{}}
          onSuccess={this.receiveDashboardData}
          then={() => (
            <DashboardUI
              user={this.props.user}
              dashboardData={this.state.dashboardData}
              joinGame={form => api.preGame.joinGame.post({}, form)}
              joinGameSuccess={this.joinGame}
              createGame={() => api.preGame.createGame.post({}, {})}
              createGameSuccess={this.joinGame}
              markGameAsStale={gameId => {
                api.dashboard.markGameAsStale
                  .post({}, {gameId})
                  .then(this.receiveDashboardData)
                  .catch(e => console.error(`Couldn't mark game as stale:`, e))
              }}
            />
          )}
        />
      </DashboardLayout>
    )
  }
}
