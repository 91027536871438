import {
  TPropertyWildCardData,
  TStandardPropertyType,
} from '~/api/generated/types/common'
import SpecialPropertyCard from '~/models/game/Cards/SpecialPropertyCard'
import {allStandardPropertyTypes} from '~/game/types'

export default class PropertyWildCard extends SpecialPropertyCard<
  TPropertyWildCardData
> {
  public value: undefined = undefined

  standardPropertyTypes(): Set<TStandardPropertyType> {
    return allStandardPropertyTypes
  }
}
