import {FontAwesomeIcon, FontAwesomeIconProps} from '@fortawesome/react-fontawesome'
import React from 'react'
import {OmitStrict} from 'type-zoo'
import HoverTrackingDiv, {
  IHoverTrackingDivProps,
} from '~/components/UtilityDivs/HoverTrackingDiv'
import {colorNameToHex} from '~/design-system/Colors'
import {safely} from '~/utils'
import {IFontAwesomeIconProps} from '~/utils/FontAwesome/types'

export interface IHoverableFontAwesomeIconDivProps
  extends OmitStrict<IHoverTrackingDivProps, 'children'> {
  icon: IFontAwesomeIconProps
  /** When the div is hovered, these optional icon props will be shallow merged with the original props, if given.
   * Icon props are reused if nothing is specified.
   */
  hoverIcon?: Partial<IFontAwesomeIconProps>
  /** Perform a deep merge of the styles prop above, rather than a shallow replacement. */
  mergeStylesWhenHovered?: boolean
  children: (icon: React.ReactNode, hovered: boolean) => React.ReactNode
}

/**
 * Many of our designs call for an icon to change colors or styles when the div it's inside of is hovered.
 * This div wraps the options for changing an icon given the div's hovered status and returns the correct icon
 * to the children function for placement.
 */
export class HoverableFontAwesomeIconDiv extends React.Component<
  IHoverableFontAwesomeIconDivProps
> {
  render() {
    const {
      icon,
      hoverIcon,
      mergeStylesWhenHovered,
      children,
      ...divProps
    } = this.props

    return (
      <HoverTrackingDiv {...divProps}>
        {hovered =>
          children(
            <AlternateStateFontAwesomeIcon
              alternateState={hovered}
              icon={icon}
              alternateIcon={hoverIcon}
              mergeStylesWhenAlternate={mergeStylesWhenHovered}
            />,
            hovered
          )
        }
      </HoverTrackingDiv>
    )
  }
}

interface IAlternateStateFontAwesomeIconProps
  extends OmitStrict<
    FontAwesomeIconProps,
    'icon' | 'style' | 'className' | 'color'
  > {
  icon: IFontAwesomeIconProps
  /** When `hovered === true`,
   * these optional icon props will be shallow merged with the original props, if given.
   * Icon props are reused if nothing is specified.
   */
  alternateIcon?: Partial<IFontAwesomeIconProps>
  alternateState: boolean
  /** Perform a deep merge of the styles prop above, rather than a shallow replacement. */
  mergeStylesWhenAlternate?: boolean
}

/**
 * Many of our designs call for an icon to change colors or styles due to some user interaction.
 * This class wraps the options for changing an icon given a boolean and returns the correct icon.
 * This is the controlled version, whereas HoverableFontAwesomeIconDiv does hover detection for you.
 *
 * (It's exported to be useful in scenarios where deciding whether or not to show the alternate state of the icon
 * is not trivially decided by a parent div being hovered or not. Hovering is just the most common use case we have.)
 */
export class AlternateStateFontAwesomeIcon extends React.Component<
  IAlternateStateFontAwesomeIconProps
> {
  render() {
    const {
      alternateState,
      icon,
      alternateIcon,
      mergeStylesWhenAlternate,
      ...iconProps
    } = this.props

    const altStyleRaw: React.CSSProperties | undefined = alternateIcon?.style
    const altStyleToUse: React.CSSProperties | undefined = mergeStylesWhenAlternate
      ? {...icon.style, ...altStyleRaw}
      : altStyleRaw

    const style = (alternateState && altStyleToUse) || icon.style
    const color = safely(
      (alternateState && alternateIcon?.color) || icon.color,
      colorNameToHex
    )
    const faIcon = (alternateState && alternateIcon?.icon) || icon.icon
    const className = (alternateState && alternateIcon?.className) || icon.className

    return (
      <FontAwesomeIcon {...iconProps} {...{style, color, icon: faIcon, className}} />
    )
  }
}
