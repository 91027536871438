import classnames from 'classnames'
import {css, keyframes} from 'emotion'
import * as React from 'react'
import {ColorName, colorNameToHex} from '~/design-system/Colors'
import styles from './styles.module.css'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  sizePx: number // length & width of square
  clipDistancePct: number // what percent of sizePx should the animation extend past the perimeter of the square
  pathWidthPx: number
  color: ColorName
  hideAnimation?: boolean
  speedSec: number
}

export default class SquareBorderAnimation extends React.Component<IProps> {
  // CSS from https://codepen.io/Rplus/pen/lEDBj?editors=0100
  // via https://freefrontend.com/css-border-animations/
  render() {
    const {
      sizePx,
      clipDistancePct,
      pathWidthPx,
      color: colorName,
      hideAnimation,
      speedSec: animationSec,
      className,
      ...divProps
    } = this.props
    const clipSize = sizePx * (1 + clipDistancePct * 2)
    const colorHex = colorNameToHex(colorName)

    const animation = keyframes`
      0%, 100% {clip: rect(0px, ${clipSize}px, ${pathWidthPx}px, 0px); }
      25% {clip: rect(0px, ${pathWidthPx}px, ${clipSize}px, 0px); }
      50% {clip: rect(calc(${clipSize}px - ${pathWidthPx}px), ${clipSize}px, ${clipSize}px, 0px); }
      75% {clip: rect(0px, ${clipSize}px, ${clipSize}px, calc(${clipSize}px - ${pathWidthPx}px)); }
    `
    return (
      <div
        {...divProps}
        className={classnames(
          className,
          styles.bb,
          css`
            width: ${sizePx}px;
            height: ${sizePx}px;
            color: ${colorHex};

            &::before,
            &::after {
              margin: calc(-1 * ${clipDistancePct} * 100%);
              box-shadow: inset 0 0 0 ${hideAnimation ? 0 : pathWidthPx}px;
              animation: ${animation} ${animationSec}s linear infinite;
            }

            &::before {
              animation-delay: calc(${animationSec}s * -0.5);
            }
          `
        )}
      />
    )
  }
}
