import * as R from 'ramda'
import {
  TAlone,
  TPortfolioNeighborhoodData,
  TPropertyCardData,
  TStandardPropertyType,
} from '~/api/generated/types/common'
import {skins, TSkin} from '~/game/skins'
import Model from '~/models/base'
import ResidenceCard from '~/models/game/Cards/ResidenceCard'
import {cardFactory, propertyCardFactory} from '~/models/game/factories'
import PropertyWildCard from '~/models/game/Cards/PropertyWildCard'
import SpecialPropertyCard from '~/models/game/Cards/SpecialPropertyCard'
import StandardPortfolioNeighborhood from '~/models/game/PortfolioNeighborhoods/StandardPortfolioNeighborhood'
import WildCardPortfolioNeighborhood from '~/models/game/PortfolioNeighborhoods/WildCardPortfolioNeighborhood'
import {AnyPropertyCard} from '~/models/game/types'

// Represents one grouping of properties on the table in front of each player
// abstract because `WildCardPortfolioNeighborhood`s don't have residences
export default abstract class PortfolioNeighborhood extends Model {
  // protected raw: TPortfolioNeighborhoodData
  public id: string
  public neighborhood: TStandardPropertyType | TAlone
  public properties: AnyPropertyCard[]
  public residences: ResidenceCard[]

  public abstract isCompleteSet(): boolean

  constructor(data: TPortfolioNeighborhoodData) {
    super()
    // this.raw = data
    this.id = data.id
    this.neighborhood = data.neighborhood

    const rawProperties: TPropertyCardData[] = data.properties
    this.properties = rawProperties.map(propertyCardFactory)

    const residences = 'residences' in data ? data.residences : []
    this.residences = residences.map<ResidenceCard>(cardFactory)
  }

  public abstract isStandardPortfolioNeighborhood(): this is StandardPortfolioNeighborhood
  public isWildCardPortfolioNeighborhood(): this is WildCardPortfolioNeighborhood {
    return !this.isStandardPortfolioNeighborhood()
  }

  // `undefined` means you can't charge rent on this PN ('alone' only)
  public abstract rent(): number | undefined

  sortedProperties = (): AnyPropertyCard[] => {
    return R.sortWith(
      [
        R.ascend(p => p instanceof PropertyWildCard),
        R.ascend(p => p instanceof SpecialPropertyCard),
        R.ascend(p => p.version),
      ],
      this.properties
    )
  }

  propertiesAndResidences = (): (AnyPropertyCard | ResidenceCard)[] => [
    ...this.properties,
    ...this.residences,
  ]

  displayColor = (skin: TSkin): string => {
    return this.neighborhood === 'alone'
      ? 'Alone'
      : skins[skin].colorNames[this.neighborhood]
  }
}
