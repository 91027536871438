import {TResidenceCardData} from '~/api/generated/types/common'
import Card, {TAvailableMovesData} from '~/models/game/Card'
import {cardValue, residenceRentValue} from '~/game/types'
import MajorActionPlayAsCash from '~/models/game/MajorActions/MajorActionPlayAsCash'
import MajorActionPlayToNeighborhood from '~/models/game/MajorActions/MajorActionPlayToNeighborhood'
import MajorActionResidenceToCash from '~/models/game/MajorActions/MajorActionResidenceToCash'
import StandardPortfolioNeighborhood from '~/models/game/PortfolioNeighborhoods/StandardPortfolioNeighborhood'
import {AnyMajorAction} from '~/models/game/types'
import {unreachableCase} from '~/utils'

export default class ResidenceCard extends Card<TResidenceCardData> {
  public value: number

  constructor(data: TResidenceCardData) {
    super(data)
    this.value = cardValue[data.type]
  }

  public fileName(): string {
    return `residence_${this.type}`
  }

  public availableMovesFromHand({
    portfolio,
    rules,
  }: TAvailableMovesData): AnyMajorAction[] {
    const applicableNeighborhoods = portfolio.neighborhoods.filter(pn => {
      if (!(pn instanceof StandardPortfolioNeighborhood) || !pn.isCompleteSet()) {
        return false
      }

      if (
        !rules.residencesOnUtilitiesAndRailroads &&
        (pn.neighborhood === 'utility' || pn.neighborhood === 'railroad')
      ) {
        return false
      }

      return ((): boolean => {
        switch (rules.residenceStacking) {
          case 'oneEachUnordered':
            return pn.residences.find(r => r.type === this.type) === undefined
          case 'oneEachOrdered':
            return this.type === 'house'
              ? pn.residences.length === 0
              : pn.residences.length === 1 && pn.residences[0].type === 'house'
          case 'oneTotal':
            return pn.residences.length === 0
          case 'unrestricted':
            return true
          default:
            return unreachableCase(rules.residenceStacking)
        }
      })()
    })

    return [
      ...applicableNeighborhoods.map(
        an =>
          new MajorActionPlayToNeighborhood({
            type: 'playToNeighborhood',
            card: this.datasource,
            portfolioNeighborhoodId: an.id,
          })
      ),
      new MajorActionPlayAsCash({
        type: 'playAsCash',
        card: this.datasource,
      }),
    ]
  }

  public availableMovesFromTable(
    data: TAvailableMovesData
  ): AnyMajorAction | AnyMajorAction[] {
    if (
      data.portfolio.neighborhoods.find(pn =>
        pn.residences.find(r => r.id === this.id)
      )
    ) {
      return new MajorActionResidenceToCash({
        type: 'residenceToCash',
        card: this.datasource,
      })
    }
    return []
  }

  public additionalRentValue(): number {
    return residenceRentValue(this.type)
  }
}
