import {TRuleset} from '~/game/ruleset'
import {TSkin} from '~/game/skins'
import {TMinorActionPromptDataBase, TMinorActionPromptType} from '~/game/types'
import {DataBackedModel} from '~/models/base'
import {JustSayNoCard} from '~/models/game/Cards/ActionCard'
import GamePosition from '~/models/game/GamePosition'
import ExposedHand from '~/models/game/Hands/ExposedHand'
import InProgressMajorAction from '~/models/game/InProgressMajorAction'
import MinorAction from '~/models/game/MinorAction'
import MinorActionJustSayNo from '~/models/game/MinorActions/MinorActionJustSayNo'
import Player from '~/models/game/Player'
import Portfolio from '~/models/game/Portfolio'
import {Tense} from '~/utils/language'
import * as L from '~/utils/List'
import {create} from '~/utils/List'

export type TMinorActionPromptValidResponseData = {
  gamePosition: GamePosition
  // the portfolio of the player making the decision. I avoided using `GamePosition.currentPlayer.portfolio`
  // here instead because spectators might be shown something about available moves later? not sure.
  portfolio: Portfolio
  inProgressMajorAction: InProgressMajorAction
  hand: ExposedHand
  rules: TRuleset
}

export type TMinorActionPromptUserFacingDescriptionData = {
  gamePosition: GamePosition

  // In order for there to be a MinorActionPrompt, there must also be these things in GamePosition, but there's no type
  // safety there, so we pass them separately
  // TODO could potentially make a GamePosition subclass that guarantees this and things like minorActor being present
  inProgressMajorAction: InProgressMajorAction

  tense: Tense
  skin: TSkin
}

/** This describes what happened to cause a minor action to be requested from a player.
 *  The backend sends this to the frontend.
 */
export default abstract class MinorActionPrompt<
    DataType extends TMinorActionPromptDataBase
  >
  extends DataBackedModel<DataType>
  implements TMinorActionPromptDataBase {
  type: TMinorActionPromptType
  minorActorPlayerId: string
  minorActor: Player // who is being prompted to act (often, the 'defender')
  majorActor: Player // who started the InProgressMajorAction (often, the 'attacker')

  constructor(data: DataType, majorActor: Player, minorActor: Player) {
    super(data)

    // workaround here necessary maybe until `declare` works
    // right now the properties above w/o initializers cause the `DataBackedModel` constructor's
    // dynamically assigned values to be overwritten by `undefined`
    this.type = data.type
    this.minorActorPlayerId = data.minorActorPlayerId
    this.majorActor = majorActor
    this.minorActor = minorActor
  }

  public validResponses(
    data: TMinorActionPromptValidResponseData
  ): MinorAction<any>[] {
    return L.create(this._validResponses(data))
  }

  protected abstract _validResponses(
    data: TMinorActionPromptValidResponseData
  ): L.ListCreationParameter<MinorAction<any>>

  // utility to add a JSN to a list of minor actions, iff applicable, since all
  // minor action prompts' validResponses will want that added on
  protected withJustSayNo(
    data: TMinorActionPromptValidResponseData,
    responses: L.ListCreationParameter<MinorAction<any>>
  ): MinorAction<any>[] {
    const respArr = create(responses)
    if (data.hand.cards.find(c => c instanceof JustSayNoCard)) {
      respArr.push(
        new MinorActionJustSayNo({
          minorActorPlayerId: this.minorActorPlayerId,
          type: 'justSayNo',
        })
      )
    }
    return respArr
  }

  public abstract userFacingDescription(
    data: TMinorActionPromptUserFacingDescriptionData
  ): string
}
