import {OmitStrict} from 'type-zoo'
import {TMinorActionPromptDataPayRent} from '~/api/generated/types/common'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MajorActionRentBase from '~/models/game/MajorActions/MajorActionRentBase'
import MinorAction from '~/models/game/MinorAction'
import {
  TMinorActionPromptUserFacingDescriptionData,
  TMinorActionPromptValidResponseData,
} from '~/models/game/MinorActionPrompt'
import MinorActionPromptPaySomething from '~/models/game/MinorActionPrompts/MinorActionPromptPaySomething'
import MinorActionPayRent from '~/models/game/MinorActions/MinorActionPayRent'
import Player from '~/models/game/Player'
import {sentenceCase, show$, xVerbY} from '~/utils/language'

type PublicMembersFromApi = OmitStrict<TMinorActionPromptDataPayRent, 'type'>

export default class MinorActionPromptPayRent
  extends MinorActionPromptPaySomething<TMinorActionPromptDataPayRent>
  implements PublicMembersFromApi {
  portfolioNeighborhoodId: string // what neighborhood the rent we're being prompted to pay was charged for
  majorAction: MajorActionRentBase

  constructor(
    data: TMinorActionPromptDataPayRent,
    majorAction: MajorActionRentBase,
    majorActor: Player,
    minorActor: Player
  ) {
    super(data, majorActor, minorActor)
    this.portfolioNeighborhoodId = data.portfolioNeighborhoodId
    this.majorAction = majorAction
  }

  protected _validResponses(
    data: TMinorActionPromptValidResponseData
  ): MinorAction<any> | MinorAction<any>[] {
    return this.withJustSayNo(
      data,
      new MinorActionPayRent({
        ...this.datasource,
        paidWith: [], // this will be filled in later once the user chooses how to pay
      })
    )
  }

  public userFacingDescription(
    data: TMinorActionPromptUserFacingDescriptionData
  ): string {
    const {gamePosition, tense} = data
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined
    const {majorActor, minorActor} = this

    const {conjugatedVerb, xNoun, yNoun} = xVerbY(
      tense,
      minorActor,
      'pay',
      majorActor,
      currentPlayer?.id
    )

    return [
      xNoun === minorActor.username ? xNoun : sentenceCase(xNoun),
      conjugatedVerb,
      yNoun,
      show$(Math.min(minorActor.portfolio.totalValue(), this.millionsOwed)),
    ].join(' ')
  }
}
