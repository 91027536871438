import * as React from 'react'
import {connect} from 'react-redux'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import Spacer from '~/components/Spacer'
import {DSTextDiv} from '~/design-system/DSText'
import DSLink from '~/design-system/Link'
import {LoginAgnosticDashboardLayout} from '~/layouts/DashboardLayout'
import {TNotSignedInYetReferrerPayload} from '~/pages/Auth/SignIn'
import {ReduxState} from '~/redux/reducers/root'
import {isolateRouteComponentProps} from '~/routing/Route/SignedIn'
import {staticURLs} from '~/routing/sitemap'
import {isPresent} from '~/utils'

interface IReduxProps extends Pick<ReduxState, 'userState'> {}

interface INotFoundProps extends RouteComponentProps<any>, IReduxProps {
  // use this to send a bugsnag notification for a 404. send a string to add `context` to the bug report.
  notify?: boolean | string
  withoutDashboardFrame?: boolean
}

class NotFoundRouteComponent extends React.Component<INotFoundProps> {
  componentDidMount() {
    const {location, notify} = this.props

    const shouldNotify = typeof notify === 'string' ? true : !!notify
    const context: string | undefined =
      typeof notify === 'string' && isPresent(notify) ? notify : undefined

    if (shouldNotify) {
      const errorMessage = 'Unexpected 404 rendered for path: ' + location.pathname
      // notifyBugsnag({errorClass: 'bug', errorMessage}, {context})
      console.warn(errorMessage, context)
    }
  }

  render404Message() {
    const signinPayload: TNotSignedInYetReferrerPayload = {
      referrer: this.props.location,
    }

    return (
      <>
        <DSTextDiv size="h3">Not Found</DSTextDiv>
        <Spacer height="30px" />
        <DSTextDiv size="h2">Hmm...</DSTextDiv>
        <Spacer height="12px" />
        <DSTextDiv size="body-17">
          That page couldn't be found.
          {!this.props.userState.user && (
            <>
              {' '}
              Do you need to{' '}
              <DSLink
                underline
                to={{pathname: staticURLs.auth.signin, state: signinPayload}}
              >
                sign in
              </DSLink>
              ?
            </>
          )}
        </DSTextDiv>
      </>
    )
  }

  render() {
    if (this.props.withoutDashboardFrame) {
      return this.render404Message()
    }

    return (
      <LoginAgnosticDashboardLayout
        {...isolateRouteComponentProps(this.props)}
        user={this.props.userState.user}
      >
        {this.render404Message()}
      </LoginAgnosticDashboardLayout>
    )
  }
}

const mapStateToProps = (state: ReduxState): IReduxProps => ({
  userState: state.userState,
})

export default connect(mapStateToProps)(withRouter(NotFoundRouteComponent))
