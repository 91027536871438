import {Howl, HowlOptions} from 'howler'
import {ExtractStrict} from 'type-zoo'
import {unreachableCase} from '~/utils/index'

/// Notification types

export type TUserNotificationType = 'turnChange' | 'attack' | 'spectator'

export type TAttackDirection = 'fromMe' | 'againstMe' | 'uninvolved'

type TUserNotificationDataBase = {
  type: TUserNotificationType
}
interface IUserNotificationDataTurnChange extends TUserNotificationDataBase {
  type: ExtractStrict<TUserNotificationType, 'turnChange'>
  isMyTurn: boolean
}
interface IUserNotificationDataAttack extends TUserNotificationDataBase {
  type: ExtractStrict<TUserNotificationType, 'attack'>
  direction: TAttackDirection
}
interface IUserNotificationDataSpectator extends TUserNotificationDataBase {
  type: ExtractStrict<TUserNotificationType, 'spectator'>
}
export type TUserNotificationData =
  | IUserNotificationDataTurnChange
  | IUserNotificationDataAttack
  | IUserNotificationDataSpectator

/// Notification settings

export type TUserNotificationPerSettingOptions = {
  sound: boolean
  browserNotification: boolean
}

export const allUserNotificationPerSettingOptionsFalse: Record<
  keyof TUserNotificationPerSettingOptions,
  false
> = {
  sound: false,
  browserNotification: false,
}

export type TUserNotificationSettings = {
  myTurn: TUserNotificationPerSettingOptions
  someoneElsesTurn: TUserNotificationPerSettingOptions

  attackFromMe: TUserNotificationPerSettingOptions
  attackAgainstMe: TUserNotificationPerSettingOptions
  attackUninvolved: TUserNotificationPerSettingOptions

  spectatorRequestReceived: TUserNotificationPerSettingOptions
}

export const humanSettingDescriptions: Record<
  keyof TUserNotificationSettings,
  string
> = {
  myTurn: 'Your turn',
  someoneElsesTurn: `Someone else's turn`,

  attackFromMe: `When you attack another player`,
  attackAgainstMe: `When another player attacks you`,
  attackUninvolved: `When two other parties attack each other`,

  spectatorRequestReceived: `When someone sends you a spectator request`,
}

export const humanPerSettingOptions: Record<
  keyof TUserNotificationPerSettingOptions,
  string
> = {
  sound: `Sound`,
  browserNotification: `Browser Notif`,
}

/// Sending notifications

export const sendNotification = (
  data: TUserNotificationData,
  settings: TUserNotificationSettings
): void => {
  /*
    interface NotificationOptions {
      actions?: NotificationAction[]; // actions the user can take on the notif itself
      badge?: string; // image when notification is condensed to just an image for space
      body?: string; // text body
      data?: any; // arbitrary data passed thru the notification system
      dir?: NotificationDirection; // ltr or rtl
      icon?: string; // the icon showed e.g. on the right of the notif on Mac
      image?: string; // some image to display in the notification? not sure
      lang?: string;
      renotify?: boolean; // reshow the notif when an existing tag is repeated?
      requireInteraction?: boolean; // force the user to dismiss?
      silent?: boolean; // no sound (i guess this is for mobile?) or vibration
      tag?: string; // a unique ID for the notification, used w/ renotify
      timestamp?: number;
      vibrate?: VibratePattern;
  }
  */

  const defaultBrowserNotifOpts: Required<
    Pick<NotificationOptions, 'renotify' | 'icon'>
  > &
    NotificationOptions = {
    renotify: true,
    icon: '/logo192.png',
  }

  let browserNotifOpts: Required<
    Pick<NotificationOptions, 'body' | 'tag' | 'renotify' | 'icon'>
  > &
    NotificationOptions

  let soundOptions: HowlOptions | undefined
  let specificSettings: TUserNotificationPerSettingOptions

  switch (data.type) {
    case 'turnChange': {
      const tag = [data.type, data.isMyTurn].join(' ')
      let body: string
      if (data.isMyTurn) {
        body = `It’s your turn`
        specificSettings = settings.myTurn
        soundOptions = {
          src: ['/sounds/intuition.mp3', '/sounds/intuition.ogg'],
        }
      } else {
        body = `Someone else’s turn just started.`
        specificSettings = settings.someoneElsesTurn
      }
      browserNotifOpts = {
        ...defaultBrowserNotifOpts,
        body,
        tag,
      }
      break
    }
    case 'attack': {
      const tag = [data.type, data.direction].join(' ')
      let body: string
      soundOptions = {
        src: ['/sounds/all-eyes-on-me.mp3', '/sounds/all-eyes-on-me.ogg'],
      }
      switch (data.direction) {
        case 'againstMe':
          body = `Someone attacked you!`
          specificSettings = settings.attackAgainstMe
          break
        case 'fromMe':
          body = `You played an attack card.`
          specificSettings = settings.attackFromMe
          break
        case 'uninvolved':
          body = `An action card was played.`
          specificSettings = settings.attackUninvolved
          break
        default:
          return unreachableCase(data.direction)
      }
      browserNotifOpts = {
        ...defaultBrowserNotifOpts,
        body,
        tag,
      }
      break
    }
    case 'spectator': {
      const tag = data.type
      const body = `Someone wants to spectate your hand`
      specificSettings = settings.myTurn
      soundOptions = {
        src: [
          '/sounds/i-demand-attention-244.ogg',
          '/sounds/i-demand-attention-244.ogg',
        ],
      }
      browserNotifOpts = {
        ...defaultBrowserNotifOpts,
        body,
        tag,
      }
      break
    }
    default:
      return unreachableCase(data)
  }

  if (specificSettings.sound && soundOptions) {
    playSound(soundOptions)
  }
  if (specificSettings.browserNotification) {
    sendBrowserNotification(browserNotifOpts)
  }
}

const playSound = (soundOptions: HowlOptions) => {
  const sound = new Howl(soundOptions)
  sound.on('loaderror', e => {
    console.warn('howl loaderror', e)
  })
  sound.on('playerror', e => {
    console.warn('howl playerror', e)
  })
  sound.play()
}

const sendBrowserNotification = (browserNotificationOpts: NotificationOptions) => {
  if (!window.Notification) {
    console.warn('Notification not supported in this browser.')
    return
  }

  const createNotification = () => {
    new Notification('Mercury Deals', browserNotificationOpts)
  }

  const permissionStatus = Notification.permission
  if (permissionStatus === 'granted') {
    createNotification()
    return
  } else if (permissionStatus !== 'denied') {
    try {
      Notification.requestPermission()
        .then(createNotification)
        .catch(e => undefined)
    } catch (e) {
      // this is for Safari which apparently still uses this deprecated non-Promise API
      console.warn('using old Notification system:', e)
      /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
      Notification.requestPermission(
        permission => permission === 'granted' && createNotification()
      )
    }
  }
}
