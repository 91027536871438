import {
  TInProgressMajorActionData,
  TLastRentThisVisit,
} from '~/api/generated/types/common'
import {DataBackedModel} from '~/models/base'
import {
  majorActionFactory,
  minorActionFactory,
  minorActionPromptFactory,
} from '~/models/game/factories'
import MinorAction from '~/models/game/MinorAction'
import Player from '~/models/game/Player'
import {
  AnyMinorActionPrompt,
  MajorActionAllowsMinorActions,
} from '~/models/game/types'
import * as NEL from '~/utils/NEList'

export default class InProgressMajorAction extends DataBackedModel<
  TInProgressMajorActionData
> {
  majorAction: MajorActionAllowsMinorActions
  pendingMinorActions: NEL.NEList<AnyMinorActionPrompt>

  // server orders these in most-recent-first order.
  // having deterministic order is important to the JSN code, at least.
  previousMinorActions: MinorAction<any>[]
  majorActor: Player

  constructor(
    data: TInProgressMajorActionData,
    majorActor: Player,
    players: Player[],
    lastRentThisVisit: TLastRentThisVisit | undefined
  ) {
    super(data)
    this.majorActor = majorActor
    this.majorAction = majorActionFactory(
      data.majorActionData,
      majorActor.portfolio,
      {
        lastRentThisVisit,
      }
    )

    // this NEL is nice to enforce for now. I'm not sure if e.g. pastGamePositions
    // will violate this constraint or not yet.
    const pendingMinorActions = NEL.createSafe(
      data.pendingMinorActions.map(pma => {
        const minorActor = players.find(p => p.id === pma.minorActorPlayerId)
        if (!minorActor) {
          throw new Error(
            `minor actor not found in player list when constructing InProgressMajorAction: ${pma}`
          )
        }
        return minorActionPromptFactory(pma, this.majorAction, {
          majorActor,
          minorActor,
        })
      })
    )

    if (!pendingMinorActions) {
      // this happened once when accepting a forced deal, where the forced deal created a complete set for the attacked
      // player. I couldn't figure out from the data why it happened, so I added more logging here just in case
      console.error({
        data,
        majorActor,
        players,
        lastRentThisVisit,
        pendingMinorActions,
        majorAction: this.majorAction,
      })
      throw new Error(`InProgressMajorAction has no pendingMinorActions`)
    }
    this.pendingMinorActions = pendingMinorActions

    // will probably want to add major/minor actor to this constructor too like above
    this.previousMinorActions = data.previousMinorActions.map((pma, idx) => {
      // the UI wants to be able to say who the acquiescer gave up _to_ which is implicit in
      // previousMinorActions. we make it explicit here for the rest of the app to use.
      if (pma.type === 'acquiesce') {
        // idx + 1 because previousMinorActions is ordered (newest first, oldest last) by the server
        if (data.previousMinorActions[idx + 1]?.type === 'justSayNo') {
          return minorActionFactory({
            ...pma,
            prevailingPlayerId:
              data.previousMinorActions[idx + 1].minorActorPlayerId,
          })
        } else {
          // there should have been a JSN played before this!
          console.warn('error data', data.previousMinorActions, pma, idx)
          throw new Error(
            `Can't build InProgressMajorAction when an Acquiesce is not 'after' by a JustSayNo in previousMinorActions`
          )
        }
      } else {
        return minorActionFactory(pma)
      }
    })
  }
}
