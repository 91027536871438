export type TGameLobbySize = 'small' | 'medium' | 'large'
export const gameLobbySizeHuman: Record<TGameLobbySize, string> = {
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
}

type TGameBoardSizeData = {
  size: number
  cardWidth: number
  framePadding: number
}

type TGameLobbySizeData = {
  board: TGameBoardSizeData
  hand: {cardWidth: number}
}

export const sizeData: Record<TGameLobbySize, TGameLobbySizeData> = {
  small: {
    board: {size: 600, cardWidth: 50, framePadding: 120},
    hand: {cardWidth: 70},
  },
  medium: {
    board: {size: 800, cardWidth: 70, framePadding: 180},
    hand: {cardWidth: 80},
  },
  large: {
    board: {size: 1000, cardWidth: 80, framePadding: 200},
    hand: {cardWidth: 90},
  },
}
