import classnames from 'classnames'
import * as React from 'react'
import {HTMLInputType} from '~/components/ProvideForm/types'
import ProvideLabeledLineTextInput, {
  IProvideLabeledLineTextInputProps,
} from '~/components/FormFields/ProvideLabeledLineTextInput'
import styles from './styles.module.css'

export interface ILabeledLineTextInputProps
  extends IProvideLabeledLineTextInputProps {
  inputType?: HTMLInputType
}

export default class LabeledLineTextInput extends React.Component<
  ILabeledLineTextInputProps
> {
  render() {
    let {inputType, children, ...provideInputProps} = this.props

    return (
      <ProvideLabeledLineTextInput
        {...provideInputProps}
        toInput={inputProps => (
          <input
            {...inputProps}
            className={classnames(styles.input, inputProps.className)}
            type={inputType || 'text'}
            ref={provideInputProps.inputRef as React.Ref<HTMLInputElement>}
          />
        )}
      />
    )
  }
}
