import React from 'react'
import DashboardLayout from '~/layouts/DashboardLayout'
import {
  IAdminRouteComponentProps,
  isolateAdminRouteComponentProps,
} from '~/routing/Route/Admin'

interface IProps extends IAdminRouteComponentProps<any> {}

export default class AdminLayout extends React.Component<IProps> {
  render() {
    return (
      <DashboardLayout {...isolateAdminRouteComponentProps(this.props)}>
        {this.props.children}
      </DashboardLayout>
    )
  }
}
