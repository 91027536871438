import {takeWhile} from 'ramda'
import {TMinorActionDataJustSayNo} from '~/api/generated/types/common'
import Card from '~/models/game/Card'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MinorAction, {
  TMinorActionUserFacingDescriptionData,
} from '~/models/game/MinorAction'
import {compact} from '~/utils'
import {sentenceCase, xVerbY} from '~/utils/language'

export default class MinorActionJustSayNo extends MinorAction<
  TMinorActionDataJustSayNo
> {
  userFacingDescription(data: TMinorActionUserFacingDescriptionData): string {
    const {
      tense,
      skin,
      gamePosition,
      minorActor,
      majorActor,
      inProgressMajorAction,
    } = data
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined

    // minorActor is who played the JustSayNo
    // majorActor is who started the fight
    // if the initiator of the InProgressMajorAction has played the (second) JustSayNo,
    // the majorActor and minorActor will both be the same, so
    // we have to look up the otherPlayer (the currentLoser) of the exchange elsewhere

    // (first filter previousMinorActions to remove, say, a previously acquiesced JSN. we only care about the "current"/
    // "active" JSN, even if another player played one on a previous step of, say, a universal rent)
    const previousJustSayNos = takeWhile(
      pma => pma.type === 'justSayNo',
      inProgressMajorAction.previousMinorActions
    )
    const otherPlayerId = previousJustSayNos
      .map(pma => pma.minorActorPlayerId)
      .find(minorId => minorId !== majorActor.id)
    const otherPlayer =
      otherPlayerId && gamePosition.players.find(p => p.id === otherPlayerId)

    const [currentWinner, currentLoser] =
      otherPlayer && minorActor.id === majorActor.id
        ? [majorActor, otherPlayer]
        : [minorActor, majorActor]

    const {conjugatedVerb, xNoun, yNounPossessive} = xVerbY(
      tense,
      currentWinner,
      tense === 'imperativePrompt' ? 'Play' : 'play',
      currentLoser,
      currentPlayer?.id
    )

    if (tense === 'imperativePrompt') {
      // when presenting a minor action in the UI as a prompt,
      // avoid saying 'You play Just Say No against Foo', instead 'Play Just Say No'
      return compact([
        conjugatedVerb,
        Card.displayNameForCard('justSayNo', skin),
      ]).join(' ')
    }

    return compact([
      xNoun === currentWinner.username ? xNoun : sentenceCase(xNoun),
      conjugatedVerb,
      Card.displayNameForCard('justSayNo', skin),
      'against',
      yNounPossessive,
      'attack',
    ]).join(' ')
  }
}
