import {TMajorActionDirectionalRentData} from '~/api/generated/types/common'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import {TMajorActionUserFacingDescriptionData} from '~/models/game/MajorAction'
import {DirectionalRentCard} from '~/models/game/Cards/RentCard'
import MajorActionRentBase from '~/models/game/MajorActions/MajorActionRentBase'
import Portfolio from '~/models/game/Portfolio'
import StandardPortfolioNeighborhood from '~/models/game/PortfolioNeighborhoods/StandardPortfolioNeighborhood'
import {standardPNForIdThrows} from '~/models/game/utils'
import {compact} from '~/utils'
import {sentenceCase, show$, xVerbY} from '~/utils/language'

type PublicMembersFromApi = Pick<
  TMajorActionDirectionalRentData,
  'minorActorPlayerId'
>

export default class MajorActionDirectionalRent
  extends MajorActionRentBase
  implements PublicMembersFromApi {
  card: DirectionalRentCard
  minorActorPlayerId: string // target of the rent

  // which neighborhood was used to initiate the rent
  public portfolioNeighborhood: StandardPortfolioNeighborhood

  constructor(data: TMajorActionDirectionalRentData, portfolio: Portfolio) {
    super(data)
    this.card = new DirectionalRentCard(data.card)

    this.portfolioNeighborhood = standardPNForIdThrows(portfolio)(
      data.portfolioNeighborhoodId
    )
    this.minorActorPlayerId = data.minorActorPlayerId
  }

  public userFacingDescription({
    tense,
    skin,
    gamePosition,
  }: TMajorActionUserFacingDescriptionData): string {
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined
    const majorActor = gamePosition.majorActorPlayer
    const minorActor = gamePosition.players.find(
      o => o.id === this.minorActorPlayerId
    )
    if (!minorActor) {
      throw new Error(
        `Minor actor not found in MajorActionDirectionalRent description`
      )
    }

    const color = this.portfolioNeighborhood.displayColor(skin)
    const standardRentValue = this.portfolioNeighborhood.rent()
    const dropFirstNoun = tense === 'imperativePrompt'

    const {conjugatedVerb, xNoun, yNoun} = xVerbY(
      tense,
      majorActor,
      dropFirstNoun ? 'Play' : 'play',
      minorActor,
      currentPlayer?.id
    )

    return compact([
      dropFirstNoun
        ? undefined
        : xNoun === majorActor.username
        ? xNoun
        : sentenceCase(xNoun),
      conjugatedVerb,
      show$(standardRentValue),
      ...(minorActor.portfolio.totalValue() < standardRentValue
        ? [`(${show$(minorActor.portfolio.totalValue())})`]
        : []),
      this.card.displayName(skin),
      `(${color})`,
      'against',
      yNoun,
    ]).join(' ')
  }
}
