import * as React from 'react'
import {IFormProps} from '~/components/ProvideForm/types'

export interface IProvideAutosaveProps {
  formProps: IFormProps<any>
  shouldSubmitOnMount: boolean
}

/**
 * Pass this component the formProps for a form to have the field automatically submit
 * as soon as it renders
 */
export default class SubmitOnMount extends React.Component<IProvideAutosaveProps> {
  componentDidMount() {
    if (this.props.shouldSubmitOnMount) {
      this.props.formProps.onSubmit()
    }
  }

  render() {
    return null
  }
}
