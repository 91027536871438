import * as React from 'react'
import BaseLayout, {IBaseLayoutProps} from '~/layouts/BaseLayout'
import {NoStyleLink} from '~/routing/ReactRouterLinks'
import {staticURLs} from '~/routing/sitemap'
import styles from './styles.module.css'

interface IProps extends IBaseLayoutProps {}

export default class FEMLayout extends React.Component<IProps> {
  render() {
    return (
      <BaseLayout {...this.props}>
        <div className={styles.topBar}>
          <div className={styles.left}>
            <NoStyleLink to={staticURLs.dashboard}>
              <img
                className={styles.logoAndBrand}
                src="/icons/logo-and-brand-currentColor.svg"
              />
            </NoStyleLink>
          </div>
        </div>
        <div className={styles.femLayoutFrame}>{this.props.children}</div>
      </BaseLayout>
    )
  }
}
