import * as React from 'react'
import ReactModal from 'react-modal'
import {HelmetProvider} from 'react-helmet-async'
import LoadingScreen, {TLoadingState} from '~/components/LoadingScreen'
import * as utils from '~/pages/utils'
import {updateGlobalDataFromServer} from '~/redux/massUpdate'
import {history} from '~/redux/store'
import Routes from '~/routing/routes'

interface IProps {}
interface IState {
  loadState: TLoadingState
}

export default class SinglePageApplication extends React.Component<IProps, IState> {
  state: IState = {
    loadState: 'loading',
  }

  componentDidMount() {
    // http://reactcommunity.org/react-modal/accessibility/
    ReactModal.setAppElement('#root')

    utils.spaLaunchSetup()

    updateGlobalDataFromServer('app-load')
      .then(() => {
        this.setState({loadState: 'success'})
      })
      .catch(e => {
        console.warn(`Updating global data on app-load had an error: `, e)
        this.setState({loadState: 'failed'})
      })
  }

  render() {
    return (
      <HelmetProvider>
        <LoadingScreen loadState={this.state.loadState} renderChildrenOnFailure>
          <Routes history={history} />
        </LoadingScreen>
      </HelmetProvider>
    )
  }
}
