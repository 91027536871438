import * as R from 'ramda'
import {
  TStandardPortfolioNeighborhoodData,
  TStandardPropertyType,
} from '~/api/generated/types/common'
import {cardsPerSet, rentValue} from '~/game/types'
import PortfolioNeighborhood from '~/models/game/PortfolioNeighborhood'
import ResidenceCard from '~/models/game/Cards/ResidenceCard'

// Represents one grouping of properties on the table in front of each player
// except "alone" wildcards - those are in a separate class because they don't have residences
export default class StandardPortfolioNeighborhood extends PortfolioNeighborhood {
  // protected raw: TStandardPortfolioNeighborhoodData
  public residences: ResidenceCard[]
  public neighborhood: TStandardPropertyType

  constructor(data: TStandardPortfolioNeighborhoodData) {
    super(data)
    // this.raw = data
    this.neighborhood = data.neighborhood
    this.residences = data.residences.map(r => new ResidenceCard(r))
  }

  isCompleteSet(): boolean {
    return this.properties.length >= cardsPerSet[this.neighborhood]
  }

  isStandardPortfolioNeighborhood() {
    return true
  }

  rent(): number {
    return (
      rentValue(this.neighborhood, this.properties.length) +
      R.sum(this.residences.map(r => r.additionalRentValue()))
    )
  }
}
