import {
  TLastRentThisVisit,
  TMajorActionDoubleTheRentData,
} from '~/api/generated/types/common'
import {DoubleTheRentCard} from '~/models/game/Cards/ActionCard'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import {TMajorActionUserFacingDescriptionData} from '~/models/game/MajorAction'
import MajorActionRentBase from '~/models/game/MajorActions/MajorActionRentBase'
import StandardPortfolioNeighborhood from '~/models/game/PortfolioNeighborhoods/StandardPortfolioNeighborhood'
import {mutateIf} from '~/utils'
import {inTense, sentenceCase, show$} from '~/utils/language'

// weirdly, we replace everything from TMajorActonDoubleTheRentData here :P
type PublicMembersFromApi = {}

export default class MajorActionDoubleTheRent
  extends MajorActionRentBase
  implements PublicMembersFromApi {
  card: DoubleTheRentCard
  // which neighborhood was used to initiate the rent
  portfolioNeighborhood: StandardPortfolioNeighborhood

  lastRentThisVisit: TLastRentThisVisit

  constructor(
    data: TMajorActionDoubleTheRentData,
    lastRentThisVisit: TLastRentThisVisit
  ) {
    super(data)
    this.card = new DoubleTheRentCard(data.card)
    this.lastRentThisVisit = lastRentThisVisit
    this.portfolioNeighborhood = new StandardPortfolioNeighborhood(
      lastRentThisVisit.portfolioNeighborhood
    )
  }

  public userFacingDescription({
    tense,
    skin,
    gamePosition,
  }: TMajorActionUserFacingDescriptionData): string {
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined
    const majorActor = gamePosition.majorActorPlayer
    const dropFirstNoun = tense === 'imperativePrompt'

    const color = this.portfolioNeighborhood.displayColor(skin)
    const originalRent = this.lastRentThisVisit.originalRentAction

    return [
      dropFirstNoun
        ? undefined
        : currentPlayer?.id === majorActor.id
        ? 'You'
        : majorActor.username,
      mutateIf(inTense(tense)('play'), dropFirstNoun, sentenceCase),
      show$(this.portfolioNeighborhood.rent()),
      this.card.displayName(skin),
      `(${color})`,
      ...(originalRent.type === 'directionalRent'
        ? [
            'against',
            gamePosition.players.find(o => o.id === originalRent.minorActorPlayerId)
              ?.username,
          ]
        : []),
    ].join(' ')
  }
}
