import {faStar as faStarSolid, faSyncAlt} from '@fortawesome/free-solid-svg-icons'
import {faStar as faStarEmpty} from '@fortawesome/free-regular-svg-icons'
import * as R from 'ramda'
import * as React from 'react'
import ReactModal from 'react-modal'
import {connect} from 'react-redux'
import {TCardData} from '~/api/generated/types/common'
import DSButton from '~/design-system/Button'
import DSText, {DSTextDiv} from '~/design-system/DSText'
import {cardsInDeck, cardStarRating} from '~/game/types'
import Card from '~/models/game/Card'
import {cardFactory} from '~/models/game/factories'
import CardImage from '~/pages/GameLobby/GameCard/stateless'
import {joinElements} from '~/pages/utils'
import {ReduxState} from '~/redux/reducers/root'
import {setInspectingCardData} from '~/redux/reducers/ui'
import {id, safely, safelyOr} from '~/utils'
import {
  AlternateStateFontAwesomeIcon,
  HoverableFontAwesomeIconDiv,
} from '~/utils/FontAwesome/HoverableFontAwesomeIcon'
import {show$} from '~/utils/language'
import styles from './styles.module.css'

interface IReduxProps extends Pick<ReduxState, 'uiState' | 'settingsState'> {}

interface IProps extends IReduxProps, TDispatch {}

class CardInspectionModal extends React.Component<IProps> {
  render() {
    const card: Card<TCardData> | undefined = safely(
      this.props.uiState.inspectingCardData,
      cardFactory
    )
    if (!card) {
      return null
    }

    // Internal thing here is just so we can set the key, so that the rotation state
    // isn't reused by another one of these modals
    return <Internal {...this.props} key={card.id} card={card} />
  }
}

const mapStateToProps = (state: ReduxState): IReduxProps => ({
  uiState: state.uiState,
  settingsState: state.settingsState,
})

const mapDispatchToProps = {setInspectingCardData}
type TDispatch = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(CardInspectionModal)

interface IInternalProps extends IProps {
  card: Card<TCardData>
}

interface IState {
  flipped: boolean
}

class Internal extends React.Component<IInternalProps, IState> {
  state: IState = {
    flipped: false,
  }

  render() {
    const {card, settingsState} = this.props
    const skin = settingsState.skin

    const InfoGridItemData = (props: {children: React.ReactNode}) => (
      <DSTextDiv size="label-demi" color="white" className={styles.infoGridItemData}>
        {props.children}
      </DSTextDiv>
    )
    const InfoGridItemTitle = (props: {children: React.ReactNode}) => (
      <DSText size="label" color="white" className={styles.infoGridItemTitle}>
        {props.children}
      </DSText>
    )

    const requestClose = () => this.props.setInspectingCardData(undefined)

    const starRating = cardStarRating[card.type]
    const Star = (props: {filled: boolean}) => (
      <AlternateStateFontAwesomeIcon
        icon={{
          icon: faStarEmpty,
          style: {
            width: '15px',
            height: '15px',
          },
          className: styles.starIcon,
        }}
        alternateIcon={{
          icon: faStarSolid,
        }}
        alternateState={props.filled}
      />
    )

    const stars = joinElements([
      ...R.repeat(<Star filled />, starRating),
      ...R.repeat(<Star filled={false} />, Math.max(0, 5 - starRating)),
    ])

    return (
      <ReactModal
        isOpen
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={requestClose}
        overlayClassName={styles.overlay}
        className={styles.modalWrapper}
        bodyOpenClassName={styles.body}
      >
        <div className={styles.modalBackground}>
          <div className={styles.cardImageAndButtonWrapper}>
            <CardImage
              className={styles.cardImage}
              model={card}
              invert={this.state.flipped}
              skin={skin}
            />
            {card.isFlippable() && (
              <HoverableFontAwesomeIconDiv
                icon={{
                  icon: faSyncAlt,
                  color: 'pearl-grey-darkest',
                  style: {
                    width: '15px',
                    height: '15px',
                  },
                }}
                className={styles.flipIcon}
                hoverIcon={{
                  color: 'white',
                }}
                onClick={() =>
                  this.setState(prevState => ({flipped: !prevState.flipped}))
                }
              >
                {id}
              </HoverableFontAwesomeIconDiv>
            )}
          </div>
          <div className={styles.cardDescriptionWrapper}>
            <DSTextDiv
              size="body-17-demi"
              color="white"
              className={styles.cardTitle}
            >
              {card.displayName(skin)}
            </DSTextDiv>
            <DSTextDiv
              size="body-15"
              color="white"
              className={styles.cardDescriptionText}
            >
              {card.shortDescription(skin)}
            </DSTextDiv>

            <div className={styles.infoGrid}>
              <InfoGridItemTitle>Number in deck</InfoGridItemTitle>
              <InfoGridItemData>{cardsInDeck[card.type]}</InfoGridItemData>
              <InfoGridItemTitle>Cash value</InfoGridItemTitle>
              <InfoGridItemData>
                {safelyOr(card.value, show$, 'N/A')}
              </InfoGridItemData>
              <InfoGridItemTitle>Rating</InfoGridItemTitle>
              <InfoGridItemData>{stars}</InfoGridItemData>
            </div>

            <div className={styles.flexSpacer} />

            <DSButton
              size="17px"
              onClick={requestClose}
              buttonStyle="tableAction"
              className={styles.dismissButton}
            >
              Dismiss
            </DSButton>
          </div>
        </div>
      </ReactModal>
    )
  }
}
