import {PostApi} from '~/api/client'
import GetGameData from '~/api/endpoints/game/GetGameData'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TGetGameDataResponse} from '~/api/generated/types/ApproveSpectatorRequest'

type Request = {}
type TApproveSpectatorRequestPathPieces = {
  gameId: string
  spectatorPlayerId: string
}
type Response = TGetGameDataResponse

/**
 * A player in a game can allow a spectator to see their hand,
 * if a spectator has requested it.
 */
export default class ApproveSpectatorRequest extends PostApi<
  Request,
  Response,
  TApproveSpectatorRequestPathPieces
> {
  protected getMockResponse(
    params: Request,
    pathPieces: TApproveSpectatorRequestPathPieces
  ): Response {
    return GetGameData.mockResponseWithMockConfig({pathPieces})
  }

  protected getPath(pathPieces: TApproveSpectatorRequestPathPieces) {
    return serverEndpoints.approveSpectatorRequest({
      playerId: pathPieces.spectatorPlayerId,
      gameId: pathPieces.gameId,
    })
  }

  protected transformResponse(r: Response) {
    return r
  }
}
