import * as H from 'history'
import {history} from '~/redux/store'

// type NavigationFunction<Payload> = (location: H.LocationDescriptor<Payload>) => void

const go = (type: 'replace' | 'push') => <Payload>(
  location: H.LocationDescriptor<Payload>
) => {
  const resolved: H.LocationDescriptorObject<Payload> =
    typeof location === 'string' ? {pathname: location} : location
  console.log(`${type} redirecting to: ${resolved.pathname}`)

  if (type === 'replace') {
    history.replace(resolved)
  } else {
    history.push(resolved)
  }
}

export const navigateTo = go('push')
// export const navigateReplace = go('replace')
