import {OmitStrict} from 'type-zoo'
import {TMinorActionDataPayRent} from '~/api/generated/types/common'
import MinorActionPaySomething from '~/models/game/MinorActions/MinorActionPaySomething'

type PublicMembersFromApi = OmitStrict<TMinorActionDataPayRent, 'type' | 'paidWith'>

export default class MinorActionPayRent
  extends MinorActionPaySomething<TMinorActionDataPayRent>
  implements PublicMembersFromApi {
  portfolioNeighborhoodId: string // what neighborhood the rent we're paying was charged for

  constructor(data: TMinorActionDataPayRent) {
    super(data)
    this.portfolioNeighborhoodId = data.portfolioNeighborhoodId
  }
}
