import {
  TDirectionalRentCardData,
  TStandardPropertyType,
  TUniversalRentCardData,
} from '~/api/generated/types/common'
import Card, {TAvailableMovesData} from '~/models/game/Card'
import {allStandardPropertyTypes, cardValue, TRentCardData} from '~/game/types'
import MajorActionDirectionalRent from '~/models/game/MajorActions/MajorActionDirectionalRent'
import MajorActionPlayAsCash from '~/models/game/MajorActions/MajorActionPlayAsCash'
import MajorActionUniversalRent from '~/models/game/MajorActions/MajorActionUniversalRent'
import {AnyMajorAction} from '~/models/game/types'
import {lowerFirst, unreachableCase} from '~/utils'

export default abstract class RentCard<CardType extends TRentCardData> extends Card<
  CardType
> {
  public value: number

  constructor(data: CardType) {
    super(data)
    this.value = cardValue[data.type]
  }

  public fileName(): string {
    return `rent_${
      this.type === 'directionalRent'
        ? 'directionalWild'
        : lowerFirst(this.type.slice(4))
    }`
  }

  // "Which colors does this rent card charge rent for?"
  abstract standardPropertyTypes(): Set<TStandardPropertyType>
}

export class UniversalRentCard extends RentCard<TUniversalRentCardData> {
  standardPropertyTypes(): Set<TStandardPropertyType> {
    switch (this.type) {
      case 'rentDarkBlueGreen':
        return new Set<TStandardPropertyType>(['darkBlue', 'green'])
      case 'rentLightBlueBrown':
        return new Set<TStandardPropertyType>(['lightBlue', 'brown'])
      case 'rentPinkOrange':
        return new Set<TStandardPropertyType>(['pink', 'orange'])
      case 'rentRedYellow':
        return new Set<TStandardPropertyType>(['red', 'yellow'])
      case 'rentUtilityRailroad':
        return new Set<TStandardPropertyType>(['utility', 'railroad'])
      default:
        return unreachableCase(this.type)
    }
  }

  public availableMovesFromHand({
    playerGamePosition,
    portfolio,
  }: TAvailableMovesData): (MajorActionUniversalRent | MajorActionPlayAsCash)[] {
    const asCash = new MajorActionPlayAsCash({
      type: 'playAsCash',
      card: this.datasource,
    })

    if (!playerGamePosition.opponentPortfoliosWithValue().length) {
      return [asCash]
    }

    return [
      ...portfolio
        .standardNeighborhoods()
        .filter(pn => this.standardPropertyTypes().has(pn.neighborhood))
        .map(
          pn =>
            new MajorActionUniversalRent(
              {
                card: this.datasource,
                portfolioNeighborhoodId: pn.id,
                type: this.type,
              },
              portfolio
            )
        ),
      asCash,
    ]
  }
}

export class DirectionalRentCard extends RentCard<TDirectionalRentCardData> {
  standardPropertyTypes(): Set<TStandardPropertyType> {
    return allStandardPropertyTypes
  }

  public availableMovesFromHand({
    playerGamePosition,
    portfolio,
  }: TAvailableMovesData): AnyMajorAction[] {
    return [
      ...portfolio
        .standardNeighborhoods()
        .filter(pn => this.standardPropertyTypes().has(pn.neighborhood))
        .map(pn =>
          playerGamePosition.opponents
            .filter(o => o.portfolio.totalValue() > 0)
            .map(
              opponent =>
                new MajorActionDirectionalRent(
                  {
                    minorActorPlayerId: opponent.id,
                    card: this.datasource,
                    portfolioNeighborhoodId: pn.id,
                    type: 'directionalRent',
                  },
                  portfolio
                )
            )
        )
        .flat(1),
      new MajorActionPlayAsCash({
        type: 'playAsCash',
        card: this.datasource,
      }),
    ]
  }
}
