import * as React from 'react'
import {Helmet} from 'react-helmet-async'
import ScrollToTopOnMount from '~/components/ScrollToTopOnMount'
import LogoutTimer from '~/layouts/LogoutTimer'
import {updateGlobalDataFromServer} from '~/redux/massUpdate'

export interface IBaseLayoutProps {
  hasMobileDesigns?: boolean
}

export default class BaseLayout extends React.Component<IBaseLayoutProps> {
  componentDidUpdate(prevProps: IBaseLayoutProps) {
    updateGlobalDataFromServer('pageTransition')
      // dont want to do anything on response
      .then()
      .catch(() =>
        console.warn('Global Data failed to update from server in BaseLayout')
      )
  }

  // some embedded layouts have BaseLayout repeated so you have to supply the return type to render to
  render(): JSX.Element {
    const {children, hasMobileDesigns} = this.props
    return (
      <LogoutTimer>
        <div>
          <ScrollToTopOnMount />
          {!hasMobileDesigns && (
            <Helmet>
              {/* lots of pages don't have mobile designs yet, this makes them slightly better */}
              <meta name="viewport" content="width=500,initial-scale=0.6" />
            </Helmet>
          )}
          {children}
        </div>
      </LogoutTimer>
    )
  }
}
