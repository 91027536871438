import {TUserDetails} from '~/api/generated/types/common'
import {DataBackedModel} from '~/models/base'

export default class User extends DataBackedModel<
  TUserDetails
> /* implements TUserDetails */ {
  // i think `declare` is the correct thing to do here to make `implements TUserDetails`
  // and the magic in DataBackedModel work
  // but it's broken right now: https://github.com/facebook/create-react-app/issues/8918
  // /* declare*/ email: string = ''
  // /* declare*/ username: string = ''

  id: string
  username: string
  email: string
  admin: boolean

  constructor(userDetails: TUserDetails) {
    super(userDetails)
    this.id = userDetails.id
    this.email = userDetails.email
    this.username = userDetails.username
    this.admin = userDetails.admin
  }
}
