import {TMajorActionData} from '~/api/generated/types/common'
import {TSkin} from '~/game/skins'
import {TMajorActionType} from '~/game/types'
import {DataBackedModel} from '~/models/base'
import GamePosition from '~/models/game/GamePosition'
import {Tense} from '~/utils/language'

export type TMajorActionUserFacingDescriptionData = {
  gamePosition: GamePosition
  tense: Tense
  skin: TSkin
}

// extra data from e.g. GamePosition that all MajorActions need when being constructed.
// type TMajorActionConstructorContext = {
//   portfolios: Portfolio[] // all the portfolios on the table when the major action was taken
// }

type PublicMembersFromApi = Pick<
  TMajorActionData,
  'type' // | 'id'
>

export default abstract class MajorAction
  extends DataBackedModel<TMajorActionData>
  implements PublicMembersFromApi {
  // public id: string
  public type: TMajorActionType

  constructor(
    data: TMajorActionData // , context: TMajorActionConstructorContext
  ) {
    super(data)
    // this.id = data.id
    this.type = data.type
  }

  public abstract userFacingDescription(
    data: TMajorActionUserFacingDescriptionData
  ): string
}
