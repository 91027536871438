import * as React from 'react'
import {TSignUpResponse} from '~/api/endpoints/auth/SignUp'
import LabeledLineTextInput from '~/components/FormFields/LabeledLineTextInput'
import ProvideForm from '~/components/ProvideForm/normal'
import {emailConstraints, notEmpty} from '~/components/ProvideForm/utils'
import Spacer from '~/components/Spacer'
import DSButton from '~/design-system/Button'
import DSText, {DSTextDiv} from '~/design-system/DSText'
import DSLink from '~/design-system/Link'
import {passwordConstraints, repeatedPasswordConstraints} from '~/pages/Auth/utils'
import {staticURLs} from '~/routing/sitemap'
import styles from './styles.module.css'

interface IProps {
  signUp: (form: FormFields) => Promise<TSignUpResponse>
}

interface IState {
  success: boolean
}

type FormFields = {
  email: string
  username: string
  password: string
  passwordAgain: string
}

export default class SignInUI extends React.Component<IProps, IState> {
  state: IState = {
    success: false,
  }

  render() {
    if (this.state.success) {
      return (
        <div className={styles.wrapper}>
          <DSTextDiv size="body-15">
            Your signup has been received. An admin must approve your account before
            you can{' '}
            <DSLink to={staticURLs.auth.signin} underline="onHover" color="blue">
              sign in
            </DSLink>
            .
          </DSTextDiv>
        </div>
      )
    }
    return (
      <div className={styles.wrapper}>
        <DSTextDiv size="h2">Sign Up</DSTextDiv>
        <DSTextDiv size="caption">
          New accounts must be approved by an admin.
        </DSTextDiv>

        <ProvideForm<FormFields, TSignUpResponse>
          onSubmit={this.props.signUp}
          wrapperClassName={styles.formWrapper}
          submitSuccess={() => this.setState({success: true})}
          toFormFields={(generateFormFieldProps, formProps) => {
            const passwordFFPs = generateFormFieldProps(
              'password',
              passwordConstraints
            )
            return (
              <div>
                <LabeledLineTextInput
                  label="Email (used for Gravatar)"
                  placeholder=" "
                  formFieldProps={generateFormFieldProps('email', emailConstraints)}
                />
                <LabeledLineTextInput
                  label="Username"
                  placeholder=" "
                  formFieldProps={generateFormFieldProps('username', [notEmpty])}
                />

                <DSTextDiv size="body-15" className={styles.passwordWarning}>
                  <b>WARNING:</b> passwords are stored in plaintext at the moment.
                  Choose something funny for bonus ELO.
                </DSTextDiv>

                <LabeledLineTextInput
                  label="Password"
                  placeholder=" "
                  formFieldProps={passwordFFPs}
                  inputType="password"
                  onChange={() => formProps.runAllConstraints(false)}
                />

                <LabeledLineTextInput
                  label="Repeat your password"
                  placeholder=" "
                  formFieldProps={generateFormFieldProps(
                    'passwordAgain',
                    repeatedPasswordConstraints(passwordFFPs.value)
                  )}
                  inputType="password"
                />

                <Spacer height="40" />

                <DSButton
                  buttonStyle="primary"
                  size="19px"
                  onClick={() => formProps.onSubmit()}
                  loading={formProps.isFormSubmitting}
                  disabled={formProps.isFormSubmitting}
                  appearDisabled={formProps.anyErrors}
                >
                  Sign Up
                </DSButton>

                <DSText size="body-15" color="grey-dark" className={styles.orLink}>
                  <DSLink to={staticURLs.auth.signin} underline="onHover">
                    Or, Sign In
                  </DSLink>
                </DSText>
              </div>
            )
          }}
        />
      </div>
    )
  }
}
