import * as H from 'history'
import React from 'react'
import * as M from 'react-aria-menubutton'
import {navigateTo} from '~/routing/navigate'

// copied from react-router-link
// https://github.com/ReactTraining/react-router/blob/c0b8ce42d3c6b85e3a53d1c56ae12c88205d00d8/packages/react-router-dom/modules/Link.js#L17
function isModifiedEvent(event: React.KeyboardEvent | React.MouseEvent) {
  return event.metaKey || event.altKey || event.ctrlKey || event.shiftKey
}

export interface IMenuLinkItemProps extends M.MenuItemProps<any> {
  to: H.LocationDescriptor
  newTab?: boolean
}

// Like `MenuItem` except it uses a React Router link instead of an onClick handler.
// This (ab)uses some implementation details from the internals of the M.MenuItem to
// use react router links instead of a normal `<a>` tag, which M.MenuItem sorta supports.
export class MenuLinkItem extends React.Component<IMenuLinkItemProps> {
  render() {
    const {to, newTab, ...menuItemProps} = this.props
    return (
      <M.MenuItem
        tabIndex={0}
        tag="a"
        target={newTab ? '_blank' : undefined}
        href={typeof to === 'string' ? to : to.pathname}
        onClick={e => {
          // copying react-router's Link's behavior here
          if (
            !e.defaultPrevented && // onClick prevented default
            e.button === 0 && // ignore everything but left clicks
            !newTab && // let browser handle "target=_blank" etc.
            !isModifiedEvent(e) // ignore clicks with modifier keys
          ) {
            e.preventDefault()
            navigateTo(to)
          }
        }}
        onKeyDown={e => {
          // emulating what `M.MenuItem`'s default implementation would do here in onKeyDown
          // since we want to call our own item selection code instead of theirs
          if (e.key !== 'Enter' && e.key !== ' ') {
            return
          }

          // let browser handle "target=_blank" etc.
          if (newTab) {
            return
          }

          e.preventDefault()
          navigateTo(to)
        }}
        {...menuItemProps}
      >
        {this.props.children}
      </M.MenuItem>
    )
  }
}

export interface IMenuExternalLinkItemProps extends M.MenuItemProps<any> {
  href: string
  newTab?: boolean
}

// Like `MenuLinkItem` except for external links.
export class MenuExternalLinkItem extends React.Component<
  IMenuExternalLinkItemProps
> {
  render() {
    const {newTab, ...menuItemProps} = this.props
    return (
      <M.MenuItem
        tabIndex={0}
        tag="a"
        target={newTab ? '_blank' : undefined}
        {...menuItemProps}
      >
        {this.props.children}
      </M.MenuItem>
    )
  }
}
