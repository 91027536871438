import {MD5} from 'object-hash'
import {TGamePosition} from '~/api/generated/types/common'
import {TGameData} from '~/api/generated/types/common'
import {DataBackedModel} from '~/models/base'
import GamePosition, {
  PlayerGamePosition,
  SpectatorGamePosition,
} from '~/models/game/GamePosition'
import PastGamePosition from '~/models/game/PastGamePosition'
import Player from '~/models/game/Player'
import {safely} from '~/utils'
import * as NEL from '~/utils/NEList'

type PublicMembersFromApi = Pick<TGameData, 'gameId'>

const gamePositionDataToGamePosition = (
  gameData: TGameData,
  currentUserId: string
) => (gamePosition: TGamePosition) =>
  GamePosition.build(gamePosition, gameData.players, currentUserId)

export default class GameData
  extends DataBackedModel<TGameData>
  implements PublicMembersFromApi {
  readonly currentGamePosition: GamePosition
  readonly playerGamePosition: PlayerGamePosition | undefined
  readonly spectatorGamePosition: SpectatorGamePosition | undefined
  readonly gameId: string
  readonly currentUserId: string
  readonly pastGamePositions: NEL.NEList<PastGamePosition> | undefined
  readonly winner?: Player

  // MD5 of the API data, better than `currentGamePosition.time` for determining if anything
  // has changed.
  readonly hash: string

  static hash(data: TGameData): string {
    return MD5(data)
  }

  constructor(
    data: TGameData,
    currentUserId: string,
    enableGameLog: boolean,
    precomputedHash?: string
  ) {
    super(data)
    this.hash = precomputedHash ?? GameData.hash(data)
    this.gameId = data.gameId
    this.currentUserId = currentUserId

    const createGamePosition = gamePositionDataToGamePosition(data, currentUserId)

    this.currentGamePosition = createGamePosition(data.currentGamePosition)
    this.playerGamePosition =
      this.currentGamePosition instanceof PlayerGamePosition
        ? this.currentGamePosition
        : undefined
    this.spectatorGamePosition =
      this.currentGamePosition instanceof SpectatorGamePosition
        ? this.currentGamePosition
        : undefined

    if (enableGameLog) {
      // NB: no longer sorting these here since we store them in a Map in Redux.
      // they need to be sorted when they are read from Redux instead
      this.pastGamePositions = NEL.createSafe(
        data.pastGamePositions.map(
          pgp => new PastGamePosition(pgp, data.players, currentUserId)
        )
      )
    } else {
      this.pastGamePositions = undefined
    }

    this.winner = safely(data.winCondition, wc =>
      this.currentGamePosition.players.find(p => p.id === wc.winnerId)
    )
  }
}
