import Portfolio from '~/models/game/Portfolio'
import PortfolioNeighborhood from '~/models/game/PortfolioNeighborhood'
import StandardPortfolioNeighborhood from '~/models/game/PortfolioNeighborhoods/StandardPortfolioNeighborhood'
import {AnyCard} from '~/models/game/types'

export const pnForIdThrows = (portfolio: Portfolio) => (
  pnId: string
): PortfolioNeighborhood => {
  const neighborhood = portfolio.neighborhoods.find(pn => pn.id === pnId)
  if (!neighborhood) {
    const msg = `PortfolioNeighborhood could not be found by id: ${pnId}`
    console.error(msg, portfolio)
    throw new Error(msg)
  }
  return neighborhood
}

export const standardPNForIdThrows = (portfolio: Portfolio) => (
  pnId: string
): StandardPortfolioNeighborhood => {
  const p = pnForIdThrows(portfolio)(pnId)
  if (!(p instanceof StandardPortfolioNeighborhood)) {
    const msg = `PortfolioNeighborhood found by id: ${pnId} was not a Standard neighborhood`
    console.error(msg, portfolio)
    throw new Error(msg)
  }
  return p
}

export const valueOfCards = (cards: AnyCard[]): number =>
  cards.reduce((acc, c) => acc + (c.value ?? 0), 0)
