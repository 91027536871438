import {faChevronDown} from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'
import React from 'react'
import Gravatar from '~/components/Gravatar/stateless'
import StyleableDropdown, {
  TStyleableDropdownChoices,
} from '~/components/StyleableDropdown'
import {shadowStyleToClass} from '~/design-system/ShadowUtils'
import User from '~/models/app/User'
import {staticURLs} from '~/routing/sitemap'
import {unreachableCase} from '~/utils'
import {AlternateStateFontAwesomeIcon} from '~/utils/FontAwesome/HoverableFontAwesomeIcon'
import styles from './styles.module.css'

interface IProps {
  user: User
}

export type TSettingsCircleChoice = 'admin' | 'settings' | 'signout'

const SettingsCircleChoices: TStyleableDropdownChoices<TSettingsCircleChoice> = {
  admin: {
    render: () => <span key="admin">Admin</span>,
    type: 'link',
    location: staticURLs.admin.index,
  },
  settings: {
    render: () => <span key="settings">Settings</span>,
    type: 'link',
    location: staticURLs.settings.index,
  },
  signout: {
    render: () => <span key="signout">Sign Out</span>,
    type: 'link',
    location: staticURLs.auth.signout,
  },
}

export default class SettingsCircle extends React.Component<IProps> {
  render() {
    const {user} = this.props

    return (
      <StyleableDropdown<TSettingsCircleChoice>
        classes={{
          buttonWrapper: styles['circle-wrapper'],
          menu: classnames(
            styles['dropdown-menu'],
            shadowStyleToClass('white', true)
          ),
          choice: styles['dropdown-choice'],
        }}
        arrowSettings={undefined}
        onSelection={(s: TSettingsCircleChoice) => {
          switch (s) {
            case 'admin':
            case 'settings':
            case 'signout':
              break
            default: {
              unreachableCase(s)
            }
          }
        }}
        selectedItem={undefined}
        choices={SettingsCircleChoices}
        hiddenChoices={user.admin ? undefined : 'admin'}
        closeOnSelection
        renderButton={({isOpen}) => (
          <>
            <Gravatar email={user.email} size={50} />

            <AlternateStateFontAwesomeIcon
              icon={{
                icon: faChevronDown,
                className: styles.arrow,
                style: {
                  width: '9px',
                  height: '24px',
                },
              }}
              alternateIcon={{
                style: {
                  transform: `rotate(-180deg)`,
                },
              }}
              alternateState={isOpen}
              mergeStylesWhenAlternate
            />
          </>
        )}
      />
    )
  }
}
