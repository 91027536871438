import {PostApi} from '~/api/client'
import APIInvalidCSRFError from '~/api/client/APIError/APIInvalidCSRFError'
import APIError from '~/api/client/APIError/base'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TSignUpParams} from '~/api/generated/types/Signup'
import {TJSONError} from '~/api/types'

export type TSignUpResponse = {}

export default class SignUp extends PostApi<TSignUpParams, TSignUpResponse> {
  protected mockRequestFailure(
    params: TSignUpParams,
    pathPieces: {}
  ): TJSONError | null {
    if (params.password === 'failfailfail') {
      return {errors: {password: [`That password isn't right.`]}}
    }

    return super.mockRequestFailure(params, pathPieces)
  }

  protected getMockResponse(params: TSignUpParams): TSignUpResponse {
    return {}
  }

  protected getPath() {
    return serverEndpoints.signup()
  }

  protected transformResponse(r: TSignUpResponse) {
    return r
  }

  protected automaticallyRetry(error: APIError, failedAttempts: number): boolean {
    return error instanceof APIInvalidCSRFError && failedAttempts < 2
  }
}
