import {History} from 'history'
import React from 'react'
import {Switch} from 'react-router-dom'
import {ConnectedRouter} from 'connected-react-router'
import AdminIndexPage from '~/pages/Admin/Index'
import AdminSignupsPage from '~/pages/Admin/SignUps'
import SignInPage from '~/pages/Auth/SignIn'
import SignOutPage from '~/pages/Auth/SignOut'
import SignUpPage from '~/pages/Auth/SignUp'
import DashboardPage from '~/pages/Dashboard'
import GameLobbyPage from '~/pages/GameLobby'
import GameLobbyV2Page from '~/pages/GameLobbyV2'
import HomePage from '~/pages/Home'
import NotFound from '~/pages/NotFound'
import SettingsPage from '~/pages/Settings'
import WaitingRoomPage from '~/pages/WaitingRoom'
import {dynamicURLs, staticURLs} from '~/routing/sitemap'
import * as Route from '~/routing/Route'

const Routes = ({history}: {history: History}) => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route.Simple exact path={staticURLs.home} component={HomePage} />

      {/* Auth */}
      <Route.NotSignedIn
        exact
        path={staticURLs.auth.signin}
        component={SignInPage}
      />
      <Route.NotSignedIn
        exact
        path={staticURLs.auth.signup}
        component={SignUpPage}
      />
      <Route.Simple exact path={staticURLs.auth.signout} component={SignOutPage} />

      {/* Dashboard */}
      <Route.SignedIn exact path={staticURLs.dashboard} component={DashboardPage} />
      <Route.SignedIn
        exact
        path={staticURLs.settings.index}
        component={SettingsPage}
      />

      {/* Gameplay */}
      <Route.SignedIn
        exact
        path={dynamicURLs.game.waitingRoom({gameId: ':gameId'})}
        component={WaitingRoomPage}
      />
      <Route.SignedIn
        exact
        path={dynamicURLs.game.lobby({gameId: ':gameId'})}
        component={GameLobbyPage}
      />
      <Route.SignedIn
        exact
        path={dynamicURLs.game.lobbyV2({gameId: ':gameId'})}
        component={GameLobbyV2Page}
      />

      {/* Admin */}
      <Route.Admin exact path={staticURLs.admin.index} component={AdminIndexPage} />
      <Route.Admin
        exact
        path={staticURLs.admin.signups}
        component={AdminSignupsPage}
      />

      {/* catch-all route - 404 */}
      <Route.Simple component={NotFound} />
    </Switch>
  </ConnectedRouter>
)

export default Routes
