import {
  TCardData,
  TDualPropertyCardData,
  TLiquidatedCardData,
  TMajorActionPassGoData,
  TMajorActionDirectionalRentData,
  TMajorActionUniversalRentData,
  TMajorActionDoubleTheRentData,
  TMajorActionSlyDealData,
  TMajorActionForcedDealData,
  TMajorActionDealBreakerData,
  TMajorActionDebtCollectorData,
  TMajorActionBirthdayData,
  TMajorActionPlayAsCashData,
  TMajorActionPlayToNeighborhoodData,
  TMajorActionDiscardData,
  TMajorActionChangeNeighborhoodData,
  TPortfolioNeighborhoodData,
  TPropertyCardData,
  TPropertyWildCardData,
  TPropertyWildCardPortfolioNeighborhoodData,
  TResidenceCardData,
  TStandardPortfolioNeighborhoodData,
  TStandardPropertyCardData,
  TMajorActionData,
  TMinorActionDataPayRent,
  TMinorActionDataPayOther,
  TMinorActionDataAcceptForcedDeal,
  TMinorActionDataAcceptSlyDeal,
  TMinorActionDataAcceptDealBreaker,
  TMinorActionDataJustSayNo,
  TMinorActionPromptDataPayRent,
  TMinorActionPromptDataForcedDeal,
  TMinorActionPromptDataJustSayNo,
  TMinorActionPromptDataDebtCollectorBirthday,
  TMinorActionPromptDataDealBreaker,
  TMinorActionPromptDataSlyDeal,
  TMinorActionPromptData,
  TDirectionalRentCardData,
  TUniversalRentCardData,
  TSlyDealCardData,
  TBirthdayCardData,
  TDealBreakerCardData,
  TDebtCollectorCardData,
  TDoubleTheRentCardData,
  TForcedDealCardData,
  TPassGoCardData,
  TCashCardData,
  TMajorActionDataThatAllowsMinorAction,
  TLastRentThisVisit,
  TMajorActionResidenceToCashData,
} from '~/api/generated/types/common'
import {
  cardClass,
  TBetterMinorActionData,
  TBetterMinorActionDataAcquiesce,
  TJustSayNoCardData,
  TNonRentActionCardData,
  TPlayableCardData,
} from '~/game/types'
import ActionCard, {
  BirthdayCard,
  DealBreakerCard,
  DebtCollectorCard,
  DoubleTheRentCard,
  ForcedDealCard,
  JustSayNoCard,
  PassGoCard,
  SlyDealCard,
} from '~/models/game/Cards/ActionCard'
import Card from '~/models/game/Card'
import CashCard from '~/models/game/Cards/CashCard'
import DualPropertyCard from '~/models/game/Cards/DualPropertyCard'
import MajorAction from '~/models/game/MajorAction'
import MajorActionBirthday from '~/models/game/MajorActions/MajorActionBirthday'
import MajorActionChangeNeighborhood from '~/models/game/MajorActions/MajorActionChangeNeighborhood'
import MajorActionDealBreaker from '~/models/game/MajorActions/MajorActionDealBreaker'
import MajorActionDebtCollector from '~/models/game/MajorActions/MajorActionDebtCollector'
import MajorActionDirectionalRent from '~/models/game/MajorActions/MajorActionDirectionalRent'
import MajorActionDiscard from '~/models/game/MajorActions/MajorActionDiscard'
import MajorActionDoubleTheRent from '~/models/game/MajorActions/MajorActionDoubleTheRent'
import MajorActionForcedDeal from '~/models/game/MajorActions/MajorActionForcedDeal'
import MajorActionPassGo from '~/models/game/MajorActions/MajorActionPassGo'
import MajorActionRentBase from '~/models/game/MajorActions/MajorActionRentBase'
import MajorActionPlayAsCash from '~/models/game/MajorActions/MajorActionPlayAsCash'
import MajorActionPlayToNeighborhood from '~/models/game/MajorActions/MajorActionPlayToNeighborhood'
import MajorActionResidenceToCash from '~/models/game/MajorActions/MajorActionResidenceToCash'
import MajorActionSlyDeal from '~/models/game/MajorActions/MajorActionSlyDeal'
import MajorActionUniversalRent from '~/models/game/MajorActions/MajorActionUniversalRent'
import MinorAction from '~/models/game/MinorAction'
import MinorActionPrompt from '~/models/game/MinorActionPrompt'
import MinorActionPromptDealBreaker from '~/models/game/MinorActionPrompts/MinorActionPromptDealBreaker'
import MinorActionPromptForcedDeal from '~/models/game/MinorActionPrompts/MinorActionPromptForcedDeal'
import MinorActionPromptJustSayNo from '~/models/game/MinorActionPrompts/MinorActionPromptJustSayNo'
import MinorActionPromptPayOther from '~/models/game/MinorActionPrompts/MinorActionPromptPayOther'
import MinorActionPromptPayRent from '~/models/game/MinorActionPrompts/MinorActionPromptPayRent'
import MinorActionPromptSlyDeal from '~/models/game/MinorActionPrompts/MinorActionPromptSlyDeal'
import MinorActionAcceptDealBreaker from '~/models/game/MinorActions/MinorActionAcceptDealBreaker'
import MinorActionAcceptForcedDeal from '~/models/game/MinorActions/MinorActionAcceptForcedDeal'
import MinorActionAcceptSlyDeal from '~/models/game/MinorActions/MinorActionAcceptSlyDeal'
import MinorActionAcquiesce from '~/models/game/MinorActions/MinorActionAcquiesce'
import MinorActionJustSayNo from '~/models/game/MinorActions/MinorActionJustSayNo'
import MinorActionPayOther from '~/models/game/MinorActions/MinorActionPayOther'
import MinorActionPayRent from '~/models/game/MinorActions/MinorActionPayRent'
import Player from '~/models/game/Player'
import Portfolio from '~/models/game/Portfolio'
import PortfolioNeighborhood from '~/models/game/PortfolioNeighborhood'
import PropertyCard from '~/models/game/Cards/PropertyCard'
import PropertyWildCard from '~/models/game/Cards/PropertyWildCard'
import {DirectionalRentCard, UniversalRentCard} from '~/models/game/Cards/RentCard'
import ResidenceCard from '~/models/game/Cards/ResidenceCard'
import StandardPortfolioNeighborhood from '~/models/game/PortfolioNeighborhoods/StandardPortfolioNeighborhood'
import StandardPropertyCard from '~/models/game/Cards/StandardPropertyCard'
import {
  AnyActionCard,
  AnyMinorActionPrompt,
  AnyLiquidatedCard,
  MajorActionAllowsMinorActions,
  AnyPlayableCard,
  AnyPropertyCard,
} from '~/models/game/types'
import WildCardPortfolioNeighborhood from '~/models/game/PortfolioNeighborhoods/WildCardPortfolioNeighborhood'
import {unreachableCase} from '~/utils'

/* eslint-disable no-redeclare */
function cardFactory(c: TNonRentActionCardData): AnyActionCard
function cardFactory(c: TCashCardData): CashCard
function cardFactory(c: TDualPropertyCardData): DualPropertyCard
function cardFactory(c: TPropertyWildCardData): PropertyWildCard
function cardFactory(c: TStandardPropertyCardData): StandardPropertyCard
function cardFactory(c: TResidenceCardData): ResidenceCard
function cardFactory(c: TPlayableCardData): AnyPlayableCard
function cardFactory(c: TDirectionalRentCardData): DirectionalRentCard
function cardFactory(c: TUniversalRentCardData): UniversalRentCard
function cardFactory(c: TLiquidatedCardData): AnyLiquidatedCard
function cardFactory(
  c: TDualPropertyCardData | TPropertyWildCardData
): DualPropertyCard | PropertyWildCard
function cardFactory(
  c: TDirectionalRentCardData | TUniversalRentCardData
): UniversalRentCard | DirectionalRentCard
function cardFactory(
  c: TLiquidatedCardData | TPropertyCardData
): AnyLiquidatedCard | AnyPropertyCard
function cardFactory(c: TCardData): Card<any>
function cardFactory<C extends TCardData>(c: C): Card<C> {
  const ct = cardClass[c.type]
  switch (ct) {
    case 'action':
      // all this casting boils down to TS being lame, it can't narrow type parameters like C like we want here
      // https://github.com/microsoft/TypeScript/issues/13995
      return actionCardFactory(c as TNonRentActionCardData)
    case 'cash':
      return new CashCard(c as TCashCardData) as any
    case 'dualProperty':
      return new DualPropertyCard(c as TDualPropertyCardData) as any
    case 'propertyWildCard':
      return new PropertyWildCard(c as TPropertyWildCardData) as any
    case 'standardProperty':
      return new StandardPropertyCard(c as TStandardPropertyCardData) as any
    case 'rent':
      if (c.type === 'directionalRent') {
        return new DirectionalRentCard(c as TDirectionalRentCardData) as any
      } else {
        return new UniversalRentCard(c as TUniversalRentCardData) as any
      }
    case 'residence':
      return new ResidenceCard(c as TResidenceCardData) as any
    default:
      return unreachableCase(ct)
  }
}

function actionCardFactory(c: TBirthdayCardData): BirthdayCard
function actionCardFactory(c: TDealBreakerCardData): DealBreakerCard
function actionCardFactory(c: TDebtCollectorCardData): DebtCollectorCard
function actionCardFactory(c: TDoubleTheRentCardData): DoubleTheRentCard
function actionCardFactory(c: TForcedDealCardData): ForcedDealCard
function actionCardFactory(c: TJustSayNoCardData): JustSayNoCard
function actionCardFactory(c: TPassGoCardData): PassGoCard
function actionCardFactory(c: TSlyDealCardData): SlyDealCard
function actionCardFactory(c: TNonRentActionCardData): ActionCard<any>
function actionCardFactory(c: TNonRentActionCardData): ActionCard<any> {
  switch (c.type) {
    case 'birthday':
      return new BirthdayCard(c as TBirthdayCardData)
    case 'dealBreaker':
      return new DealBreakerCard(c as TDealBreakerCardData)
    case 'debtCollector':
      return new DebtCollectorCard(c as TDebtCollectorCardData)
    case 'doubleTheRent':
      return new DoubleTheRentCard(c as TDoubleTheRentCardData)
    case 'forcedDeal':
      return new ForcedDealCard(c as TForcedDealCardData)
    case 'justSayNo':
      return new JustSayNoCard(c as TJustSayNoCardData)
    case 'passGo':
      return new PassGoCard(c as TPassGoCardData)
    case 'slyDeal':
      return new SlyDealCard(c as TSlyDealCardData)
    default:
      return unreachableCase(c.type)
  }
}

function propertyCardFactory(c: TDualPropertyCardData): DualPropertyCard
function propertyCardFactory(c: TPropertyWildCardData): PropertyWildCard
function propertyCardFactory(c: TStandardPropertyCardData): StandardPropertyCard
function propertyCardFactory(
  c: TStandardPropertyCardData | TDualPropertyCardData | TPropertyWildCardData
): StandardPropertyCard | DualPropertyCard | PropertyWildCard
function propertyCardFactory<C extends TPropertyCardData>(c: C): PropertyCard<C> {
  switch (c.type) {
    case 'wildUtilityAndRailroad':
    case 'wildRedAndYellow':
    case 'wildPinkAndOrange':
    case 'wildLightBlueAndRailroad':
    case 'wildLightBlueAndBrown':
    case 'wildGreenAndRailroad':
    case 'wildDarkBlueAndGreen':
      return new DualPropertyCard(c as TDualPropertyCardData) as any
    case 'propertyWildCard':
      return new PropertyWildCard(c as TPropertyWildCardData) as any
    default:
      return new StandardPropertyCard(c as TStandardPropertyCardData) as any
  }
}

function neighborhoodCardFactory(
  c: TPropertyCardData | TResidenceCardData
): PropertyCard<any> | ResidenceCard {
  switch (c.type) {
    case 'house':
    case 'hotel':
      return new ResidenceCard(c)
    default:
      return propertyCardFactory(c)
  }
}

function portfolioNeighborhoodFactory(
  d: TStandardPortfolioNeighborhoodData
): StandardPortfolioNeighborhood
function portfolioNeighborhoodFactory(
  d: TPropertyWildCardPortfolioNeighborhoodData
): WildCardPortfolioNeighborhood
function portfolioNeighborhoodFactory(
  d: TPortfolioNeighborhoodData
): PortfolioNeighborhood
function portfolioNeighborhoodFactory(
  d: TPortfolioNeighborhoodData
): PortfolioNeighborhood {
  switch (d.neighborhood) {
    case 'alone':
      return new WildCardPortfolioNeighborhood(d)
    default:
      return new StandardPortfolioNeighborhood(d)
  }
}

// DoubleTheRent makes things complicated. I could store lastRentThisVisit inside Portfolio
// to avoid this, since that is the only card that needs more than the current Portfolio to figure itself out,
// but that didn't seem right from a data organization perspective. We'll see how this plays out.
type TMajorActionFactoryExtraData = {
  lastRentThisVisit?: TLastRentThisVisit
}

function majorActionFactory(
  ad: TMajorActionPassGoData,
  portfolio: Portfolio,
  extraData: TMajorActionFactoryExtraData
): MajorActionPassGo
function majorActionFactory(
  ad: TMajorActionDirectionalRentData,
  portfolio: Portfolio,
  extraData: TMajorActionFactoryExtraData
): MajorActionDirectionalRent
function majorActionFactory(
  ad: TMajorActionUniversalRentData,
  portfolio: Portfolio,
  extraData: TMajorActionFactoryExtraData
): MajorActionUniversalRent
function majorActionFactory(
  ad: TMajorActionDoubleTheRentData,
  portfolio: Portfolio,
  extraData: TMajorActionFactoryExtraData
): MajorActionDoubleTheRent
function majorActionFactory(
  ad: TMajorActionSlyDealData,
  portfolio: Portfolio,
  extraData: TMajorActionFactoryExtraData
): MajorActionSlyDeal
function majorActionFactory(
  ad: TMajorActionForcedDealData,
  portfolio: Portfolio,
  extraData: TMajorActionFactoryExtraData
): MajorActionForcedDeal
function majorActionFactory(
  ad: TMajorActionDealBreakerData,
  portfolio: Portfolio,
  extraData: TMajorActionFactoryExtraData
): MajorActionDealBreaker
function majorActionFactory(
  ad: TMajorActionDebtCollectorData,
  portfolio: Portfolio,
  extraData: TMajorActionFactoryExtraData
): MajorActionDebtCollector
function majorActionFactory(
  ad: TMajorActionBirthdayData,
  portfolio: Portfolio,
  extraData: TMajorActionFactoryExtraData
): MajorActionBirthday
function majorActionFactory(
  ad: TMajorActionPlayAsCashData,
  portfolio: Portfolio,
  extraData: TMajorActionFactoryExtraData
): MajorActionPlayAsCash
function majorActionFactory(
  ad: TMajorActionPlayToNeighborhoodData,
  portfolio: Portfolio,
  extraData: TMajorActionFactoryExtraData
): MajorActionPlayToNeighborhood
function majorActionFactory(
  ad: TMajorActionDiscardData,
  portfolio: Portfolio,
  extraData: TMajorActionFactoryExtraData
): MajorActionDiscard
function majorActionFactory(
  ad: TMajorActionChangeNeighborhoodData,
  portfolio: Portfolio,
  extraData: TMajorActionFactoryExtraData
): MajorActionChangeNeighborhood
function majorActionFactory(
  ad: TMajorActionResidenceToCashData,
  portfolio: Portfolio,
  extraData: TMajorActionFactoryExtraData
): MajorActionResidenceToCash
function majorActionFactory(
  ad: TMajorActionDataThatAllowsMinorAction,
  portfolio: Portfolio,
  extraData: TMajorActionFactoryExtraData
): MajorActionAllowsMinorActions
function majorActionFactory(
  ad: TMajorActionData,
  portfolio: Portfolio,
  extraData: TMajorActionFactoryExtraData
): MajorAction
function majorActionFactory(
  ad: TMajorActionData,
  portfolio: Portfolio,
  extraData: TMajorActionFactoryExtraData
): MajorAction {
  switch (ad.type) {
    case 'birthday':
      return new MajorActionBirthday(ad)
    case 'dealBreaker':
      return new MajorActionDealBreaker(ad)
    case 'debtCollector':
      return new MajorActionDebtCollector(ad)
    case 'doubleTheRent':
      if (!extraData.lastRentThisVisit) {
        throw new Error(
          'lastRentThisVisit is missing when creating a MajorActionDoubleTheRent in majorActionFactory'
        )
      }
      return new MajorActionDoubleTheRent(ad, extraData.lastRentThisVisit)
    case 'forcedDeal':
      return new MajorActionForcedDeal(ad)
    case 'passGo':
      return new MajorActionPassGo(ad)
    case 'directionalRent':
      return new MajorActionDirectionalRent(ad, portfolio)
    case 'rentDarkBlueGreen':
    case 'rentLightBlueBrown':
    case 'rentPinkOrange':
    case 'rentRedYellow':
    case 'rentUtilityRailroad':
      return new MajorActionUniversalRent(ad, portfolio)
    case 'slyDeal':
      return new MajorActionSlyDeal(ad)
    case 'playAsCash':
      return new MajorActionPlayAsCash(ad)
    case 'playToNeighborhood':
      return new MajorActionPlayToNeighborhood(ad)
    case 'discard':
      return new MajorActionDiscard(ad)
    case 'changeNeighborhood':
      return new MajorActionChangeNeighborhood(ad)
    case 'residenceToCash':
      return new MajorActionResidenceToCash(ad)
    default:
      return unreachableCase(ad)
  }
}

function minorActionFactory(ad: TMinorActionDataPayRent): MinorActionPayRent
function minorActionFactory(
  ad: TMinorActionDataAcceptForcedDeal
): MinorActionAcceptForcedDeal
function minorActionFactory(
  ad: TMinorActionDataAcceptSlyDeal
): MinorActionAcceptSlyDeal
function minorActionFactory(
  ad: TMinorActionDataAcceptDealBreaker
): MinorActionAcceptDealBreaker
function minorActionFactory(ad: TMinorActionDataPayOther): MinorActionPayOther
function minorActionFactory(ad: TMinorActionDataJustSayNo): MinorActionJustSayNo
function minorActionFactory(
  ad: TBetterMinorActionDataAcquiesce
): MinorActionAcquiesce
function minorActionFactory(
  ad: TBetterMinorActionData
): MinorAction<TBetterMinorActionData> // not sure if this is right
function minorActionFactory(ad: TBetterMinorActionData): MinorAction<any> {
  switch (ad.type) {
    case 'birthday':
    case 'debtCollector':
      return new MinorActionPayOther(ad)
    case 'dealBreaker':
      return new MinorActionAcceptDealBreaker(ad)
    case 'forcedDeal':
      return new MinorActionAcceptForcedDeal(ad)
    case 'doubleTheRent':
    case 'directionalRent':
    case 'universalRent':
      return new MinorActionPayRent(ad)
    case 'slyDeal':
      return new MinorActionAcceptSlyDeal(ad)
    case 'justSayNo':
      return new MinorActionJustSayNo(ad)
    case 'acquiesce':
      return new MinorActionAcquiesce(ad)
    default:
      return unreachableCase(ad)
  }
}

type TMinorActionPromptFactoryExtraData = {
  majorActor: Player
  minorActor: Player
}

function minorActionPromptFactory(
  ad: TMinorActionPromptDataPayRent,
  majorAction: MajorActionRentBase,
  extraData: TMinorActionPromptFactoryExtraData
): MinorActionPromptPayRent
function minorActionPromptFactory(
  ad: TMinorActionPromptDataForcedDeal,
  majorAction: MajorActionForcedDeal,
  extraData: TMinorActionPromptFactoryExtraData
): MinorActionPromptForcedDeal
function minorActionPromptFactory(
  ad: TMinorActionPromptDataSlyDeal,
  majorAction: MajorActionSlyDeal,
  extraData: TMinorActionPromptFactoryExtraData
): MinorActionPromptSlyDeal
function minorActionPromptFactory(
  ad: TMinorActionPromptDataDealBreaker,
  majorAction: MajorActionDealBreaker,
  extraData: TMinorActionPromptFactoryExtraData
): MinorActionPromptDealBreaker
function minorActionPromptFactory(
  ad: TMinorActionPromptDataDebtCollectorBirthday,
  majorAction: MajorActionDebtCollector | MajorActionBirthday,
  extraData: TMinorActionPromptFactoryExtraData
): MinorActionPromptPayOther
function minorActionPromptFactory(
  ad: TMinorActionPromptDataJustSayNo,
  majorAction: MajorAction, // any major action of yours was JSN'd, you can JSN back
  extraData: TMinorActionPromptFactoryExtraData
): MinorActionPromptJustSayNo
function minorActionPromptFactory(
  ad: TMinorActionPromptData,
  majorAction: MajorAction,
  extraData: TMinorActionPromptFactoryExtraData
): AnyMinorActionPrompt
function minorActionPromptFactory<
  MI extends TMinorActionPromptData,
  MJ extends MajorAction
>(
  ad: MI,
  majorAction: MJ,
  extraData: TMinorActionPromptFactoryExtraData
): MinorActionPrompt<MI> {
  // all this casting boils down to TS being lame, it can't narrow type parameters like C like we want here
  // https://github.com/microsoft/TypeScript/issues/13995
  switch (ad.type) {
    case 'birthday':
    case 'debtCollector':
      return new MinorActionPromptPayOther(
        ad as TMinorActionPromptDataDebtCollectorBirthday,
        extraData.majorActor,
        extraData.minorActor
      ) as any
    case 'dealBreaker':
      return new MinorActionPromptDealBreaker(
        ad as TMinorActionPromptDataDealBreaker,
        extraData.majorActor,
        extraData.minorActor
      ) as any
    case 'forcedDeal':
      return new MinorActionPromptForcedDeal(
        ad as TMinorActionPromptDataForcedDeal,
        extraData.majorActor,
        extraData.minorActor
      ) as any
    case 'doubleTheRent':
    case 'directionalRent':
    case 'universalRent':
      return new MinorActionPromptPayRent(
        ad as TMinorActionPromptDataPayRent,
        majorAction as any,
        extraData.majorActor,
        extraData.minorActor
      ) as any
    case 'slyDeal':
      return new MinorActionPromptSlyDeal(
        ad as TMinorActionPromptDataSlyDeal,
        extraData.majorActor,
        extraData.minorActor
      ) as any
    case 'justSayNo':
      return new MinorActionPromptJustSayNo(
        ad as TMinorActionPromptDataJustSayNo,
        extraData.majorActor,
        extraData.minorActor
      ) as any
    default:
      return unreachableCase(ad.type)
  }
}
/* eslint-enable no-redeclare */

export {
  cardFactory,
  propertyCardFactory,
  portfolioNeighborhoodFactory,
  neighborhoodCardFactory,
  majorActionFactory,
  minorActionFactory,
  minorActionPromptFactory,
}
