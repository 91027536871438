import classnames from 'classnames'
import {css} from 'emotion'
import * as React from 'react'
import Gravatar from '~/components/Gravatar/stateless'
import SquareBorderAnimation from '~/components/SquareBorderAnimation/stateless'
import {px} from '~/components/utils'
import {TRuleset} from '~/game/ruleset'
import {TSkin} from '~/game/skins'
import GameData from '~/models/game/GameData'
import {SpectatorGamePosition} from '~/models/game/GamePosition'
import Player from '~/models/game/Player'
import ExposedPlayer from '~/models/game/Players/ExposedPlayer'
import CardImage, {cardHeightFromWidth} from '~/pages/GameLobby/GameCard/stateless'
import {sizeData, TGameLobbySize} from '~/pages/GameLobby/types'
import styles from './styles.module.css'

interface IProps {
  gameData: GameData
  spectatorGamePosition: SpectatorGamePosition
  gameLobbySize: TGameLobbySize
  skin: TSkin
  rules: TRuleset
  classNames?: {
    wrapper?: string
  }
}

export default class SpectatedHands extends React.Component<IProps> {
  render() {
    const {skin, gameLobbySize, spectatorGamePosition, classNames} = this.props

    const cardWidthPx = sizeData[gameLobbySize].hand.cardWidth * 0.8
    const cardHeightPx = cardHeightFromWidth(cardWidthPx)
    const xOffsetPct = 1.1
    const yOffsetPct = 0

    const exposedPlayers = spectatorGamePosition.players.filter(
      (p: Player): p is ExposedPlayer => p instanceof ExposedPlayer
    )

    return exposedPlayers.map(player => {
      const cards = player.hand.cards
      const cardsWrapperStyle = {
        width: px(cardWidthPx + (cards.length - 1) * xOffsetPct * cardWidthPx),
        height: px(cardHeightPx + (cards.length - 1) * yOffsetPct * cardHeightPx),
      }

      const isPlayersMajorTurn =
        this.props.gameData.currentGamePosition.majorActorPlayerId === player.id
      const isPlayersMinorTurn =
        this.props.gameData.currentGamePosition.minorActorPlayer?.id === player.id

      return (
        <div
          key={player.id}
          className={classnames(
            styles.handWrapper,
            classNames?.wrapper,
            css`
              min-width: ${cardsWrapperStyle.width};
            `
          )}
        >
          <SquareBorderAnimation
            sizePx={30}
            clipDistancePct={0.1}
            pathWidthPx={2.5}
            color={isPlayersMajorTurn ? 'poker-green' : 'red'}
            hideAnimation={!isPlayersMajorTurn && !isPlayersMinorTurn}
            speedSec={isPlayersMajorTurn ? 7 : 4}
            className={styles.squareBorderAnim}
          >
            <Gravatar email={player.email} size={30} />
          </SquareBorderAnimation>
          <div
            className={classnames(
              styles.cardsWrapper,
              css`
                width: ${cardsWrapperStyle.width};
                height: ${cardsWrapperStyle.height};
                min-height: ${cardsWrapperStyle.height};
              `
            )}
          >
            {cards.map((c, i) => (
              <CardImage
                key={c.id}
                model={c}
                skin={skin}
                invert={false}
                draggable={false}
                className={classnames(
                  styles.cardImage,
                  css`
                    width: ${cardWidthPx}px;
                    height: ${cardHeightPx}px;
                    left: ${xOffsetPct * i * cardWidthPx}px;
                    top: ${yOffsetPct * i * cardHeightPx}px;
                  `
                )}
              />
            ))}
          </div>
        </div>
      )
    })
  }
}
