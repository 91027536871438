import classnames from 'classnames'
import {css} from 'emotion'
import * as R from 'ramda'
import * as React from 'react'
import {DSTextDiv} from '~/design-system/DSText'
import {TRuleset} from '~/game/ruleset'
import {TSkin} from '~/game/skins'
import Card from '~/models/game/Card'
import Decagon from '~/components/Shapes/Decagon'
import PlayerPortfolio from '~/pages/GameLobby/GameBoard/Portfolio'
import CardImage, {
  CardBackImage,
  cardHeightFromWidth,
} from '~/pages/GameLobby/GameCard/stateless'
import {sizeData, TGameLobbySize} from '~/pages/GameLobby/types'
import GameData from '~/models/game/GameData'
import Dodecagon from '~/components/Shapes/Dodecagon'
import Octagon from '~/components/Shapes/Octagon'
import dealTunaSrc from '~/img/art/dealtuna.png'
import {safelyOr} from '~/utils'
import {rotateLeftUntil} from '~/utils/List'
import styles from './styles.module.css'

export interface IGameBoardUIProps {
  gameData: GameData
  classNames?: {
    wrapper?: string
    board?: string
  }

  size: TGameLobbySize
  rules: TRuleset
  skin: TSkin

  clickableCardIds: Set<string>
  onCardClicked: (card: Card<any>, event: React.MouseEvent<any>) => void
  selectedCardIds: Set<string>
}

export default class GameBoardUI extends React.Component<IGameBoardUIProps> {
  render() {
    const {
      classNames,
      gameData,
      size: gameLobbySize,
      skin,
      clickableCardIds,
      onCardClicked,
      selectedCardIds,
    } = this.props
    const {currentGamePosition} = gameData
    const rotatedPlayerList = safelyOr(
      gameData.playerGamePosition,
      pgp =>
        rotateLeftUntil(
          p => p.id === pgp.currentPlayer.id,
          currentGamePosition.players
        ),
      currentGamePosition.players
    )

    const {size, cardWidth} = sizeData[gameLobbySize].board
    const cardHeight = cardHeightFromWidth(cardWidth)

    const playerCount = currentGamePosition.portfolios.length
    const shapes = false
    const octagon =
      (shapes && playerCount <= 4 && playerCount % 3) || playerCount % 8 === 0
    const decagon = shapes && playerCount % 5 === 0
    const dodecagon = shapes && !decagon && !octagon

    const centerCardXPaddingPx = 7
    const centerCardYPaddingPx = 7
    const centerCardSpacerPx = 8

    const cardInCenterWidth = cardWidth + centerCardXPaddingPx * 2

    return (
      <div className={classnames(classNames?.wrapper)}>
        {decagon ? (
          <Decagon
            sizePx={size}
            className={classnames(styles.board, classNames?.board)}
          />
        ) : octagon ? (
          <Octagon
            sizePx={size}
            className={classnames(styles.board, classNames?.board)}
          />
        ) : dodecagon ? (
          <Dodecagon
            sizePx={size}
            className={classnames(styles.board, classNames?.board)}
          />
        ) : null}
        <div
          className={classnames(
            styles.cardsInCenter,
            css`
              width: ${cardWidth * 2 +
              centerCardSpacerPx +
              centerCardXPaddingPx * 4}px;
              height: ${cardHeight + centerCardYPaddingPx * 2}px;
            `
          )}
        >
          <DSTextDiv
            size="caption-demi"
            color="white"
            className={styles.cardsInDeckCount}
          >
            {currentGamePosition.cardsInDeck} cards left
          </DSTextDiv>
          <div
            className={classnames(
              styles.cardInCenterWrap,
              css`
                width: ${cardInCenterWidth}px;
              `
            )}
          >
            <div
              style={{position: 'relative', width: cardWidth, height: cardHeight}}
            >
              {R.times(
                i => (
                  <CardBackImage
                    key={i}
                    className={css`
                      width: ${cardWidth}px;
                      left: ${-i * 0.7}px;
                      top: ${-i * 0.7}px;
                      position: absolute;
                    `}
                    skin={skin}
                  />
                ),
                Math.min(currentGamePosition.cardsInDeck, 7)
              )}
            </div>
          </div>
          <div
            className={classnames(
              styles.cardInCenterWrap,
              css`
                margin-left: ${centerCardSpacerPx}px;
                width: ${cardInCenterWidth}px;
              `
            )}
          >
            {currentGamePosition.recentlyPlayedCards[0] && (
              <CardImage
                className={css`
                  width: ${cardWidth}px;
                `}
                model={currentGamePosition.recentlyPlayedCards[0]}
                invert={false}
                skin={skin}
              />
            )}
          </div>
          {gameData.winner && (
            <DSTextDiv
              size="h3"
              className={classnames(
                styles.winnerBanner,
                css`
                  min-width: ${size / 3}px;
                  max-width: ${size / 2}px;
                  min-height: ${cardHeight + centerCardYPaddingPx * 2}px;
                `
              )}
            >
              <img src={dealTunaSrc} style={{height: cardHeight}} />
              <p>{gameData.winner.username} won the game!</p>
            </DSTextDiv>
          )}
        </div>

        {rotatedPlayerList.map((player, i) => {
          const rotationDeg = (i * 360) / playerCount
          return (
            <PlayerPortfolio
              key={player.id}
              cardWidthPx={cardWidth}
              classNames={{
                portfolioRotationWrapper: classnames(
                  styles.portfolioRotationWrapper,
                  css`
                    width: ${size}px;
                    height: ${size}px;
                    transform: rotate(${rotationDeg}deg)
                      translate(0, ${size / 2 - cardHeight * 0.6}px);
                  `
                ),
                portfolioInnerWrapper: css`
                  width: ${size / 2}px;
                `,
              }}
              invertedRotationDeg={-1 * rotationDeg}
              player={player}
              gameData={gameData}
              clickableCardIds={clickableCardIds}
              onCardClicked={onCardClicked}
              selectedCardIds={selectedCardIds}
              skin={skin}
            />
          )
        })}
      </div>
    )
  }
}
