import * as qs from 'query-string'
import environment from '~/config/environment'
import {TTime} from '~/game/types'
import {numberFromQueryStringObj, stringFromPartial} from '~/utils/components'

// for mocks that might return data or not, and the API doesn't have an easily-toggled-for-testing parameter
export type MockResponsePreset = 'empty' | 'partial' | 'complete'

export type MockQueryParam = 'mockDelay' | 'loggedOut' | 'preset' | 'time'

class MockConfig {
  public static readonly enabled: boolean = environment.backendSource === 'mock'
  public static responsePreset: MockResponsePreset | undefined = undefined
  public static loggedOut: boolean = false
  // TODO it would be cool to use localStorage or something for this so we can
  //   update the time w/o adding a button to the UI or something
  public static time: TTime = 0

  /** Overrides the default delay to all mock responses. in milliseconds */
  public static globalDelayMs: number | undefined = undefined

  public static setupMockConfigParams(): void {
    if (!this.enabled) {
      return
    }

    const queryParams = qs.parse(location.search) as Partial<
      Record<MockQueryParam, string>
    >

    this.responsePreset = stringFromPartial(queryParams)(
      'preset'
    ) as MockResponsePreset
    this.globalDelayMs = numberFromQueryStringObj(queryParams)('mockDelay')
    this.loggedOut = !!queryParams.loggedOut
    this.time = numberFromQueryStringObj(queryParams)('time') ?? 0
  }
}

export default MockConfig
