import {TMinorActionPromptDataDealBreaker} from '~/api/generated/types/common'
import Card from '~/models/game/Card'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MinorAction from '~/models/game/MinorAction'
import MinorActionPrompt, {
  TMinorActionPromptUserFacingDescriptionData,
  TMinorActionPromptValidResponseData,
} from '~/models/game/MinorActionPrompt'
import MinorActionAcceptDealBreaker from '~/models/game/MinorActions/MinorActionAcceptDealBreaker'
import Player from '~/models/game/Player'
import {sentenceCase, xVerbY} from '~/utils/language'

export default class MinorActionPromptDealBreaker extends MinorActionPrompt<
  TMinorActionPromptDataDealBreaker
> {
  portfolioNeighborhoodId: string // the dealbroken portfolio

  constructor(
    data: TMinorActionPromptDataDealBreaker,
    majorActor: Player,
    minorActor: Player
  ) {
    super(data, majorActor, minorActor)
    this.portfolioNeighborhoodId = data.portfolioNeighborhoodId
  }

  protected _validResponses(
    data: TMinorActionPromptValidResponseData
  ): MinorAction<any> | MinorAction<any>[] {
    return this.withJustSayNo(
      data,
      new MinorActionAcceptDealBreaker({
        minorActorPlayerId: this.minorActorPlayerId,
        portfolioNeighborhoodId: this.portfolioNeighborhoodId,
        type: 'dealBreaker',
      })
    )
  }

  public userFacingDescription(
    data: TMinorActionPromptUserFacingDescriptionData
  ): string {
    const {gamePosition, tense, skin} = data
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined
    const {majorActor, minorActor} = this

    const {conjugatedVerb, xNoun, yNounPossessive} = xVerbY(
      tense,
      minorActor,
      'respondTo',
      majorActor,
      currentPlayer?.id
    )

    return [
      xNoun === minorActor.username ? xNoun : sentenceCase(xNoun),
      conjugatedVerb,
      yNounPossessive,
      Card.displayNameForCard('dealBreaker', skin),
    ].join(' ')
  }
}
