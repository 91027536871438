import {format} from 'date-fns'
import {css} from 'emotion'
import * as RA from 'ramda-adjunct'
import * as React from 'react'
import classnames from 'classnames'
import {TSubmitMajorActionResponse} from '~/api/generated/types/SubmitMajorAction'
import {TSubmitMinorActionResponse} from '~/api/generated/types/SubmitMinorAction'
import {TTime} from '~/game/types'
import Card from '~/models/game/Card'
import {ForcedDealCard, SlyDealCard} from '~/models/game/Cards/ActionCard'
import InProgressMajorAction from '~/models/game/InProgressMajorAction'
import MajorActionForcedDeal from '~/models/game/MajorActions/MajorActionForcedDeal'
import MajorActionSlyDeal from '~/models/game/MajorActions/MajorActionSlyDeal'
import MinorActionPrompt from '~/models/game/MinorActionPrompt'
import MinorActionPromptJustSayNo from '~/models/game/MinorActionPrompts/MinorActionPromptJustSayNo'
import MinorActionPromptPaySomething from '~/models/game/MinorActionPrompts/MinorActionPromptPaySomething'
import PastGamePosition from '~/models/game/PastGamePosition'
import InProgressMajorActionUI from '~/pages/GameLobby/InProgressMajorActionUI/stateless'
import PendingMinorActionUI from '~/pages/GameLobby/PendingMinorActionUI/stateless'
import {px} from '~/components/utils'
import {TSkin} from '~/game/skins'
import MajorAction from '~/models/game/MajorAction'
import MinorAction from '~/models/game/MinorAction'
import SpectatedHands from '~/pages/GameLobby/SpectatedHands/statelesss'
import {joinElements} from '~/pages/utils'
import {compact, safely, unreachableCase} from '~/utils'
import {isAtEndOfScroll, scrollToBottom} from '~/utils/components'
import * as M from '~/utils/Map'
import {TRuleset} from '~/game/ruleset'
import CurrentPlayerHand from '~/pages/GameLobby/CurrentPlayerHand'
import DSText, {DSTextDiv} from '~/design-system/DSText'
import GameData from '~/models/game/GameData'
import GameBoard from '~/pages/GameLobby/GameBoard'
import {sizeData, TGameLobbySize} from '~/pages/GameLobby/types'
import {NEList} from '~/utils/NEList'
import * as S from '~/utils/Set'
import styles from './styles.module.css'

interface IProps {
  gameData: GameData
  chooseMajorAction: (majorAction: MajorAction) => Promise<any>
  chooseMajorActionSubmitSuccess: (response: TSubmitMajorActionResponse) => void
  chooseMinorAction: (
    minorAction: MinorAction<any>
  ) => Promise<TSubmitMinorActionResponse>
  chooseMinorActionSubmitSuccess: (response: TSubmitMinorActionResponse) => void

  gameLobbySize: TGameLobbySize
  skin: TSkin
  rules: TRuleset

  allPastGamePositions: NEList<PastGamePosition> | undefined
}

interface IState {
  submittingTableMajorAction: boolean
  submittingMinorActionReply: boolean
  clickableCardState: TClickableCardState

  // for getDerivedStateFromProps
  propTime: TTime

  // moved up from CurrentPlayerHand since we want to read it for IClickableCardStatePlaySlyDeal
  currentPlayerHandSelectedCardId?: string
}

export default class GameLobbyUI extends React.Component<IProps, IState> {
  state: IState = {
    submittingTableMajorAction: false,
    submittingMinorActionReply: false,
    propTime: -1,
    clickableCardState: {} as any, // fixed in getDerivedStateFromProps
  }

  static getDerivedStateFromProps(
    nextProps: IProps,
    prevState: IState
  ): Partial<IState> | null {
    if (nextProps.gameData.currentGamePosition.time === prevState.propTime) {
      // // NB: this can cause weirdness if we do any changes to the game
      // // without updating the time value, which so far I've only seen when we
      // // MDC something in the game while it is running. Not even sure we need
      // // to do this effort-saving early return tbh, but i'l leave it for now
      // console.log('skipping clickableCardState update b/c time is equal', {
      //   nextProps,
      //   prevState,
      //   newTime: nextProps.gameData.currentGamePosition.time,
      //   oldTime: prevState.propTime,
      // })
      return null
    }

    let returnVal: Partial<IState> = {
      propTime: nextProps.gameData.currentGamePosition.time,
    }

    if (
      prevState.currentPlayerHandSelectedCardId &&
      !nextProps.gameData.playerGamePosition?.currentPlayerHand.cards.find(
        c => c.id === prevState.currentPlayerHandSelectedCardId
      )
    ) {
      // clear selected card if it gets used
      returnVal.currentPlayerHandSelectedCardId = undefined
    }

    const clickableCardState = determineClickableCards(
      nextProps.gameData,
      nextProps.rules,
      {...prevState, ...returnVal}
    )

    // remember card selection if we already had one in the same mode
    // (mostly here to reset the various states should some of the, say, selected
    // cards no longer be selectable given the new state of the game. not convinced
    // this couldn't be done elsewhere, like in `determineClickableCards`. TODO)
    if (
      prevState.clickableCardState.mode === 'rotateProperty' &&
      clickableCardState.mode === 'rotateProperty' &&
      prevState.clickableCardState.selectedCardId &&
      clickableCardState.clickableCardIds.has(
        prevState.clickableCardState.selectedCardId
      )
    ) {
      returnVal = {
        ...returnVal,
        clickableCardState: {
          ...clickableCardState,
          selectedCardId: prevState.clickableCardState.selectedCardId,
        },
      }
    } else if (
      prevState.clickableCardState.mode === 'payRent' &&
      clickableCardState.mode === 'payRent'
    ) {
      returnVal = {
        ...returnVal,
        clickableCardState: {
          ...clickableCardState,
          selectedCardIds: S.intersection(
            prevState.clickableCardState.selectedCardIds,
            clickableCardState.clickableCardIds
          ),
        },
      }
    } else {
      returnVal = {
        ...returnVal,
        clickableCardState,
      }
    }

    return returnVal
  }

  chooseMinorAction = (
    minorAction: MinorAction<any>
  ): Promise<TSubmitMinorActionResponse> => {
    const {chooseMinorAction} = this.props

    this.setState({submittingMinorActionReply: true})

    // detect errors here, but also pass them on
    const promise = chooseMinorAction(minorAction)
    promise
      .catch(e => {
        console.error('error submitting minor action: ', e)
      })
      .finally(() => this.setState({submittingMinorActionReply: false}))
      .catch(e => undefined)

    return promise
  }

  renderClickableCardStateRotateProperty(
    clickableCardState: IClickableCardStateRotateProperty
  ) {
    const {gameData, chooseMajorAction, skin, rules} = this.props
    const {playerGamePosition} = gameData

    if (!playerGamePosition) {
      return null
    }

    const {currentPlayer} = playerGamePosition

    const availableTableMoves = currentPlayer.availableMovesFromTableByCardId(
      rules,
      playerGamePosition
    )
    const anyAvailableMoves = M.anyPass(v => !!v.length, availableTableMoves)
    const selectedCard = safely(clickableCardState.selectedCardId, scId =>
      currentPlayer.portfolio.allCards().find(c => c.id === scId)
    )

    const heading = ((): string => {
      if (selectedCard) {
        return compact([
          'Available movements for your',
          selectedCard.displayName(skin),
          playerGamePosition.isMyMajorTurn() ? '' : '(once it is your turn)',
        ]).join(' ')
      } else if (anyAvailableMoves) {
        return `Click a movable card you've played to see a list of options here.`
      } else {
        return `(No played cards can be moved right now)`
      }
    })()

    return (
      <div
        className={classnames(
          styles.selectedTableCardActions,
          this.state.submittingTableMajorAction && styles.loading,
          !anyAvailableMoves && styles.inactive,
          playerGamePosition.isMyMajorTurn() && styles.myTurn,
          playerGamePosition.inProgressMajorAction && styles.hidden
        )}
      >
        <DSTextDiv size="body-16" bold>
          {heading}
        </DSTextDiv>
        <ul>
          {M.maybeGet(clickableCardState.selectedCardId, availableTableMoves)?.map(
            action => {
              const desc = action.userFacingDescription({
                gamePosition: playerGamePosition,
                skin,
                tense: 'imperativePrompt',
              })
              // TODO better loading state and error handling / UI for this promise...
              //  aka use the form
              return (
                <DSText
                  size="body-15"
                  tag="li"
                  key={desc}
                  className={styles.actionChoice}
                  onClick={() => {
                    this.setState({submittingTableMajorAction: true})
                    chooseMajorAction(action)
                      .then(r => {
                        this.setState({submittingTableMajorAction: false}, () =>
                          this.props.chooseMajorActionSubmitSuccess(r)
                        )
                      })
                      .catch(e => {
                        console.error('error submitting table major action: ', e)
                        this.setState({submittingTableMajorAction: false})
                      })
                  }}
                >
                  {desc}
                </DSText>
              )
            }
          )}
        </ul>
      </div>
    )
  }

  renderPendingMinorAction(
    clickableCardState: IClickableCardStatePayRent | IClickableCardStateNone
  ) {
    const {gameData, skin, rules} = this.props
    const {playerGamePosition} = gameData

    if (
      !clickableCardState.inProgressMajorAction ||
      !playerGamePosition ||
      (clickableCardState.mode === 'none' &&
        !clickableCardState.pendingMinorActionForMe)
    ) {
      // spectator or nothing to show right now
      return null
    }

    const selectedCardIds =
      clickableCardState.mode === 'payRent'
        ? clickableCardState.selectedCardIds
        : new Set<string>()
    return (
      <PendingMinorActionUI
        chooseMinorAction={this.chooseMinorAction}
        chooseMinorActionSubmitSuccess={this.props.chooseMinorActionSubmitSuccess}
        skin={skin}
        rules={rules}
        playerGamePosition={playerGamePosition}
        inProgressMajorAction={clickableCardState.inProgressMajorAction}
        selectedCardIds={selectedCardIds}
      />
    )
  }

  renderInProgressMajorAction() {
    const {gameData, skin, rules} = this.props
    const {currentGamePosition} = gameData

    return safely(currentGamePosition.inProgressMajorAction, ipma => (
      <InProgressMajorActionUI
        skin={skin}
        rules={rules}
        currentGamePosition={currentGamePosition}
        inProgressMajorAction={ipma}
      />
    ))
  }

  selectCurrentPlayerHandCard = (id: string | undefined) => {
    const {gameData, rules} = this.props

    this.setState(prevState => ({
      currentPlayerHandSelectedCardId: id,
      clickableCardState: determineClickableCards(gameData, rules, {
        ...prevState,
        currentPlayerHandSelectedCardId: id,
      }),
    }))
  }

  renderHandAndChoices({boardWrapperSize}: {boardWrapperSize: number}) {
    const {chooseMajorAction, skin, gameLobbySize, gameData, rules} = this.props
    const {clickableCardState} = this.state

    return (
      <div
        className={classnames(
          styles.currentHandAndTableActions,
          css`
            height: ${px(boardWrapperSize)};
          `
        )}
      >
        {gameData.playerGamePosition && (
          <CurrentPlayerHand
            gameData={gameData}
            playerGamePosition={gameData.playerGamePosition}
            gameLobbySize={gameLobbySize}
            skin={skin}
            rules={rules}
            classNames={{wrapper: styles.currentPlayerHand}}
            chooseMajorAction={chooseMajorAction}
            chooseMajorActionSubmitSuccess={
              this.props.chooseMajorActionSubmitSuccess
            }
            selectedCardId={
              gameData.playerGamePosition.play === 'discardPlay'
                ? undefined
                : this.state.currentPlayerHandSelectedCardId
            }
            selectCard={this.selectCurrentPlayerHandCard}
            clickableCardState={clickableCardState}
          />
        )}
        {gameData.spectatorGamePosition && (
          <SpectatedHands
            gameData={gameData}
            spectatorGamePosition={gameData.spectatorGamePosition}
            gameLobbySize={gameLobbySize}
            skin={skin}
            rules={rules}
          />
        )}

        {this.renderInProgressMajorAction()}
        {(() => {
          switch (clickableCardState.mode) {
            case 'playSlyDeal':
            case 'playForcedDeal':
              return null
            case 'payRent':
            case 'none':
              return this.renderPendingMinorAction(clickableCardState)
            case 'rotateProperty':
              return this.renderClickableCardStateRotateProperty(clickableCardState)
            default:
              return unreachableCase(clickableCardState)
          }
        })()}
      </div>
    )
  }

  cardClicked = (card: Card<any>) => {
    const {clickableCardState} = this.state
    const {gameData} = this.props
    const {playerGamePosition} = gameData

    switch (clickableCardState.mode) {
      case 'payRent':
        this.setState(prevState => {
          if (prevState.clickableCardState.mode !== 'payRent') {
            console.error(
              'clickableCardState mode changed from payRent before setState could modify it',
              prevState,
              clickableCardState
            )
            return null
          }

          return {
            clickableCardState: {
              ...prevState.clickableCardState,
              selectedCardIds: S.toggleMembership(
                card.id,
                prevState.clickableCardState.selectedCardIds
              ),
            },
          }
        })
        break
      case 'rotateProperty':
        this.setState(prevState => {
          if (prevState.clickableCardState.mode !== 'rotateProperty') {
            console.error(
              'clickableCardState mode changed from rotateProperty before setState could modify it',
              prevState,
              clickableCardState
            )
            return null
          }

          return {
            clickableCardState: {
              ...prevState.clickableCardState,
              selectedCardId:
                prevState.clickableCardState.selectedCardId === card.id
                  ? undefined
                  : card.id,
            },
          }
        })
        break
      case 'playSlyDeal':
        this.setState(prevState => {
          if (prevState.clickableCardState.mode !== 'playSlyDeal') {
            console.error(
              'clickableCardState mode changed from slyDeal before setState could modify it',
              prevState,
              clickableCardState
            )
            return null
          }

          return {
            clickableCardState: {
              ...prevState.clickableCardState,
              selectedCardToRequestId:
                prevState.clickableCardState.selectedCardToRequestId === card.id
                  ? undefined
                  : card.id,
            },
          }
        })
        break
      case 'playForcedDeal':
        this.setState(prevState => {
          if (prevState.clickableCardState.mode !== 'playForcedDeal') {
            console.error(
              'clickableCardState mode changed from playForcedDeal before setState could modify it',
              prevState,
              clickableCardState
            )
            return null
          }

          if (
            playerGamePosition?.currentPlayer.portfolio
              .allProperties()
              .find(c => c.id === card.id)
          ) {
            // clicked my own card
            return {
              clickableCardState: {
                ...prevState.clickableCardState,
                selectedCardToOfferId:
                  prevState.clickableCardState.selectedCardToOfferId === card.id
                    ? undefined
                    : card.id,
              },
            }
          } else {
            // clicked an opponent's card
            return {
              clickableCardState: {
                ...prevState.clickableCardState,
                selectedCardToRequestId:
                  prevState.clickableCardState.selectedCardToRequestId === card.id
                    ? undefined
                    : card.id,
              },
            }
          }
        })
        break
      case 'none':
        return
      default:
        return unreachableCase(clickableCardState)
    }
  }

  selectedTableCardIds = (): Set<string> => {
    const {clickableCardState} = this.state
    switch (clickableCardState.mode) {
      case 'rotateProperty':
        return S.createOrEmpty(clickableCardState.selectedCardId)
      case 'playSlyDeal':
        return S.createOrEmpty(clickableCardState.selectedCardToRequestId)
      case 'playForcedDeal':
        return S.create(
          compact([
            clickableCardState.selectedCardToRequestId,
            clickableCardState.selectedCardToOfferId,
          ])
        )
      case 'payRent':
        return clickableCardState.selectedCardIds
      case 'none':
        return new Set()
      default:
        return unreachableCase(clickableCardState)
    }
  }

  render() {
    const {skin, rules, gameLobbySize} = this.props
    const {clickableCardIds} = this.state.clickableCardState

    const boardWrapperSize =
      sizeData[gameLobbySize].board.size + sizeData[gameLobbySize].board.framePadding

    return (
      <div>
        <div className={styles.boardAndHand}>
          <GameBoard
            classNames={{
              wrapper: classnames(
                styles.gameBoardWrapper,
                css`
                  width: ${px(boardWrapperSize)};
                  min-width: ${px(boardWrapperSize)};
                  height: ${px(boardWrapperSize)};
                `
              ),
            }}
            gameData={this.props.gameData}
            size={gameLobbySize}
            skin={skin}
            rules={rules}
            clickableCardIds={clickableCardIds}
            onCardClicked={this.cardClicked}
            selectedCardIds={this.selectedTableCardIds()}
          />

          {this.renderHandAndChoices({boardWrapperSize})}

          <div className={styles.whitespaceSpacer} />

          <GameLog pastGamePositions={this.props.allPastGamePositions} skin={skin} />
        </div>
      </div>
    )
  }
}

// depending on the game state, the player can click one, many, or no cards already
// played on the table as a part of the action they wish to take.
// e.g. if they are being asked to pay rent, they can choose multiple cards, but only
// those with a cash value. TClickableCardState carries that information.
type TClickableCardMode =
  | 'rotateProperty' // rotate SpecialPropertyCards
  | 'payRent' // choose the cards to pay a rent/etc charged against you
  | 'playSlyDeal' // choose the card to steal when playing a SlyDeal
  | 'playForcedDeal' // choose the cards to exchange when playing a ForcedDeal
  | 'none' // no cards on the table can/need to be clicked by the user atm. includes spectators (can't click anything).
type TClickableCardStateBase = {
  mode: TClickableCardMode
  clickableCardIds: Set<string>
}
interface IClickableCardStateRotateProperty extends TClickableCardStateBase {
  mode: 'rotateProperty'
  selectedCardId: string | undefined
}
interface IClickableCardStatePayRent extends TClickableCardStateBase {
  mode: 'payRent'
  selectedCardIds: Set<string>
  inProgressMajorAction: InProgressMajorAction
}
interface IClickableCardStatePlaySlyDeal extends TClickableCardStateBase {
  mode: 'playSlyDeal'
  selectedCardToRequestId: string | undefined
}
interface IClickableCardStatePlayForcedDeal extends TClickableCardStateBase {
  mode: 'playForcedDeal'
  selectedCardToOfferId: string | undefined
  selectedCardToRequestId: string | undefined
}
interface IClickableCardStateNone extends TClickableCardStateBase {
  mode: 'none' // added for spectators, also represents players who can't/don't need to take action atm
  inProgressMajorAction: InProgressMajorAction | undefined
  pendingMinorActionForMe: MinorActionPrompt<any> | undefined
}
export type TClickableCardState =
  | IClickableCardStateRotateProperty
  | IClickableCardStatePayRent
  | IClickableCardStatePlaySlyDeal
  | IClickableCardStatePlayForcedDeal
  | IClickableCardStateNone

const determineClickableCards = (
  gameData: GameData,
  rules: TRuleset,
  state: IState
): TClickableCardState => {
  const {currentGamePosition, playerGamePosition} = gameData
  if (!playerGamePosition) {
    // TODO not sure if/how the inProgressMajorAction here should work for spectators, we'll see. added it to the None
    //  type for convenience here in this file for now
    return {
      mode: 'none',
      clickableCardIds: new Set(),
      inProgressMajorAction: currentGamePosition.inProgressMajorAction,
      pendingMinorActionForMe: undefined,
    }
  }

  const {currentPlayer} = playerGamePosition

  if (playerGamePosition.inProgressMajorAction) {
    // there is an active/pending minorAction. if the action is on me, prompt me, else,
    // I can't do anything right now.
    const pendingMinorActionForMe = playerGamePosition.inProgressMajorAction.pendingMinorActions.find(
      pma => pma.minorActorPlayerId === currentPlayer.id
    )
    if (
      pendingMinorActionForMe &&
      (pendingMinorActionForMe instanceof MinorActionPromptPaySomething ||
        pendingMinorActionForMe instanceof MinorActionPromptJustSayNo)
    ) {
      const clickableCards = currentPlayer.portfolio
        .allCards()
        .filter(c => c.value)
        .map(c => c.id)
      return {
        mode: 'payRent',
        clickableCardIds: new Set(clickableCards),
        selectedCardIds: new Set(),
        inProgressMajorAction: playerGamePosition.inProgressMajorAction,
      }
    } else if (pendingMinorActionForMe) {
      return {
        mode: 'none',
        clickableCardIds: new Set(),
        inProgressMajorAction: playerGamePosition.inProgressMajorAction,
        pendingMinorActionForMe,
      }
    }
  } else if (
    playerGamePosition.isMyMajorTurn() &&
    state.currentPlayerHandSelectedCardId
  ) {
    const selectedCard = playerGamePosition.currentPlayerHand.cards.find(
      c => c.id === state.currentPlayerHandSelectedCardId
    )

    const moves = RA.ensureArray(
      selectedCard?.availableMovesFromHand({
        playerGamePosition,
        portfolio: playerGamePosition.currentPlayer.portfolio,
        rules,
      }) ?? []
    )

    if (selectedCard instanceof SlyDealCard) {
      const clickableCards = compact(
        moves.map(ma =>
          ma instanceof MajorActionSlyDeal ? ma.requestedCard.id : undefined
        )
      )
      return {
        mode: 'playSlyDeal',
        clickableCardIds: new Set(clickableCards),
        selectedCardToRequestId:
          state.clickableCardState.mode === 'playForcedDeal'
            ? state.clickableCardState.selectedCardToRequestId
            : undefined,
      }
    } else if (selectedCard instanceof ForcedDealCard) {
      const clickableCards = compact(
        moves.map(ma =>
          ma instanceof MajorActionForcedDeal
            ? [ma.requestedCard.id, ma.offeredCard.id]
            : undefined
        )
      ).flat(1)
      return {
        mode: 'playForcedDeal',
        clickableCardIds: new Set(clickableCards),
        selectedCardToRequestId:
          state.clickableCardState.mode === 'playSlyDeal'
            ? state.clickableCardState.selectedCardToRequestId
            : undefined,
        selectedCardToOfferId: undefined,
      }
    }
  }

  // seems like nothing else applies. If it is my turn, I can rotateProperty,
  // otherwise, there's no card-clicking necessary.
  if (playerGamePosition?.isMyMajorTurn()) {
    const availableTableMoves = currentPlayer.availableMovesFromTableByCardId(
      rules,
      playerGamePosition
    )
    const clickableCardIds = M.keySet(
      M.filter(arr => !!arr.length, availableTableMoves)
    )
    return {
      mode: 'rotateProperty',
      selectedCardId: undefined,
      clickableCardIds,
    }
  } else {
    return {
      mode: 'none',
      clickableCardIds: new Set(),
      inProgressMajorAction: playerGamePosition.inProgressMajorAction,
      pendingMinorActionForMe: undefined,
    }
  }
}

interface IGameLogProps {
  skin: TSkin
  pastGamePositions: NEList<PastGamePosition> | undefined
}

interface IGameLogState {
  autoscroll: boolean
}

class GameLog extends React.PureComponent<IGameLogProps, IGameLogState> {
  state: IGameLogState = {
    autoscroll: true,
  }

  // if we end up wanting this behavior here and elsewhere, https://www.npmjs.com/package/react-bottom-scroll-listener
  // worked pretty nicely, but didn't detect the `autoscroll: false` case for me, so I removed it.
  // maybe making a component like that that also handled that would be nice
  scrollRef = React.createRef<HTMLDivElement>()

  componentDidMount() {
    this.maybeAutoscroll()
  }

  componentDidUpdate(prevProps: IGameLogProps, prevState: IGameLogState) {
    this.maybeAutoscroll()
  }

  maybeAutoscroll() {
    if (
      this.scrollRef.current &&
      this.state.autoscroll &&
      !isAtEndOfScroll(this.scrollRef.current)
    ) {
      scrollToBottom(this.scrollRef.current)
    }
  }

  render() {
    const {skin, pastGamePositions} = this.props

    return (
      <div
        ref={this.scrollRef}
        className={styles.gameLogWrapper}
        onScroll={() => {
          const div = this.scrollRef.current
          if (!div) {
            return
          }

          const atEnd = isAtEndOfScroll(div)
          if (this.state.autoscroll && !atEnd) {
            this.setState({autoscroll: false})
          } else if (!this.state.autoscroll && atEnd) {
            this.setState({autoscroll: true})
          }
        }}
      >
        {pastGamePositions?.map(pgp => {
          // TODO `hh` for 12hr format if we have user settings for it
          return (
            <div key={pgp.date.valueOf() + pgp.gamePosition.time}>
              <DSText size="tiny" color="pearl-grey-darkest">
                {format(pgp.date, `[HH:mm:ss]`)}
              </DSText>{' '}
              <DSText size="tiny">
                {joinElements(
                  compact([
                    // NB: I don't think a major & minor action happening at the same time ever actually
                    // makes sense. However, the backend sometimes does this, we're looking into it.
                    // For now, the minor action usually seems to be the earlier of the two when it happens,
                    // so showing them both like this is a band-aid for now that doesn't hurt too much.
                    pgp.minorActionTaken?.userFacingDescription({
                      gamePosition: pgp.gamePosition,
                      inProgressMajorAction: pgp.gamePosition.inProgressMajorAction!,
                      majorActor: pgp.gamePosition.majorActorPlayer,
                      minorActor: pgp.gamePosition.minorActorPlayer!,
                      skin,
                      tense: 'past',
                    }),
                    pgp.majorActionTaken?.userFacingDescription({
                      gamePosition: pgp.gamePosition,
                      skin,
                      tense: 'past',
                    }),
                  ]),
                  i => (
                    <br key={i} />
                  )
                )}
              </DSText>
            </div>
          )
        })}
      </div>
    )
  }
}
