import {PostApi} from '~/api/client'
import {transformGameData} from '~/api/endpoints/game/GetGameData'
import gameDataMock from '~/api/endpoints/game/GetGameData/mock'
import {serverEndpoints} from '~/api/generated/endpoints'
import {
  TCreateMinorAction,
  TSubmitMinorActionResponse,
} from '~/api/generated/types/SubmitMinorAction'
import {TJSONError} from '~/api/types'

type Request = TCreateMinorAction
type Response = TSubmitMinorActionResponse
type PathPieces = {
  gameId: string
}

/**
 * Respond to an attack, like a rent / sly deal / etc.
 */
export default class SubmitMinorAction extends PostApi<
  Request,
  Response,
  PathPieces
> {
  protected mockRequestFailure(
    params: Request,
    pathPieces: PathPieces
  ): TJSONError | null {
    if (pathPieces.gameId === 'fail') {
      return {errors: {gameId: [`That game doesn't exist.`]}}
    }

    return super.mockRequestFailure(params, pathPieces)
  }

  protected getMockResponse(params: Request): Response {
    return {
      gameData: gameDataMock,
    }
  }

  protected getPath(pathPieces: PathPieces) {
    return serverEndpoints.submitMinorAction(pathPieces)
  }

  protected transformResponse(r: Response) {
    return {
      ...r,
      gameData: transformGameData(r.gameData),
    }
  }
}
