import {addDays, addHours, addMinutes, addSeconds} from 'date-fns'

export const daysAgo = (n: number, d: Date = new Date()): string =>
  addDays(d, -1 * n).toISOString()

export const hrsAgo = (n: number, d: Date = new Date()): string =>
  addHours(d, -1 * n).toISOString()

export const minsAgo = (n: number, d: Date = new Date()): string =>
  addMinutes(d, -1 * n).toISOString()

export const secsAgo = (n: number, d: Date = new Date()): string =>
  addSeconds(d, -1 * n).toISOString()

export const inDays = (n: number, d: Date = new Date()): string =>
  addDays(d, n).toISOString()

export const inMinutes = (n: number, d: Date = new Date()): string =>
  addMinutes(d, n).toISOString()
