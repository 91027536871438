import * as H from 'history'
import * as R from 'ramda'
import * as React from 'react'
import MockConfig from '~/api/mocks/config'

/**
 *  The H.LocationDescriptor can be a string (URL path) or an object (with React Router state/payload, etc.).
 *  This function returns the object version, regardless of the input version.
 */
export const locationDescriptorToLocationDescriptorObject = (
  locationDescriptor: H.LocationDescriptor
): H.LocationDescriptorObject => {
  if (typeof locationDescriptor === 'string') {
    return {
      pathname: locationDescriptor,
    }
  }
  return locationDescriptor
}

export const scrollToElement = (
  id: string,
  options?: ScrollLogicalPosition
): boolean => {
  // not very reactive but most of the react scroll components were
  // way more complicated then these 3 lines of code
  const element = document.getElementById(id)
  if (element) {
    const block: ScrollLogicalPosition = options || 'start'
    const inline: ScrollLogicalPosition = options || 'start'
    element.scrollIntoView({behavior: 'smooth', block, inline})
    return true
  }
  return false
}

// called when the SPA first launches.
export const spaLaunchSetup = (): void => {
  MockConfig.setupMockConfigParams()
}

/** Join ReactNode elements together, with an optional separator (like `Array.join`, but for JSX) */
export const joinElements = (
  join: Array<React.ReactNode>,
  separator?: (idx: number) => React.ReactNode
) => {
  return R.dropLast(1, R.flatten(join.map((e, i) => [e, separator?.(i)])))
}
