import * as React from 'react'
import {OmitStrict} from 'type-zoo'
import SettingsCircle from '~/components/SettingsCircle'
import BaseLayout from '~/layouts/BaseLayout'
import {NoStyleLink} from '~/routing/ReactRouterLinks'
import {ROUTE_TYPE_KEY} from '~/routing/Route'
import {
  IPropsGuaranteedBySignedInRoutes,
  ISignedInRouteComponentProps,
} from '~/routing/Route/SignedIn'
import {staticURLs} from '~/routing/sitemap'
import {safelyOr} from '~/utils'
import {RequireOptionalKeyPresence} from '~/utils/types'
import styles from './styles.module.css'

// both Route.Admin and Route.SignedInRequiresOrg use the dashboard layout right now
// and they both pass the same props, except the ROUTE_TYPE_KEY bit, so accept them both.
// NB: these are manually provided by the page using the DashboardLayout because we don't have a custom `withRouter`
//     for Route.SignedInRequiresOrg / ISignedInRequiredOrgRouteComponentProps
// you can use `isolateSignedInRouteComponentProps` to grab them easily
interface IManuallyProvidedRouteProps
  extends OmitStrict<ISignedInRouteComponentProps<any>, typeof ROUTE_TYPE_KEY> {}

interface IProps extends IManuallyProvidedRouteProps {
  title?: string
  topRightStuff?: React.ReactNode | React.ReactNode[]
}

export default class DashboardLayout extends React.Component<IProps> {
  render() {
    return <LoginAgnosticDashboardLayout {...this.props} />
  }
}

// A version of the DashboardLayout that can handle both when a user is logged in and not logged in.
export class LoginAgnosticDashboardLayout extends React.Component<
  OmitStrict<IProps, keyof IPropsGuaranteedBySignedInRoutes> &
    RequireOptionalKeyPresence<Partial<IPropsGuaranteedBySignedInRoutes>>
> {
  render() {
    return (
      <BaseLayout>
        <div className={styles.topBar}>
          <div className={styles.left}>
            <NoStyleLink to={staticURLs.dashboard}>
              <img
                className={styles.logoAndBrand}
                src="/icons/logo-and-brand-currentColor.svg"
              />
            </NoStyleLink>
          </div>
          <div className={styles.right}>
            {this.props.topRightStuff}
            {safelyOr(
              this.props.user,
              u => (
                <SettingsCircle user={u} />
              ),
              null
            )}
          </div>
        </div>
        <div className={styles.dashboardLayoutFrame}>{this.props.children}</div>
      </BaseLayout>
    )
  }
}
