import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {Menu, Item, ItemParams, contextMenu} from 'react-contexify'
import {connect} from 'react-redux'
import CardImage, {IGameCardProps} from '~/pages/GameLobby/GameCard/stateless'
import {setInspectingCardData} from '~/redux/reducers/ui'
import {unreachableCase} from '~/utils'

import 'react-contexify/dist/ReactContexify.css'

const V2_LOBBY_CARD_IMAGE_CONTEXT_MENU_ID = (cardId: string) =>
  `V2_LOBBY_CARD_IMAGE_CONTEXT_MENU_ID_${cardId}`
type TContextMenuOption = 'inspectCard'

interface IV2LobbyCardImageProps extends IGameCardProps, TDispatch {}

class V2LobbyCardImageInternal extends React.Component<IV2LobbyCardImageProps> {
  handleContextMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault()
    contextMenu.show({
      event,
      id: V2_LOBBY_CARD_IMAGE_CONTEXT_MENU_ID(this.props.model.id),
    })
  }

  handleItemClick = (option: TContextMenuOption) => (_itemParams: ItemParams) => {
    switch (option) {
      case 'inspectCard':
        this.props.setInspectingCardData(this.props.model.datasource)
        contextMenu.hideAll() // this shouldn't be necessary... but it seems to be for some cards
        return
      default:
        return unreachableCase(option)
    }
  }

  renderContextMenu() {
    return (
      <Menu
        theme="light"
        id={V2_LOBBY_CARD_IMAGE_CONTEXT_MENU_ID(this.props.model.id)}
      >
        <Item disabled={false} onClick={this.handleItemClick('inspectCard')}>
          View details...
        </Item>
      </Menu>
    )
  }

  render() {
    const {setInspectingCardData: _, ...restProps} = this.props

    // NB: the CSS `transform`s that we do on the game lobby to position it on the screen
    // ruins the `position: fixed` that the context menu does to position itself.
    // so instead, we have to render the context menu above/outside of any transforms.
    // this way, it will position the context menu with respect to the viewport as desired.
    // https://github.com/fkhadra/react-contexify/issues/27#issuecomment-926259847
    return (
      <>
        {ReactDOM.createPortal(this.renderContextMenu(), document.body)}
        <CardImage onContextMenu={this.handleContextMenu} {...restProps} />
      </>
    )
  }
}

const mapDispatchToProps = {setInspectingCardData}
type TDispatch = typeof mapDispatchToProps

const V2LobbyCardImage = connect(
  undefined,
  mapDispatchToProps
)(V2LobbyCardImageInternal)
export {V2LobbyCardImage}
