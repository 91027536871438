import * as R from 'ramda'
import {TLiquidatedCardData, TPlayerPortfolio} from '~/api/generated/types/common'
import {neighborhoodOrderMap} from '~/game/types'
import {valueOfCards} from '~/models/game/utils'
import {DataBackedModel} from '~/models/base'
import Card from '~/models/game/Card'
import CashCard from '~/models/game/Cards/CashCard'
import PropertyCard from '~/models/game/Cards/PropertyCard'
import PropertyWildCard from '~/models/game/Cards/PropertyWildCard'
import {cardFactory, portfolioNeighborhoodFactory} from '~/models/game/factories'
import PortfolioNeighborhood from '~/models/game/PortfolioNeighborhood'
import StandardPortfolioNeighborhood from '~/models/game/PortfolioNeighborhoods/StandardPortfolioNeighborhood'
import {AnyCard, AnyLiquidatedCard, AnyValuableCard} from '~/models/game/types'
import {NEList} from '~/utils/NEList'
import {isInstance} from '~/utils/types'

// Represents the cards on the table in front of each player
export default class Portfolio extends DataBackedModel<TPlayerPortfolio> {
  public liquidatedCards: AnyLiquidatedCard[]
  public neighborhoods: PortfolioNeighborhood[]
  public playerId: string

  constructor(data: TPlayerPortfolio) {
    super(data)
    this.playerId = data.playerId
    this.neighborhoods = data.neighborhoods.map(portfolioNeighborhoodFactory)
    this.liquidatedCards = data.cashPile.map((c: TLiquidatedCardData) => {
      return cardFactory(c)
    })
  }

  public cashBalance(): number {
    return valueOfCards(this.liquidatedCards)
  }

  /* cash balance + property values */
  public totalValue(): number {
    return valueOfCards(this.allValuableCards())
  }

  public allCards(): AnyCard[] {
    return [
      ...this.sortedNeighborhoods()
        .map(n => [...n.properties, ...n.residences])
        .flat(1),
      ...this.liquidatedCards,
    ]
  }

  public allValuableCards(): AnyValuableCard[] {
    return this.allCards().filter(
      (c: Card<any>): c is AnyValuableCard => !(c instanceof PropertyWildCard)
    )
  }

  public allProperties(): PropertyCard<any>[] {
    return this.sortedNeighborhoods()
      .map(n => n.properties)
      .flat(1)
  }

  public completeSets(): PortfolioNeighborhood[] {
    return this.sortedNeighborhoods().filter(n => n.isCompleteSet())
  }

  public incompleteSets(): PortfolioNeighborhood[] {
    return this.sortedNeighborhoods().filter(n => !n.isCompleteSet())
  }

  public propertiesNotInSets(): PropertyCard<any>[] {
    return this.sortedNeighborhoods()
      .filter(n => !n.isCompleteSet())
      .map(pn => pn.properties)
      .flat(1)
  }

  public standardNeighborhoods = (): StandardPortfolioNeighborhood[] => {
    return this.sortedNeighborhoods().filter(
      isInstance(StandardPortfolioNeighborhood)
    )
  }

  public sortedNeighborhoods = () => {
    return R.sortWith<PortfolioNeighborhood>(
      [
        R.ascend<PortfolioNeighborhood>(
          pn => neighborhoodOrderMap.get(pn.neighborhood) ?? 0
        ),
        // complete sets first, then by ID to keep the list order stable
        R.descend(pn => pn.isCompleteSet()),
        R.ascend(pn => pn.id),
      ],
      this.neighborhoods
    )
  }

  /** Returns a list of lists of cash cards, grouped by cash value, and stable-sorted within each group. */
  public sortedCashPile = (): NEList<AnyLiquidatedCard>[] => {
    return R.groupWith<AnyLiquidatedCard>(
      (c1, c2) => c1.value === c2.value,
      R.sortWith(
        [
          R.descend<AnyLiquidatedCard>(lc => lc.value),
          R.ascend<AnyLiquidatedCard>(lc => lc instanceof CashCard),
          // just sort by card type and version to get the same order every time here
          R.ascend<AnyLiquidatedCard>(lc => lc.type),
          R.ascend<AnyLiquidatedCard>(lc => lc.version),
        ],
        this.liquidatedCards
      )
    ) as NEList<AnyLiquidatedCard>[]
  }
}
