import * as React from 'react'
import SettingsCircle from '~/components/SettingsCircle'
import BaseLayout from '~/layouts/BaseLayout'
import {NoStyleLink} from '~/routing/ReactRouterLinks'
import {ISignedInRouteComponentProps} from '~/routing/Route/SignedIn'
import {staticURLs} from '~/routing/sitemap'
import styles from './styles.module.css'

interface IProps extends ISignedInRouteComponentProps<any> {}

export default class GameLobbyV2Layout extends React.Component<IProps> {
  render() {
    return (
      <BaseLayout>
        <div className={styles.topBar}>
          <div className={styles.left}>
            <NoStyleLink to={staticURLs.dashboard}>
              <img
                className={styles.logoAndBrand}
                src="/icons/logo-and-brand-currentColor.svg"
              />
            </NoStyleLink>
          </div>
          <div className={styles.right}>
            <SettingsCircle user={this.props.user} />
          </div>
        </div>
        <div className={styles.gameLobbyFrame}>{this.props.children}</div>
      </BaseLayout>
    )
  }
}
