import {PostApi} from '~/api/client'
import GetWaitingRoomData from '~/api/endpoints/game/PreGame/GetWaitingRoomData'
import globalDataMock from '~/api/endpoints/GetInitialData/mock'
import {serverEndpoints} from '~/api/generated/endpoints'
import {TJSONError} from '~/api/types'

export type TJoinGameResponse = {}

export type TJoinGameParams = {
  // password?: string // TODO probably something optional like this?
}

export type TJoinGamePathPieces = {
  gameId: string
}

/**
 * Join a game lobby before it begins.
 */
export default class JoinGame extends PostApi<
  TJoinGameParams,
  TJoinGameResponse,
  TJoinGamePathPieces
> {
  protected mockRequestFailure(
    params: TJoinGameParams,
    pathPieces: TJoinGamePathPieces
  ): TJSONError | null {
    if (pathPieces.gameId === 'fail') {
      return {errors: {gameId: [`That game doesn't exist.`]}}
    }

    return super.mockRequestFailure(params, pathPieces)
  }

  protected getMockResponse(params: TJoinGameParams): TJoinGameResponse {
    GetWaitingRoomData.resetMockPlayerCount()
    return globalDataMock
  }

  protected getPath(pathPieces: TJoinGamePathPieces) {
    return serverEndpoints.joinGame(pathPieces)
  }

  protected transformResponse(r: TJoinGameResponse) {
    return r
  }
}
