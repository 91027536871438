import {TBetterMinorActionDataAcquiesce} from '~/game/types'
import Card from '~/models/game/Card'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MinorAction, {
  TMinorActionUserFacingDescriptionData,
} from '~/models/game/MinorAction'
import {sentenceCase, xVerbY} from '~/utils/language'

// Agree to a JustSayNo played against your own MajorAction
export default class MinorActionAcquiesce extends MinorAction<
  TBetterMinorActionDataAcquiesce
> {
  userFacingDescription({
    tense,
    majorActor,
    minorActor,
    skin,
    inProgressMajorAction,
    gamePosition,
  }: TMinorActionUserFacingDescriptionData): string {
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined

    // NB: inProgressMajorAction's majorActor & minorActor are both the player who acquiesced
    // here, since you can only Acquiesce if your original attack action is JSN'd (once, or w/ the third JSN).

    const otherPlayer = gamePosition.players.find(
      p => p.id === this.datasource.prevailingPlayerId
    )

    if (!otherPlayer) {
      console.error(`Error data:`, {
        majorActor,
        minorActor,
        inProgressMajorAction,
        gamePosition,
      })
      throw new Error(
        `No 'other player' in a MinorActionAcquiesce! There should be.`
      )
    }

    const {conjugatedVerb, xNoun, yNounPossessive} = xVerbY(
      tense,
      minorActor,
      tense === 'imperativePrompt' ? 'Accept' : 'accept',
      otherPlayer,
      currentPlayer?.id
    )

    return [
      ...(tense === 'imperativePrompt'
        ? [] // 'Accept otherPerson's Just Say No' instead of 'You accept ...'
        : [xNoun === minorActor.username ? xNoun : sentenceCase(xNoun)]),
      conjugatedVerb,
      yNounPossessive,
      Card.displayNameForCard('justSayNo', skin),
    ].join(' ')
  }
}
