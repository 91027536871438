import {routerMiddleware} from 'connected-react-router'
import {createBrowserHistory} from 'history'
import {applyMiddleware, createStore} from 'redux'
import {enableBatching} from 'redux-batched-actions'
import {composeWithDevTools} from 'redux-devtools-extension'
import {createRootReducer} from '~/redux/reducers/root'

export const history = createBrowserHistory({})

const store = createStore(
  enableBatching(createRootReducer(history)),
  composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history) // for dispatching history actions to Redux
    )
  )
)

export default store
