import {PostApi} from '~/api/client'
import {transformGameData} from '~/api/endpoints/game/GetGameData'
import {gameDataMockAtTime} from '~/api/endpoints/game/GetGameData/mock'
import {serverEndpoints} from '~/api/generated/endpoints'
import {
  TCreateMajorAction,
  TSubmitMajorActionResponse,
} from '~/api/generated/types/SubmitMajorAction'
import MockConfig from '~/api/mocks/config'
import {TJSONError} from '~/api/types'

type Request = TCreateMajorAction
type Response = TSubmitMajorActionResponse
type PathPieces = {
  gameId: string
}

/**
 * "Take a turn"
 */
export default class SubmitMajorAction extends PostApi<
  Request,
  Response,
  PathPieces
> {
  protected mockRequestFailure(
    params: Request,
    pathPieces: PathPieces
  ): TJSONError | null {
    if (pathPieces.gameId === 'fail') {
      return {errors: {gameId: [`That game doesn't exist.`]}}
    }

    return super.mockRequestFailure(params, pathPieces)
  }

  protected getMockResponse(params: Request): Response {
    return {
      gameData: gameDataMockAtTime(++MockConfig.time),
    }
  }

  protected getPath(pathPieces: PathPieces) {
    return serverEndpoints.submitMajorAction(pathPieces)
  }

  protected transformResponse(r: Response) {
    return {
      ...r,
      gameData: transformGameData(r.gameData),
    }
  }
}
