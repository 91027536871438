import classnames from 'classnames'
import * as React from 'react'
import {OmitStrict} from 'type-zoo'
import {skins, TSkin} from '~/game/skins'
import Card from '~/models/game/Card'
import styles from './styles.module.css'

// eslint-disable-next-line @typescript-eslint/naming-convention
const CARD_WxH_RATIO = 250 / 407
export const cardHeightFromWidth = (width: number): number => width / CARD_WxH_RATIO

type TImageProps = OmitStrict<
  React.ImgHTMLAttributes<HTMLImageElement>,
  'src' | 'className'
>

export interface IGameCardProps extends TImageProps {
  model: Card<any>
  invert: boolean
  skin: TSkin
  className?: string
  roundedCorners?: boolean
}

const CardImage = (props: IGameCardProps) => {
  const {model, invert, skin, className, roundedCorners, ...imgProps} = props
  return (
    <img
      {...imgProps}
      className={classnames(
        styles.cardImage,
        roundedCorners && styles.roundedCorners,
        className,
        invert && styles.upsideDown
      )}
      src={model.imagePath(skin)}
    />
  )
}

export default CardImage

interface IGameCardBackProps extends TImageProps {
  skin: TSkin
  className?: string
  roundedCorners?: boolean
}

export const CardBackImage = (props: IGameCardBackProps) => {
  const {skin, className, roundedCorners, ...imgProps} = props
  return (
    <img
      {...imgProps}
      className={classnames(
        styles.cardImage,
        roundedCorners && styles.roundedCorners,
        className
      )}
      src={`/cards/${skin}/${skins[skin].backOfCardFilename}`}
    />
  )
}
