import {OmitStrict} from 'type-zoo'
import {TMajorActionDealBreakerData} from '~/api/generated/types/common'
import {DealBreakerCard} from '~/models/game/Cards/ActionCard'
import {PlayerGamePosition} from '~/models/game/GamePosition'
import MajorAction, {
  TMajorActionUserFacingDescriptionData,
} from '~/models/game/MajorAction'
import {unambiguousNeighborhoodDescriptionData} from '~/models/game/MajorActions/utils'
import {compact} from '~/utils'
import {sentenceCase, xVerbY} from '~/utils/language'

type PublicMembersFromApi = OmitStrict<TMajorActionDealBreakerData, 'card' | 'type'>

export default class MajorActionDealBreaker
  extends MajorAction
  implements PublicMembersFromApi {
  card: DealBreakerCard
  minorActorPlayerId: string // the attacked player
  portfolioNeighborhoodId: string // the to-be-deal-broken neighborhood

  constructor(data: TMajorActionDealBreakerData) {
    super(data)
    this.card = new DealBreakerCard(data.card)
    this.minorActorPlayerId = data.minorActorPlayerId
    this.portfolioNeighborhoodId = data.portfolioNeighborhoodId
  }

  public userFacingDescription({
    tense,
    skin,
    gamePosition,
  }: TMajorActionUserFacingDescriptionData): string {
    const currentPlayer =
      gamePosition instanceof PlayerGamePosition
        ? gamePosition.currentPlayer
        : undefined
    const majorActor = gamePosition.majorActorPlayer
    const minorActor = gamePosition.players.find(
      o => o.id === this.minorActorPlayerId
    )
    if (!minorActor) {
      throw new Error(`Minor actor not found in MajorActionSlyDeal description`)
    }

    const requestedPortfolioNeighborhood = minorActor.portfolio.neighborhoods.find(
      pn => pn.id === this.portfolioNeighborhoodId
    )

    if (!requestedPortfolioNeighborhood) {
      throw new Error(
        `Deal Breaker's requested PortfolioNeighborhood not found in minor actor's portfolio`
      )
    }

    const {sameColorCount, sameColorIndex} = unambiguousNeighborhoodDescriptionData(
      minorActor.portfolio,
      requestedPortfolioNeighborhood
    )
    const dropFirstNoun = tense === 'imperativePrompt'

    const {conjugatedVerb, xNoun, yNounPossessive} = xVerbY(
      tense,
      majorActor,
      dropFirstNoun ? 'Play' : 'play',
      minorActor,
      currentPlayer?.id
    )

    return compact([
      dropFirstNoun
        ? undefined
        : xNoun === majorActor.username
        ? xNoun
        : sentenceCase(xNoun),
      conjugatedVerb,
      this.card.displayName(skin),
      'against',
      yNounPossessive,
      requestedPortfolioNeighborhood.displayColor(skin),
      'set',
      sameColorCount > 1 ? `#${sameColorIndex + 1}` : undefined,
    ]).join(' ')
  }
}
