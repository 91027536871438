import {OmitStrict} from 'type-zoo'

export type TReactSetStateStateParameter<S, K extends keyof S, P> =
  | ((prevState: Readonly<S>, props: Readonly<P>) => Pick<S, K> | S | null)
  | (Pick<S, K> | S | null)

/** The type of a `this.setState` function in React. React doesn't export this; it's useful to replicate it. */
export type TReactSetState<S, K extends keyof S, P> = (
  state: TReactSetStateStateParameter<S, K, P>,
  callback?: () => void
) => void

/** My own version of how setState can be legally called. I've had trouble using TReactSetState before without a cast */
export type TReactSetStateWithPartial<S> = (
  state: Partial<S> | null,
  callback?: () => void
) => void

/**
 * Makes some keys K of T optional, while leaving the other keys as-is
 * e.g. PartialPartial<{foo: string, bar: number}, 'bar'> = {foo: string, bar?: number}
 */
export type PartialPartial<T, K extends keyof T> = OmitStrict<T, K> &
  Partial<Pick<T, K>>

/**
 * Given an object type, this removes all key optionality (`?`) and replaces it with `| undefined`.
 * Converts a type like {foo?: string, bar: number} to {foo: string | undefined, bar: number}
 *
 * see https://github.com/microsoft/TypeScript/issues/31025#issuecomment-484734942
 * for why this `& keyof any` hack works / is necessary.
 */
export type RequireOptionalKeyPresence<O> = {[K in keyof O & keyof any]: O[K]}

/**
 * Returns a type representing all property names of a given type O that have a value that extends the given type T.
 * e.g. PropertiesWithType<{foo: string, bar: number, baz: boolean}, boolean> = 'baz'
 */
export type PropertiesWithType<O, T> = {
  [K in keyof O]: O[K] extends T ? K : never
}[keyof O]

// a nice wrapper around instanceof checks that gets TS to recognize you've narrowed the type of an object/list/etc.
// without having to be explicit with generics/types
// e.g. `abstractClassList.filter(isInstance(ConcreteClass))`
// instead of abstractClassList.filter((a: AbstractClass): a is ConcreteClass => a instanceof ConcreteClass)
// https://github.com/microsoft/TypeScript/issues/38454
// https://github.com/microsoft/TypeScript/issues/5101
export function isInstance<T>(ctor: new (...args: any[]) => T): (x: any) => x is T {
  return (x: any): x is T => x instanceof ctor
}

/* eslint-disable max-len */
/* prettier-ignore */
// Enumerate/Range taken from https://github.com/microsoft/TypeScript/issues/15480#issuecomment-601714262
// NB: apparently only works for ranges w/ about 40 numbers.
type PrependNextNum<A extends Array<unknown>> = A['length'] extends infer T ? ((t: T, ...a: A) => void) extends ((...x: infer X) => void) ? X : never : never
type EnumerateInternal<A extends Array<unknown>, N extends number> = {
  0: A
  1: EnumerateInternal<PrependNextNum<A>, N>
}[N extends A['length'] ? 0 : 1]
export type Enumerate<N extends number> = EnumerateInternal<
  [],
  N
> extends (infer E)[]
  ? E
  : never
export type Range<FROM extends number, TO extends number> = Exclude<
  Enumerate<TO>,
  Enumerate<FROM>
>
/* eslint-enable max-len */
